import { useEffect, useState } from "react";
import PrescriptionHeader from "./PrescriptionHeader";
import { errorToastMessage, toastMessage } from "../../../../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../../../../utils/http";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
} from "../../style";
import {
  Add,
  Delete,
  DoNotDisturb,
  Edit,
  EditNotifications,
  ExpandMore,
  ReadMore,
} from "@mui/icons-material";
import PrescriptionItem from "./PrescriptionItem";
import { NoDataContainer } from "../../../../../Common/styles/table";
import AddPrescriptionModal from "./AddPrescriptionModal";
import { DateTime } from "luxon";
import BasicDetailsModal from "./BasicDetailsModal";
import EditReminderModal from "./EditReminderModal";
import DetailsModal from "./DetailsModal";

const RoleMap: any = {
  participant: "Patient",
  caregiver: "Caregiver",
  doctor: "Doctor",
};

const Prescription = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showBasicDetailModal, setShowBasicDetailModal] =
    useState<boolean>(false);
  const [showReminderModal, setShowReminderModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const { id } = useParams();
  const [selectedEntry, setSelectedEntry] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchPrescriptionData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/medication/prescribe?userId=${id}`
        );
        const data = res.data.data;
        const startOfToday = DateTime.now().startOf("day");
        const values = Object.keys(data)
          .sort()
          .reverse()
          .map((key: any) => {
            const entries: any[] = [];
            data[key].forEach((entry: any) => {
              let time = "";
              entry?.reminder?.forEach((reminder: any) => {
                reminder?.timings?.forEach((timing: any) => {
                  const timeStr = DateTime.fromFormat(
                    timing?.timing,
                    "HH:mm:ss"
                  ).toFormat("hh:mm a");
                  time = time ? time + ", " + timeStr : timeStr;
                });
              });
              let reminder;
              if (entry?.reminder[0]) {
                const rem = entry?.reminder[0];
                reminder = {
                  frequency: rem.frequency,
                  startDate: rem?.startDate
                    ? DateTime.fromISO(rem.startDate).toFormat("dd/MM/yyyy")
                    : "-",
                  endDate: rem?.endDate
                    ? DateTime.fromISO(rem.endDate).toFormat("dd/MM/yyyy")
                    : null,
                  daysEnabled: rem?.daysEnabled || null,
                  dayTimings:
                    rem?.timings?.map((timing: any) => {
                      return {
                        timing: DateTime.fromFormat(
                          timing?.timing,
                          "HH:mm:ss"
                        ).toFormat("HH:mm"),
                        isReminderEnabled: timing.isReminderEnabled,
                      };
                    }) || null,
                };
              }

              entries.push({
                id: entry.id,
                medicationId: entry.medicationId,
                name: entry.name,
                strength: entry.strength,
                notes: entry.notes,
                startDate: entry?.startDate
                  ? DateTime.fromISO(entry.startDate).toFormat("dd-MM-yyyy")
                  : "-",
                endDate: entry?.endDate
                  ? DateTime.fromISO(entry.endDate).toFormat("dd-MM-yyyy")
                  : "-",
                prescribedBy: RoleMap[entry?.createdUserRole],
                started: startOfToday >= DateTime.fromISO(entry.startDate),
                ended: startOfToday >= DateTime.fromISO(entry.endDate),
                time: time || "-",
                reminder,
              });
            });

            return {
              entries: entries,
              dayLabel: DateTime.fromFormat(key || "", "yyyy-LL-dd").toFormat(
                "cccc"
              ),
              dateLabel: DateTime.fromFormat(key || "", "yyyy-LL-dd").toFormat(
                "dd LLLL yyyy"
              ),
            };
          });
        setData(values || []);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchPrescriptionData();
  }, [id, setLoading, setData, toggle]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    closeMenu();
  };

  const openBasicDetailModal = () => {
    setShowBasicDetailModal(true);
  };
  const closeBasicDetailModal = () => {
    setShowBasicDetailModal(false);
    closeMenu();
  };

  const openEditReminderModal = () => {
    setShowReminderModal(true);
  };
  const closeEditReminderModal = () => {
    setShowReminderModal(false);
    closeMenu();
  };

  const openDetailsModal = () => {
    setShowDetailsModal(true);
  };
  const closeDetailsModal = () => {
    setShowDetailsModal(false);
    closeMenu();
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>, entry: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedEntry(entry);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedEntry(null);
  };

  const handleAction = async (action: string) => {
    try {
      if (action === "viewDetails" && selectedEntry?.id) {
        openDetailsModal();
      } else if (action === "editBasicDetail" && selectedEntry?.id) {
        openBasicDetailModal();
      } else if (action === "editReminder" && selectedEntry?.id) {
        openEditReminderModal();
      } else if (action === "endMedication" && selectedEntry?.id) {
        setMenuLoader(true);
        const res: AxiosResponse = await http.patch(
          `/medication/${selectedEntry?.id}/end-medication`
        );
        setMenuLoader(false);
        toastMessage("success", res.data.message);
        closeMenu();
        refreshPage();
      } else if (action === "delete" && selectedEntry?.id) {
        setMenuLoader(true);
        const res: AxiosResponse = await http.delete(
          `/medication/${selectedEntry?.id}`
        );
        setMenuLoader(false);
        toastMessage("success", res.data.message);
        closeMenu();
        refreshPage();
      }
    } catch (err) {
      errorToastMessage(err as Error);
      setMenuLoader(false);
    }
  };

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };

  return (
    <>
      <PrescriptionHeader openModal={openModal} />
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={25} />
        </Box>
      ) : data.length > 0 ? (
        <>
          {data.map((item: any) => {
            return (
              <Accordion
                key={item?.dateLabel}
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === `panel${item.dateLabel}`}
                onChange={handleChange(`panel${item.dateLabel}`)}
                sx={JournalAccordion}
              >
                <AccordionSummary
                  expandIcon={
                    <IconButton>
                      <ExpandMore sx={{ fontSize: 24 }} />
                    </IconButton>
                  }
                  sx={{
                    ".Mui-expanded": {
                      m: "12px 0px !important",
                    },
                  }}
                >
                  <Box sx={JournalAccordionHeaderContainer}>
                    <Box sx={JournalAccordionHeader}>
                      <Box>
                        <Typography
                          fontWeight={600}
                          fontSize={"22px"}
                          color={"#355962"}
                          mb={0.5}
                        >
                          {item?.dateLabel}
                        </Typography>
                        <Typography
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#355962"}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {item?.dayLabel}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    flexWrap: "wrap",
                    mb: 2,
                  }}
                >
                  {item.entries.map((entry: any) => {
                    return (
                      <PrescriptionItem
                        key={entry?.id}
                        entry={entry}
                        openMenu={openMenu}
                      />
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      ) : (
        <NoDataContainer
          sx={{
            height: "calc(100% - 104px)",
            borderRadius: "12px",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Typography variant="subtitle1" fontWeight="medium" color="#4B5563">
            No Prescribed medications
          </Typography>
          <Button variant="outlined" onClick={openModal} startIcon={<Add />}>
            Add
          </Button>
        </NoDataContainer>
      )}

      {showModal && (
        <AddPrescriptionModal
          showModal={showModal}
          closeModal={closeModal}
          entry={selectedEntry}
          refreshPage={refreshPage}
          userId={id}
        />
      )}

      {showBasicDetailModal && (
        <BasicDetailsModal
          showModal={showBasicDetailModal}
          closeModal={closeBasicDetailModal}
          entry={selectedEntry}
          refreshPage={refreshPage}
          userId={id}
        />
      )}

      {showReminderModal && (
        <EditReminderModal
          showModal={showReminderModal}
          closeModal={closeEditReminderModal}
          entry={selectedEntry}
          refreshPage={refreshPage}
          userId={id}
        />
      )}

      {showDetailsModal && (
        <DetailsModal
          showModal={showDetailsModal}
          closeModal={closeDetailsModal}
          entry={selectedEntry}
          refreshPage={refreshPage}
          userId={id}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem
          key="viewDetails"
          onClick={() => {
            handleAction("viewDetails");
          }}
        >
          <ListItemIcon>
            <ReadMore fontSize="small" />
          </ListItemIcon>
          <ListItemText>View Details</ListItemText>
        </MenuItem>
        {selectedEntry && !selectedEntry?.ended && (
          <MenuItem
            key="editBasicDetail"
            onClick={() => {
              handleAction("editBasicDetail");
            }}
            disabled={menuLoader}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit Basic Details</ListItemText>
          </MenuItem>
        )}
        {selectedEntry && !selectedEntry?.ended && (
          <MenuItem
            key="editReminder"
            onClick={() => {
              handleAction("editReminder");
            }}
            disabled={menuLoader}
          >
            <ListItemIcon>
              <EditNotifications fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit Reminder</ListItemText>
          </MenuItem>
        )}
        {selectedEntry && selectedEntry?.started && !selectedEntry?.ended && (
          <MenuItem
            key="endMedication"
            onClick={() => {
              handleAction("endMedication");
            }}
            disabled={menuLoader}
          >
            <ListItemIcon>
              <DoNotDisturb fontSize="small" />
            </ListItemIcon>
            <ListItemText>End Medication</ListItemText>
          </MenuItem>
        )}
        {selectedEntry && (
          <MenuItem
            key="delete"
            onClick={() => {
              handleAction("delete");
            }}
            disabled={menuLoader}
          >
            <ListItemIcon>
              <Delete fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
            {menuLoader && (
              <ListItemIcon>
                <CircularProgress size={18} sx={{ ml: 1 }} />
              </ListItemIcon>
            )}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default Prescription;

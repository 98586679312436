import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Add, ChevronLeft } from "@mui/icons-material";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../../../../Common/styles/header";

const PrescriptionHeader = ({ openModal }: any) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <StyledHeader sx={{ mb: 3 }}>
      <Box sx={{ ...HeaderLeftContent, gap: 0 }}>
        <IconButton onClick={handleBack}>
          <ChevronLeft fontSize="large" htmlColor="#111928" />
        </IconButton>
        <Typography fontSize={22} fontWeight="medium" color="#355962">
          Prescribe Medication
        </Typography>
      </Box>
      <Box sx={HeaderRightContent}>
        <Button variant="contained" startIcon={<Add />} onClick={openModal}>
          Add Prescribe Medication
        </Button>
      </Box>
    </StyledHeader>
  );
};

export default PrescriptionHeader;

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import participantsSlice from "./reducers/participantsSlice";
import userSlice from "./reducers/userSlice";
import administratorsSlice from "./reducers/administratorsSlice";
import chatbotSlice from "./reducers/chatbotSlice";
import onboardingBuilderSlice from "./reducers/onboardingSlice";
import cmsSlice from "./reducers/cmsSlice";
import cloudSlice from "./reducers/cloudSlice";
import chatbotBuilderSlice from "./reducers/chatbotBuilderSlice";
import cmsBuilderSlice from "./reducers/cmsBuilderSlice";
import cmsBuilderV2Slice from "./reducers/cmsBuilderV2Slice";
import questionSlice from "./reducers/questionSlice";
import questionListSlice from "./reducers/questionListSlice";
import exerciseCollectionSlice from "./reducers/exerciseCollectionSlice";
import healthSlice from "./reducers/healthSlice";
import notificationSlice from "./reducers/notificationSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    participants: participantsSlice,
    administrators: administratorsSlice,
    chatbot: chatbotSlice,
    chatbotBuilder: chatbotBuilderSlice,
    onboarding: onboardingBuilderSlice,
    cms: cmsSlice,
    exerciseCollection: exerciseCollectionSlice,
    cloud: cloudSlice,
    cmsBuilder: cmsBuilderSlice,
    cmsBuilderV2: cmsBuilderV2Slice,
    question: questionSlice,
    quesList: questionListSlice,
    health: healthSlice,
    notification: notificationSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { SxProps } from "@mui/material";

export const BuilderWrapper: SxProps = {
  display: "flex",
  flexDirection: "column",
  gap: 3,
  paddingBlock: 3,
};

export const CardStyle: SxProps = {
  bgcolor: "#fff",
  borderRadius: 2,
  padding: 2.5,
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
};

export const CardAvatar: SxProps = {
  bgcolor: "#D9D9D9",
  borderRadius: "8px",
  width: "60px",
  height: "60px",
};

export const gridWrapper: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(362px, 1fr))",
  gap: "12px",
  mb: 2.5,
};

export const BorderedBox: SxProps = {
  display: "flex",
  alignItems: "center",
  gap: 2,
  minHeight: "100px",
  backgroundColor: "#FFF",
  padding: "12px",
  borderRadius: "8px",
  border: "1px solid  #E0E3EB",
};

export const ChipStyle: SxProps = {
  fontSize: 14,
  fontWeight: 500,
  paddingBlock: "20px",
  paddingRight: "7px",
  backgroundColor: "#E3F1F4",
  color: "text.primary",
  border: "none",
  borderRadius: "8px",
  textTransform: "capitalize",
  "& .MuiSvgIcon-root": {
    color: "#374151",
    fontSize: "16px",
  },
};

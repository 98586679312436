import webviewHttp from "./webviewHttp";

export const addBookmark = async (body: any, lessonId: string) => {
  await webviewHttp.post(`/lmsV2/lessons/${lessonId}/bookmarks`, body);
};

export const removeBookmark = async (pageId: any, lessonId: string) => {
  await webviewHttp.delete(
    `/lmsV2/lessons/${lessonId}/bookmarks?pageId=${pageId}`
  );
};

export const getBookmarks = async (lessonId: any) => {
  const id = localStorage.getItem("userId") || "";
  const res = await webviewHttp.get(
    `/lmsV2/lessons/${lessonId}/bookmarks?userId=${id}`
  );
  return res;
};

import React, { useState } from "react";
import { indxToAlpha } from "../../../utils/education";

type Props = {
  choices: any;
};

const PollPreview: React.FC<Props> = ({ choices }) => {
  const [val, setValue] = useState<any>();
  const changeHandler = (id: string) => {
    setValue(id);
  };

  return (
    <>
      <div className="question-choices">
        {choices &&
          choices.map((choice: any, index: number) => {
            let text = "";
            if (val) {
              if (val === choice.id) {
                text = "100%";
              } else {
                text = "0%";
              }
            }
            return (
              <div
                key={choice.id}
                className={
                  val === choice.id
                    ? "question-choice selected"
                    : "question-choice"
                }
                onClick={() => changeHandler(choice.id)}
              >
                {choice?.image && (
                  <img
                    src={choice.image}
                    className="question-choice-image"
                    alt="choice"
                  />
                )}
                <span>{indxToAlpha(index) + ". " + choice?.title}</span>
                {text && <span className="aggregate">{text}</span>}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PollPreview;

/* global __PROPERTIES__ */
import defaultAxios from 'axios';
import mapKeys from 'lodash/fp/mapKeys';
import pick from 'lodash/fp/pick';
import cloneDeep from 'lodash/cloneDeep';
import createLogger from './log';
import prependProxyToUrlIfNeeded from '../helpers/proxyUrlHelper';

const logging = createLogger('StaticConfig');

/**
 * @typedef {Object} builtInConfig properties that are baked in and are injected through webpack
 * @property {String} version Build version (comes from package.json)
 * @property {String} buildHash Commit hash this build was built from
 * @property {Object} minimumVersion
 * @property {String} minimumVersion.firefox Minimum version of Firefox needed to support OpenTok
 * @property {String} minimumVersion.chrome Minimum version of Chrome needed to support OpenTok
 * @property {Boolean} debug If true sets logging level to DEBUG else sets it to WARN
 * @property {String} websiteURL Used to construct urls to the TokBox website
 * @property {String} loggingURL Where to send analytical events
 * @property {String} apiURL The API to talk to (Anvil)
*/

/** @type builtInConfig */
const builtInConfig = cloneDeep(__PROPERTIES__);

const whitelistAllowedRuntimeProperties = pick([
  'apiURL',
  'assetURL',
  'cdnURL',
  'sessionInfoOverrides',
  'loggingURL',
]);

const liveConfigMap = {
  apiUrl: 'apiURL',
  loggingUrl: 'loggingURL',
};

const normalizeLiveConfig = mapKeys(key => liveConfigMap[key]);

function staticConfigFactory({ axios = defaultAxios } = {}) {
  /**
   * @class StaticConfig
   */
  class StaticConfig {
    static onlyLocal() {
      const runtimeProperties = cloneDeep(global.OTProperties);
      return new StaticConfig({
        ...builtInConfig,
        ...whitelistAllowedRuntimeProperties(runtimeProperties),
      });
    }
    /**
     * Construct a StaticConfig instance with baked in, runtime, and live configuration
     *
     * @static
     * @param {any} { sessionId, token, useIpWhitelistConfigUrl }
     * @memberof StaticConfig
     * @return {Promise<StaticConfig>} A promise to an instance of StaticConfig
     */
    static get({ partnerId, token, useIpWhitelistConfigUrl, proxyUrl }) {
      const getLiveConfig = () => {
        const localStaticConfig = this.onlyLocal();

        if (!localStaticConfig.configUrl) {
          return Promise.resolve({});
        }

        if ((useIpWhitelistConfigUrl === true) && !localStaticConfig.ipWhitelistConfigUrl) {
          return Promise.resolve({});
        }

        let finalConfigUrl = (useIpWhitelistConfigUrl === true) ?
          localStaticConfig.ipWhitelistConfigUrl : localStaticConfig.configUrl;
        finalConfigUrl = prependProxyToUrlIfNeeded(finalConfigUrl, proxyUrl);

        return axios.get(`${finalConfigUrl}/project/${partnerId}/config.json`, { headers: { 'X-TB-TOKEN-AUTH': token } })
          .then(({ data }) => data);
      };

      return getLiveConfig()
        .catch((err) => {
          logging.error('could not reach live config service', err);
          return {};
        })
        .then((liveConfig) => {
          const runtimeProperties = cloneDeep(global.OTProperties);
          const config = {
            ...builtInConfig,
            ...whitelistAllowedRuntimeProperties(runtimeProperties),
            ...normalizeLiveConfig(liveConfig),
          };
          return new StaticConfig(config);
        });
    }
    constructor(config) {
      Object.defineProperty(this, 'config', { value: Object.freeze(cloneDeep(config)) });
    }
    get configUrl() {
      return this.config.configURL;
    }
    get ipWhitelistConfigUrl() {
      return this.config.ipWhitelistConfigURL;
    }
    get apiUrl() {
      return this.config.apiURL;
    }
    get loggingUrl() {
      return this.config.loggingURL;
    }
    get apiEnabled() {
      return typeof this.config.apiEnabled !== 'undefined' ? this.config.apiEnabled : true;
    }
    get version() {
      return this.config.version;
    }
    get clientVersion() {
      return `js-${(this.version || 'unknown').replace('v', '')}`;
    }
    get buildHash() {
      return this.config.buildHash;
    }
    get minimumVersion() {
      return this.config.minimumVersion;
    }
    get websiteUrl() {
      return this.config.websiteURL;
    }
    get debug() {
      return this.config.debug === 'true' || this.config.debug === true;
    }
    get sessionInfoOverrides() {
      return this.config.sessionInfoOverrides;
    }
    get cdnUrl() {
      return this.config.cdnURL || `${global.location.protocol}//${global.location.host}`;
    }
    get assetUrl() {
      return this.config.assetURL || `${this.cdnUrl}/webrtc/${this.version}`;
    }
  }
  return StaticConfig;
}

export default staticConfigFactory;

import { Box } from "@mui/material";
import React from "react";
import { QuestionStatementStyle, QuestionTitleStyle } from "../QBPreview.style";

export const StatementType: React.FC<any> = ({ q }) => {
  return (
    <Box sx={QuestionStatementStyle} key={q.id}>
      <Box sx={QuestionTitleStyle}>{q.title}</Box>
      {/* {q.attachment && (
        <span
          className="ms-3 color-primary cp"
          onClick={() => viewImage(q.attachment)}
        >
          View Image
        </span>
      )} */}
    </Box>
  );
};

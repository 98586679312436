import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  page: number;
  type: string;
  searchText: string;
};

const PatientUrlSetter = ({ type, page, searchText }: Props) => {
  let [, setSearchParams] = useSearchParams();
  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (type) {
      params.set("type", type);
    }
    if (searchText) {
      params.set("search", searchText);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, type, page, searchText]);

  return <></>;
};

export default PatientUrlSetter;

/* eslint-disable no-underscore-dangle */
import { Client as SFrameClientDefault } from '@vonage/js-sframe';
import normalizeKeyDefault from './normalizeKey';

const addDecryptEventsToClient = async (client) => {
  await client.setEnableOnSuccess(true);

  // The SFrame client dispatches events error or success for every frame but we only want to listen to the event
  // when state changes e.g. error -> success means we emit 'decryptionRestored'.
  // To achieve this we add a listener only for error at first, then when an event is fired we remove the listener
  // to avoid handling the repeated events and add a listener for the opposing event so we catch when the state changes again.
  const onError = () => {
    client.removeEventListener('error', onError);
    client.addEventListener('success', onSuccess); // eslint-disable-line no-use-before-define
    client.dispatchEvent(new Event('decryptFailed'));
  };
  const onSuccess = () => {
    client.removeEventListener('success', onSuccess);
    client.addEventListener('error', onError);
    client.dispatchEvent(new Event('decryptionRestored'));
  };
  client.addEventListener('error', onError);
};

const create = async (senderId, deps = {}) => {
  const SFrameClient = deps.SFrameClient || SFrameClientDefault;
  const normalizeKey = deps.normalizeKey || normalizeKeyDefault;

  const client = await SFrameClient.create(senderId);

  // Cache original method, since we need to ensure it's compatible
  // with it's legacy SFrame counterpart
  client._setReceiverEncryptionKey = client.setReceiverEncryptionKey;
  client.setReceiverEncryptionKey = async (_senderId, key) => {
    const normalizedKey = await normalizeKey(key);

    return client._setReceiverEncryptionKey(_senderId, normalizedKey);
  };

  // Cache original method, since we need to ensure it's compatible
  // with it's legacy SFrame counterpart
  client._setSenderEncryptionKey = client.setSenderEncryptionKey;
  client.setSenderEncryptionKey = async (key) => {
    const normalizedKey = await normalizeKey(key);

    return client._setSenderEncryptionKey(normalizedKey);
  };

  addDecryptEventsToClient(client);

  return client;
};

const StandardSFrameClient = {
  create,
};

export default StandardSFrameClient;

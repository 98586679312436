import React, { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import http from "../../../utils/http";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

let schema = yup.object().shape({
  active: yup.boolean().required("Active Field is Required"),
  name: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Clinic Name is Required"),
  primaryContact: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Primary Contact is Required"),
  email: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Email is Required"),
  phoneNumber: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Phone Number is Required"),
  address: yup.object().shape({
    addressStreet: yup
      .string()
      .transform((value) => (value ? value?.trim() : value))
      .required("Address Street is Required"),
    addressCity: yup
      .string()
      .transform((value) => (value ? value?.trim() : value))
      .required("Address City is Required"),
    addressState: yup
      .string()
      .transform((value) => (value ? value?.trim() : value))
      .required("Address State is Required"),
    addressPostalCode: yup
      .string()
      .transform((value) => (value ? value?.trim() : value))
      .required("Address Postal Code is Required"),
    countryISOCode: yup
      .string()
      .transform((value) => (value ? value?.trim() : value))
      .required("Country ISO Code is Required"),
  }),
  contactPosition: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Contact Position is Required"),
  fulfilmentScheme: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Fulfilment Scheme is Required"),
  sponsorId: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Sponsor ID is Required"),
  clinicNotes: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Clinic Notes are Required"),
  principalClinicianName: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Principal Clinician Name is Required"),
  principalClinicianPosition: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Principal Clinician Position is Required"),
  principalClinicianPhone: yup.string().optional(),
  // .transform((value) => (value ? value?.trim() : value))
  // .required("Principal Clinician Phone Number is Required"),
  timezone: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Time Zone is Required"),
  region: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Region is Required"),
  patientType: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Patient Type is Required"),
  // emrProvider: yup.object().shape({
  //   provider: yup
  //     .string()
  //     .transform((value) => (value ? value?.trim() : value))
  //     .required("Provider is Required"),
  //   id: yup
  //     .string()
  //     .transform((value) => (value ? value?.trim() : value))
  //     .required("EMR ID is Required"),
  //   name: yup
  //     .string()
  //     .transform((value) => (value ? value?.trim() : value))
  //     .required("EMR Name is Required"),
  // }),
  // fulfilmentTypeLabel: yup
  //   .string()
  //   .transform((value) => (value ? value?.trim() : value))
  //   .required("Fulfilment Type Label is Required"),
  fulfilmentTypeId: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Fulfilment Type is Required"),
  testClinic: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Test Clinic is Required"),
  summaryEnabled: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Summary is Required"),
  kelvinStartDate: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Kevlin Start Date is Required"),
  kelvinEndDate: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Kevlin End Date is Required"),
  principalClinicianEmail: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("Principal Clinician Email is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
  refreshPage: () => void;
};

const ClinicRegion = [
  { value: "AU", label: "AU" },
  { value: "AS", label: "AS" },
  { value: "EU", label: "EU" },
  { value: "US", label: "US" },
];

const ClinicFulfilmentTypeId = [
  { value: "CF", label: "Clinic Fulfilment" },
  { value: "CTS", label: "CTS Clinic Fulfilment" },
  { value: "GKC_F", label: "Global Kinetics Fulfilment" },
];

const ClinicPatientType = [
  { value: "ROUTINE", label: "ROUTINE" },
  { value: "RPM", label: "RPM" },
];
const AddClinicsModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = { ...values };
      body.phoneNumber = "+" + body.phoneNumber;
      body.principalClinicianPhone = body.principalClinicianPhone
        ? "+" + body.principalClinicianPhone
        : "";
      body.kelvinStartDate = DateTime.fromFormat(
        body.kelvinStartDate,
        "dd/MM/yyyy"
      ).toFormat("yyyy-MM-dd");
      body.kelvinEndDate = DateTime.fromFormat(
        body.kelvinEndDate,
        "dd/MM/yyyy"
      ).toFormat("yyyy-MM-dd");
      let res: AxiosResponse;
      if (data?.id) {
        res = await http.put(`/clinic/${data.id}`, body);
      } else {
        res = await http.post(`/clinic`, body);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={data?.id ? "Edit Clinic" : "Create New Clinic"}
          onCloseClick={closeModal}
        />

        <Formik
          initialValues={{
            active: data?.active || false,
            name: data?.name || "",
            primaryContact: data?.primaryContact || "",
            email: data?.email || "",
            phoneNumber: data?.phoneNumber ? data?.phoneNumber?.slice(1) : "",
            address: {
              addressStreet: data?.address?.addressStreet || "",
              addressCity: data?.address?.addressCity || "",
              addressState: data?.address?.addressState || "",
              addressPostalCode: data?.address?.addressPostalCode || "",
              countryISOCode: data?.address?.countryISOCode || "",
            },
            contactPosition: data?.contactPosition || "",
            fulfilmentScheme: data?.fulfilmentScheme || false,
            sponsorId: data?.sponsorId || "Mahalo",
            clinicNotes: data?.clinicNotes || "",
            // contactPhone: data?.contactPhone || "",
            principalClinicianName: data?.principalClinicianName || "",
            principalClinicianPosition: data?.principalClinicianPosition || "",
            principalClinicianPhone: data?.principalClinicianPhone
              ? data?.principalClinicianPhone.slice(1)
              : "",
            timezone: data?.timezone || "",
            region: data?.region || "AU",
            patientType: data?.patientType || "ROUTINE",
            // patientId: data?.patientId || "",
            // emrProvider: {
            //   provider: data?.emrProvider?.provider || "",
            //   id: data?.emrProvider?.id || "",
            //   name: data?.emrProvider?.name || "",
            // },
            fulfilmentTypeId: data?.fulfilmentTypeId || "CF",
            fulfilmentTypeLabel: data?.fulfilmentTypeLabel || "",
            testClinic: data?.testClinic || false,
            summaryEnabled: data?.summaryEnabled || false,
            kelvinStartDate: data?.kelvinStartDate || "",
            kelvinEndDate: data?.kelvinEndDate || "",
            contactEmail: data?.contactEmail || "",
            principalClinicianEmail: data?.principalClinicianEmail || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            errors,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinic-name">
                    Active <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="active"
                    sx={{ width: "50%" }}
                    value={values.active}
                    onChange={(e) => {
                      setFieldValue("active", e.target.value);
                    }}
                  >
                    {[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.active && errors?.active
                      ? (errors?.active as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinic-name">
                    Clinic Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="clinic-name"
                    multiline
                    {...getFieldProps("name")}
                    error={touched?.name && errors?.name ? true : false}
                    helperText={
                      touched?.name && errors?.name
                        ? (errors?.name as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="primaryContact">
                    Primary Contact <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="primaryContact"
                    {...getFieldProps("primaryContact")}
                    error={
                      touched?.primaryContact && errors?.primaryContact
                        ? true
                        : false
                    }
                    helperText={
                      touched?.primaryContact && errors?.primaryContact
                        ? (errors?.primaryContact as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="email"
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="phone-number">
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      id: "phone-number",
                    }}
                    value={values.phoneNumber}
                    onChange={(value) => setFieldValue("phoneNumber", value)}
                    country={"au"}
                    inputStyle={{
                      width: "100%",
                      height: "52px",
                      borderRadius: "6px",
                      fontSize: "16px",
                    }}
                    isValid={
                      touched?.phoneNumber && errors?.phoneNumber ? false : true
                    }
                  />
                  {touched?.phoneNumber && errors?.phoneNumber && (
                    <FormHelperText error>
                      {errors?.phoneNumber as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box
                sx={{
                  color: "#355962",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  Address
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address.street">
                    Address Street <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-street"
                    {...getFieldProps("address.addressStreet")}
                    error={
                      touched?.address?.addressStreet &&
                      errors?.address?.addressStreet
                        ? true
                        : false
                    }
                    helperText={
                      touched?.address?.addressStreet &&
                      errors?.address?.addressStreet
                        ? (errors?.address?.addressStreet as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address.city">
                    Address City <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-city"
                    {...getFieldProps("address.addressCity")}
                    error={
                      touched?.address?.addressCity &&
                      errors?.address?.addressCity
                        ? true
                        : false
                    }
                    helperText={
                      touched?.address?.addressCity &&
                      errors?.address?.addressCity
                        ? (errors?.address?.addressCity as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address.state">
                    Address State <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-state"
                    {...getFieldProps("address.addressState")}
                    error={
                      touched?.address?.addressState &&
                      errors?.address?.addressState
                        ? true
                        : false
                    }
                    helperText={
                      touched?.address?.addressState &&
                      errors?.address?.addressState
                        ? (errors?.address?.addressState as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel
                    sx={LabelStyle}
                    htmlFor="address.addressPostalCode"
                  >
                    Address Postal Code <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-addressPostalCode"
                    {...getFieldProps("address.addressPostalCode")}
                    error={
                      touched?.address?.addressPostalCode &&
                      errors?.address?.addressPostalCode
                        ? true
                        : false
                    }
                    helperText={
                      touched?.address?.addressPostalCode &&
                      errors?.address?.addressPostalCode
                        ? (errors?.address?.addressPostalCode as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address.countryISOCode">
                    Country ISO Code <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-isoCode"
                    {...getFieldProps("address.countryISOCode")}
                    error={
                      touched?.address?.countryISOCode &&
                      errors?.address?.countryISOCode
                        ? true
                        : false
                    }
                    helperText={
                      touched?.address?.countryISOCode &&
                      errors?.address?.countryISOCode
                        ? (errors?.address?.countryISOCode as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="primaryContact">
                    Contact Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="primary-contact"
                    {...getFieldProps("primaryContact")}
                    error={
                      touched?.primaryContact && errors?.primaryContact
                        ? true
                        : false
                    }
                    helperText={
                      touched?.primaryContact && errors?.primaryContact
                        ? (errors?.primaryContact as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="contact-position">
                    Contact Position <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="contact-position"
                    {...getFieldProps("contactPosition")}
                    error={
                      touched?.contactPosition && errors?.contactPosition
                        ? true
                        : false
                    }
                    helperText={
                      touched?.contactPosition && errors?.contactPosition
                        ? (errors?.contactPosition as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                {/* <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="address.contactPhone">
                    Contact Phone
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      id: "address-contactPhone",
                    }}
                    value={values?.contactPhone}
                    onChange={(value) =>
                      setFieldValue("address.contactPhone", value)
                    }
                    country={"us"}
                    inputStyle={{
                      width: "100%",
                      height: "52px",
                      borderRadius: "6px",
                      fontSize: "16px",
                    }}
                    isValid={
                      touched?.contactPhone && errors?.contactPhone
                        ? false
                        : true
                    }
                  />
                </FormControl> */}
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="fulfilmentScheme">
                    Fulfilment Scheme <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="fulfilment-scheme"
                    value={values.fulfilmentScheme}
                    onChange={(e) => {
                      setFieldValue("fulfilmentScheme", e.target.value);
                    }}
                  >
                    {[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.fulfilmentScheme && errors?.fulfilmentScheme
                      ? (errors?.fulfilmentScheme as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="sponsorId">
                    Sponsor ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    // disabled={true}
                    // InputProps={{
                    //   readOnly: true,
                    // }}
                    // aria-readonly
                    id="sponsor-id"
                    {...getFieldProps("sponsorId")}
                    sx={{ width: "50%" }}
                    error={
                      touched?.sponsorId && errors?.sponsorId ? true : false
                    }
                    placeholder="Mahalo"
                    helperText={
                      touched?.sponsorId && errors?.sponsorId
                        ? (errors?.sponsorId as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinicNotes">
                    Clinic Notes <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-notes"
                    multiline
                    {...getFieldProps("clinicNotes")}
                    error={
                      touched?.clinicNotes && errors?.clinicNotes ? true : false
                    }
                    helperText={
                      touched?.clinicNotes && errors?.clinicNotes
                        ? (errors?.clinicNotes as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="principalClinicianName">
                    Principal Clinician Name
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-principal-name"
                    {...getFieldProps("principalClinicianName")}
                    error={
                      touched?.principalClinicianName &&
                      errors?.principalClinicianName
                        ? true
                        : false
                    }
                    helperText={
                      touched?.principalClinicianName &&
                      errors?.principalClinicianName
                        ? (errors?.principalClinicianName as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel
                    sx={LabelStyle}
                    htmlFor="principalClinicianPosition"
                  >
                    Principal Clinican Position{" "}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-principal-position"
                    {...getFieldProps("principalClinicianPosition")}
                    error={
                      touched?.principalClinicianPosition &&
                      errors?.principalClinicianPosition
                        ? true
                        : false
                    }
                    helperText={
                      touched?.principalClinicianPosition &&
                      errors?.principalClinicianPosition
                        ? (errors?.principalClinicianPosition as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2, mb: 2.5 }}>
                <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="principalClinicianPhone">
                    Principal Clinician Phone
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      id: "phone-number",
                    }}
                    value={values?.principalClinicianPhone}
                    onChange={(value) =>
                      setFieldValue("principalClinicianPhone", value)
                    }
                    country={"us"}
                    inputStyle={{
                      width: "100%",
                      height: "52px",
                      borderRadius: "6px",
                      fontSize: "16px",
                    }}
                    isValid={
                      touched?.principalClinicianPhone &&
                      errors?.principalClinicianPhone
                        ? false
                        : true
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="principalClinicianEmail">
                    Principal Clinician Email{" "}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="principal-clinician-email"
                    {...getFieldProps("principalClinicianEmail")}
                    error={
                      touched?.principalClinicianEmail &&
                      errors?.principalClinicianEmail
                        ? true
                        : false
                    }
                    helperText={
                      touched?.principalClinicianEmail &&
                      errors?.principalClinicianEmail
                        ? (errors?.principalClinicianEmail as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="timeZone">
                    Time Zone <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="timezone"
                    {...getFieldProps("timezone")}
                    error={touched?.timezone && errors?.timezone ? true : false}
                    helperText={
                      touched?.timezone && errors?.timezone
                        ? (errors?.timezone as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="region">
                    Region <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="region"
                    value={values.region}
                    onChange={(e) => {
                      setFieldValue("region", e.target.value);
                    }}
                  >
                    {ClinicRegion.map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.region && errors?.region
                      ? (errors?.region as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              {/* <Box
                sx={{
                  color: "#355962",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  Patient Type <span style={{ color: "red" }}>*</span>
                </Typography>
              </Box> */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="patient-type">
                    Patient Type <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="patient-type"
                    value={values.patientType}
                    onChange={(e) => {
                      setFieldValue("patientType", e.target.value);
                    }}
                  >
                    {ClinicPatientType.map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.patientType && errors?.patientType
                      ? (errors?.patientType as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
                {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="patientId">
                    Patient ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="patient-id"
                    {...getFieldProps("patient.id")}
                    error={
                      touched?.patientId && errors?.patientId ? true : false
                    }
                    helperText={
                      touched?.patientId && errors?.patientId
                        ? (errors?.patientId as string)
                        : " "
                    }
                  />
                </FormControl> */}
              </Box>
              {/* <Box
                sx={{
                  color: "#355962",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  EMR Provider
                </Typography>
              </Box> */}
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="emrProvider.provider">
                    EMR Provider <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="emr-provider"
                    {...getFieldProps("emrProvider.provider")}
                    error={
                      touched?.emrProvider?.provider &&
                      errors?.emrProvider?.provider
                        ? true
                        : false
                    }
                    helperText={
                      touched?.emrProvider?.provider &&
                      errors?.emrProvider?.provider
                        ? (errors?.emrProvider?.provider as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="emrProvider.id">
                    EMR ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="emr-id"
                    {...getFieldProps("emrProvider.id")}
                    error={
                      touched?.emrProvider?.id && errors?.emrProvider?.id
                        ? true
                        : false
                    }
                    helperText={
                      touched?.emrProvider?.id && errors?.emrProvider?.id
                        ? (errors?.emrProvider?.id as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ ...InputWrapper, width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="emrProvider.name">
                    EMR Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="emr-name"
                    {...getFieldProps("emrProvider.name")}
                    error={
                      touched?.emrProvider?.name && errors?.emrProvider?.name
                        ? true
                        : false
                    }
                    helperText={
                      touched?.emrProvider?.name && errors?.emrProvider?.name
                        ? (errors?.emrProvider?.name as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
              {/* <Box
                sx={{
                  color: "#355962",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  Fulfilment Type
                </Typography>
              </Box> */}
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="fulfilmentType.type">
                    FulFilment Type <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="fulfilment-type"
                    sx={{ width: "50%" }}
                    {...getFieldProps("fulfilmentType.type")}
                    error={
                      touched?.fulfilmentType?.type &&
                      errors?.fulfilmentType?.type
                        ? true
                        : false
                    }
                    helperText={
                      touched?.fulfilmentType?.type &&
                      errors?.fulfilmentType?.type
                        ? (errors?.fulfilmentType?.type as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="fulfilmentType-id">
                    Fulfilment Type <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="fulfilmenttype-id"
                    value={values.fulfilmentTypeId}
                    onChange={(e) => {
                      setFieldValue("fulfilmentTypeId", e.target.value);
                    }}
                  >
                    {ClinicFulfilmentTypeId.map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.fulfilmentTypeId && errors?.fulfilmentTypeId
                      ? (errors?.fulfilmentTypeId as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="testClinic">
                    Test Clinic <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="test-clinic"
                    value={values.testClinic}
                    onChange={(e) => {
                      setFieldValue("testClinic", e.target.value);
                    }}
                  >
                    {[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.testClinic && errors?.testClinic
                      ? (errors?.testClinic as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="summaryEnabled">
                    Summary Enabled <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="summary-enabled"
                    value={values.summaryEnabled}
                    onChange={(e) => {
                      setFieldValue("summaryEnabled", e.target.value);
                    }}
                  >
                    {[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.summaryEnabled && errors?.summaryEnabled
                      ? (errors?.summaryEnabled as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="kelvinStartDate">
                    Kelvin Start Date <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={
                      values?.kelvinStartDate
                        ? DateTime.fromFormat(
                            values?.kelvinStartDate,
                            "dd/MM/yyyy"
                          )
                        : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue(
                        "kelvinStartDate",
                        newValue.toFormat("dd/MM/yyyy")
                      );
                      if (
                        values.kelvinEndDate &&
                        newValue >
                          DateTime.fromFormat(
                            values?.kelvinEndDate,
                            "dd/MM/yyyy"
                          )
                      ) {
                        setFieldValue("kelvinEndDate", "");
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        inputProps: {
                          readOnly: true,
                          placeholder: "Select date",
                        },
                        error:
                          touched?.kelvinStartDate && errors?.kelvinStartDate
                            ? true
                            : false,
                        helperText:
                          touched?.kelvinStartDate && errors?.kelvinStartDate
                            ? (errors?.kelvinStartDate as string)
                            : " ",
                      },
                    }}
                    // renderInput={(
                    //   params: JSX.IntrinsicAttributes & TextFieldProps
                    // ) => (
                    //   <TextField
                    //     {...params}
                    //     inputProps={{
                    //       ...params.inputProps,
                    //       readOnly: true,
                    //       placeholder: "Select date",
                    //     }}
                    //     error={
                    //       touched?.kelvinStartDate && errors?.kelvinStartDate
                    //         ? true
                    //         : false
                    //     }
                    //     helperText={
                    //       touched?.kelvinStartDate && errors?.kelvinStartDate
                    //         ? (errors?.kelvinStartDate as string)
                    //         : " "
                    //     }
                    //   />
                    // )}
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="fulfillmentType.label">
                    Kelvin End Date <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={
                      values?.kelvinEndDate
                        ? DateTime.fromFormat(
                            values?.kelvinEndDate,
                            "dd/MM/yyyy"
                          )
                        : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue(
                        "kelvinEndDate",
                        newValue.toFormat("dd/MM/yyyy")
                      );
                      if (
                        values.kelvinStartDate &&
                        newValue <
                          DateTime.fromFormat(
                            values?.kelvinStartDate,
                            "dd/MM/yyyy"
                          )
                      ) {
                        setFieldValue("kelvinStartDate", "");
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        inputProps: {
                          readOnly: true,
                          placeholder: "Select date",
                        },
                        error:
                          touched?.kelvinEndDate && errors?.kelvinEndDate
                            ? true
                            : false,
                        helperText:
                          touched?.kelvinEndDate && errors?.kelvinEndDate
                            ? (errors?.kelvinEndDate as string)
                            : " ",
                      },
                    }}
                    // renderInput={(
                    //   params: JSX.IntrinsicAttributes & TextFieldProps
                    // ) => (
                    //   <TextField
                    //     {...params}
                    //     inputProps={{
                    //       ...params.inputProps,
                    //       readOnly: true,
                    //       placeholder: "Select date",
                    //     }}
                    //     error={
                    //       touched?.kelvinEndDate && errors?.kelvinEndDate
                    //         ? true
                    //         : false
                    //     }
                    //     helperText={
                    //       touched?.kelvinEndDate && errors?.kelvinEndDate
                    //         ? (errors?.kelvinEndDate as string)
                    //         : " "
                    //     }
                    //   />
                    // )}
                  />
                </FormControl>
              </Box>
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddClinicsModal;

import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeModalData } from "../../../../Redux/reducers/cmsBuilderV2Slice";
import { CMSInputWrapper, CMSInputLabel, resourceWrapper } from "../styles";

type Props = {
  section: any;
  readOnly?: boolean;
};

const ResourceItem: React.FC<Props> = ({ section, readOnly }) => {
  const dispatch = useDispatch();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    dispatch(
      changeModalData({
        type,
        value: event.target.value,
      })
    );
  };

  return (
    <Box sx={resourceWrapper}>
      <FormControl sx={CMSInputWrapper}>
        <FormLabel sx={CMSInputLabel}>Resource Label</FormLabel>
        <TextField
          fullWidth
          value={section?.resourceTitle}
          onChange={(event) => {
            handleChange(event, "resourceTitle");
          }}
          placeholder="Resource name"
          InputProps={{ readOnly }}
        />
      </FormControl>
      <FormControl sx={CMSInputWrapper}>
        <FormLabel sx={CMSInputLabel}>Resource Link</FormLabel>
        <TextField
          fullWidth
          value={section?.resourceUrl}
          onChange={(event) => {
            handleChange(event, "resourceUrl");
          }}
          placeholder="Resource link"
          InputProps={{ readOnly }}
        />
      </FormControl>
    </Box>
  );
};

export default ResourceItem;

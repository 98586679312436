import { Box, IconButton, TextField, Typography } from "@mui/material";
import {
  QuesBlockStyle,
  QuestionContentStyle,
  QuestionImageStyle,
  QuestionIndexStyle,
  QuestionTitleStyle,
  remarkContainerStyle,
  remarkLabelStyle,
} from "./QBPreview.style";
import { TextType } from "./QuestionType/TextType";
import { NumberType } from "./QuestionType/NumberType";
import { DateTimeType, DateType, TimeType } from "./QuestionType/DateType";
import { CheckboxType, LikertType, RadioType } from "./QuestionType/ChoiceType";
import { Settings } from "@mui/icons-material";
import { openQuestionSettings } from "../../Redux/actions/questionAction";
import { useAppDispatch } from "../../Redux/hooks";
import { GroupType } from "./QuestionType/GroupType";
import { StatementType } from "./QuestionType/StatementType";
import { FileType } from "./QuestionType/FileType";
import { GridType } from "./QuestionType/GridType";

const questionTypes: any = {
  short_text: TextType,
  long_text: TextType,
  number: NumberType,
  score_input: NumberType,
  date: DateType,
  date_time: DateTimeType,
  time: TimeType,
  multiple_choice: RadioType,
  yes_no: RadioType,
  checkbox: CheckboxType,
  likert_scale: LikertType,
  group: GroupType,
  upload_file: FileType,
  grid: GridType,
};

// ***TODO***
// Attachment display

type BlockProp = {
  q: any;
  index: number;
  parentIndex?: number;
};

const QuestionBlock: React.FC<BlockProp> = ({ q, index, parentIndex }) => {
  const dispatch = useAppDispatch();
  const TypeComponent = questionTypes[q.type];

  const handleSettingsClick = (index: number, parentIndex?: number) => {
    dispatch(openQuestionSettings(index, parentIndex));
  };

  if (q.type === "statement") {
    return <StatementType q={q} key={q.id} />;
  }

  if (!TypeComponent) {
    return null;
  }

  return (
    <Box sx={QuesBlockStyle} key={q.id}>
      <Box mb={2} sx={{ display: "flex" }}>
        {q.questionNo && (
          <Typography sx={QuestionIndexStyle}>{q.questionNo}</Typography>
        )}
        <Box>
          <Box sx={QuestionTitleStyle}>
            {q.title}
            {q.required && " *"}
          </Box>
          {q.attachment?.href && (
            <Box sx={QuestionImageStyle}>
              <img
                src={q.attachment?.href}
                alt="Question"
                className="question-image"
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* {q.attachment && (
                  <div className="question-attachment">
                    <img src={q.attachment.href} alt="question-attachment" />
                  </div>
                )} */}
      <Box sx={QuestionContentStyle}>
        <TypeComponent question={q} groupIndex={index} />
        {q.remark && (
          <Box sx={remarkContainerStyle}>
            <Box sx={remarkLabelStyle}>Enter Remarks</Box>
            <TextField placeholder="Type your text here" fullWidth />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <IconButton
          aria-label="settings"
          size="medium"
          onClick={() => handleSettingsClick(index, parentIndex)}
        >
          <Settings fontSize="medium" sx={{ color: "primary.main" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default QuestionBlock;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Skeleton,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledHeader, HeaderLeftContent } from "../Common/styles/header";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  StyledTableCell,
  NoDataContainer,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
  TableLayoutStyle,
} from "../Common/styles/table";
import { errorToastMessage } from "../../utils/toast";
import http from "../../utils/http";
import { PersonOutlineOutlined } from "@mui/icons-material";
import {
  BorderedInfoContainer,
  HeaderStyle,
  InfoContainer,
  text1,
  text2,
} from "./style";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";

const BillingHistory = () => {
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [userData, setUserData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [userDataLoader, setUserDataLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [totalHistory, setTotalHistory] = useState(0);
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [toggleLoader, setToggleLoader] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, page]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await http.get(`/user-invoices/${id}/history`);
        const resData = res?.data?.data;
        const newData = resData?.invoices?.map((item: any) => ({
          id: item?.id,
          enrollmentDate: DateTime?.fromFormat(
            item?.enrollmentDate,
            "yyyy-LL-dd"
          )?.toFormat("LL/dd/yyyy"),
          dropOffDate: item?.exitDate
            ? DateTime?.fromFormat(item?.exitDate, "yyyy-LL-dd")?.toFormat(
                "LL/dd/yyyy"
              )
            : "",
          billingCycleStart: DateTime?.fromFormat(
            item?.billingCycleStartDate,
            "yyyy-LL-dd"
          )?.toFormat("LL/dd/yyyy"),
          billingCycleEnd: DateTime?.fromFormat(
            item?.billingCycleEndDate,
            "yyyy-LL-dd"
          )?.toFormat("LL/dd/yyyy"),
          billingStatus: item?.billingState,
          activeDays: item?.activeDays?.toString(),
          activeMins: item?.activeMins?.toString(),
          activeDaysCptCode: item?.activeDaysCptCode,
          activeMinsCptCode: item?.activeMinsCptCode,
        }));
        setData(newData || []);
        setTotalHistory(resData?.count || 0);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        errorToastMessage(error as Error);
      }
    };
    if (id) getData();
  }, [id, page, toggleLoader]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setUserDataLoader(true);
        const res = await http.get(`/participants/${id}/profile`);
        const resData = res?.data?.data;
        setUserData({
          userName: `${resData?.user?.firstName || ""} ${
            resData?.user?.lastName || ""
          }`,
          userEmail: resData?.user?.email,
          enrollmentDate: DateTime?.fromISO(resData?.user?.createdAt)?.toFormat(
            "LL/dd/yyyy"
          ),
          userGender: resData?.gender,
        });
        setUserDataLoader(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setUserDataLoader(false);
      }
    };
    if (id) fetchUserData();
  }, [id, setLoading]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const download = async (startDate: string, endDate: string, type: string) => {
    try {
      setDownloadLoader(true);
      // const isoStartDate = DateTime?.fromFormat(startDate, "LL/dd/yyyy")
      //   ?.toUTC()
      //   ?.toISO();
      // const isoEndDate = DateTime?.fromFormat(endDate, "LL/dd/yyyy")
      //   ?.toUTC()
      //   ?.toISO();
      const isoStartDate = DateTime?.fromFormat(
        startDate || "",
        "LL/dd/yyyy"
      )?.toFormat("yyyy-MM-dd");
      const isoEndDate = DateTime?.fromFormat(
        endDate || "",
        "LL/dd/yyyy"
      )?.toFormat("yyyy-MM-dd");
      const res: AxiosResponse = await http.get(
        `/user-invoices/download-${type}?userId=${id}&startDate=${isoStartDate}&endDate=${isoEndDate}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Reports.${type}`);
      document.body.appendChild(link);
      link.click();
      setDownloadLoader(false);
      setToggleLoader((prev) => !prev);
    } catch (error) {
      setDownloadLoader(false);

      errorToastMessage(error as Error);
    }
  };

  const handleRowClick = (item: any) => {
    navigate(
      `/app/billing-reports/details/${id}?startDate=${item?.billingCycleStart}&endDate=${item?.billingCycleEnd}`
    );
  };

  return (
    <>
      {downloadLoader && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon sx={{ color: "#637E85" }} />
          </IconButton>

          <Typography fontSize={30} fontWeight="bold">
            View History
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={HeaderStyle}>
        <Box sx={{ display: "flex" }}>
          <Avatar
            variant={"square"}
            sx={{
              height: "80px",
              width: "80px",
              bgcolor: "lightgray",
              color: "#000",
              mr: 2,
              borderRadius: "5px",
            }}
          >
            <PersonOutlineOutlined fontSize="large" />
          </Avatar>
          <Box sx={BorderedInfoContainer}>
            {userDataLoader ? (
              <>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "27px", width: "100px", mb: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "18px", width: "100px" }}
                />
              </>
            ) : (
              <>
                <Typography sx={text1}>{userData?.userName || "-"}</Typography>
                <Typography sx={text2}>{userData?.userEmail || "-"}</Typography>
              </>
            )}
          </Box>
          <Box sx={BorderedInfoContainer}>
            {userDataLoader ? (
              <>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "27px", width: "100px", mb: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "18px", width: "100px" }}
                />
              </>
            ) : (
              <>
                <Typography sx={text1}>
                  {userData?.userGender || "-"}
                </Typography>
                <Typography sx={text2}>Gender</Typography>
              </>
            )}
          </Box>
          <Box sx={InfoContainer}>
            {userDataLoader ? (
              <>
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "27px", width: "100px", mb: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{ height: "18px", width: "100px" }}
                />
              </>
            ) : (
              <>
                <Typography sx={text1}>
                  {userData?.enrollmentDate || "-"}
                </Typography>
                <Typography sx={text2}>Enrollment date</Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "20px",
          bgcolor: totalHistory > pageSize ? "#fff" : "transparent",
        }}
      >
        <Table>
          <TableHead sx={{ ...TableLayoutStyle, height: "62px" }}>
            <TableRow>
              <StyledTableCell>Enrollment Date</StyledTableCell>
              <StyledTableCell>Drop Off Date</StyledTableCell>
              <StyledTableCell>Billing Cycle Start</StyledTableCell>
              <StyledTableCell>Billing Cycle End</StyledTableCell>
              <StyledTableCell>
                <p>Active Days</p>
                <p>(CPT Code)</p>
              </StyledTableCell>
              <StyledTableCell>
                <p>Active Mins</p>
                <p>(CPT Code)</p>
              </StyledTableCell>
              <StyledTableCell>Billing Status</StyledTableCell>
              <StyledTableCell>Download</StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && data?.length > 0 && (
            <>
              <TableBody
                sx={{
                  display: "block",
                  height:
                    totalHistory > pageSize
                      ? "calc(100vh - 438px)"
                      : "calc(100vh - 430px)",
                  overflow: "auto",
                }}
              >
                {data?.map((item: any) => (
                  <TableRow
                    key={item?.id}
                    sx={{
                      ...TableLayoutStyle,
                    }}
                  >
                    <StyledTableCell
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(item)}
                    >
                      {item?.enrollmentDate || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item?.dropOffDate || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item?.billingCycleStart || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item?.billingCycleEnd || "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item?.activeDays}{" "}
                      {`(${item?.activeDaysCptCode || "NA"})`}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item?.activeMins}{" "}
                      {`(${item?.activeMinsCptCode || "NA"})`}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item?.billingStatus === "ready_to_download"
                        ? "Ready to download"
                        : "Downloaded"}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                    >
                      <Typography
                        sx={{
                          color: "#327091",
                          textDecoration: "underline",
                          fontSize: "14px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          download(
                            item?.billingCycleStart,
                            item?.billingCycleEnd,
                            "csv"
                          );
                        }}
                      >
                        CSV
                      </Typography>

                      <Typography
                        sx={{
                          color: "#327091",
                          textDecoration: "underline",
                          fontSize: "14px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          download(
                            item?.billingCycleStart,
                            item?.billingCycleEnd,
                            "pdf"
                          );
                        }}
                      >
                        PDF
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {totalHistory > pageSize && (
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={totalHistory}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
        {!loading && data?.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default BillingHistory;

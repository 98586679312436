import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../Redux/hooks";
import { setCmsDefaults } from "../../Redux/reducers/cmsSlice";

const CMSUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("type") || "learning";
    const search = searchParams.get("search") || "";
    const filter = searchParams.get("filter")?.split(",") || [];
    const lang = searchParams.get("lang")?.split(",") || [];
    const page = parseInt(searchParams.get("page") || "0") || 0;
    dispatch(
      setCmsDefaults({
        search,
        type,
        filter,
        lang,
        page,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(CMSUrlLoader);

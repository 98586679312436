import React, { useMemo } from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  TextField,
  InputAdornment,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";

import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { MoreVert } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";

type Props = {
  type: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

const ClinicRequestList = ({
  type,
  page,
  setPage,
  searchText,
  setSearchText,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [patientReqData, setPatientReqData] = useState<any>([]);
  const [patientReqDataCount, setPatientReqDataCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `clinic/users/clinic-request?page=${
          page + 1
        }&size=${pageSize}`;
        if (searchText) {
          url += `&search=${searchText}`;
        }
        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data?.data?.map((row: any) => ({
          id: row?.id,
          clinicId: row?.clinicId,
          name: row?.user?.firstName
            ? row?.user?.lastName
              ? `${row?.user?.firstName} ${row?.user?.lastName}`
              : row?.user?.firstName
            : "",
          email: row?.user?.email,
          clinicRequested: row?.clinic?.name,
          action: row?.status,
          userId: row?.userId,
        }));
        setPatientReqData(newData || []);
        setPatientReqDataCount(data?.count || 0);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [page, toggleLoader, searchText]);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setPage(0);
        setSearchText(val);
      }, 500),
    [setPage, setSearchText]
  );

  const handleAssociate = async (row: any) => {
    try {
      let body: any = {
        status: "approved",
        userId: row?.userId,
        clinicId: row.clinicId,
      };
      const res: AxiosResponse = await http.post(
        `/participants/clinic/assign-clinic`,
        body
      );
      toastMessage("success", res.data.message);
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  const rejectRequest = async () => {
    try {
      setMenuLoader(true);
      let body: any = {
        status: "rejected",
        userId: selectedRow.userId,
        clinicId: selectedRow.clinicId,
      };
      const res: AxiosResponse = await http.post(
        `/participants/clinic/assign-clinic`,
        body
      );
      toastMessage("success", res.data.message);
      handleMenuClose();
      setMenuLoader(false);
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setMenuLoader(false);
    }
  };

  return (
    <Box sx={{ mt: 3, overflowX: "scroll" }}>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "70%" }}
          placeholder="Search By Email"
          defaultValue={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Patient Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Clinic Requested</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          {!loading && patientReqData?.length > 0 && (
            <>
              <TableBody>
                {patientReqData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell
                      sx={{
                        maxWidth: "250px",
                        color: "#327091",
                      }}
                    >
                      <Typography noWrap fontWeight={600} fontSize="14px">
                        {row?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={400} fontSize="14px">
                        {row?.email}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={400} fontSize="14px">
                        {row?.clinicRequested}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: "250px",
                        color: "#327091",
                      }}
                    >
                      {row?.action === "pending" && (
                        <Typography
                          sx={{ cursor: "pointer" }}
                          noWrap
                          fontWeight={600}
                          fontSize="14px"
                          onClick={() => handleAssociate(row)}
                        >
                          Link to Clinic
                        </Typography>
                      )}
                      {row?.action === "approved" && (
                        <Typography noWrap fontWeight={600} fontSize="14px">
                          Linked
                        </Typography>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row?.action === "pending" && (
                        <IconButton onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVert />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {patientReqDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={patientReqDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
        {!loading && patientReqData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: "400px",
              width: "20ch",
            },
          }}
        >
          <MenuItem onClick={rejectRequest} disabled={menuLoader}>
            <ListItemText>
              <Typography color="#111928" fontWeight={500}>
                Reject Request
              </Typography>
            </ListItemText>
            {menuLoader && (
              <ListItemIcon>
                <CircularProgress size={18} sx={{ ml: 1 }} />
              </ListItemIcon>
            )}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default React.memo(ClinicRequestList);

import {
  ArticleIcon,
  AssessmentIcon,
  AudioIcon,
  PictureIcon,
  PollingIcon,
  ResourcesIcon,
  TitleIcon,
  VideoIcon,
} from "./Icons";

export const CMSIconMap: any = {
  title: TitleIcon,
  subtitle: TitleIcon,
  video: VideoIcon,
  question: AssessmentIcon,
  text: ArticleIcon,
  picture: PictureIcon,
  resource: ResourcesIcon,
  poll: PollingIcon,
  audio: AudioIcon,
  spaced_learning: AssessmentIcon,
};

export const CMSTitleMap: any = {
  title: "Title",
  subtitle: "Subtitle",
  video: "Video",
  question: "Assessment",
  text: "Article Or Text",
  picture: "Picture",
  resource: "Resources",
  poll: "Polling Card",
  audio: "Audio",
};

export const cards = [
  {
    key: "1",
    name: "Title",
    description: "Title block for headers and sub-headers",
    type: "title",
    value: "",
  },
  {
    key: "2",
    name: "Subtitle",
    description: "Subtitle block to introduce page sections below title",
    type: "subtitle",
    value: "",
  },
  {
    key: "3",
    name: "Video",
    description: "Video block to support for video content",
    type: "video",
    label: "",
    value: "",
  },
  {
    key: "4",
    name: "Assessment",
    description:
      "Question block to add multiple choice or open response question",
    type: "question",
  },
  {
    key: "5",
    name: "Article or Text",
    description: "Text block for body text",
    type: "text",
    value: "",
  },
  {
    key: "6",
    name: "Picture",
    description: "Image block for sharing pictures",
    type: "picture",
    label: "",
    value: "",
    altText: "",
  },
  {
    key: "9",
    name: "Audio",
    description: "Audio block to play audios",
    type: "audio",
    value: "",
    label: "",
  },
  {
    key: "10",
    name: "Spaced Learning",
    description: "Spaced Learning block to add multiple choice question",
    type: "spaced_learning",
  },
  {
    key: "7",
    name: "Resources",
    description: "Resource block to input images, PDF and links",
    type: "resource",
  },
  {
    key: "8",
    name: "Polling Card",
    description: "Polling card to conduct polls",
    type: "poll",
    value: "",
  },
];

export const cardsV2 = [
  {
    key: "1",
    name: "Title",
    description: "Title block for headers and sub-headers",
    type: "title",
    value: "",
  },
  {
    key: "2",
    name: "Subtitle",
    description: "Subtitle block to introduce page sections below title",
    type: "subtitle",
    value: "",
  },
  {
    key: "3",
    name: "Video",
    description: "Video block to support for video content",
    type: "video",
    resourceTitle: "",
    resourceUrl: "",
  },
  {
    key: "4",
    name: "Assessment",
    description:
      "Question block to add multiple choice or open response question",
    type: "question",
    questionTitle: "",
  },
  {
    key: "5",
    name: "Article or Text",
    description: "Text block for body text",
    type: "text",
    value: "",
  },
  {
    key: "6",
    name: "Picture",
    description: "Image block for sharing pictures",
    type: "picture",
    resourceTitle: "",
    resourceAltTitle: "",
    resourceUrl: "",
  },
  {
    key: "9",
    name: "Audio",
    description: "Audio block to play audios",
    type: "audio",
    resourceTitle: "",
    resourceUrl: "",
  },
  {
    key: "7",
    name: "Resource",
    description: "Resource block to input images, PDF and links",
    type: "resource",
    resourceTitle: "",
    resourceUrl: "",
  },
  {
    key: "8",
    name: "Polling Card",
    description: "Polling card to conduct polls",
    type: "poll",
    questionTitle: "",
  },
];

export const LessonQuestions = [
  {
    type: "multi_select",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
  },
  {
    type: "slider",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
  },
  {
    type: "timer",
    title: "",
  },
  {
    type: "single_select",
    title: "",
    wrongExplanation: "",
    correctExplanation: "",
  },
  {
    type: "text",
    title: "",
  },
];

export const LessonQuestionsV2 = [
  {
    questionType: "multi_select",
    wrongExplanation: "",
    correctExplanation: "",
    imageUrl: "",
  },
  {
    questionType: "slider",
    wrongExplanation: "",
    correctExplanation: "",
    imageUrl: "",
  },
  {
    questionType: "single_select",
    wrongExplanation: "",
    correctExplanation: "",
    imageUrl: "",
  },
  {
    questionType: "text",
    imageUrl: "",
  },
];

export const LessonQuestionsTitleMap: any = {
  multi_select: "Multiple choice question",
  slider: "Slider",
  single_select: "Quiz",
  text: "Text",
};

import { SxProps } from "@mui/material";

export const HeaderStyle: SxProps = {
  height: "112px",
  width: "100%",
  borderRadius: "10px",
  background: "#FEFEFE",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mt: 3,
  display: "flex",
  alignItems: "center",
  p: 2,
  justifyContent: "space-between",
};

export const InfoContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  pr: 2,
  mr: 2,
};

export const BorderedInfoContainer: SxProps = {
  ...InfoContainer,
  borderRight: "1px solid",
  borderColor: "#E0E3EB",
};

export const text1: SxProps = {
  color: "#111928",
  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "150%",
  mb: 1,
  textTransform: "capitalize",
};

export const text2: SxProps = {
  color: "#6B7280",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "150%",
};

import { Box, Slider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { indxToAlpha } from "../../../utils/education";
import { getTranslation } from "../../../utils/webviewLang";

type Props = {
  questions: any;
  lang: string;
};

const CMSQuestionPreview: React.FC<Props> = ({ questions, lang }) => {
  const questionTypes: any = {
    multi_select: MCQ,
    single_select: Quiz,
    slider: SliderComp,
    text: Text,
  };

  return (
    <>
      {questions.map((q: any) => {
        if (questionTypes[q.type] === undefined) return null;
        const TypeComponent = questionTypes[q.type];
        return (
          <div className="question-container" key={q.id}>
            <div className="question-title">{q.title}</div>
            {q.imageUrl && (
              <img src={q.imageUrl} className="question-image" alt="question" />
            )}
            <TypeComponent question={q} lang={lang} />
          </div>
        );
      })}
    </>
  );
};

const MCQ = ({ question, lang }: any) => {
  const [answer, setAnswer] = useState<any[]>([]);

  const changeHandler = (id: string) => {
    if (answer.includes(id)) {
      setAnswer(answer.filter((a) => a !== id));
    } else {
      setAnswer((pre) => [...pre, id]);
    }
  };

  let submitted = answer?.length > 0;
  let explanation = null;
  if (submitted && question?.correctAnswer?.length > 0 && answer?.length > 0) {
    const correct =
      question?.correctAnswer?.every((id: any) => {
        return answer?.includes(id);
      }) && question?.correctAnswer?.length === answer.length;
    correct ? (explanation = "correct") : (explanation = "wrong");
  }

  return (
    <>
      <div className="question-choices">
        {question.choices.map((choice: any, index: any) => {
          const selected = answer.includes(choice.id);
          return (
            <div
              key={choice.id}
              className={
                selected ? "question-choice selected" : "question-choice"
              }
              onClick={() => changeHandler(choice.id)}
            >
              {choice.image && (
                <img
                  src={choice.image}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
            </div>
          );
        })}
      </div>
      <div className="compulsory">
        {"(" + getTranslation("question_compulsory", lang) + ")"}
      </div>
      {submitted && explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

const Text = ({ lang }: any) => {
  return (
    <TextField
      multiline
      rows={3}
      placeholder={getTranslation("answer_placeholder", lang)}
      fullWidth
    />
  );
};

const Quiz = ({ question, lang }: any) => {
  const [answer, setAnswer] = useState<any>(null);

  const changeHandler = (id: string) => {
    setAnswer(id);
  };

  let submitted = answer ? true : false;
  let explanation = null;
  if (submitted && question?.correctAnswer?.length > 0) {
    question?.correctAnswer?.[0] === answer
      ? (explanation = "correct")
      : (explanation = "wrong");
  }

  return (
    <>
      <div className="question-choices">
        {question.choices.map((choice: any, index: number) => {
          return (
            <div
              key={choice.id}
              className={
                answer === choice.id
                  ? "question-choice selected"
                  : "question-choice"
              }
              onClick={() => changeHandler(choice.id)}
            >
              {choice.image && (
                <img
                  src={choice.image}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
            </div>
          );
        })}
      </div>
      <div className="compulsory">
        {"(" + getTranslation("question_compulsory", lang) + ")"}
      </div>
      {submitted && explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

const SliderComp = ({ question }: any) => {
  const [marks, setMarks] = useState<any[]>([]);

  const [val, setVal] = useState<any>();
  const choice = question.choices[0];

  const changeHandler = (_1: any, newValue: number | number[]) => {
    setVal(newValue);
  };

  useEffect(() => {
    const newMarks = [
      {
        value: parseInt(choice.minValue),
        label: "",
        text: choice.minLabel,
      },
    ];
    if (choice.midValue && choice.midLabel) {
      newMarks.push({
        value: parseInt(choice.midValue),
        label: "",
        text: choice.midLabel,
      });
    }
    newMarks.push({
      value: parseInt(choice.maxValue),
      label: "",
      text: choice.maxLabel,
    });
    setMarks(newMarks);
  }, [choice, setMarks]);

  let explanation = null;
  if (typeof val === "number") {
    const cutoff = parseInt(choice.cutoff);
    if (typeof cutoff === "number" && !isNaN(cutoff)) {
      if (val > cutoff) {
        explanation = "correct";
      } else {
        explanation = "wrong";
      }
    }
  }

  return (
    <>
      <Box sx={{ pr: 1 }}>
        <Slider
          step={1}
          marks={marks}
          value={typeof val === "number" ? val : 0}
          onChange={changeHandler}
          min={parseInt(choice.minValue)}
          max={parseInt(choice.maxValue)}
        />
      </Box>
      <div className="slider-marks">
        {marks.map((mark) => {
          return (
            <div
              className={
                val >= mark.value ? "slider-mark selected" : "slider-mark"
              }
              key={mark.value}
            >
              {mark.text}
            </div>
          );
        })}
      </div>
      {explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

export default CMSQuestionPreview;

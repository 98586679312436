import difference from 'lodash/difference';
import getPartialSdpAnswer from './partialSdpTransformations/getPartialSdpAnswer';
import SDPHelpersDefault from './sdp_helpers';
import getPartialIceRestartSdpOffer from './partialSdpTransformations/getPartialIceRestartSdpOffer';
import createGetFullRemoteSdp from './partialSdpTransformations/getFullRemoteSdp';

const createPeerConnectionSDP = (deps = {}) => {
  const sdpHelpers = deps.helpers || SDPHelpersDefault;
  const getFullRemoteSdp = deps.getFullRemoteSdp || createGetFullRemoteSdp();
  const peerConnectionSDP = {
    sdp: {
      mids: [],
      mediaSections: [],
      headers: [],
      bundle: null,
      version: null,
      tracks: [],
    },
    subscribePcIdsByMid: {},

    updateParsedSdp(sdp) {
      Object.assign(this.sdp, sdp);
    },

    updateSdpWithNewOffer(sdp) {
      const parsedSdp = sdpHelpers.updateSDPWithNewOffer({
        mediaSections: this.sdp.mediaSections,
        headers: this.sdp.headers,
        version: this.sdp.version,
      }, sdp.sdp);
      Object.assign(this.sdp, parsedSdp);
    },

    setOfferAndCreateAnswer(offer) {
      this.updateSdpWithNewOffer(offer);
      return sdpHelpers.createSDP(this.sdp.headers, this.sdp.mediaSections);
    },

    createSdp() {
      return sdpHelpers.createSDP(this.sdp.headers, this.sdp.mediaSections);
    },

    addSubscriberMid(mid, subscriberPcId) {
      this.subscribePcIdsByMid[mid] = subscriberPcId;
    },

    removeMidAndTrack(mid) {
      this.sdp.tracks = this.sdp.tracks.filter(track => track !== mid);
      const index = this.sdp.mids.findIndex(m => m === mid);
      if (index < 0) {
        return;
      }

      this.sdp.mids[index] = null;

      // Disable mediaSection for mid
      this.sdp.mediaSections[index] = sdpHelpers.getDisabledSectionForMid(mid);
    },

    updateSdpHeaders() {
      // We only update headers when removing tracks with no iceRestart. Thus no new ice
      // credentials. Every time headers are updated, it is a new version
      const newVersion = this.sdp.version + 1;
      this.sdp.headers = sdpHelpers.updateVersion(this.sdp.headers, this.sdp.version, newVersion);
      this.sdp.version = newVersion;

      // Update bundle with the current tracks
      this.sdp.headers = sdpHelpers.updateBundleLine(this.sdp.headers, this.sdp.tracks);
      this.sdp.bundle = sdpHelpers.getBundleLine(this.sdp.headers);
    },

    processAnswer(newSdp) {
      // In order to prevent a media sections mismatch with Mantis, we extract the minimum
      // information from Mantis answer:
      //  - version
      //  - iceCredentials
      //  - first enabled media section
      // We need iceCredentials because they are new and we don't have them, and we also need the
      // first enabled media section because is one containing the credentials. Once gathered, we
      // update the cached SDP with this new information.
      const { headers, version, mediaSections, mids } = sdpHelpers.parseMantisSDP(newSdp);

      const updatedHeaders = sdpHelpers.updateVersion(this.sdp.headers, this.sdp.version, version);
      this.sdp.version = version;

      this.sdp.headers = sdpHelpers.updateIceCredentials(updatedHeaders, headers);

      this.sdp.mediaSections = sdpHelpers.updateFirstEnabledMediaSection(this.sdp,
        { mediaSections, mids });

      return sdpHelpers.createSDP(this.sdp.headers, this.sdp.mediaSections);
    },

    getPartialSdpAnswer(newAnswer) {
      if (!this.midsToAdd) {
        return newAnswer;
      }
      const partialSdp = getPartialSdpAnswer(sdpHelpers.parseSDP(newAnswer), this.midsToAdd);
      this.midsToAdd = undefined;
      return sdpHelpers.createSDP(partialSdp.headers, partialSdp.mediaSections);
    },

    getIceRestartPartialSdp(newOffer) {
      const partialSdp = getPartialIceRestartSdpOffer(sdpHelpers.parseSDP(newOffer));
      return sdpHelpers.createSDP(partialSdp.headers, partialSdp.mediaSections);
    },

    getPartialSdp(type, fullSdp) {
      if (type === 'answer') {
        return this.getPartialSdpAnswer(fullSdp);
      }
      return this.getIceRestartPartialSdp(fullSdp);
    },

    processOffer(parsedRemoteSdp) {
      // Add only mids that are not currently present in the cached SDP.
      this.midsToAdd = difference(parsedRemoteSdp.mids, this.sdp.mids);

      const fullSdp = getFullRemoteSdp('offer', parsedRemoteSdp, this.sdp);
      this.updateParsedSdp(fullSdp);
      return sdpHelpers.createSDP(fullSdp.headers, fullSdp.mediaSections);
    },

    removeSubscriberMids(subscriberPcId) {
      const midsToRemove = Object.keys(this.subscribePcIdsByMid)
        .filter(mid => this.subscribePcIdsByMid[mid] === subscriberPcId);

      midsToRemove.forEach((mid) => {
        this.removeMidAndTrack(mid);
        delete this.subscribePcIdsByMid[mid];
      });

      this.updateSdpHeaders();
    },

    isHead(subscriberPcId) {
      const headMid = this.sdp.mids.find(mid => mid !== null);
      return this.subscribePcIdsByMid[headMid] === subscriberPcId;
    },
  };

  return peerConnectionSDP;
};

export default createPeerConnectionSDP;

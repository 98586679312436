import { Box, Typography } from "@mui/material";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import ClinicRequestList from "./Tabs/ClinicRequestList";
import DeleteRequestList from "./Tabs/DeleteRequestList";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import { useState } from "react";
import PatientUrlLoader from "./PatientUrlLoader";
import PatientUrlSetter from "./PatientUrlSetter";

const PatientRequests = () => {
  const [type, setType] = useState("clinic_request");
  const [urlLoaded, setUrlLoaded] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>("");

  const handleChange = (_: any, newValue: string) => {
    setType(newValue);
    setPage(0);
  };

  return urlLoaded ? (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Patient Requests
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Clinic Request"
            value="clinic_request"
            {...a11yProps(0)}
          />
          <StyledTab
            label="Delete Request"
            value="delete_request"
            {...a11yProps(1)}
          />
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <MainTabPanel value={type} index={"clinic_request"}>
          <ClinicRequestList
            type={type}
            page={page}
            setPage={setPage}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </MainTabPanel>
        <MainTabPanel value={type} index={"delete_request"}>
          <DeleteRequestList
            type={type}
            page={page}
            setPage={setPage}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </MainTabPanel>
      </Box>
      <PatientUrlSetter page={page} type={type} searchText={searchText} />
    </>
  ) : (
    <PatientUrlLoader
      setPage={setPage}
      setType={setType}
      setSearchText={setSearchText}
      setUrlLoaded={setUrlLoaded}
    />
  );
};

export default PatientRequests;

import { useAppSelector } from "../../../Redux/hooks";
// import DailyLog from "./Block/Questionnaire/DailyLog/DailyLog";
// import Food from "./Block/Food/Food";
// import Medication from "./Block/Medication/Medication";
// import Onboarding from "./Block/Questionnaire/Onboarding/Onboarding";
// import PKGSummary from "./Block/PKGSummary/PKGSummary";
// import Reports from "./Block/Reports/Reports";
import PatientOverview from "./Block/PatientOverview/PatientOverview";
// import Exercises from "./Block/Exercises/Exercises";
import Chat from "../../UserManagement/Participants/Chat/Chat";
import ExerciseAnalysis from "./Block/ExerciseAnalysis/ExerciseAnalysis";
import QuestionnaireTabs from "./Block/Questionnaire/QuestionBlock/QuestionnaireTabs";

const HealthBlockSelector = () => {
  const { type } = useAppSelector((state) => state.health);

  switch (type) {
    case "patient_overview":
      return <PatientOverview />;
    case "chat":
      return <Chat />;
    // case "pkg_summary":
    //   return <PKGSummary />;
    // case "food":
    //   return <Food />;
    // case "onboarding":
    //   return <Onboarding />;
    // case "medication":
    //   return <Medication />;
    // case "exercise":
    //   return <Exercises />;
    // case "daily_log":
    //   return <DailyLog type="daily-log" />;
    // case "symptoms":
    //   return <DailyLog type="daily-symptoms" />;
    case "questionnaire":
      return <QuestionnaireTabs />;
    case "exercise_analysis":
      return <ExerciseAnalysis />;
    // case "reports":
    //   return <Reports />;
    default:
      return null;
  }
};

export default HealthBlockSelector;

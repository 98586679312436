import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";

const QuestionUrlSetter = () => {
  let [, setSearchParams] = useSearchParams();
  const { searchText, filterId, page } = useAppSelector(
    (state) => state.quesList
  );

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (searchText) {
      params.set("search", searchText);
    }
    if (filterId) {
      params.set("filter", filterId);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, searchText, page, filterId]);

  return <></>;
};

export default QuestionUrlSetter;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface administratorsState {
  urlLoaded: boolean;
  loading: boolean;
  type: string;
  page: number;
  searchText: string;
  sortOrder: string;
  sortColumn: string;
  administratorsData: any[];
  totalAdministrators: number;
  toggleLoader: boolean;
}

const initialState: administratorsState = {
  urlLoaded: false,
  loading: true,

  type: "master_admin",
  page: 0,
  searchText: "",
  sortOrder: "",
  sortColumn: "",
  toggleLoader: false,

  administratorsData: [],
  totalAdministrators: 0,
};

export const administratorsSlice = createSlice({
  name: "administrators",
  initialState: initialState,
  reducers: {
    setAdministratorsLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAdministratorsType: (state, action: PayloadAction<string>) => {
      state.searchText = "";
      state.sortColumn = "";
      state.sortOrder = "";
      state.page = 0;
      state.type = action.payload;
    },
    setAdministratorsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    refreshAdministratorsPage: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setAdministratorsSearchText: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.searchText = action.payload;
    },
    setAdministratorsDetails: (state, action: PayloadAction<any>) => {
      state.administratorsData = action.payload;
    },
    setAdministratorsCount: (state, action: PayloadAction<number>) => {
      state.totalAdministrators = action.payload;
    },
    setAdministratorsSort: (
      state,
      action: PayloadAction<{ column: string; order: string }>
    ) => {
      state.sortColumn = action.payload.column;
      state.sortOrder = action.payload.order;
    },
    setAdministratorsDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        type: string;
        order: string;
        sort: string;
        page: number;
      }>
    ) => {
      state.type = action.payload.type;
      state.searchText = action.payload.search;
      state.sortOrder = action.payload.order;
      state.sortColumn = action.payload.sort;
      state.page = action.payload.page;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    reset: () => initialState,
  },
});

export const {
  setAdministratorsLoader,
  setAdministratorsType,
  setAdministratorsPage,
  setAdministratorsDefaults,
  setUrlLoadedFalse,
  setAdministratorsSearchText,
  setAdministratorsDetails,
  setAdministratorsCount,
  setAdministratorsSort,
  refreshAdministratorsPage,
  reset,
} = administratorsSlice.actions;

export default administratorsSlice.reducer;

import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { Box, Typography } from "@mui/material";
import ProgramInsights from "./ProgramInsightsTab/ProgramInsights";

const Dashboard = () => {
  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Dashboard
          </Typography>
        </Box>
      </StyledHeader>
      {/* <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Program Insights"
            value="program"
            {...a11yProps(0)}
          />
          <StyledTab label="DTx Insights" value="dtx" {...a11yProps(1)} />
        </StyledTabs>
      </Box> */}
      <Box sx={{ mt: "24px" }}>
        {/* <MainTabPanel value={type} index={"program"}> */}
        <ProgramInsights />
        {/* </MainTabPanel> */}
        {/* <MainTabPanel value={type} index={"dtx"}>
          <DTxInsights />
        </MainTabPanel> */}
      </Box>
    </>
  );
};

export default Dashboard;

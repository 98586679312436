import { SxProps } from "@mui/material";

export const rowFlexStyle: SxProps = {
  display: "flex",
  flexDirection: "row",
};

export const rowFlexStyleAlignCenter: SxProps = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const colFlexStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
};

import { Box, Typography } from "@mui/material";
import React from "react";
import { DashboardCardStyle } from "./dashboard.style";

type Props = {
  data: any;
};

const DashboardCard = ({ data }: Props) => {
  const formattedCount = data?.count?.toLocaleString();
  const formattedTotal = data?.total?.toLocaleString();
  return (
    <Box sx={DashboardCardStyle}>
      <Typography fontSize={32} fontWeight="medium" color="#355962">
        {data?.total
          ? `${formattedCount}/${formattedTotal}`
          : `${formattedCount}`}
      </Typography>
      <Typography variant="subtitle1" fontWeight="medium" color="#637E85">
        {data?.label}
      </Typography>
      {/* {progress && (
          <CircularProgressWithLabel value={progress} size={80} fontSize={20} />
        )} */}
    </Box>
  );
};

export default DashboardCard;

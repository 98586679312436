import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { errorToastMessage } from "../../../utils/toast";
import { CardsWrapper } from "../dashboard.style";
import DashboardCard from "../DashboardCard";
import { LoadingContainer } from "../../CMS/cms.style";
// import TopSymptoms from "./TopSymptoms";
// import PatientRequestsBar from "./PatientRequestsBar";
// import PatientAdherence from "./PatientAdherence";
// import AffectingSymptoms from "./QolAffectingSymptoms";
import { useAppSelector } from "../../../Redux/hooks";
import { canViewAllMetrics } from "../../../utils/roles";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { formatAnalyticsData } from "../../../utils/dashboard";
// import http from "../../utils/http";
// import { AxiosResponse } from "axios";

// const calculateTickValue = (data: any) => {
//   const maxNo = Math.max(...data.map((v: any) => v.high + v.low + v.medium));
//   const arr = Array.from(
//     { length: Math.floor(maxNo / 20) },
//     (_, i) => (i + 1) * 20
//   );
//   return maxNo > 20 ? arr.length + 1 : arr.length;
// };
const ProgramInsights = () => {
  const userRole = useAppSelector((state) => state.user.role);
  const [loading, setLoading] = useState(false);
  const [patientAnalytics, setPatientAnalytics] = useState<any[]>([]);

  const [hasAllPatientMetricsAccess] = useState<Boolean>(
    canViewAllMetrics.includes(userRole)
  );
  useEffect(() => {
    const fetchProgramData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get("/dashboard");
        const data = res?.data?.data;
        const patientAnalyticsData = formatAnalyticsData(
          data?.metrics || [],
          hasAllPatientMetricsAccess
        );
        setPatientAnalytics(patientAnalyticsData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchProgramData();
  }, [hasAllPatientMetricsAccess]);

  return !loading ? (
    <>
      <Box sx={CardsWrapper}>
        {patientAnalytics?.map((item: any) => {
          if (!item.hide) {
            return <DashboardCard key={item.label} data={item} />;
          }
          return null;
        })}
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          gap: "16px",
          flexWrap: "wrap",
        }}
      >
        <TopSymptoms
          data={topFiveSymptoms}
          // tickValue={calculateTickValue(healthProgress) || 0}
        />
        <AffectingSymptoms data={affectingSymptoms} />
        <PatientAdherence data={adherence} />
        {hasPatientRequestAccess && <PatientRequestsBar data={requestsData} />}
      </Box> */}
    </>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default ProgramInsights;

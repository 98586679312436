// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable  import/newline-after-import, no-param-reassign */
import clone from 'lodash/clone';
import NativeVideoElementWrapperFactory from './helpers/video_element/NativeVideoElementWrapper/NativeVideoElementWrapper';
import eventing from './helpers/eventing';
import createLogger from './helpers/log';
import logLevels from './helpers/logLevels';
import allDeviceHelpers from './helpers/deviceHelpers';
import audioContextFactory from './helpers/audio_context';
import GetAudioLevelSampler from './helpers/audio_level_samplers/get_audio_level_sampler';
import WebAudioAudioLevelSampler from './helpers/audio_level_samplers/webaudio_audio_level_sampler';
import IntervalRunner from './ot/interval_runner';
import ExceptionCodes from './ot/exception_codes';
import OTErrorClass from './ot/ot_error_class';
import getNativeEnumerateDevicesFactory from './helpers/getNativeEnumerateDevices';
import VideoElementFacadeFactory from './helpers/video_element/index';
import videoOrientationFactory from './helpers/video_orientation';
import eventNames from './helpers/eventNames';
import Event from './helpers/Event';
import getStaticConfig from './helpers/StaticConfig';
import AnalyticsHelper from './helpers/analytics';
import setDeprecatedProperty from './helpers/setDeprecatedProperty';
import runtimeProperties from './runtimeProperties';
import mutableRuntimeProperties from './mutableRuntimeProperties';
import getNoConflict from './helpers/no_conflict';
import sessionObjects from './ot/session/objects';
import otErrorFactory from './helpers/otError';
import Errors from './ot/Errors';
import getWindowMock from './helpers/createWindowMock';
import OTHelpers from './common-js-helpers/OTHelpers';
import APIKEY from './ot/api_key';
import AudioLevelTransformer from './ot/audio_level_transformer';
import calculateCapableSimulcastStreams from './ot/publisher/calculateCapableSimulcastStreams';
import Chrome from './ot/chrome/chrome';
import EnvironmentLoader from './ot/environment_loader';
import generateConstraintInfo from './ot/publisher/generateConstraintInfo';
import guidStorage from './helpers/guid_storage';
import setLogLevel from './ot/setLogLevel';
import { parseIceServers } from './RaptorSession/raptor/parseIceServers';
import PUBLISH_MAX_DELAY from './ot/publisher/max_delay';
import StreamChannel from './ot/stream_channel';
import StylableComponent from './ot/styling/stylable_component';
import systemRequirements from './ot/system_requirements';
import getPeerConnection from './ot/peer_connection/peer_connection';
import getChromeExtensionHelper from './ot/screensharing/chrome_extension_helper';
import getCreateChromeMixin from './ot/publisher/createChromeMixin';
import getEvents from './ot/events';
import getGenerateSimpleStateMachine from './ot/generate_simple_state_machine';
import getMediaHelper from './helpers/get_user_media';
import getScreenSharing from './ot/screensharing/screen_sharing';
import getProcessPubOptions from './ot/publisher/processPubOptions';
import getMedia from './ot/getUserMedia';
import getInterpretPeerConnectionError from './ot/interpretPeerConnectionError';
import getMicrophone from './ot/publisher/microphone';
import getVideoElementErrorMap from './helpers/video_element/videoElementErrorMap';
import getCertificates from './ot/peer_connection/set_certificates';
import getPublisherPeerConnection from './ot/peer_connection/publisher_peer_connection';
import getSubscriberPeerConnection from './ot/peer_connection/subscriber_peer_connection';
import getPublishingState from './ot/publisher/state';
import getReportIssue from './ot/report_issue';
import getWidgetView from './helpers/widget_view';
import getSubscriber from './ot/subscriber';
import getPublisher from './ot/publisher';
import getInitPublisher from './ot/publisher/init';
import getSession from './ot/session';
import getInitSession from './ot/session/init';
import getSupportedCodecs from './ot/getSupportedCodecs';
import hasMediaProcessorSupport from './ot/hasMediaProcessorSupport';
import hasEndToEndEncryptionSupport from './ot/hasEndToEndEncryptionSupport';
import getArchive from './ot/archive';
import Capabilities from './ot/capabilities';
import Connection from './ot/connection';
import getDevices from './ot/get_devices';
import getAudioOutputDevices from './ot/get-audio-output-devices';
import getActiveAudioOutputDevice from './ot/get-active-audio-output-device';
import setAudioOutputDevice from './ot/set-audio-output-device';
import Qos from './ot/peer_connection/qos/Qos';
import SessionDispatcher from './RaptorSession/raptor/SessionDispatcher';
import Signal from './RaptorSession/raptor/Signal';
import Stream from './ot/stream';
import SubscribingState from './ot/subscriber/state';
import getStatsHelpers from './ot/peer_connection/get_stats_helpers';
import getStatsAdapter from './ot/peer_connection/getStatsAdapter';
import { setProxyUrl } from './ot/proxyUrl';
import unblockAudio from './ot/unblockAudio';
import audioOutputDevicesChangeListener from './ot/audio-output-devices-change-listener';

import '../css/ot.css';
const StaticConfig = getStaticConfig();

const logging = createLogger('OT');

const staticConfig = StaticConfig.onlyLocal();

// We need to do this first because of circular dependency issues with otplugin.js. @TODO: fix this.
const OT = {};

// noConflict is constructed before we override global.OT so we keep the original value
OT.noConflict = getNoConflict();

global.OT = OT;
/* eslint-disable-next-line import/no-commonjs */
module.exports = OT;

(() => {
  let TB = OT;
  Object.defineProperty(
    global,
    'TB',
    {
      get: () => {
        if (TB === OT) {
          // eslint-disable-next-line no-console
          console.warn('window.TB is deprecated, and will be removed in the future. Please access via window.OT');
        }
        return TB;
      },
      set: (_TB) => {
        TB = _TB;
      }, // We provide a setter so noConflict works
      configurable: true,
    }
  );
})();

const windowMock = getWindowMock(global);
const PeerConnection = getPeerConnection({
  global: windowMock,
});

const otError = otErrorFactory({});

const audioContext = audioContextFactory();
const chromeExtensionHelper = getChromeExtensionHelper({
  otError,
});
const createChromeMixin = getCreateChromeMixin();
const getNativeEnumerateDevices = getNativeEnumerateDevicesFactory({
  global: windowMock,
});
const deviceHelpers = allDeviceHelpers({
  getNativeEnumerateDevices,
});
const Events = getEvents();
const generateSimpleStateMachine = getGenerateSimpleStateMachine();
const getUserMediaHelper = getMediaHelper({
  otError,
  global: windowMock,
});
const screenSharing = getScreenSharing({
  chromeExtensionHelper,
  otError,
});
const processPubOptions = getProcessPubOptions({
  deviceHelpers,
  generateConstraintInfo,
  getUserMediaHelper,
  global: windowMock,
  OTHelpers,
  screenSharing,
});
const getUserMedia = getMedia({
  otError,
  processPubOptions,
});
const interpretPeerConnectionError = getInterpretPeerConnectionError({
  otError,
});
const Microphone = getMicrophone();
const videoElementErrorMap = getVideoElementErrorMap({
  otError,
});
const NativeVideoElementWrapper = NativeVideoElementWrapperFactory({
  global: windowMock,
  videoElementErrorMap,
});
const setCertificates = getCertificates({
  global: windowMock,
});
const PublisherPeerConnection = getPublisherPeerConnection({
  PeerConnection,
  setCertificates,
});
const SubscriberPeerConnection = getSubscriberPeerConnection({
  PeerConnection,
  setCertificates,
});
const PublishingState = getPublishingState();
const reportIssue = getReportIssue({
  otError,
});
const VideoElementFacade = VideoElementFacadeFactory({
  NativeVideoElementWrapper,
});
const VideoOrientation = videoOrientationFactory();
const WidgetView = getWidgetView({
  VideoElementFacade,
});

const Subscriber = getSubscriber({
  interpretPeerConnectionError,
  otError,
  SubscriberPeerConnection,
  WidgetView,
});

const Publisher = getPublisher({
  APIKEY,
  createChromeMixin,
  deviceHelpers,
  EnvironmentLoader,
  Errors,
  Events,
  ExceptionCodes,
  calculateCapableSimulcastStreams,
  global: windowMock,
  interpretPeerConnectionError,
  IntervalRunner,
  Microphone,
  otError,
  OTErrorClass,
  OTHelpers,
  parseIceServers,
  processPubOptions,
  PUBLISH_MAX_DELAY,
  PublisherPeerConnection,
  PublishingState,
  StreamChannel,
  systemRequirements,
  VideoOrientation,
  WidgetView,
});

const initPublisher = getInitPublisher({
  otError,
  Publisher,
});

const Session = getSession({
  global: windowMock,
  initPublisher,
  otError,
  Publisher,
  Subscriber,
});

const initSession = getInitSession({
  Session,
});

// Allow events to be bound on OT
eventing(OT);

setDeprecatedProperty(
  OT,
  '$',
  {
    name: 'OT.$',
    getMessage: 'Please use an external library like jQuery to select elements from the page.',
    value: OTHelpers,
  }
);

// Define the APIKEY this is a global parameter which should not change
OT.APIKEY = APIKEY.value;

OT.AnalyserAudioLevelSampler = WebAudioAudioLevelSampler;

OT.Archive = getArchive;
OT.ArchiveEvent = Events.ArchiveEvent;
OT.ArchiveUpdatedEvent = Events.ArchiveUpdatedEvent;
OT.AudioLevelTransformer = AudioLevelTransformer;
OT.AudioLevelUpdatedEvent = Events.AudioLevelUpdatedEvent;
OT.Capabilities = Capabilities;
OT.Chrome = Chrome;
OT.Connection = Connection;
OT.ConnectionCapabilities = OT.Connection.Capabilities;
OT.ConnectionEvent = Events.ConnectionEvent;

Object.keys(logLevels).forEach((name) => {
  OT[name.toUpperCase()] = logLevels[name].priority;
});

OT.NONE = 0;

OT.debug = logging.debug;
OT.error = logging.error;
OT.info = logging.info;
OT.log = logging.log;
OT.warn = logging.warn;

OT.DestroyedEvent = Events.DestroyedEvent;

OT.EnvLoadedEvent = Events.EnvLoadedEvent;

// TODO: re-expose old screenSharing api

OT.Error = OTErrorClass;

OT.Error.on(eventNames.EXCEPTION, (exceptionEvent) => {
  if (exceptionEvent.target === OT.Error) {
    // Rebind target to OT if it's set to OT.Error to preserve old behaviour.
    const exceptionEventClone = clone(exceptionEvent);
    exceptionEventClone.target = OT;
    OT.dispatchEvent(exceptionEventClone);
  } else {
    OT.dispatchEvent(exceptionEvent);
  }
});

OT.Event = Event;
OT.ExceptionCodes = ExceptionCodes;
OT.ExceptionEvent = Events.ExceptionEvent;

OT.getDevices = getDevices;
OT.getAudioOutputDevices = getAudioOutputDevices;
OT.getActiveAudioOutputDevice = getActiveAudioOutputDevice;
OT.setAudioOutputDevice = setAudioOutputDevice();
OT.GetAudioLevelSampler = GetAudioLevelSampler;
OT.HAS_REQUIREMENTS = 1;

OT.IntervalRunner = IntervalRunner;
OT.IssueReportedEvent = Events.IssueReportedEvent;
OT.MediaStoppedEvent = Events.MediaStoppedEvent;
OT.Microphone = Microphone;
OT.NOT_HAS_REQUIREMENTS = 0;
OT.PeerConnection = PeerConnection;

// TODO: this is here for repel, but it doesn't belong here
OT.PeerConnection.QOS = Qos;

OT.Publisher = Publisher;
OT.PublisherPeerConnection = PublisherPeerConnection;
OT.PublishingState = PublishingState;
OT.MuteForcedEvent = Events.MuteForcedEvent;
OT.Session = Session;
OT.SessionConnectEvent = Events.SessionConnectEvent;
OT.SessionDisconnectEvent = Events.SessionDisconnectEvent;
OT.SessionDispatcher = SessionDispatcher;
OT.Signal = Signal;
OT.SignalEvent = Events.SignalEvent;
OT.Stream = Stream;
OT.StreamChannel = StreamChannel;
OT.StreamEvent = Events.StreamEvent;
OT.StreamPropertyChangedEvent = Events.StreamPropertyChangedEvent;
OT.StreamUpdatedEvent = Events.StreamUpdatedEvent;
OT.StylableComponent = StylableComponent;
OT.Subscriber = Subscriber;
OT.SubscriberPeerConnection = SubscriberPeerConnection;
OT.SubscribingState = SubscribingState;
OT.VideoDimensionsChangedEvent = Events.VideoDimensionsChangedEvent;
OT.VideoDisableWarningEvent = Events.VideoDisableWarningEvent;
OT.VideoElement = VideoElementFacade;
OT.VideoEnabledChangedEvent = Events.VideoEnabledChangedEvent;
OT.VideoOrientation = VideoOrientation;
OT.WidgetView = WidgetView;

OT.getSupportedCodecs = getSupportedCodecs;
OT.hasMediaProcessorSupport = hasMediaProcessorSupport;
OT.hasEndToEndEncryptionSupport = hasEndToEndEncryptionSupport;

OT._ = {
  AnalyticsHelper,
  getClientGuid: guidStorage.get,
  StaticConfig,
};

/**
 *
 * The currently loaded version of OpenTok.js.
 *
 * @property {string} version
 * @readonly
 * @memberof OT
 */
Object.defineProperty(OT, 'version', { value: staticConfig.version });

const properties = {};

const canPropertyBeMutated = property =>
  mutableRuntimeProperties.includes(property);

setDeprecatedProperty(properties, 'version', {
  value: staticConfig.version,
  name: 'OT.properties.version',
  getWarning: 'Please use OT.version instead',
  warnOnSet: true,
  setWarning: 'Mutating version has no effect',
});

runtimeProperties.forEach((keyMap) => {
  let value;
  let key;

  if (Array.isArray(keyMap)) {
    const [propKey, staticConfigKey] = keyMap;
    key = propKey;
    value = staticConfig[staticConfigKey] || staticConfigKey;
  } else {
    value = staticConfig[keyMap];
    key = keyMap;
  }
  const isPropertyMutable = canPropertyBeMutated(key);
  setDeprecatedProperty(properties, key, {
    value,
    name: `OT.properties.${key}`,
    warnOnSet: true,
    canSet: isPropertyMutable,
    setWarning: isPropertyMutable ? `Mutating ${key} can cause side effects` : `Mutating ${key} has no effect`,
  });
});

OT.properties = properties;

// OT.addEventListener comes from eventing(OT)
OT.audioContext = audioContext;
OT.checkScreenSharingCapability = screenSharing.checkCapability;
OT.checkSystemRequirements = systemRequirements.check;
OT.components = {};

// OT.dispatchEvent comes from eventing(OT)
// OT.emit comes from eventing(OT)
OT.generateSimpleStateMachine = generateSimpleStateMachine;
OT.getErrorTitleByCode = OTErrorClass.getTitleByCode;
OT.getLogs = logging.getLogs;

// This is misspelled in production too, being compatible here.
OT.getStatsAdpater = getStatsAdapter;

OT.getStatsHelpers = getStatsHelpers;
OT.getUserMedia = getUserMedia;
OT.handleJsException = OTErrorClass.handleJsException;
OT.initPublisher = initPublisher;

OT.setProxyUrl = setProxyUrl;

OT.initSession = function (apiKey, sessionId, opt) {
  if (sessionId == null) {
    sessionId = apiKey;
    apiKey = null;
  }

  // Ugly hack, make sure OT.APIKEY is set
  // TODO: Yep, sure is ugly. It appears to be needed by raptor. We should fix this situation.
  // UPDATE: This hack is the only reason why we need to wrap the actual initSession.
  if (APIKEY.value.length === 0 && apiKey) {
    APIKEY.value = apiKey;
    OT.APIKEY = apiKey;
  }

  return initSession(apiKey, sessionId, opt);
};

const deprecatedMessage = key => `${key} is deprecated and will be removed in a future version of OpenTok`;
const warnAndReturn = (name, value) => () => {
  // eslint-disable-next-line no-console
  console.warn(deprecatedMessage(name));
  return value;
};

// discourage us and others from using these OT methods
Object.defineProperties(OT, {
  isUnloaded: { get: warnAndReturn('OT.isUnloaded', EnvironmentLoader.isUnloaded) },
  onLoad: { get: warnAndReturn('OT.onLoad', EnvironmentLoader.onLoad) },
  onUnload: { get: warnAndReturn('OT.onUnload', EnvironmentLoader.onUnload) },
  overrideGuidStorage: { get: warnAndReturn('OT.overrideGuidStorage', guidStorage.override) },
});

// OT.off comes from eventing(OT)
// OT.on comes from eventing(OT)
// OT.once comes from eventing(OT)
// OT.removeEventListener comes from eventing(OT)
// OT.trigger comes from eventing(OT)

// Exposed here for partner usage.
OT.pickScreenSharingHelper = screenSharing.pickHelper;
OT.publishers = sessionObjects.publishers;
OT.registerScreenSharingExtension = screenSharing.registerExtension;
OT.registerScreenSharingExtensionHelper = screenSharing.registerExtensionHelper;

OT.reportIssue = reportIssue;
OT.sessions = sessionObjects.sessions;
OT.setLogLevel = setLogLevel;
OT.shouldLog = logging.shouldLog;
OT.subscribers = sessionObjects.subscribers;
OT.unblockAudio = unblockAudio;
OT.upgradeSystemRequirements = systemRequirements.upgrade;

// Tidy up everything on unload
EnvironmentLoader.onUnload(() => {
  sessionObjects.publishers.destroy();
  sessionObjects.subscribers.destroy();
  sessionObjects.sessions.destroy('unloaded');
});

audioOutputDevicesChangeListener();

/**
 * This method is deprecated. Use <a href="#on">on()</a> or <a href="#once">once()</a> instead.
 *
 * <p>
 * Registers a method as an event listener for a specific event.
 * </p>
 *
 * <p>
 * The OT object dispatches one type of event &#151; an <code>exception</code> event. The
 * following code adds an event listener for the <code>exception</code> event:
 * </p>
 *
 * <pre>
 * OT.addEventListener("exception", exceptionHandler);
 *
 * function exceptionHandler(event) {
 *    alert("exception event. \n  code == " + event.code + "\n  message == " + event.message);
 * }
 * </pre>
 *
 * <p>
 *   If a handler is not registered for an event, the event is ignored locally. If the event
 *   listener function does not exist, the event is ignored locally.
 * </p>
 * <p>
 *   Throws an exception if the <code>listener</code> name is invalid.
 * </p>
 *
 * @param {String} type The string identifying the type of event.
 *
 * @param {Function} listener The function to be invoked when the OT object dispatches the event.
 * @see <a href="#on">on()</a>
 * @see <a href="#once">once()</a>
 * @memberof OT
 * @method addEventListener
 *
 */

/**
 * This method is deprecated. Use <a href="#off">off()</a> instead.
 *
 * <p>
 * Removes an event listener for a specific event.
 * </p>
 *
 * <p>
 *   Throws an exception if the <code>listener</code> name is invalid.
 * </p>
 *
 * @param {String} type The string identifying the type of event.
 *
 * @param {Function} listener The event listener function to remove.
 *
 * @see <a href="#off">off()</a>
 * @memberof OT
 * @method removeEventListener
 */

/**
* Adds an event handler function for one or more events.
*
* <p>
* The OT object dispatches one type of event &#151; an <code>exception</code> event. The following
* code adds an event
* listener for the <code>exception</code> event:
* </p>
*
* <pre>
* OT.on("exception", function (event) {
*   // This is the event handler.
* });
* </pre>
*
* <p>You can also pass in a third <code>context</code> parameter (which is optional) to define the
* value of
* <code>this</code> in the handler method:</p>
*
* <pre>
* OT.on("exception",
*   function (event) {
*     // This is the event handler.
*   }),
*   session
* );
* </pre>
*
* <p>
* If you do not add a handler for an event, the event is ignored locally.
* </p>
*
* @param {String} type The string identifying the type of event.
* @param {Function} handler The handler function to process the event. This function takes the event
* object as a parameter.
* @param {Object} context (Optional) Defines the value of <code>this</code> in the event handler
* function.
*
* @memberof OT
* @method on
* @see <a href="#off">off()</a>
* @see <a href="#once">once()</a>
* @see <a href="#events">Events</a>
*/

/**
* Adds an event handler function for an event. Once the handler is called, the specified handler
* method is
* removed as a handler for this event. (When you use the <code>OT.on()</code> method to add an event
* handler, the handler
* is <i>not</i> removed when it is called.) The <code>OT.once()</code> method is the equivilent of
* calling the <code>OT.on()</code>
* method and calling <code>OT.off()</code> the first time the handler is invoked.
*
* <p>
* The following code adds a one-time event handler for the <code>exception</code> event:
* </p>
*
* <pre>
* OT.once("exception", function (event) {
*   console.log(event);
* }
* </pre>
*
* <p>You can also pass in a third <code>context</code> parameter (which is optional) to define the
* value of
* <code>this</code> in the handler method:</p>
*
* <pre>
* OT.once("exception",
*   function (event) {
*     // This is the event handler.
*   },
*   session
* );
* </pre>
*
* <p>
* The method also supports an alternate syntax, in which the first parameter is an object that is a
* hash map of
* event names and handler functions and the second parameter (optional) is the context for this in
* each handler:
* </p>
* <pre>
* OT.once(
*   {exeption: function (event) {
*     // This is the event handler.
*     }
*   },
*   session
* );
* </pre>
*
* @param {String} type The string identifying the type of event. You can specify multiple event
* names in this string,
* separating them with a space. The event handler will process the first occurence of the events.
* After the first event,
* the handler is removed (for all specified events).
* @param {Function} handler The handler function to process the event. This function takes the event
* object as a parameter.
* @param {Object} context (Optional) Defines the value of <code>this</code> in the event handler
* function.
*
* @memberof OT
* @method once
* @see <a href="#on">on()</a>
* @see <a href="#once">once()</a>
* @see <a href="#events">Events</a>
*/

/**
 * Removes an event handler.
 *
 * <p>Pass in an event name and a handler method, the handler is removed for that event:</p>
 *
 * <pre>OT.off("exceptionEvent", exceptionEventHandler);</pre>
 *
 * <p>If you pass in an event name and <i>no</i> handler method, all handlers are removed for that
 * events:</p>
 *
 * <pre>OT.off("exceptionEvent");</pre>
 *
 * <p>
 * The method also supports an alternate syntax, in which the first parameter is an object that is a
 * hash map of
 * event names and handler functions and the second parameter (optional) is the context for matching
 * handlers:
 * </p>
 * <pre>
 * OT.off(
 *   {
 *     exceptionEvent: exceptionEventHandler
 *   },
 *   this
 * );
 * </pre>
 *
 * @param {String} type (Optional) The string identifying the type of event. You can use a space to
 * specify multiple events, as in "eventName1 eventName2 eventName3". If you pass in no
 * <code>type</code> value (or other arguments), all event handlers are removed for the object.
 * @param {Function} handler (Optional) The event handler function to remove. If you pass in no
 * <code>handler</code>, all event handlers are removed for the specified event <code>type</code>.
 * @param {Object} context (Optional) If you specify a <code>context</code>, the event handler is
 * removed for all specified events and handlers that use the specified context.
 *
 * @memberof OT
 * @method off
 * @see <a href="#on">on()</a>
 * @see <a href="#once">once()</a>
 * @see <a href="#events">Events</a>
 */

/**
 * Dispatched by the OT class when the app encounters an exception.
 * Note that you set up an event handler for the <code>exception</code> event by calling the
 * <code>OT.on()</code> method.
 *
 * @name exception
 * @event
 * @borrows ExceptionEvent#message as this.message
 * @memberof OT
 * @see ExceptionEvent
 */

import eventing from './eventing';

function isDomReady() {
  // if there is no document, how is the document object model ready?
  return (
    typeof document === 'undefined' ||
    document.readyState === 'complete' ||
    (document.readyState === 'interactive' && document.body)
  );
}

let unloaded = false;

const domState = {
  isDomLoaded: isDomReady,
  isDomUnloaded() {
    return unloaded;
  },
  whenUnloaded: new Promise((resolve) => {
    if (typeof window !== 'undefined') {
      const unload = () => {
        unloaded = true;
        domState.emit('unload');
        resolve();
      };
      window.addEventListener('unload', unload);
    }
  }),
  whenLoaded: new Promise((resolve) => {
    if (isDomReady()) {
      resolve();
      return;
    }
    const load = () => {
      document.removeEventListener('DOMContentLoaded', load);
      document.removeEventListener('load', load);
      domState.emit('load');
      resolve();
    };
    document.addEventListener('DOMContentLoaded', load);
    document.addEventListener('load', load);
  }),
};

eventing(domState);

export default domState;

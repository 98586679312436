import axios from "axios";
import http from "./http";

export const uploadFile = async (file: any, type: string) => {
  const fileObj = {
    assets: [{ fileName: file.name, type }],
  };
  const res = await http.post("/assets/get_upload_urls", fileObj);
  await axios.put(res.data.data[0].presignedUploadUrl, file, {
    headers: { "Content-Type": res.data.data[0].mimeType },
  });
  return res.data.data[0].postUploadImageUrl;
};

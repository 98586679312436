import QuestionPickerItem from "./QuestionPickerItem";
import { questionTypes } from "../questionTypes";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useAppSelector } from "../../../Redux/hooks";

const QBPickerContainer = styled("div")(({ theme }) => ({
  // height: "100%",
  // padding: theme.spacing(2.5, 3.75),
  // borderRight: "1px solid",
  // borderRightColor: "#E7E7E7",
}));

const QBPickerWrapper = styled("div")(({ theme }) => ({
  minHeight: "250px",
  maxHeight: "calc(100vh - 152px)",
  top: 0,
  position: "sticky",
  borderRadius: "4px",
  width: "278px",
  backgroundColor: "#ffffff",
  // boxShadow: "0px 10px 24px rgba(0, 0, 0, 0.12)",
  padding: theme.spacing(2),
  overflow: "hidden",
  "&:hover": {
    overflow: "auto",
  },
}));

const QuestionPicker = () => {
  const editable = useAppSelector((state) => state.question.editable);

  return (
    <QBPickerContainer>
      <QBPickerWrapper>
        <Typography variant="h5" color="textSecondary" sx={{ mb: 1.25 }}>
          Select question type
        </Typography>
        <Typography variant="body1" color="#6B7280" sx={{ mb: 2.5 }}>
          Drag and drop new question types to the left. Edit or rearrange them
          after.
        </Typography>
        <div>
          {questionTypes.map((questionType) => (
            <QuestionPickerItem
              key={questionType.key}
              questionType={questionType}
              editable={editable}
            />
          ))}
        </div>
      </QBPickerWrapper>
    </QBPickerContainer>
  );
};

export default QuestionPicker;

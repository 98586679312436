import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { DateCalendar } from "@mui/x-date-pickers";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { errorToastMessage } from "../../../../../utils/toast";
import { DateTime } from "luxon";
import { NoDataContainer } from "../../../../Common/styles/table";
import AnalysisItem from "./AnalysisItem";

const ExerciseAnalysis = () => {
  const [data, setData] = useState<any>({});
  const [currentDate, setCurrentDate] = useState<DateTime>(
    DateTime.local().startOf("month")
  );
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [error, setError] = useState("");
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.local().endOf("month")
  );
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (currentDate && endDate) {
          setLoading(true);

          const start = currentDate.startOf("day");
          const end = endDate.endOf("day");

          const res: AxiosResponse = await http.get(
            `/user-progress/admin?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const data = res?.data?.data;
          const newData = {
            usage: {
              value: data?.kemtaiUsage,
              title: "Active Days",
              notes: "Days you have done at least one Toro Session",
            },
            streak: {
              value: data?.kemtaiStreak,
              title: "Active Days in a row",
              notes: "Consecutive days you have done at least one Toro session",
            },
            dates: data?.kemtaiUsageDates?.map((item: { date: string }) =>
              DateTime?.fromFormat(item?.date, "yyyy-MM-dd")
            ),
          };
          setData(newData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [setLoading, id, currentDate, endDate]);

  const handleMonthChange = (date: any) => {
    setCurrentDate(date.startOf("month"));
    setEndDate(date.endOf("month"));
  };

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: 2,
      }}
    >
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : data ? (
    <Box
      mt={2}
      display={"flex"}
      bgcolor={"#FFFFFF"}
      gap={8}
      minHeight={"600px"}
      padding={2}
      flexWrap={"wrap"}
    >
      <Box
        sx={{
          // minWidth: "600px",
          flex: 1,
          "& .calendar-view": {
            "& .MuiDayCalendar-weekContainer": {
              "& .MuiPickersDay-root": {
                backgroundColor: "transparent",
                border: 1,
                color: "#828282",
                cursor: "default",
              },
            },
          },
        }}
        px={7}
        py={7}
      >
        <DateCalendar
          className="calendar-view"
          // onChange={handleMonthChange}
          onMonthChange={handleMonthChange}
          readOnly
          value={currentDate}
          disableHighlightToday
          slots={{
            day: (props: PickersDayProps<DateTime>) => {
              const isSelected = data?.dates?.some((item: DateTime) =>
                item?.hasSame(props?.day, "day")
              );

              return (
                <PickersDay
                  {...props}
                  disableRipple
                  disableTouchRipple
                  sx={{
                    backgroundColor: isSelected
                      ? "#DBF5F2 !important"
                      : "transparent",
                  }}
                />
              );
            },
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        mt={3}
        sx={{ flex: 1 }}
      >
        <AnalysisItem data={data?.usage} />
        <AnalysisItem data={data?.streak} />
      </Box>
    </Box>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default ExerciseAnalysis;

import generateKey from './../sframe/generateKey';
import arrayBufferToHex from './arrayBufferToHex';

// Note: this same algorithm is being use by the native SDKs.  (We have a unit
// test to confirm correctness as well).
const extractSenderIdForStandardSFrame = async (sessionId, connectionId) => {
  const key = await generateKey(sessionId, connectionId);
  const exportedKey = await window.crypto.subtle.exportKey('raw', key);
  const trimmedKeyBuffer = [...new Uint8Array(exportedKey)];

  // We trim to workaround javascript's issues with large integers
  trimmedKeyBuffer.splice(6);
  const keyAsHex = arrayBufferToHex(trimmedKeyBuffer);

  return parseInt(keyAsHex, 16);
};

export default async ({ sessionId, connectionId }) =>
  extractSenderIdForStandardSFrame(sessionId, connectionId);

import { ThemeProvider, createTheme } from "@mui/material";
import { cmsTheme } from "./cmsTheme";
import { useMemo } from "react";

const CMSThemeWrapper = ({ children, isDarkMode }: any) => {
  const theme = useMemo(
    () =>
      createTheme({
        ...cmsTheme,
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                color: isDarkMode ? "#fff" : "#000",
                backgroundColor: isDarkMode ? "#2E2E2E" : "none",
                "& fieldset": {
                  borderColor: "rgba(121, 122, 130, 0.3)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(121, 122, 130, 0.5) !important",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#5091CD !important",
                },
              },
            },
          },
        },
      }),
    [isDarkMode]
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CMSThemeWrapper;

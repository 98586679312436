// import { logCustomGaEvent } from "../../../utils/webviewAnalytics";

type Props = {
  item: any;
  pageId: string;
};

const WebviewResource: React.FC<Props> = ({ item, pageId }) => {
  const clickHandler = (value: string) => {
    try {
      // logCustomGaEvent("em_href_click", {
      //   page_id: pageId,
      //   section_id: item?.id,
      //   href: value,
      // });
      if (
        localStorage.getItem("mobileOs") === "ios" &&
        //@ts-ignore
        window.webkit &&
        //@ts-ignore
        window.webkit.messageHandlers &&
        //@ts-ignore
        window.webkit.messageHandlers.callbackHandler
      ) {
        const obj = {
          command: "open-link",
          url: value,
        };
        //@ts-ignore
        window.webkit.messageHandlers.callbackHandler.postMessage(obj);
      } else if (
        localStorage.getItem("mobileOs") === "RN" &&
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage
      ) {
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage?.(
          JSON.stringify({
            command: "open-link",
            url: value,
          })
        );
        //@ts-ignore
      } else if (window.glowWebview) {
        //@ts-ignore
        window.glowWebview.openLink(value);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="cms-resources" key={item.id}>
      <div>
        <div
          className="link"
          onClick={() => clickHandler(item.resourceUrl)}
          style={{
            display: "inline-block",
          }}
        >
          {item.resourceTitle}
        </div>
      </div>
    </div>
  );
};

export default WebviewResource;

import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
} from "../../style";
import { ExpandMore, TaskAlt } from "@mui/icons-material";
import { NoDataContainer } from "../../../../../Common/styles/table";
import { leftPanel } from "../style";
import { DateTime } from "luxon";

const QuestionMenu = ({ handleResponseClick, data, type }: any) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={leftPanel}>
      <Typography variant="subtitle2" fontWeight="medium" color="#355962">
        Questionnaires
      </Typography>
      <Typography variant="body1" fontSize="12px" color="#6B7280" mb={3}>
        Select the questionnaire to see the responses
      </Typography>

      {data.length > 0 ? (
        data.map((item: any) => {
          return (
            <Accordion
              key={item.id}
              TransitionProps={{ unmountOnExit: true }}
              expanded={expanded === `panel${item.id}`}
              onChange={handleChange(`panel${item.id}`)}
              sx={JournalAccordion}
            >
              <AccordionSummary
                expandIcon={
                  <IconButton>
                    <ExpandMore sx={{ fontSize: 24 }} />
                  </IconButton>
                }
                sx={{
                  ".Mui-expanded": {
                    m: "12px 0px !important",
                  },
                }}
              >
                <Box sx={JournalAccordionHeaderContainer}>
                  <Box sx={JournalAccordionHeader}>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight="medium"
                        color={"#355962"}
                        mb={0.5}
                      >
                        {item?.name}
                      </Typography>

                      {/* <Typography
                      fontWeight="light"
                      fontSize={"12px"}
                      color={"#6B7280"}
                    >
                      {item?.questionCount === 0
                        ? "Not Started | 0 Responses"
                        : `${item?.questionCount} Questionnaires`}
                    </Typography> */}
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  py: 1,
                  px: 2,
                  mb: 2,
                }}
              >
                {item?.patientAttempts.length > 0 ? (
                  item?.patientAttempts.map((response: any, index: number) => {
                    return (
                      <Box
                        key={response.id}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleResponseClick(
                            response?.questionnaireId,
                            response?.id,
                            response?.completedAt,
                            response?.status,
                            item?.name
                          )
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "space-between",
                            alignItems: "center",
                            gap: 1,
                            border: "1px solid #E5E7EB",
                            borderRadius: "8px",
                            p: "12px 8px",
                            mb: 1,
                          }}
                        >
                          {type === "onDemand" ? (
                            <Typography variant="body1" fontWeight="regular">
                              {DateTime?.fromISO(
                                response?.completedAt
                              )?.toFormat("LLLL dd yyyy")}
                            </Typography>
                          ) : (
                            <Typography variant="body1" fontWeight="regular">
                              {DateTime?.fromISO(response?.sentAt)?.toFormat(
                                "LLLL dd yyyy"
                              )}
                            </Typography>
                          )}
                          {response?.status === "completed" && (
                            <TaskAlt color="success" />
                          )}
                          {/* <Typography
                          variant="body1"
                          fontSize={12}
                          color="#6B7280"
                        >
                          {item?.patientAttempts.length} Responses
                        </Typography> */}
                        </Box>
                        {/* {index + 1 < item?.patientAttempts.length && (
                          <Divider sx={{ mt: "10px", mb: 2 }} />
                        )} */}
                      </Box>
                    );
                  })
                ) : (
                  <NoDataContainer>
                    <Typography variant="body1" color="gray">
                      No Responses
                    </Typography>
                  </NoDataContainer>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
    </Box>
  );
};

export default QuestionMenu;

import React, { useMemo } from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  TextField,
  InputAdornment,
  CircularProgress,
  ListItemIcon,
} from "@mui/material";
import { useEffect, useState } from "react";

import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { MoreVert } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
// import AddPatientModal from "../Modals/AddPatientModal";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import StatusTag from "../../Common/UI/StatusTag";

type Props = {
  type: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasModifyAccess: boolean;
};

const Patients = ({
  type,
  page,
  setPage,
  searchText,
  setSearchText,
  // showModal,
  // setShowModal,
  hasModifyAccess,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [patientData, setPatientsData] = useState<any>([]);
  const [patientDataCount, setPatientsDataCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        setLoading(true);
        let url = `/participants/${id}/clinic?page=${
          page + 1
        }&size=${pageSize}`;

        if (searchText) {
          url += `&search=${searchText}`;
        }

        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data?.data?.map((patient: any) => ({
          id: patient?.id,
          name: patient?.firstName
            ? patient?.lastName
              ? `${patient?.firstName} ${patient?.lastName}`
              : patient?.firstName
            : "-",
          email: patient?.email,
          careGiver: patient?.userCaregiver?.careGiver?.firstName
            ? patient?.userCaregiver?.careGiver?.lastName
              ? `${patient?.userCaregiver?.careGiver?.firstName} ${patient?.userCaregiver?.careGiver?.lastName}`
              : patient?.userCaregiver?.careGiver?.firstName
            : "-",
          status: patient?.status,
        }));

        setPatientsData(newData || []);
        setPatientsDataCount(data?.count || 0);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchPatientData();
  }, [page, searchText, toggleLoader, id]);

  // const closeModal = () => {
  //   setShowModal(false);
  // };
  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleArchive = async () => {
    try {
      setMenuLoader(true);
      const newData = {
        status: selectedRow?.status === "inactive" ? "active" : "inactive",
      };
      const res: AxiosResponse = await http.put(
        `/participants/${selectedRow?.id}`,
        newData
      );
      setMenuLoader(false);
      handleMenuClose();
      refreshPage();
      toastMessage("success", res.data.message);
    } catch (err) {
      setMenuLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
      }, 500),
    [setSearchText]
  );

  const handleClick = (id: string) => {
    navigate(`/app/participants/${id}`);
  };

  return (
    <Box>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "70%" }}
          placeholder="Search by Email"
          defaultValue={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          key={type}
        />
      </Box>
      <Box sx={{ overflowX: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Caregiver</StyledTableCell>
              {/* <StyledTableCell>Phone Number</StyledTableCell> */}
              <StyledTableCell>Status</StyledTableCell>
              {/* <StyledTableCell>Caregiver</StyledTableCell> */}
              {hasModifyAccess && <StyledTableCell />}
            </TableRow>
          </TableHead>
          {!loading && patientData?.length > 0 && (
            <>
              <TableBody>
                {patientData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell
                      sx={{ maxWidth: "250px", cursor: "pointer" }}
                      onClick={() => handleClick(row?.id)}
                    >
                      <Typography
                        noWrap
                        fontWeight={600}
                        fontSize="14px"
                        color="#327091"
                      >
                        {row?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={500} fontSize="16px">
                        {row?.email}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ textTransform: "capitalize" }}>
                      <Typography
                        noWrap
                        fontWeight={600}
                        fontSize="14px"
                        color="#327091"
                      >
                        {row?.careGiver}
                      </Typography>
                    </StyledTableCell>
                    {/* <StyledTableCell
                      sx={{ textTransform: "capitalize" }}
                    ></StyledTableCell> */}
                    <StyledTableCell>
                      <StatusTag status={row?.status} />
                    </StyledTableCell>
                    {/* <StyledTableCell
                      sx={{ textTransform: "capitalize" }}
                    ></StyledTableCell> */}
                    {hasModifyAccess && (
                      <StyledTableCell align="right">
                        <IconButton onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVert />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              {patientDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={patientDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
        {!loading && patientData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: "400px",
            },
          }}
        >
          <MenuItem disabled={menuLoader} onClick={handleArchive}>
            <ListItemText>
              {selectedRow?.status === "inactive" ? "Restore" : "Archive"}
            </ListItemText>
            {menuLoader && (
              <ListItemIcon>
                <CircularProgress size={18} sx={{ ml: 1 }} />
              </ListItemIcon>
            )}
          </MenuItem>
        </Menu>
        {/* {showModal && (
          <AddPatientModal
            showModal={showModal}
            closeModal={closeModal}
            refreshPage={refreshPage}
          />
        )} */}
      </Box>
    </Box>
  );
};

export default Patients;

import { Box, SxProps } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import "../../utils/firebaseInit";
import Header from "./Header";
import { canRecieveNotifications } from "../../utils/roles";
import { useAppSelector } from "../../Redux/hooks";

const LayoutStyle: SxProps = {
  px: "24px",
  paddingBottom: "20px",
  flex: 1,
  minHeight: "1px",
  backgroundColor: "#FFF6EB",
  overflow: "auto",
};

const AppLayout = () => {
  const { role } = useAppSelector((state) => state.user);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Sidebar />
      <Box
        sx={{
          flex: 1,
          minWidth: "1px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {canRecieveNotifications(role) ? (
          <Box sx={LayoutStyle}>
            <Header />
            <Outlet />
          </Box>
        ) : (
          <Box sx={{ ...LayoutStyle, paddingTop: "40px" }}>
            <Outlet />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AppLayout;

export const Dot = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
  >
    <circle cx="2" cy="2" r="2" fill="#6B7280" />
  </svg>
);

export const ImageUploadIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66797 26.668L14.3113 19.0246C14.9364 18.3997 15.7841 18.0487 16.668 18.0487C17.5518 18.0487 18.3995 18.3997 19.0246 19.0246L26.668 26.668M23.3346 23.3346L25.978 20.6913C26.6031 20.0664 27.4508 19.7153 28.3346 19.7153C29.2185 19.7153 30.0662 20.0664 30.6913 20.6913L33.3346 23.3346M23.3346 13.3346H23.3513M10.0013 33.3346H30.0013C30.8854 33.3346 31.7332 32.9834 32.3583 32.3583C32.9834 31.7332 33.3346 30.8854 33.3346 30.0013V10.0013C33.3346 9.11725 32.9834 8.2694 32.3583 7.64428C31.7332 7.01916 30.8854 6.66797 30.0013 6.66797H10.0013C9.11725 6.66797 8.2694 7.01916 7.64428 7.64428C7.01916 8.2694 6.66797 9.11725 6.66797 10.0013V30.0013C6.66797 30.8854 7.01916 31.7332 7.64428 32.3583C8.2694 32.9834 9.11725 33.3346 10.0013 33.3346Z"
      stroke="#6B7280"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { Box, Modal } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import React from "react";
import { ModalBaseStyles } from "../Common/styles/modal";

const MiniCalenderModal = ({ setDate, date, closeModal }: any) => {
  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, height: "60vh", width: "30vw" }}>
        <DateCalendar
          value={date}
          onChange={(newDate: any) => {
            setDate(newDate);
            closeModal();
          }}
          views={["day"]}
          className="calendar-view"
        />
      </Box>
    </Modal>
  );
};

export default MiniCalenderModal;

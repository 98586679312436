import { Box, Modal, Typography } from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";

const ViewNotificationModal = ({ showModal, closeModal, data }: any) => {
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "15vh" }}>
        <ModalHeader title={data?.title} onCloseClick={closeModal} />
        <Typography variant="body1" fontWeight="regular" color="#6B7280">
          {data?.body}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ViewNotificationModal;

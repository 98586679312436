import { SxProps } from "@mui/material";

export const QuestionItemV2Container: SxProps = {
  borderRadius: "12px",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  backgroundColor: "#ffffff",
};

export const QuestionItemV2Header: SxProps = {
  p: 2,
  minHeight: "56px",
  display: "flex",
  alignItems: "center",
};

export const QuestionItemV2Content: SxProps = {
  p: 2,
  minHeight: "56px",
};

export const DisplayText: SxProps = {
  fontWeight: "500",
  fontSize: "16px",
  color: "#111928",
};

export const UnansweredText: SxProps = {
  fontWeight: "500",
  fontSize: "16px",
  color: "gray",
};

export const PictureChoiceContainer: SxProps = {
  display: "flex",
  flexWrap: "wrap",
  mt: 2,
};

export const ChoiceContainer: SxProps = {
  px: 3,
  py: 2,
  mb: 2,
  borderRadius: "8px",
  background: "#F3F4F6",
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: "500",
  color: "#0F1734",
};

export const pictureChoiceItem: SxProps = {
  height: "171px",
  width: "171px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  fontWeight: "500",
  color: "#0F1734",
  border: "1px solid #9CA3AF",
  mr: 2,
  mb: 2,
  borderRadius: "10px",
};

export const selectedPictureChoiceItem: SxProps = {
  ...pictureChoiceItem,
  border: "1px solid #327091",
  color: "#327091",
  background: "rgba(40, 62, 106, 0.04)",
};

export const selectedChoiceContainer: SxProps = {
  ...ChoiceContainer,
  border: "1px solid #327091",
  color: "#327091",
};

export const leftPanel: SxProps = {
  width: '340px',
  bgcolor: "#ffffff",
  borderRadius: "12px",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  p: "22px 20px",
};

export const LANGUAGES = [
  { value: "en", label: "English" },
  { value: "ms", label: "Malay" },
  { value: "zh", label: "Chinese" },
  { value: "ta", label: "Tamil" },
];
export const langMap: any = {
  en: "English",
  ms: "Malay",
  zh: "Chinese",
  ta: "Tamil",
};

export const getLang = () => {
  let lang = localStorage.getItem("lang") || "en";
  if (!langMap[lang]) {
    lang = "en";
  }
  return lang;
};

import React, { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setExerciseCollectionModalId,
  toggleExerciseCollection,
} from "../../../Redux/reducers/exerciseCollectionSlice";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { uploadFile } from "../../../utils/upload";
import { useDropzone } from "react-dropzone";
import { ImageUploadIcon } from "../Icons";
import { UploadWrapper } from "../ExerciseCollection.style";

let schema = yup.object().shape({
  title: yup.string().required("Collection Name is Required"),
});

const UploadItem: React.FC<any> = ({ image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          // contact be for bucket folder
          const url = await uploadFile(file, "education_lesson_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} id="exercise-picture-upload" />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const ExerciseCollectionModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const [initialData, setInitialData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const { exerciseCollectionModalId } = useAppSelector(
    (state) => state.exerciseCollection
  );
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(setExerciseCollectionModalId(null));
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        ...values,
      };

      let res: AxiosResponse;
      if (exerciseCollectionModalId !== "new") {
        res = await http.put(
          `/exercise-collection/${exerciseCollectionModalId}`,
          body
        );
        toastMessage("success", res.data.message);
        dispatch(toggleExerciseCollection());
        closeModal();
      } else {
        res = await http.post(`/exercise-collection`, body);
        toastMessage("success", res.data.message);
        dispatch(toggleExerciseCollection());
        closeModal();
        navigate(
          `/app/exercise/${res.data.data.id}/categories?title=${
            res.data?.data?.title || values?.title
          }`
        );
      }
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res: AxiosResponse = await http.get(
          `/exercise-collection/${exerciseCollectionModalId}`
        );
        const response = res.data.data;
        setInitialData({
          title: response?.title || "",
          imageUrl: response?.imageUrl || "",
        });
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setExerciseCollectionModalId(null));
      }
    };
    if (exerciseCollectionModalId !== "new") {
      fetchDetails();
    } else {
      setInitialData({});
      setLoading(false);
    }
  }, [exerciseCollectionModalId, dispatch, setInitialData, setLoading]);

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={
            exerciseCollectionModalId === "new"
              ? "Add Exercise Collection"
              : "Edit Exercise Collection"
          }
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              title: initialData?.title || "",
              imageUrl: initialData?.imageUrl || "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({
              handleSubmit,
              getFieldProps,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="exercise-collection-name">
                    Collection Name
                  </FormLabel>
                  <TextField
                    placeholder="Collection Name"
                    id="exercise-collection-name"
                    {...getFieldProps("title")}
                    error={touched?.title && errors?.title ? true : false}
                    helperText={
                      touched?.title && errors?.title
                        ? (errors?.title as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="exercise-picture-upload">
                    Upload Picture
                  </FormLabel>
                  <UploadItem
                    image={values?.imageUrl}
                    setFieldValue={setFieldValue}
                  />
                </FormControl>
                <Box sx={ModalActionButtonStyles}>
                  {!submitLoader ? (
                    <>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ExerciseCollectionModal;

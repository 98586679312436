export const timezoneMap: any = {
  "America/New_York": "EST (Eastern Standard Time)",
  "America/Chicago": "CST (Central Standard Time)",
  "America/Denver": "MST (Mountain Standard Time)",
  "America/Los_Angeles": "PST (Pacific Standard Time)",
  "America/Anchorage": "AKST (Alaska Standard Time)",
  "Pacific/Honolulu": "HAST (Hawaii-Aleutian Standard Time)",
};

export const timezoneAbbrMap: any = {
  "America/New_York": "EST",
  "America/Chicago": "CST",
  "America/Denver": "MST",
  "America/Los_Angeles": "PST",
  "America/Anchorage": "AKST",
  "Pacific/Honolulu": "HAST",
};

export const timeZoneOptions = [
  { value: "America/New_York", label: "Eastern Standard Time (EST) UTC-5:00" },
  { value: "America/Chicago", label: "Central Standard Time (CST) UTC-6:00" },
  { value: "America/Denver", label: "Mountain Standard Time (PST) UTC-7:00" },
  {
    value: "America/Los_Angeles",
    label: "Pacific Standard Time (PST) UTC-8:00",
  },
  { value: "America/Anchorage", label: "Alaska Standard Time (AKST) UTC-9:00" },
  {
    value: "Pacific/Honolulu",
    label: "Hawaii-Aleutian Time Zone (HAST) UTC-10:00",
  },
];

export const timezoneWithUtcMap: any = {
  "America/New_York": "Eastern Standard Time (EST) UTC-5:00",
  "America/Chicago": "Central Standard Time (CST) UTC-6:00",
  "America/Denver": "Mountain Standard Time (PST) UTC-7:00",
  "America/Los_Angeles": "Pacific Standard Time (PST) UTC-8:00",
  "America/Anchorage": "Alaska Standard Time (AKST) UTC-9:00",
  "Pacific/Honolulu": "Hawaii-Aleutian Time Zone (HAST) UTC-10:00",
};

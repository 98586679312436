/* eslint-disable global-require */
import ExceptionCodes from './exception_codes';
import OTErrorClass from './ot_error_class';
import sessionObjects from './session/objects';
import otError from '../helpers/otError';
import Errors from './Errors';
import apiKeyDefault from './api_key';
// import AnalyticsHelper from '../helpers/analytics';

let proxyUrl;
export const errorMessages = {
  SET_PROXY_URL_TIMING_ERROR: 'The proxyUrl was set after a session or publisher was created.',
  PROXY_URL_ALREADY_SET_ERROR: 'The proxyUrl has already been set. Setting it again will not have any effect.',
};

/**
 * Sets the URL of the IP proxy server. With the IP proxy feature, the client
 * routes all internet traffic (except for media streams) via your proxy server.
 * <p>
 * You must call this method before calling any other OpenTok methods.
 * This ensures that the proxy server is used for OpenTok traffic. Otherwise,
 * the call fails, and the <code>OT</code> object dispatches an
 * <code>exception</code> event. The <code>OT</code> object also dispatches an
 * <code>exception</code> event if you call this method after having
 * previously called it.
 * <p>
 * This is available as an add-on feature. For more information, see the
 * <a href="https://tokbox.com/developer/guides/ip-proxy/">IP proxy developer
 * guide</a>.
 *
 * @method OT.setProxyUrl
 * @memberof OT
 */

export function setProxyUrl(url) {
  const AnalyticsHelper = require('../helpers/analytics');
  const apiKey = apiKeyDefault;

  // This is necessary to break a circular dependancy, but seems hacky and should be cleaned up
  const analytics = new AnalyticsHelper();

  const hasSessionInitalized = sessionObjects.sessions.length() > 0;
  const hasPublisherInitalized = sessionObjects.publishers.length() > 0;

  let sessionId;

  if (hasSessionInitalized) {
    const connectedSession = sessionObjects.sessions.find(session => session.currentState === 'connected');
    sessionId = connectedSession ?
      connectedSession.sessionId : sessionObjects.sessions.find().sessionId;
  } else {
    sessionId = '';
  }

  const target = {
    sessionId,
    apiKey: apiKey.value,
  };

  if (hasPublisherInitalized || hasSessionInitalized) {
    const error = otError()(
      Errors.SET_PROXY_URL_TIMING_ERROR, new Error(errorMessages.SET_PROXY_URL_TIMING_ERROR),
      ExceptionCodes.SET_PROXY_URL_TIMING_ERROR
    );
    OTErrorClass.handleJsException({
      error,
      target,
      analytics,
    });
  } else if (proxyUrl) {
    const error = otError()(
      Errors.PROXY_URL_ALREADY_SET_ERROR, new Error(errorMessages.PROXY_URL_ALREADY_SET_ERROR),
      ExceptionCodes.PROXY_URL_ALREADY_SET_ERROR
    );
    OTErrorClass.handleJsException({
      error,
      target,
      analytics,
    });
  } else {
    // eslint-disable-next-line no-param-reassign
    proxyUrl = url;
  }
}
export const getProxyUrl = () => proxyUrl;

// this method should only be exposed to tests
export const clearProxyUrl = () => {
  proxyUrl = null;
};

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
// import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../utils/toast";
import {
  ApptCard,
  // CalendarContainer,
  CalendarWrapper,
  DisApptCard,
  DividerStyle,
  FlexContainer,
} from "./style";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { setHealthType } from "../../Redux/reducers/healthSlice";
import { useAppDispatch } from "../../Redux/hooks";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RescheduleModal from "./RescheduleModal";
import MiniCalenderModal from "./MiniCalenderModal";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { timezoneAbbrMap } from "../../utils/timezone";

const ListSlots: React.FC<any> = ({ data, handleClick, openMenu }) => {
  return (
    <Box
      sx={{
        flex: 1,
        pr: 1,
      }}
    >
      {data.map((appt: any) => {
        return (
          <Box
            sx={
              appt?.unformattedEndTime >=
                DateTime.local({
                  zone: appt?.unformattedTimezone,
                }) && appt?.status === "pending"
                ? { ...ApptCard, marginLeft: "3%" }
                : { ...DisApptCard, marginLeft: "3%" }
            }
            key={appt?.id}
            onClick={() => {
              handleClick(appt);
            }}
          >
            <Box />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Typography
                fontSize={24}
                fontWeight="medium"
                textTransform="capitalize"
              >
                {appt?.startTime} {appt?.timezone} - {appt?.endTime}{" "}
                {appt?.timezone}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight="light"
                textTransform="capitalize"
              >
                {`(${appt?.name})`}
              </Typography>
            </Box>
            <Box>
              {appt?.unformattedEndTime >=
                DateTime.local({
                  zone: appt?.unformattedTimezone,
                }) &&
                appt?.status === "pending" && (
                  <IconButton size="small" onClick={(e) => openMenu(e, appt)}>
                    <MoreVertIcon sx={{ fontSize: "24px", color: "#111928" }} />
                  </IconButton>
                )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const MyCalendar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [row, setRow] = useState<any>(null);
  const [date, setDate] = useState<any>(
    searchParams.get("date") &&
      DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy").isValid
      ? DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy")
      : DateTime.now()
  );
  const [data, setData] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showModal, setShowModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const openMenu = (event: React.MouseEvent<HTMLElement>, row: any) => {
    event.stopPropagation();
    setRow(row);
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setRow(null);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const closeCalendarModal = () => {
    setShowCalendarModal(false);
  };

  // const reschedule = () => {
  //   const redate = date?.toFormat("dd-LL-yyyy");
  //   navigate(`/app/my-calendar/reschedule/${row?.id}?date=${redate}`);
  // };

  useEffect(() => {
    const params = new URLSearchParams();
    if (date) {
      params.set("date", date?.toFormat("dd-LL-yyyy"));
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, date]);

  const refreshPage = () => {
    setToggle((prev: any) => !prev);
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/consultation?startDate=${date
          ?.startOf("day")
          .toUTC()
          .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data;
        const newData = resData
          ?.map((patient: any) => ({
            id: patient?.id,
            patientId: patient?.userId,
            startTime: DateTime.fromISO(patient?.scheduledAt)
              ?.setZone(patient?.coach?.timezone)
              ?.toFormat("hh:mm a"),
            endTime: DateTime.fromISO(patient?.scheduledEndTime)
              ?.setZone(patient?.coach?.timezone)
              ?.toFormat("hh:mm a"),
            unformattedEndTime: DateTime.fromISO(
              patient?.scheduledEndTime
            )?.setZone(patient?.coach?.timezone),
            unformattedStartTime: DateTime.fromISO(
              patient?.scheduledAt
            )?.setZone(patient?.coach?.timezone),
            name:
              patient?.user?.firstName + " " + patient?.user?.lastName || "",
            status: patient?.status,
            timezone: timezoneAbbrMap[patient?.coach?.timezone] || "EST",
            unformattedTimezone: patient?.coach?.timezone,
          }))
          .sort((a: any, b: any) => {
            return (
              (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
              (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
            );
          });

        setData(newData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [date, toggle]);

  const handleClick = (appt: any) => {
    if (
      appt?.unformattedEndTime >=
        DateTime.local({ zone: appt?.unformattedTimezone }) &&
      appt?.status === "pending"
    ) {
      dispatch(setHealthType("patient_overview"));
      navigate(`/app/participants/${appt?.patientId}`);
    }
  };

  const subtractDate = () => {
    let newDate = DateTime.fromISO(date).minus({ day: 1 });
    setDate(newDate);
  };
  const addDate = () => {
    let newDate = DateTime.fromISO(date).plus({ day: 1 });
    setDate(newDate);
  };

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {/* <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 0 }}>
          <Typography fontSize={30} fontWeight="bold">
            My Calendar
          </Typography>
        </Box>
      </StyledHeader> */}
      <Box
        sx={{
          ...CalendarWrapper,
          overflow: "auto",
          minHeight: "calc(100vh - 240px)",
          gap: 1,
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <IconButton onClick={() => subtractDate()}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography
            onClick={() => {
              setShowCalendarModal(true);
            }}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "600",
              lineHeight: "2.25rem",
              textAlign: "center",
              color: "#327091",
              cursor: "pointer",
            }}
          >
            {DateTime?.fromISO(date)?.toFormat("LLL dd yyyy")}
          </Typography>
          <IconButton onClick={() => addDate()}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyItems: "center",
            marginTop: "50px",
            flex: 1,
          }}
        >
          {/* {!loading ? ( */}
          {data.length > 0 ? (
            <>
              <ListSlots
                data={data?.filter((_, index) => index % 2 === 0)}
                handleClick={handleClick}
                openMenu={openMenu}
              />
              <Divider
                flexItem
                orientation="vertical"
                sx={{ ...DividerStyle, marginBlock: "0px" }}
              />
              <ListSlots
                data={data?.filter((_, index) => index % 2 !== 0)}
                handleClick={handleClick}
                openMenu={openMenu}
              />
            </>
          ) : (
            <Box sx={FlexContainer}>
              <Typography variant="subtitle2" color="gray">
                No appointments
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "265px",
          },
        }}
      >
        {/* <MenuItem onClick={reschedule}>
          <ListItemText
            sx={{
              color: "#111928",
            }}
          >
            Re-schedule
          </ListItemText>
        </MenuItem>
        <Divider /> */}

        <MenuItem>
          <ListItemText
            sx={{
              color: "#F05252",
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => {
              setShowModal(true);
              setAnchorEl(null);
            }}
          >
            Cancel appointment
          </ListItemText>
          {/* </>
                  )} */}
        </MenuItem>
      </Menu>
      {showCalendarModal && (
        <MiniCalenderModal
          showModal={showCalendarModal}
          closeModal={closeCalendarModal}
          date={date}
          setDate={setDate}
        />
      )}
      {showModal && (
        <RescheduleModal
          showModal={showModal}
          closeModal={closeModal}
          id={row?.id}
          refreshPage={refreshPage}
          reschedule={true}
        />
      )}
    </>
  );
};

export default MyCalendar;

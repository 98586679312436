import { createSlice } from "@reduxjs/toolkit";

export interface notificationRefreshState {
  refreshNotifications: boolean;
  refreshNotifTable: boolean;
}

const initialState: notificationRefreshState = {
  refreshNotifications: true,
  refreshNotifTable: true,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    setRefreshNotification: (state) => {
      state.refreshNotifications = !state.refreshNotifications;
    },
    setRefreshNotifTable: (state) => {
      state.refreshNotifTable = !state.refreshNotifTable;
    },
  },
});

export const { setRefreshNotification, setRefreshNotifTable } =
  notificationSlice.actions;

export default notificationSlice.reducer;

import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { DateTime } from "luxon";
import { GridContainer, DateWrapper } from "./style";

const getStyle = (type: string, isSelected: boolean) => {
  if (isSelected) {
    return {
      borderColor: type === "makeAvailable" ? "#07B152" : "#BDBDBD",
      color: type === "makeAvailable" ? "#07B152" : "#BDBDBD",
    };
  } else {
    return {
      borderColor: type === "makeAvailable" ? "#BDBDBD" : "#07B152",
      color: type === "makeAvailable" ? "#BDBDBD" : "#07B152",
    };
  }
};

const slots = [
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
];

const MarkAvailabilityModal = ({
  showModal,
  closeModal,
  data,
  selectedDate,
  refreshPage,
  slotIds,
}: any) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState<any[]>([]);

  // console.log({ selectedSlots });
  // console.log({ slotIds });

  const handleSlotSelection = (slot: any) => {
    const selectedData =
      showModal === "makeAvailable" ? slot?.time : slot?.startTime;
    if (selectedSlots.includes(selectedData)) {
      setSelectedSlots((prev) => prev.filter((item) => item !== selectedData));
    } else {
      setSelectedSlots((prev) => [...prev, selectedData]);
    }
  };

  const convertTime = (time: any) => {
    const parsedTime = DateTime.fromFormat(time || "", "hh:mm a");
    const combinedDateTime = selectedDate?.set({
      hour: parsedTime.hour,
      minute: parsedTime.minute,
    });
    const startTime = combinedDateTime;
    const endTime = combinedDateTime?.plus({ hour: 1 });

    let slots = [];

    for (
      let currentTime = startTime;
      currentTime < endTime;
      currentTime = currentTime?.plus({ minutes: 15 })
    ) {
      let slotEnd =
        currentTime?.plus({ minutes: 15 }) > endTime
          ? endTime
          : currentTime?.plus({ minutes: 15 });

      slots.push({
        startTime: currentTime?.toUTC()?.toISO(),
        endTime: slotEnd?.toUTC()?.toISO(),
      });
    }

    return slots;
  };

  const submitHandler = async () => {
    try {
      setSubmitLoader(true);
      if (!selectedSlots.length) {
        throw new Error("Please select at least one slot");
      }
      let newSlots: any;
      let res: AxiosResponse;
      if (showModal === "makeAvailable") {
        newSlots = selectedSlots
          ?.map((item: any) => convertTime(item))
          ?.reduce((acc, val) => acc.concat(val), []);
        res = await http.post(`/consultation/availability`, {
          slotStartTime: newSlots,
        });
      } else {
        newSlots = [];
        selectedSlots.forEach((slot) => {
          const slotHour = DateTime.fromFormat(slot, "hh:mm a").toFormat("H");
          if (slotIds[slotHour]) newSlots.push(slotIds[slotHour]);
        });
        // console.log({ newSlots });
        const finalData = newSlots.flatMap((sIds: any) =>
          sIds.map((sId: any) => sId)
        );
        // console.log({ finalData });
        res = await http.patch(`/consultation/availability/remove`, {
          slotIds: finalData,
        });
      }
      toastMessage("success", res.data.message);
      refreshPage();
      closeModal();
      setSubmitLoader(false);
    } catch (err) {
      closeModal();
      refreshPage();
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal ? true : false} onClose={closeModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: "20vh",
        }}
      >
        <ModalHeader
          title={`Make ${
            showModal === "makeAvailable" ? "Available" : "Unavailable"
          }`}
          onCloseClick={closeModal}
        />
        {data.length ? (
          showModal === "makeAvailable" ? (
            <Box sx={{ ...GridContainer, padding: 1 }}>
              {data?.map((timing: any) => {
                const isSelected = selectedSlots.includes(timing?.time);
                // const isSelected = selectedSlots.includes(
                //   showModal === "makeAvailable" ? timing?.time : timing?.id
                // );

                return (
                  <Box
                    key={timing?.id}
                    sx={{ ...DateWrapper, ...getStyle(showModal, isSelected) }}
                    onClick={() => handleSlotSelection(timing)}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={18}
                      fontStyle="normal"
                    >
                      {timing?.startTime} - {timing?.endTime}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <Box sx={{ ...GridContainer, padding: 1 }}>
              {data?.map((timing: any) => {
                const valid = slots.includes(timing?.startTime);
                const isSelected = selectedSlots.includes(timing?.startTime);

                return valid ? (
                  <Box
                    key={timing?.id}
                    sx={{
                      ...DateWrapper,
                      ...getStyle(showModal, isSelected),
                    }}
                    onClick={() => handleSlotSelection(timing)}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={18}
                      fontStyle="normal"
                    >
                      {timing?.startTime} -{" "}
                      {DateTime.fromFormat(timing.startTime, "hh:mm a")
                        .plus({ hour: 1 })
                        .toFormat("hh:mm a")}
                    </Typography>
                  </Box>
                ) : null;
              })}
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1" color="gray">
              No Slots Available
            </Typography>
          </Box>
        )}

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 3 }}
        >
          {!submitLoader ? (
            <>
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>
              <Button variant="contained" onClick={submitHandler}>
                Confirm
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default MarkAvailabilityModal;

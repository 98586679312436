import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setExerciseCollectionLoader,
  setExerciseCollectionData,
  setExerciseLibraryData,
  setExerciseLibraryDataCount,
  setExercisePage,
} from "../reducers/exerciseCollectionSlice";
import { AppThunk } from "../store";
import { paddedPageSize } from "../../Components/Common/styles/table";

export const fetchExerciseLibraryData =
  (page: number, searchText: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setExerciseCollectionLoader(true));
      let url = `/exercise?pagination=true&page=${
        page + 1
      }&size=${paddedPageSize}`;
      if (searchText) {
        url += `&search=${searchText}`;
      }
      const res: AxiosResponse = await http.get(url);
      const dataCount = res.data?.data?.count;
      const newData = res.data?.data?.exercises.map((module: any) => {
        return {
          id: module?.id,
          title: module?.name,
          imageUrl: module?.thumbnailUrl,
        };
      });
      if (newData.length === 0 && dataCount > 0 && page > 0) {
        dispatch(setExercisePage(Math.floor(dataCount / paddedPageSize) - 1));
        return;
      }

      dispatch(setExerciseLibraryData(newData));
      dispatch(setExerciseLibraryDataCount(dataCount || 0));
      dispatch(setExerciseCollectionLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setExerciseCollectionLoader(false));
    }
  };
export const fetchExerciseCollectionData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setExerciseCollectionLoader(true));
    let url = `/exercise-collection?pagination=false`;
    const res: AxiosResponse = await http.get(url);
    const newData = res.data?.data?.collections.map((module: any) => {
      return {
        id: module?.id,
        title: module?.title,
        position: module?.position,
        imageUrl: module?.imageUrl || "",
      };
    });

    dispatch(setExerciseCollectionData(newData));
    dispatch(setExerciseCollectionLoader(false));
  } catch (err) {
    errorToastMessage(err as Error);
    dispatch(setExerciseCollectionLoader(false));
  }
};

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { ChevronLeft } from "@mui/icons-material";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../utils/toast";
import { DateCalendar } from "@mui/x-date-pickers";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { LoadingContainer } from "../CMS/cms.style";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import ConfirmationModal from "./ConfirmationModal";
import { GridContainer, DateWrapper } from "./style";
import { getColor } from "../../utils/schedule";
import {
  CalendarWrapper,
  CalendarContainer,
  DividerStyle,
} from "../MyCalendar/style";

const AssignSlots = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchLoader, setSearchLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<DateTime | null>(
    searchParams.get("date") &&
      DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy").isValid
      ? DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy")
      : DateTime.now()
  );
  const [data, setData] = useState<any[]>([]);
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [patients, setPatients] = useState<any>([]);
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();
    if (date) {
      params.set("date", date?.toFormat("dd-LL-yyyy"));
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, date]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/consultation/availability?startDate=${date
          ?.startOf("day")
          .toUTC()
          .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data;

        const newData = resData
          ?.filter((item: any) => !item?.assignedTo)
          .filter((item: any) => {
            return DateTime.fromISO(item?.startTime) > DateTime.local();
          })
          .map((item: any) => ({
            id: item?.id,
            // time: DateTime.fromISO(item.dateTime).toFormat("hh:mm a"),
            startTime: DateTime.fromISO(item?.startTime).toFormat("hh:mm a"),
            endTime: item?.endTime
              ? DateTime.fromISO(item?.endTime).toFormat("hh:mm a")
              : DateTime.fromISO(item?.startTime)
                  .plus({ hour: 1 })
                  .toFormat("hh:mm a"),
            status: "available",
          }))
          .sort((a: any, b: any) => {
            return (
              (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
              (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
            );
          });

        setData(newData || []);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [date, toggle]);

  const handleSelectSlot = (id: string) => {
    if (selectedSlots.includes(id)) {
      setSelectedSlots((prev) => prev.filter((slot) => slot !== id));
    } else {
      setSelectedSlots((prev) => [...prev, id]);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            let url = `/coach/participant?search=${value}`;

            const res: AxiosResponse = await http.get(url);
            const patientsData = res.data?.data?.map((item: any) => {
              return {
                id: item?.participant?.id,
                name:
                  item?.participant?.firstName +
                  " " +
                  item?.participant?.lastName,
              };
            });
            setPatients(patientsData);
            setSearchLoader(false);
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setSearchLoader(false);
        }
      }, 500),
    []
  );

  const handleAssign = async () => {
    try {
      setSubmitLoader(true);
      if (!selectedPatient) {
        throw new Error("Please select a patient to assign");
      }
      if (!selectedSlots.length) {
        throw new Error("Please select a slot to assign");
      }
      setShowModal(true);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 0 }}>
          <IconButton onClick={handleBack}>
            <ChevronLeft fontSize="large" htmlColor="#111928" />
          </IconButton>
          <Typography fontSize={30} fontWeight="bold">
            Assign Slots
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={CalendarWrapper}>
        <Box sx={CalendarContainer}>
          <Typography fontWeight="medium" variant="h6" sx={{ mb: "50px" }}>
            Date
          </Typography>
          <Box sx={{ paddingInline: "60px", flex: 1 }}>
            <DateCalendar
              value={date}
              onChange={(newDate: any) => {
                setDate(newDate);
              }}
              disablePast
              views={["day"]}
              className="calendar-view"
            />
          </Box>
        </Box>
        <Divider flexItem orientation="vertical" sx={DividerStyle} />
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box
            sx={{
              px: 2,
            }}
          >
            <Typography variant="h6" fontWeight="medium" sx={{ mb: "20px" }}>
              Patient
            </Typography>
            <FormControl sx={{ ...InputWrapper, mb: 5 }}>
              <FormLabel sx={LabelStyle} htmlFor="patient">
                Patient
              </FormLabel>
              <Autocomplete
                key={selectedPatient ? selectedPatient?.id : ""}
                filterOptions={(x) => x}
                onInputChange={(_1: any, value: any, reason: string) => {
                  if (reason === "input") handleSearch(value);
                }}
                onChange={(_1: any, value: any) => {
                  setSelectedPatient(value);
                }}
                options={patients}
                value={selectedPatient || null}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                loading={searchLoader}
                loadingText={<CircularProgress size={20} />}
                noOptionsText="No Results"
                clearOnBlur={false}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search..." />
                )}
              />
            </FormControl>
          </Box>
          <Typography variant="h6" fontWeight="medium" sx={{ mb: 3, px: 2 }}>
            Time
          </Typography>
          {!loading ? (
            data?.length ? (
              <>
                <Box
                  sx={{
                    height: "40vh",
                    overflow: "auto",
                    px: 2,
                  }}
                >
                  <Box sx={GridContainer}>
                    {data?.map((timing) => {
                      const isSelected = selectedSlots.includes(timing.id);

                      return (
                        <Box
                          key={timing.id}
                          sx={{
                            ...DateWrapper,
                            borderColor: getColor(
                              timing?.status,
                              isSelected,
                              "border"
                            ),
                            color: getColor(timing?.status, isSelected, "text"),
                          }}
                          onClick={() => {
                            handleSelectSlot(timing?.id);
                          }}
                        >
                          <Typography
                            fontWeight={500}
                            fontSize={18}
                            fontStyle="normal"
                          >
                            {timing?.startTime} - {timing?.endTime}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    px: 2,
                    mt: "10px",
                  }}
                >
                  {!submitLoader ? (
                    <Button variant="contained" onClick={handleAssign}>
                      Assign
                    </Button>
                  ) : (
                    <CircularProgress size={18} />
                  )}
                </Box>
              </>
            ) : (
              <Typography variant="body1" color="gray" sx={{ px: 2 }}>
                No Slots Available
              </Typography>
            )
          ) : (
            <Box sx={{ ...LoadingContainer, flex: 1, px: 2 }}>
              <CircularProgress size={25} />
            </Box>
          )}
        </Box>
      </Box>
      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          closeModal={closeModal}
          patient={selectedPatient}
          slots={selectedSlots}
          refreshPage={refreshPage}
          setPatient={setSelectedPatient}
          setSlots={setSelectedSlots}
        />
      )}
    </>
  );
};

export default AssignSlots;

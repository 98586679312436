import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../Redux/hooks";
import { editorBlock, editorBlockHeader, EditorWrapper } from "../styles";
import BasicDetails from "./BasicDetails";
import GroupSettings from "./GroupSettings";
import QuestionOptions from "./QuestionOptions";

const QuestionItem = () => {
  const { questions, activeQuestionIndex, type } = useAppSelector(
    (state) => state.onboarding
  );
  const selectedQuestion = questions[activeQuestionIndex];

  if (!selectedQuestion) {
    return null;
  }
  return (
    <Box sx={EditorWrapper}>
      <Box sx={editorBlock} mb={2}>
        <Typography variant="subtitle1" sx={editorBlockHeader}>
          Basic Details
        </Typography>
        <BasicDetails question={selectedQuestion} builderType={type} />
      </Box>
      {[
        "multiple_choice",
        "likert_scale",
        "checkbox",
        "picture_choice",
      ].includes(selectedQuestion.type) && (
        <Box sx={editorBlock} mb={2}>
          <Typography variant="subtitle1" sx={editorBlockHeader}>
            Options
          </Typography>
          <QuestionOptions question={selectedQuestion} builderType={type} />
        </Box>
      )}
      {selectedQuestion.type === "group" && (
        <GroupSettings question={selectedQuestion} builderType={type} />
      )}
    </Box>
  );
};

export default QuestionItem;

import { Box, SxProps, Typography } from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  changeQuestionProperty,
  setOnboardingLoader,
} from "../../../../Redux/reducers/onboardingSlice";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";
import { ImageUploadIcon } from "../../../CMS/Icons";

const UploadWrapper: SxProps = {
  borderRadius: "12px",
  border: 1,
  borderColor: "divider",
  background: "#f9fafb",
  display: "flex",
  height: "125px",
  justifyContent: "center",
  alignItems: "center",
  my: 1,
  "&:hover": {
    borderColor: "primary.main",
    cursor: "pointer",
  },
  "& .preview-image": {
    height: "120px",
    objectFit: "contain",
  },
};

type Props = {
  image: string | null;
  subIndex?: number;
};

const UploadItem: React.FC<Props> = ({ image, subIndex }) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setOnboardingLoader(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            changeQuestionProperty({
              type: "imageUrl",
              value: url,
              subIndex,
            })
          );
          dispatch(setOnboardingLoader(false));
        }
      } catch (err) {
        dispatch(setOnboardingLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, subIndex]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UploadItem;

import { SxProps } from "@mui/material";

export const DualInput: SxProps = {
  display: "flex",
  gap: 1,
  alignItems: "flex-start",
};

export const HeaderStyle: SxProps = {
  height: "103px",
  width: "100%",
  borderBottom: "1px solid #E5E7EB",
  bgcolor: "#fff",
  display: "flex",
  alignItems: "center",
  paddingLeft: "65px",
  img: {
    height: 250,
    width: 250,
  },
  "@media(max-width: 780px)": {
    padding: "25px",
  },
};

export const titleText: SxProps = {
  fontSize: "44px",
  "@media(max-width: 780px)": {
    fontSize: "34px",
  },
};

export const GrayBox: SxProps = {
  height: "40px",
  width: "100%",
  bgcolor: "#F2F2F2",
};

export const ListStyle: SxProps = {
  display: "list-item",
  listStyleType: "disc",
  listStyle: "inside",
  maxWidth: "657px",
};

export const PolicyContainer: SxProps = {
  width: "100%",
  bgcolor: "#fff",
  boxShadow: "0px -2px 10px 0px rgba(0, 0, 0, 0.07)",
  padding: "80px 100px",
  height: "100%",
  overflow: "auto",
  "@media(max-width: 780px)": {
    padding: "15px",
  },
};

export const PolicyBorderedContainer: SxProps = {
  border: "1px solid #AAACAA",
  borderRadius: "16px",
  padding: "45px",
  minHeight: "100%",
  "@media(max-width: 780px)": {
    padding: "15px",
  },
};

export const FlexContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
};

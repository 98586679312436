import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { QBLoader, QBPreviewContainer } from "./QBPreview.style";
import {
  loadQuestionDetails,
  questionCleanup,
} from "../../Redux/actions/questionAction";
import QBPreviewHeader from "./QBPreviewHeader";
import QBPreviewQuestions from "./QBPreviewQuestions";
import QuestionPropertiesModalWrapper from "../QuestionBuilder/QuestionProperties/QuestionPropertiesModalWrapper";
import { CircularProgress, Typography } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuestionPreview: React.FC = () => {
  const query = useQuery();
  const qid = query.get("id") || "";
  const dispatch = useAppDispatch();
  const { questionName, loading, error } = useAppSelector(
    (state) => state.question
  );

  useEffect(() => {
    dispatch(loadQuestionDetails(qid, true));
    // Store cleanup on component unmount
    return () => {
      dispatch(questionCleanup());
    };
  }, [dispatch, qid]);

  return (
    <QBPreviewContainer>
      <QBPreviewHeader name={questionName} />
      {loading ? (
        <QBLoader>
          <CircularProgress size={25} />
        </QBLoader>
      ) : error ? (
        <QBLoader>
          <Typography variant="h5" color="grey">
            {error}
          </Typography>
        </QBLoader>
      ) : (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <QBPreviewQuestions />
          </LocalizationProvider>
          <QuestionPropertiesModalWrapper />
        </>
      )}
    </QBPreviewContainer>
  );
};

export default QuestionPreview;

import {
  // ChangeEvent,
  // useMemo,
  useState,
} from "react";
// import { debounce } from "lodash";
import {
  Box,
  Button,
  // CircularProgress,
  // TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import UploadIcon from "@mui/icons-material/Upload";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import AddAdminModal from "./AddAdminModal";
import { useAppSelector } from "../../../Redux/hooks";
import { AdminMap } from "./Administrators";
// import { setAdministratorsSearchText } from "../../../Redux/reducers/administratorsSlice";
// import { errorToastMessage, toastMessage } from "../../../utils/toast";
// import http from "../../../utils/http";
// import { refreshAdministratorsPage } from "../../../Redux/reducers/administratorsSlice";
type Props = {
  hasModifyAccess: boolean;
  setToggleLoader: any;
};
const AdminHeader = ({ hasModifyAccess, setToggleLoader }: Props) => {
  const [showModal, setShowModal] = useState(false);
  // const [loader, setLoader] = useState(false);
  // const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => state.administrators);

  // const modifySearchTerm = useMemo(
  //   () =>
  //     debounce((val) => {
  //       dispatch(setAdministratorsSearchText(val));
  //     }, 500),
  //   [dispatch]
  // );

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Administrators
        </Typography>

        {/* <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "300px" }}
          placeholder="Search for Administrators"
          defaultValue={searchText}
          key={type}
        /> */}
      </Box>
      {hasModifyAccess && (
        <Box sx={HeaderRightContent}>
          <Button
            className="mr-3"
            variant="contained"
            onClick={openModal}
            startIcon={<AddIcon />}
          >
            Add {AdminMap[type]}
          </Button>
        </Box>
      )}
      {showModal && (
        <AddAdminModal
          showModal={showModal}
          closeModal={closeModal}
          type={type}
          setToggleLoader={setToggleLoader}
        />
      )}
    </StyledHeader>
  );
};

export default AdminHeader;

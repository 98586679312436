import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import { useSearchParams } from "react-router-dom";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import MedicationList from "./MedicationList";
import { useAppSelector } from "../../Redux/hooks";
import { canModifyMedication } from "../../utils/roles";

const Medication = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [showModal, setShowModal] = useState(false);
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canModifyMedication(userRole));

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());

    if (searchText) {
      params.set("search", searchText);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, page, searchText]);

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, mr: 3 }}>
          <Typography fontSize={30} fontWeight="bold">
            Medication Database
          </Typography>
        </Box>
        {hasModifyAccess && (
          <Box sx={HeaderRightContent}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setShowModal(true)}
            >
              Add Medication
            </Button>
          </Box>
        )}
      </StyledHeader>
      <MedicationList
        page={page}
        setPage={setPage}
        showModal={showModal}
        setShowModal={setShowModal}
        searchText={searchText}
        hasModifyAccess={hasModifyAccess}
        setSearchText={setSearchText}
      />
    </>
  );
};

export default Medication;

import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  LinearProgress,
  Rating,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import http from "../../../../../utils/http";
import { errorToastMessage } from "../../../../../utils/toast";
import {
  HeaderLeftContent,
  StyledHeader,
} from "../../../../Common/styles/header";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../../../Common/styles/table";
import { ContainerStyle } from "../../../cms.style";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Reviews = () => {
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [sortOrder, setSortOrder] = useState(
    searchParams.get("order") || "asc"
  );
  const [sortColumn, setSortColumn] = useState(
    searchParams.get("sort") || "createdAt"
  );
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const navigate = useNavigate();
  const lesson = useQuery().get("lesson") || "";

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("lesson", lesson);
    if (sortOrder) {
      params.set("order", sortOrder);
    }
    if (sortColumn) {
      params.set("sort", sortColumn);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [page, lesson, sortOrder, sortColumn, setSearchParams]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/lms/lessons/${id}/review?size=15&page=${page + 1}`;
        if (sortOrder && sortColumn) {
          url += `&orderBy=${sortOrder}&sortBy=${sortColumn}`;
        }
        const res = await http.get(url);
        if (
          res.data.data.count > 0 &&
          res.data.data.rows?.length === 0 &&
          page > 0
        ) {
          setPage(0);
          return;
        }
        setTotal(res.data.data.count);
        const inputs = res.data.data.rows.map((input: any) => {
          return {
            id: input.id,
            createdAt: DateTime.fromISO(input?.createdAt).toFormat(
              "dd'-'LL'-'yyyy hh:mm a"
            ),
            user: input?.participant?.code,
            suggestion: input.suggestion,
            rating: !input.rating ? 0 : input.rating > 5 ? 5 : input.rating,
            review: input.review,
          };
        });
        setData(inputs);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    if (id) {
      fetchDetails();
    }
  }, [setPage, setData, page, id, sortOrder, sortColumn]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      setSortOrder("");
      setSortColumn("");
    } else {
      setSortOrder(order);
      setSortColumn(column);
    }
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };
  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ChevronLeft
              onClick={handleBack}
              sx={{ cursor: "pointer", height: 50, width: 50, mr: 1 }}
            />
            <Typography variant="subtitle2" fontWeight="medium">
              Suggestions & Reviews for {lesson}
            </Typography>
          </Box>
        </Box>
      </StyledHeader>
      <Box sx={ContainerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Study Number</StyledTableCell>
              <StyledTableCell>
                Reviewed At
                <SortLabel column="createdAt" />
              </StyledTableCell>
              <StyledTableCell>
                Rating
                <SortLabel column="rating" />
              </StyledTableCell>
              <StyledTableCell>Review</StyledTableCell>
              <StyledTableCell>Suggestion</StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && data.length > 0 && (
            <>
              <TableBody>
                {data?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell>{row?.user}</StyledTableCell>
                    <StyledTableCell>{row?.createdAt}</StyledTableCell>
                    <StyledTableCell>
                      <Rating value={row?.rating} readOnly />
                    </StyledTableCell>
                    <StyledTableCell>{row?.review || "-"}</StyledTableCell>
                    <StyledTableCell>{row?.suggestion || "-"}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {total > pageSize && (
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={total}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
        {!loading && data.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Reviews;

import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { NoDataContainer } from "../../../../../Common/styles/table";
import QuestionItem from "../QuestionItemV1/QuestionItem";

const QuestionResponse = ({ loading, error, data }: any) => {
  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer sx={{ flex: 1 }}>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : data ? (
    <Box sx={{ flex: 1, minWidth: "1px" }}>
      <Box
        sx={{
          mb: 3,
          bgcolor: "#fff",
          borderRadius: "12px",
          p: 2,
          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Typography fontSize={21} fontWeight="medium" color="#355962">
          {data?.name}
        </Typography>
        {data?.date && (
          <Typography variant="subtitle1" fontWeight="regular" mt={1}>
            Submitted on : {data?.date}
          </Typography>
        )}
      </Box>
      {data?.questions.length > 0 ? (
        data?.questions.map((item: any) => (
          <Box key={item.id} sx={{ width: "100%", mb: 3 }}>
            <QuestionItem question={item} />
          </Box>
        ))
      ) : (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Responses
          </Typography>
        </NoDataContainer>
      )}
    </Box>
  ) : (
    <NoDataContainer sx={{ flex: 1 }}>
      <Typography variant="body1" color="gray">
        Select the questionnaire and tap on the date to view the responses.
      </Typography>
    </NoDataContainer>
  );
};

export default QuestionResponse;

import moment from "moment";
import {
  text_types,
  date_types,
  choice_types,
  number_types,
  fieldMap,
} from "../Components/QuestionBuilder/questionTypes";
import { v4 as uuid } from "uuid";
import { toastMessage } from "./toast";
import http from "./http";

declare type textMapType =
  | "multiple_choice"
  | "dropdown"
  | "likert_scale"
  | "checkbox";

const textMap = {
  multiple_choice: "Choice",
  dropdown: "Option",
  likert_scale: "Option",
  checkbox: "Choice",
};

function compare(a: any, b: any) {
  const posA = a.position;
  const posB = b.position;

  let comparison = 0;
  if (posA > posB) {
    comparison = 1;
  } else if (posA < posB) {
    comparison = -1;
  }
  return comparison;
}

function emptyStringValidation(qnum: any, str: string, type: string = "Title") {
  if (str.trim() === "") {
    throw new Error(`Question ${qnum} ${type} cannot be empty!`);
  }
  return str;
}

function fileProperties(qnum: any, question: any) {
  const { properties } = question;
  if (question.properties.maxRange === "") {
    throw new Error(`Q${qnum} file limit must be a number!`);
  }
  // let responseCodes = properties.responseCodes
  //   .map((code: any) => {
  //     return code;
  //   })
  //   .filter((code: any) => code.code);
  return {
    // responseCodes: responseCodes,
    maxRange: Number(properties.maxRange),
  };
}

function choiceProperties(qnum: any, question: any) {
  const { type, fields } = question;
  const choices: any[] = [];
  const responseCodes: any[] = [];
  fields.forEach(({ id, label, code, isOther, label_ch }: any, index: any) => {
    if (!label && !isOther) {
      throw new Error(
        `Q${qnum} ${textMap[type as textMapType]} Position ${
          index + 1
        } cannot be Empty!`
      );
    }

    let choice: any = {
      id: id,
      label,
      label_ch: label_ch || undefined,
      position: index + 1,
      isOther: isOther || false,
    };
    choices.push(choice);

    if (code !== "") {
      responseCodes.push({
        questionChoiceId: id,
        code: code,
      });
    }
  });

  return { choices, responseCodes };
}

function textProperties(qnum: any, question: any) {
  const { properties } = question;
  if (question.properties.maxRange === "") {
    throw new Error(`Q${qnum} Upper limit must be a number!`);
  }
  let responseCodes = properties.responseCodes.filter(
    (code: any) => code.code !== ""
  );
  return {
    responseCodes: responseCodes,
    maxRange: properties.maxRange,
  };
}

function dateProperties(qnum: any, question: any) {
  const { properties } = question;
  let responseCodes = properties.responseCodes.filter(
    (code: any) => code.code !== ""
  );
  return {
    responseCodes: responseCodes,
  };
}

function numberProperties(qnum: any, question: any) {
  const { properties } = question;

  let responseCodes = properties.responseCodes.map(
    (code: any, index: number) => {
      if (
        (code.condition === "lt" || code.condition === "lte") &&
        code.maxValue === ""
      ) {
        throw new Error(
          `Q${qnum} Response Value ${index + 1} cannot be Empty!`
        );
      }

      if (
        (code.condition === "gt" || code.condition === "gte") &&
        code.minValue === ""
      ) {
        throw new Error(
          `Q${qnum} Response Value ${index + 1} cannot be Empty!`
        );
      }

      if (code.condition === "between") {
        if (code.minValue === "") {
          throw new Error(
            `Q${qnum} Response Code ${index + 1} minimum value required!`
          );
        }

        if (code.maxValue === "") {
          throw new Error(
            `Q${qnum} Response Code ${index + 1} maximum value required!`
          );
        }
      }

      if (code.code === "") {
        throw new Error(`Q${qnum} Response Code ${index + 1} required!`);
      }
      return code;
    }
  );

  if (properties.maxRange === "") {
    throw new Error(`Q${qnum} Upper limit must be a number!`);
  }
  if (properties.minRange === "") {
    throw new Error(`Q${qnum} Lower limit must be a number!`);
  }
  return {
    responseCodes: responseCodes,
    maxRange: Number(properties.maxRange),
    minRange: Number(properties.minRange),
  };
}

function scoreInputProperties(qnum: any, question: any) {
  if (question.properties.maxRange === "") {
    throw new Error(`Q${qnum} Upper limit must be a number!`);
  }
  if (question.properties.minRange === "") {
    throw new Error(`Q${qnum} Lower limit must be a number!`);
  }
  return {
    uploadable: question.uploadable,
    properties: {
      minRange: Number(question.properties.minRange),
      maxRange: Number(question.properties.maxRange),
    },
  };
}

function gridProperties(qnum: any, question: any) {
  return {
    gridConfig: {
      rows: question.properties.rows.map((row: any) => {
        if (row.label === "") {
          throw new Error(`Q${qnum} Grid row labels cannot be empty!`);
        }
        return row;
      }),
      columns: question.properties.columns.map((col: any) => {
        if (col.label === "") {
          throw new Error(`Q${qnum} column labels cannot be empty!`);
        }
        if (col.type === "checkbox") {
          const newOptions = col.options.filter((opt: any) => opt);
          if (newOptions.length < 2) {
            throw new Error(
              `Atleast two choices are required for Q${qnum} grid column!`
            );
          }
          return {
            type: col.type,
            label: col.label,
            label_ch: col.label_ch || undefined,
            options: newOptions,
          };
        }
        return {
          type: col.type,
          label: col.label,
          label_ch: col.label_ch || undefined,
        };
      }),
    },
  };
}

function getLogicJump(qid: any, logicJumps: any) {
  let res = [];
  let newRes = [];
  if (logicJumps !== undefined && logicJumps.length) {
    res =
      logicJumps.find((logic: any) => logic.questionId === qid)?.actions || [];
  }
  if (res.length > 0) {
    newRes = res.map((item: any, index: number) => {
      return { ...item, position: String(index + 1) };
    });
  }

  return newRes;
}

const handleNumberTypesResponseCode = (responseCodes: any[]) => {
  let result = [];
  if (!responseCodes) {
    return [];
  }

  for (let i = 0; i < responseCodes.length; i++) {
    if (
      responseCodes[i].condition === "lt" ||
      responseCodes[i].condition === "lte"
    ) {
      result.push({
        condition: responseCodes[i].condition,
        code: responseCodes[i].code,
        maxValue: responseCodes[i].maxValue,
      });
    } else if (
      responseCodes[i].condition === "gt" ||
      responseCodes[i].condition === "gte" ||
      responseCodes[i].condition === "eq"
    ) {
      result.push({
        condition: responseCodes[i].condition,
        code: responseCodes[i].code,
        minValue: responseCodes[i].minValue,
      });
    } else {
      result.push({
        condition: responseCodes[i].condition,
        code: responseCodes[i].code,
        minValue: responseCodes[i].minValue,
        maxValue: responseCodes[i].maxValue,
      });
    }
  }

  return result;
};

const handleChoiceTypesResponseCode = (choiceId: any, responseCodes: any[]) => {
  const selectedResponse = responseCodes?.find(
    (element) => element.questionChoiceId === choiceId
  );
  if (selectedResponse) {
    return selectedResponse.code;
  } else {
    return "";
  }
};

export const questionResponseFormat = (questions: any[], logicJumps: any) => {
  let questionNo = 0;
  questions.sort(compare);
  const res: any[] = questions.map((question) => {
    let res = {};
    if (date_types.includes(question.type)) {
      res = {
        properties: {
          responseCodes: question?.properties?.responseCodes?.map?.(
            (code: any) => {
              return {
                code: code?.code,
              };
            }
          ) || [{ code: "" }],
        },
        branchType: "DATE",
      };
    } else if (text_types.includes(question.type)) {
      res = {
        properties: {
          responseCodes: question?.properties?.responseCodes?.map?.(
            (code: any) => {
              return {
                code: code?.code,
              };
            }
          ) || [{ code: "" }],
          maxRange: question?.properties?.maxRange || 40,
        },
        branchType: "TEXT",
      };
    } else if (number_types.includes(question.type)) {
      res = {
        enforceDecimal: question?.enforceDecimal || false,
        properties: {
          responseCodes: handleNumberTypesResponseCode(
            question.properties.responseCodes
          ),
          maxRange: question?.properties?.maxRange || 40,
          minRange: question?.properties?.minRange || 0,
        },
        branchType: "NUMBER",
      };
    } else if (choice_types.includes(question.type)) {
      res = {
        fields: question.properties.choices
          .sort(function (a: any, b: any) {
            return Number(a.position) - Number(b.position);
          })
          .map(({ id, label, position, label_ch, isOther }: any) => ({
            type: question.type === "yes_no" ? "yes_no" : "dropdown",
            label,
            id,
            label_ch: label_ch || undefined,
            position,
            isOther: isOther || false,
            code: handleChoiceTypesResponseCode(
              id,
              question.properties.responseCodes
            ),
          })),
        branchType: "CHOICE",
      };
    } else if (question.type === "upload_file") {
      res = {
        properties: {
          maxRange: question?.properties?.maxRange || 5,
        },
      };
    } else if (question.type === "group") {
      res = {
        questions: questionResponseFormat(
          question.properties.questions,
          logicJumps
        ),
      };
    } else if (question.type === "score_input") {
      res = {
        uploadable: question?.uploadable,
        properties: {
          maxRange: question?.properties?.maxRange || 1,
          minRange: question?.properties?.minRange || 0,
        },
      };
    } else if (question.type === "grid") {
      res = {
        properties: {
          ...question?.properties?.gridConfig,
        },
      };
    }
    if (question.type !== "group" && question.type !== "statement") {
      res = {
        ...res,
        required: question.required,
        remark: question.remark,
      };
    }
    if (question.type !== "statement") {
      questionNo++;
      res = {
        ...res,
        questionNo: questionNo,
      };
    }
    return {
      ...res,
      id: question.id,
      title: question.title,
      title_ch: question.title_ch || undefined,
      position: question.position,
      attachment: question.attachment,
      type: question.type,
      branches:
        question.type !== "group" ? getLogicJump(question.id, logicJumps) : [],
      questionCode: question.questionCode,
      varName: question.varName,
    };
  });

  return res;
};
export const questionResponseFormatV2 = (
  questionList: any[],
  responseList: any[]
) => {
  const findQuestionById: any = (questions: any[], targetId: string) => {
    const foundQuestion = questions.find(
      (question) => question.id === targetId
    );
    if (foundQuestion) {
      return foundQuestion;
    }
    const nestedQuestion = questions
      .map((question) => {
        if (question.questions) {
          return findQuestionById(question.questions, targetId);
        }
        return null;
      })
      .find(Boolean);
    return nestedQuestion || undefined;
  };
  responseList.forEach((response) => {
    const question = findQuestionById(questionList, response.questionId);

    if (question) {
      if (question.type === "checkbox") {
        question.response = question.response || [];
        question.response.push(response);
      } else {
        question.response = response;
      }
    }
  });
};

export const questionSubmitFormat = (questions: any[]) => {
  const varNameArray: string[] = [];
  const res: any[] = questions.map((question: any, index: number) => {
    const itemPosition = index + 1;
    let obj: any = {
      id: question.id,
      type: question.type,
      title: emptyStringValidation(itemPosition, question.title),
      title_ch: question.title_ch || undefined,
      position: itemPosition,
      required: question.required,
      remark: question.remark,
      varName: emptyStringValidation(
        itemPosition,
        question.varName,
        "Variable name"
      ),
    };

    if (varNameArray.includes(obj?.varName)) {
      throw new Error(
        `Duplicate variable names found at position  ${
          varNameArray.indexOf(obj?.varName) + 1
        } and ${itemPosition}`
      );
    } else {
      varNameArray.push(obj?.varName);
    }

    if (question?.attachment?.href) {
      obj["attachment"] = {
        href: question.attachment.href,
        type: question.attachment?.type || "image",
      };
    }

    if (question.questionCode) {
      obj["questionCode"] = question.questionCode;
    }

    if (number_types.includes(question.type)) {
      return {
        ...obj,
        enforceDecimal: question.enforceDecimal,
        properties: numberProperties(itemPosition, question),
      };
    } else if (text_types.includes(question.type)) {
      return {
        ...obj,
        properties: textProperties(itemPosition, question),
      };
    } else if (date_types.includes(question.type)) {
      return {
        ...obj,
        properties: dateProperties(itemPosition, question),
      };
    } else if (choice_types.includes(question.type)) {
      return {
        ...obj,
        properties: choiceProperties(itemPosition, question),
      };
    } else if (question.type === "upload_file") {
      return {
        ...obj,
        properties: fileProperties(itemPosition, question),
      };
    } else if (question.type === "group") {
      return {
        ...obj,
        properties: {
          questions: questionSubmitFormat(question.questions),
        },
      };
    } else if (question.type === "score_input") {
      return {
        ...obj,
        ...scoreInputProperties(itemPosition, question),
      };
    } else if (question.type === "grid") {
      return {
        ...obj,
        properties: gridProperties(itemPosition, question),
      };
    } else {
      return {
        ...obj,
      };
    }
  });
  return res;
};

const validateLogicJump = (
  logic: any[],
  questionIndex: number,
  groupIndex?: number
) => {
  const qNum =
    groupIndex !== undefined
      ? `Q ${questionIndex + 1}.${groupIndex + 1}`
      : `Q ${questionIndex + 1}`;
  logic.forEach((log: any, index: number) => {
    if (log.condition !== "always" && log.variable.value === "") {
      throw new Error(
        `${qNum} Logic jump Value cannot be Empty at Logic ${index + 1}`
      );
    }

    if (log.nextQuestionId === "") {
      throw new Error(
        `${qNum} Select a jump to question at Logic ${index + 1}`
      );
    }
  });
};

export const logicJumpFormat = (questions: any) => {
  const res: any[] = [];
  questions.forEach((question: any, index: any) => {
    if (question.type === "group") {
      question.questions.forEach((ques: any, groupIndex: number) => {
        if (ques?.branches?.length > 0) {
          validateLogicJump(ques.branches, index, groupIndex);
          res.push({
            questionId: ques.id,
            actions: [...ques.branches],
          });
        }
      });
    } else if (question?.branches?.length > 0) {
      validateLogicJump(question?.branches, index);
      res.push({
        questionId: question.id,
        actions: [...question.branches],
      });
    }
  });
  return res;
};

const CRFNumberTypes = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || typeof answer?.numberValue !== "number") {
      answers[question.id] = {
        numberValue: answer?.numberValue,
        remarkValue: answer?.remarkValue,
        textValue: answer?.textValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          numberValue: answer.numberValue,
          remarkValue: answer.remarkValue,
          textValue: answer.textValue,
        },
      };
    }
  } else if (typeof answer?.numberValue === "number") {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        numberValue: answer.numberValue,
        remarkValue: answer.remarkValue,
        textValue: answer.textValue,
      },
    };
  } else {
    return false;
  }
};

const CRFTextTypes = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || !answer?.textValue || !answer?.textValue?.trim()) {
      answers[question.id] = {
        textValue: answer?.textValue,
        remarkValue: answer?.remarkValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          textValue: answer.textValue,
        },
      };
    }
  } else if (answer?.textValue && answer?.textValue?.trim()) {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        textValue: answer.textValue,
      },
    };
  } else {
    return false;
  }
};

const CRFGrid = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || !answer?.gridValue) {
      answers[question.id] = {
        gridValue: answer?.gridValue,
        remarkValue: answer?.remarkValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          gridValue: answer.gridValue,
        },
      };
    }
  } else if (answer?.gridValue) {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        gridValue: answer.gridValue,
      },
    };
  } else {
    return false;
  }
};

const CRFDateTypes = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || !answer?.dateValue) {
      answers[question.id] = {
        dateValue: answer?.dateValue,
        remarkValue: answer?.remarkValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          dateValue: answer.dateValue,
        },
      };
    }
  } else if (answer?.dateValue) {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        dateValue: answer.dateValue,
      },
    };
  } else {
    return false;
  }
};

const CRFMultipleChoice = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || !answer?.questionChoiceId) {
      answers[question.id] = {
        questionChoiceId: answer?.questionChoiceId,
        remarkValue: answer?.remarkValue,
        textValue: answer?.textValue,
        error: "This is a required field",
      };
      return null;
    } else {
      const otherField = question.fields.find((choice: any) => choice.isOther);
      if (otherField?.id === answer.questionChoiceId && !answer.textValue) {
        answers[question.id] = {
          questionChoiceId: answer?.questionChoiceId,
          remarkValue: answer?.remarkValue,
          textValue: answer?.textValue,
          error: "The other label cannot be empty",
        };
        return null;
      }
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          questionChoiceId: answer.questionChoiceId,
          textValue: answer.textValue,
        },
      };
    }
  } else if (answer?.questionChoiceId) {
    const otherField = question.fields.find((choice: any) => choice.isOther);
    if (otherField?.id === answer.questionChoiceId && !answer.textValue) {
      answers[question.id] = {
        questionChoiceId: answer?.questionChoiceId,
        remarkValue: answer?.remarkValue,
        textValue: answer?.textValue,
        error: "The other label cannot be empty",
      };
      return null;
    }
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        questionChoiceId: answer.questionChoiceId,
        textValue: answer?.textValue,
      },
    };
  } else {
    return false;
  }
};

const CRFCheckbox = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (
      !answer ||
      !answer?.questionChoiceIds ||
      answer?.questionChoiceIds.length === 0
    ) {
      answers[question.id] = {
        questionChoiceIds: answer?.questionChoiceIds,
        remarkValue: answer?.remarkValue,
        otherChoice: answer?.otherChoice,
        error: "This is a required field",
      };
      return null;
    } else {
      const otherField = question.fields.find((choice: any) => choice.isOther);
      if (
        answer.questionChoiceIds.includes(otherField?.id) &&
        !answer?.otherChoice?.textValue
      ) {
        answers[question.id] = {
          questionChoiceIds: answer?.questionChoiceIds,
          remarkValue: answer?.remarkValue,
          otherChoice: answer?.otherChoice,
          error: "The other label cannot be empty",
        };
        return null;
      }
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          questionChoiceIds: answer.questionChoiceIds,
          otherChoice: answer?.otherChoice,
        },
      };
    }
  } else if (answer?.questionChoiceIds?.length > 0) {
    const otherField = question.fields.find((choice: any) => choice.isOther);
    if (
      answer.questionChoiceIds.includes(otherField?.id) &&
      !answer?.otherChoice?.textValue
    ) {
      answers[question.id] = {
        questionChoiceIds: answer?.questionChoiceIds,
        remarkValue: answer?.remarkValue,
        otherChoice: answer?.otherChoice,
        error: "The other label cannot be empty",
      };
      return null;
    }
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        questionChoiceIds: answer.questionChoiceIds,
        otherChoice: answer?.otherChoice,
      },
    };
  } else {
    return false;
  }
};

const CRFTimeType = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || !answer?.textValue || !answer?.textValue?.trim()) {
      answers[question.id] = {
        textValue: answer?.textValue,
        remarkValue: answer?.remarkValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          textValue: answer.textValue,
        },
      };
    }
  } else if (answer?.textValue && answer?.textValue?.trim()) {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        textValue: answer.textValue,
      },
    };
  } else {
    return false;
  }
};

const CRFquestionIterator = (
  question: any,
  newAnswers: any,
  requestBody: any[]
) => {
  if (question.type === "number" || question.type === "score_input") {
    const obj = CRFNumberTypes(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (
    text_types.includes(question.type) ||
    question.type === "upload_file"
  ) {
    const obj = CRFTextTypes(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (date_types.includes(question.type)) {
    const obj = CRFDateTypes(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (question.type === "checkbox") {
    const obj = CRFCheckbox(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (choice_types.includes(question.type)) {
    const obj = CRFMultipleChoice(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (question.type === "grid") {
    const obj = CRFGrid(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (question.type === "time") {
    const obj = CRFTimeType(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  }
};

export const submitCRF = (
  answers: any,
  questions: any[],
  hideQuestions: any[]
) => {
  const newAnswers = JSON.parse(JSON.stringify(answers));
  const requestBody: any[] = [];
  let error = false;
  questions.forEach((question) => {
    if (question.type === "group") {
      question.questions.forEach((que: any) => {
        if (!hideQuestions.includes(que.id)) {
          const isError = CRFquestionIterator(que, newAnswers, requestBody);
          if (isError) {
            error = true;
          }
        }
      });
    } else {
      if (!hideQuestions.includes(question.id)) {
        const isError = CRFquestionIterator(question, newAnswers, requestBody);
        if (isError) {
          error = true;
        }
      }
    }
  });
  return { error: error, value: requestBody, newAnswers: newAnswers };
};

const logicJumpHideQuestionsHandler = (
  questions: any[],
  questionId: any,
  nextQuestionId: any
) => {
  const hideArray = [];
  let found = false;
  for (let q of questions) {
    if (q.type !== "group") {
      if (!found && questionId === q.id) {
        found = true;
      } else if (found) {
        if (nextQuestionId === q.id) {
          return hideArray;
        } else {
          hideArray.push(q.id);
        }
      }
    } else {
      const hideGroup = found;
      for (let ques of q.questions) {
        if (!found && questionId === ques.id) {
          found = true;
        } else if (found) {
          if (nextQuestionId === ques.id) {
            return hideArray;
          } else {
            hideArray.push(ques.id);
          }
        }
      }
      if (found && hideGroup) {
        hideArray.push(q.id);
      }
    }
  }
  return hideArray;
};

export const logicJumpHelper = (
  crfAnswers: any,
  questions: any[],
  logicJumps: any[]
) => {
  const answers: any = { ...crfAnswers };
  let hideQuestions: any[] = [];
  logicJumps.forEach((logic) => {
    if (answers[logic.questionId]) {
      let answeredQuestion: any = null;
      questions.forEach((q) => {
        if (q.type === "group") {
          q.questions.forEach((ques: any) => {
            if (ques.id === logic.questionId) {
              answeredQuestion = ques;
            }
          });
        } else if (q.id === logic.questionId) {
          answeredQuestion = q;
        }
      });
      const answer = answers[logic.questionId];
      if (answeredQuestion) {
        if (answeredQuestion.branchType === "CHOICE") {
          if (answer?.questionChoiceId) {
            logic.actions.forEach((action: any) => {
              const choice = action?.variable?.value;
              if (
                action.condition === "is" &&
                choice === answer?.questionChoiceId
              ) {
                hideQuestions = [
                  ...hideQuestions,
                  ...logicJumpHideQuestionsHandler(
                    questions,
                    answeredQuestion.id,
                    action.nextQuestionId
                  ),
                ];
              } else if (
                action.condition === "is_not" &&
                choice !== answer?.questionChoiceId
              ) {
                hideQuestions = [
                  ...hideQuestions,
                  ...logicJumpHideQuestionsHandler(
                    questions,
                    answeredQuestion.id,
                    action.nextQuestionId
                  ),
                ];
              }
            });
            logicJumpAnswerDelete(answers, hideQuestions);
          }
        } else if (answeredQuestion.branchType === "TEXT") {
          if (answer?.textValue) {
            logic.actions.forEach((action: any) => {
              const value = action?.variable?.value;
              if (
                action.condition === "contains" &&
                answer?.textValue.includes(value)
              ) {
                hideQuestions = [
                  ...hideQuestions,
                  ...logicJumpHideQuestionsHandler(
                    questions,
                    answeredQuestion.id,
                    action.nextQuestionId
                  ),
                ];
              }
            });
            logicJumpAnswerDelete(answers, hideQuestions);
          }
        } else if (answeredQuestion.branchType === "NUMBER") {
          if (
            typeof answer?.numberValue !== "undefined" &&
            answer?.numberValue !== ""
          ) {
            logic.actions.forEach((action: any) => {
              const value = action?.variable?.value;
              const number = answer?.numberValue;
              if (
                (action.condition === "lt" && number < value) ||
                (action.condition === "lte" && number <= value) ||
                (action.condition === "eq" && number === value) ||
                (action.condition === "not_eq" && number !== value) ||
                (action.condition === "gte" && number >= value) ||
                (action.condition === "gt" && number > value)
              ) {
                hideQuestions = [
                  ...hideQuestions,
                  ...logicJumpHideQuestionsHandler(
                    questions,
                    answeredQuestion.id,
                    action.nextQuestionId
                  ),
                ];
              }
            });
            logicJumpAnswerDelete(answers, hideQuestions);
          }
        } else if (answeredQuestion.branchType === "DATE") {
          if (answer?.dateValue) {
            logic.actions.forEach((action: any) => {
              const value = moment(action?.variable?.value, "YYYY-MM-DD");
              const date = moment(answer?.dateValue);
              if (
                (action.condition === "on" && date.isSame(value)) ||
                (action.condition === "not_on" && !date.isSame(value)) ||
                (action.condition === "before" && date.isBefore(value)) ||
                (action.condition === "before_or_on" &&
                  date.isSameOrBefore(value)) ||
                (action.condition === "after" && date.isAfter(value)) ||
                (action.condition === "after_or_on" &&
                  date.isSameOrAfter(value))
              ) {
                hideQuestions = [
                  ...hideQuestions,
                  ...logicJumpHideQuestionsHandler(
                    questions,
                    answeredQuestion.id,
                    action.nextQuestionId
                  ),
                ];
              }
            });
          }
          logicJumpAnswerDelete(answers, hideQuestions);
        }
      }
    }
  });
  return { hideQuestions, answers };
};

export const logicJumpAnswerDelete = (answers: any, hideQuestions: any[]) => {
  hideQuestions.forEach((id) => {
    if (answers[id]) {
      delete answers[id];
    }
  });
};

export const answerViewFormat = (responses: any[]) => {
  const answerObj: any = {};
  responses.forEach((response) => {
    if (answerObj[response.questionId]) {
      const obj = answerObj[response.questionId];
      if (response.questionChoiceId && obj.questionChoiceId) {
        obj.questionChoiceIds = [
          obj.questionChoiceId,
          response.questionChoiceId,
        ];
        if (response.textValue) {
          obj.otherChoice = {
            choiceId: response.questionChoiceId,
            textValue: response.textValue,
          };
        }
        delete obj["questionChoiceId"];
      } else if (response.questionChoiceId) {
        obj.questionChoiceIds = [
          ...obj.questionChoiceIds,
          response.questionChoiceId,
        ];
        if (response.textValue) {
          obj.otherChoice = {
            choiceId: response.questionChoiceId,
            textValue: response.textValue,
          };
        }
      }
    } else {
      answerObj[response.questionId] = {
        dateValue: response.dateValue || undefined,
        gridValue: response.gridValue || undefined,
        remarkValue: response.remarkValue || undefined,
        textValue:
          response?.question?.type === "checkbox"
            ? undefined
            : response.textValue || undefined,
        numberValue:
          typeof response.numberValue === "number"
            ? response.numberValue
            : undefined,
        questionChoiceId: response.questionChoiceId
          ? response?.question?.type !== "checkbox"
            ? response.questionChoiceId
            : undefined
          : undefined,
        questionChoiceIds: response.questionChoiceId
          ? response?.question?.type === "checkbox"
            ? [response.questionChoiceId]
            : undefined
          : undefined,
        otherChoice:
          response?.question?.type === "checkbox" && response.textValue
            ? {
                choiceId: response.questionChoiceId,
                textValue: response.textValue,
              }
            : undefined,
      };
    }
  });
  return answerObj;
};

export const CRFCustomCalculation = (
  answers: any,
  questions: any,
  type: string
) => {
  if (!answers) {
    return;
  }
  if (type === process.env.REACT_APP_BMI_VAR) {
    const vars = process.env.REACT_APP_BMI_ARGS;
    if (vars) {
      const splitString = vars.split(",");
      let h1, h2, w1, w2;
      questions.forEach((q: any) => {
        if (q.type === "group") {
          q.questions.forEach((que: any) => {
            if (que.varName === splitString[0]) {
              h1 = que.id;
            } else if (que.varName === splitString[1]) {
              h2 = que.id;
            } else if (que.varName === splitString[2]) {
              w1 = que.id;
            } else if (que.varName === splitString[3]) {
              w2 = que.id;
            }
          });
        } else {
          if (q.varName === splitString[0]) {
            h1 = q.id;
          } else if (q.varName === splitString[1]) {
            h2 = q.id;
          } else if (q.varName === splitString[2]) {
            w1 = q.id;
          } else if (q.varName === splitString[3]) {
            w2 = q.id;
          }
        }
      });
      if (h1 && h2 && w1 && w2) {
        let weight = 0;
        let height = 0;
        if (answers[h1]?.numberValue && answers[h2]?.numberValue) {
          height = (answers[h1]?.numberValue + answers[h2]?.numberValue) / 2.0;
        }
        if (answers[w1]?.numberValue && answers[w2]?.numberValue) {
          weight = (answers[w1]?.numberValue + answers[w2]?.numberValue) / 2.0;
        }
        if (!height || !weight) {
          toastMessage(
            "warning",
            "Please enter all the values needed for calculation"
          );
          return;
        }
        let bmi = weight / (height * height);
        bmi = Math.round(bmi * 100) / 100;
        return bmi;
      }
    }
    // } else if (type === process.env.REACT_APP_LIPO_PROTEIN) {
    //   const vars = process.env.REACT_APP_LIPO_PROTEIN_ARGS;
    //   if (vars) {
    //     const splitString = vars.split(",");
    //     let tc, hdl, tg;
    //     questions.forEach((q: any) => {
    //       if (q.type === "group") {
    //         q.questions.forEach((que: any) => {
    //           if (que.varName === splitString[0]) {
    //             tc = que.id;
    //           } else if (que.varName === splitString[1]) {
    //             hdl = que.id;
    //           } else if (que.varName === splitString[2]) {
    //             tg = que.id;
    //           }
    //         });
    //       } else {
    //         if (q.varName === splitString[0]) {
    //           tc = q.id;
    //         } else if (q.varName === splitString[1]) {
    //           hdl = q.id;
    //         } else if (q.varName === splitString[2]) {
    //           tg = q.id;
    //         }
    //       }
    //     });
    //     if (tc && hdl && tg) {
    //       let a = null;
    //       let b = null;
    //       let c = null;
    //       if (typeof answers[tc]?.numberValue === "number") {
    //         a = answers[tc]?.numberValue;
    //       }
    //       if (typeof answers[hdl]?.numberValue === "number") {
    //         b = answers[hdl]?.numberValue;
    //       }
    //       if (typeof answers[tg]?.numberValue === "number") {
    //         c = answers[tg]?.numberValue;
    //       }
    //       if (a === null || b === null || c === null) {
    //         toastMessage(
    //           "warning",
    //           "Please enter all the values needed for calculation"
    //         );
    //         return;
    //       }
    //       let val = a - b - 0.45 * c;
    //       val = Math.round(val * 100) / 100;
    //       return val;
    //     }
    //   }
  } else if (
    type === process.env.REACT_APP_SBP_VAR ||
    type === process.env.REACT_APP_DBP_VAR ||
    type === process.env.REACT_APP_HEART_VAR
  ) {
    let vars;
    if (type === process.env.REACT_APP_SBP_VAR) {
      vars = process.env.REACT_APP_SBP_ARGS;
    } else if (type === process.env.REACT_APP_DBP_VAR) {
      vars = process.env.REACT_APP_DBP_ARGS;
    } else if (type === process.env.REACT_APP_HEART_VAR) {
      vars = process.env.REACT_APP_HEART_ARGS;
    }
    if (vars) {
      const splitString = vars.split(",");
      let b1, b2, b3;
      questions.forEach((q: any) => {
        if (q.type === "group") {
          q.questions.forEach((que: any) => {
            if (que.varName === splitString[0]) {
              b1 = que.id;
            } else if (que.varName === splitString[1]) {
              b2 = que.id;
            } else if (que.varName === splitString[2]) {
              b3 = que.id;
            }
          });
        } else {
          if (q.varName === splitString[0]) {
            b1 = q.id;
          } else if (q.varName === splitString[1]) {
            b2 = q.id;
          } else if (q.varName === splitString[2]) {
            b3 = q.id;
          }
        }
      });
      if (b1 && b2 && b3) {
        let bp = 0;
        if (
          answers[b1]?.numberValue &&
          answers[b2]?.numberValue &&
          answers[b3]?.numberValue
        ) {
          bp =
            (answers[b1]?.numberValue +
              answers[b2]?.numberValue +
              answers[b3]?.numberValue) /
            3;
          bp = Math.floor(bp);
          return bp;
        } else {
          toastMessage(
            "warning",
            "Please enter all the values needed for calculation"
          );
          return;
        }
      }
    }
  } else if (
    type === process.env.REACT_APP_GRIPL_VAR ||
    type === process.env.REACT_APP_GRIPR_VAR
  ) {
    let vars;
    if (type === process.env.REACT_APP_GRIPL_VAR) {
      vars = process.env.REACT_APP_GRIPL_ARGS;
    } else if (type === process.env.REACT_APP_GRIPR_VAR) {
      vars = process.env.REACT_APP_GRIPR_ARGS;
    }
    if (vars) {
      const splitString = vars.split(",");
      let b1, b2, b3;
      questions.forEach((q: any) => {
        if (q.type === "group") {
          q.questions.forEach((que: any) => {
            if (que.varName === splitString[0]) {
              b1 = que.id;
            } else if (que.varName === splitString[1]) {
              b2 = que.id;
            } else if (que.varName === splitString[2]) {
              b3 = que.id;
            }
          });
        } else {
          if (q.varName === splitString[0]) {
            b1 = q.id;
          } else if (q.varName === splitString[1]) {
            b2 = q.id;
          } else if (q.varName === splitString[2]) {
            b3 = q.id;
          }
        }
      });
      if (b1 && b2 && b3) {
        let bp = 0;
        if (
          answers[b1]?.numberValue &&
          answers[b2]?.numberValue &&
          answers[b3]?.numberValue
        ) {
          bp =
            (answers[b1]?.numberValue +
              answers[b2]?.numberValue +
              answers[b3]?.numberValue) /
            3.0;
          bp = Math.round(bp * 100) / 100;
          return bp;
        } else {
          toastMessage(
            "warning",
            "Please enter all the values needed for calculation"
          );
          return;
        }
      }
    }
  }
  return undefined;
};

export const queryGroup = (queries: any[]) => {
  const queryObj: any = {};
  queries?.forEach((query) => {
    if (queryObj[query.questionId]) {
      queryObj[query.questionId] = [...queryObj[query.questionId], query];
    } else {
      queryObj[query.questionId] = [query];
    }
  });
  return queryObj;
};

export const createQuestionSkeleton = (type: string) => {
  let newItem: any = {
    ...fieldMap(type),
    type,
    id: uuid(),
  };
  if (type === "group") {
    newItem = {
      ...newItem,
      questions: [
        {
          ...fieldMap("short_text"),
          type: "short_text",
          id: uuid(),
        },
      ],
    };
  }
  return newItem;
};

export const createNewOption = () => {
  return {
    type: "dropdown",
    id: uuid(),
    label: "",
    code: "",
  };
};

// ***TODO***
// Branches and response code ids clone
export const cloneQuestionItem = (sourceItem: any) => {
  const clonedItem = JSON.parse(JSON.stringify(sourceItem));
  clonedItem.id = uuid();
  if (clonedItem.fields) {
    clonedItem.fields = clonedItem.fields.map((field: any) => {
      return {
        ...field,
        id: uuid(),
      };
    });
  }
  if (clonedItem.questions) {
    clonedItem.questions = clonedItem.questions.map((subQuestion: any) => {
      const newSubquestion = { ...subQuestion, id: uuid() };
      if (subQuestion.fields) {
        newSubquestion.fields = subQuestion.fields.map((field: any) => {
          return {
            ...field,
            id: uuid(),
          };
        });
      }
      return newSubquestion;
    });
  }
  return clonedItem;
};

export const changeQuestionType = (sourceItem: any, newType: string) => {
  return {
    ...fieldMap(newType),
    type: newType,
    id: sourceItem.id,
  };
};

export const importQuestionnaire = async (values: any) => {
  let obj = {
    name: values.name,
    imageUrl: values.imageUrl || undefined,
    description: values.description || undefined,
    category: 1,
    studyId: "ade58392-631c-4435-908d-d4a0ebbfea75",
  };
  const res = await http.post("/questionnaires", obj);
  const qId = res.data.data.id;
  let questions = await questionResponseFormat(
    values.questions,
    values.logicJumps
  );
  let ques = await questionSubmitFormat(questions);
  let logicJumps = await logicJumpFormat(questions);
  const formObject = {
    id: qId,
    name: values.name,
    questions: ques,
    logicJumps: logicJumps,
    status: "draft",
  };
  await http.post(`/questionnaires/${qId}/build`, formObject);
  return qId;
};

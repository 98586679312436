import { SxProps } from "@mui/material";

export const ModuleCardsContainer: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(380px, 1fr))",
  gridGap: "20px",
  marginTop: "24px",
};

export const ModuleCardStyle: SxProps = {
  p: 2,
  bgcolor: "#fff",
  borderRadius: 2,
  boxShadow: "1px 1px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

export const CardMainText: SxProps = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textTransform: "capitalize",
};

export const CardAvatar: SxProps = {
  borderRadius: "4px",
  width: "70px",
  height: "70px",
  bgcolor: "#cccccc",
};

export const CustomChip: SxProps = {
  borderRadius: 1,
  p: "4px",
  mt: "10px",
  height: "26px",
  "& .MuiChip-label": {
    p: 0,
  },
};

export const ExerciseDetails: SxProps = {
  ml: 2,
  flex: 1,
  minWidth: "1px",
};

export const ModulesHeaderContainer: SxProps = {
  height: "40px",
  mb: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const LoadingContainer: SxProps = {
  display: "flex",
  justifyContent: "center",
  mt: "15%",
};

export const LoadMoreContainer: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  mt: 5,
};

export const ContainerStyle: SxProps = {
  // p: 2.5,
  // height: "calc(100% - 85px)",
  // overflow: "auto",
  mt: "24px",
};

export const UploadWrapper: SxProps = {
  borderRadius: "12px",
  border: 1,
  borderColor: "divider",
  background: "#f9fafb",
  display: "flex",
  height: "125px",
  justifyContent: "center",
  alignItems: "center",
  mt: 1,
  mb: 2,
  "&:hover": {
    borderColor: "primary.main",
    cursor: "pointer",
  },
  "& .preview-image": {
    height: "120px",
    objectFit: "contain",
  },
};

export const InputWrapper: SxProps = {
  width: "100%",
  justifyContent: "flex-start",
};

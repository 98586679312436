import React, { useMemo } from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  TextField,
  InputAdornment,
  CircularProgress,
  ListItemIcon,
} from "@mui/material";
import { useEffect, useState } from "react";

import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { MoreVert } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import AddClinicianModal from "../Modals/AddClinicianModal";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "../../../Redux/hooks";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import StatusTag from "../../Common/UI/StatusTag";

type Props = {
  type: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasModifyAccess: boolean;
};

const Clinicians = ({
  type,
  page,
  setPage,
  searchText,
  setSearchText,
  showModal,
  setShowModal,
  hasModifyAccess,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [clinicianData, setClinicianData] = useState<any>([]);
  const [clinicianDataCount, setClinicianDataCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { id } = useParams();
  const { role } = useAppSelector((state) => state.user);

  useEffect(() => {
    const fetchClinicianData = async () => {
      try {
        setLoading(true);

        let url;
        if (role === "clinician") {
          url = `/clinic/clinician?page=${page + 1}&size=${pageSize}`;
        } else {
          url = `/clinic/${id}/clinician?page=${page + 1}&size=${pageSize}`;
        }

        if (searchText) {
          url += `&search=${searchText}`;
        }

        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data?.data?.map((clinic: any) => ({
          id: clinic?.id,
          firstName: clinic.clinician?.firstName,
          lastName: clinic.clinician?.lastName,
          email: clinic.clinician?.email,
          phoneNumber: clinic.clinician?.phoneNumber,
          status: clinic.clinician?.status,
          userId: clinic?.userId,
        }));

        setClinicianData(newData || []);
        setClinicianDataCount(data?.count || 0);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchClinicianData();
  }, [page, searchText, toggleLoader, id, role]);

  const closeModal = () => {
    setShowModal(false);
  };
  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleArchive = async () => {
    try {
      setMenuLoader(true);
      const newData = {
        status: selectedRow?.status === "inactive" ? "active" : "inactive",
      };
      const res: AxiosResponse = await http.patch(
        `/admins/${selectedRow?.userId}/clinician`,
        newData
      );
      setMenuLoader(false);
      handleMenuClose();
      refreshPage();
      toastMessage("success", res.data.message);
    } catch (err) {
      setMenuLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
      }, 500),
    [setSearchText]
  );

  return (
    <Box>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "70%" }}
          placeholder="Search"
          defaultValue={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          key={type}
        />
      </Box>
      <Box sx={{ overflowX: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              {hasModifyAccess && <StyledTableCell />}
            </TableRow>
          </TableHead>
          {!loading && clinicianData?.length > 0 && (
            <>
              <TableBody>
                {clinicianData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={400} fontSize="14px">
                        {row?.firstName} {row.lastName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <Typography noWrap fontWeight={500} fontSize="16px">
                        {row?.email}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "250px" }}>
                      <PhoneInput
                        value={row?.phoneNumber}
                        copyNumbersOnly={false}
                        containerStyle={{
                          width: "100%",
                        }}
                        inputStyle={{
                          border: "none",
                          backgroundColor: "transparent",
                          paddingLeft: 0,
                          width: "fit-content",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                        buttonStyle={{
                          display: "none",
                        }}
                        disableDropdown={true}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <StatusTag status={row?.status} />
                    </StyledTableCell>
                    {hasModifyAccess && (
                      <StyledTableCell align="right">
                        <IconButton onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVert />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              {clinicianDataCount > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={clinicianDataCount}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
        {!loading && clinicianData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: "400px",
            },
          }}
        >
          <MenuItem disabled={menuLoader} onClick={handleArchive}>
            <ListItemText>
              {selectedRow?.status === "inactive" ? "Restore" : "Archive"}
            </ListItemText>
            {menuLoader && (
              <ListItemIcon>
                <CircularProgress size={18} sx={{ ml: 1 }} />
              </ListItemIcon>
            )}
          </MenuItem>
        </Menu>
        {showModal && (
          <AddClinicianModal
            showModal={showModal}
            closeModal={closeModal}
            refreshPage={refreshPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default Clinicians;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChatbotState {
  urlLoaded: boolean;
  loading: boolean;
  type: string;
  searchText: string;
  sortOrder: string;
  sortColumn: string;
  chatbotData: any[];
  totalChatbots: number;
  filterId: string;
  page: number;
  languageFilter: string[];
}

const initialState: ChatbotState = {
  urlLoaded: false,
  loading: true,

  type: "general",
  searchText: "",
  filterId: "",
  sortOrder: "",
  sortColumn: "",
  page: 0,
  languageFilter: [],
  chatbotData: [],
  totalChatbots: 0,
};

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState: initialState,
  reducers: {
    setChatbotLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setChatbotDetails: (state, action: PayloadAction<any>) => {
      state.chatbotData = action.payload;
    },
    setChatbotCount: (state, action: PayloadAction<number>) => {
      state.totalChatbots = action.payload;
    },
    setChatbotPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setChatbotType: (state, action: PayloadAction<string>) => {
      state.filterId = "";
      state.page = 0;
      state.searchText = "";
      state.sortColumn = "";
      state.sortOrder = "";
      state.languageFilter = [];
      state.type = action.payload;
    },
    setLanguageFilter: (state, action: PayloadAction<string[]>) => {
      state.page = 0;
      state.languageFilter = action.payload;
    },
    clearLanguageFilter: (state) => {
      state.page = 0;
      state.languageFilter = [];
    },
    setChatbotFilterId: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.filterId = action.payload;
    },
    setChatbotSearchText: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.searchText = action.payload;
    },
    setChatbotSort: (
      state,
      action: PayloadAction<{ column: string; order: string }>
    ) => {
      state.sortColumn = action.payload.column;
      state.sortOrder = action.payload.order;
    },
    setChatbotDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        page: number;
        filterId: string;
        type: string;
        order: string;
        sort: string;
        langFilter: string[];
      }>
    ) => {
      state.type = action.payload.type;
      state.searchText = action.payload.search;
      state.filterId = action.payload.filterId;
      state.sortOrder = action.payload.order;
      state.sortColumn = action.payload.sort;
      state.page = action.payload.page;
      state.languageFilter = action.payload.langFilter;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    reset: () => initialState,
  },
});

export const {
  setChatbotLoader,
  setChatbotDetails,
  setChatbotCount,
  setChatbotPage,
  setChatbotType,
  setChatbotFilterId,
  setChatbotSearchText,
  setUrlLoadedFalse,
  setChatbotDefaults,
  setChatbotSort,
  setLanguageFilter,
  clearLanguageFilter,
  reset,
} = chatbotSlice.actions;

export default chatbotSlice.reducer;

export const getColor = (status: string, isSelected: boolean, type: string) => {
  if (isSelected) {
    return "#FFC20A";
  } else {
    if (status === "available") {
      return "#30D073";
    } else if (status === "proposed") {
      return "#FFC20A";
    } else if (status === "unavailable") {
      return "#FF7373";
    } else return "#BDBDBD";
  }
};

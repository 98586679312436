import React, { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import http from "../../../utils/http";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import PhoneInput from "react-phone-input-2";
import { states } from "../../../utils/states";
import { timeZoneOptions } from "../../../utils/timezone";

let schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is Required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "First name can only contain alphabets"),
  lastName: yup
    .string()
    .required("Last Name is Required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Last name can only contain alphabets"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  // clinicId: yup.string().required("Clinic ID is Required"),
  // clinicianId: yup.string().required("Clinician ID is Required"),
  phoneNumber: yup.string().required("Phone Number is Required"),
  timezone: yup.string().required("Time Zone is Required"),
  addressLine1: yup.string().required("Address is Required"),
  addressCity: yup.string().required("Address City is Required"),
  addressState: yup.string().required("Address State is Required"),
  addressPostcode: yup.string().required("Zip Code is Required"),
  // addressCountryISOCode: yup
  //   .string()
  //   .required("Address Coutry ISO Code is Required"),
  dob: yup
    .date()
    .max(new Date(), "Please enter a valid Date of birth")
    .required("Date of Birth is Required")
    .typeError("Invalid Date"),
});

// type Props = {
//   showModal: boolean;
//   closeModal: () => void;
//   refreshPage: () => void;
//   data: any;
// };

const AddPatientModal: React.FC<any> = ({
  showModal,
  closeModal,
  refreshPage,
  data,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const { id } = useParams();

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      // let url =
      //   role === "clinician"
      //     ? `/participants/clinic`
      //     : `/participants/${id}/clinic`;
      let url: string;
      let res: AxiosResponse;
      const body = {
        ...values,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        userName: values.email,
        ownerId: "bbb465e9-3a51-4c89-991b-b00fe7c0e4d4",
        phoneNumber: "+" + values.phoneNumber,
        addressCountryISOCode: "US",
        dob: DateTime.fromISO(values.dob).toFormat("yyyy-MM-dd"),
      };
      if (id) {
        url = `/participants/${id}/edit-patient`;
        res = await http.patch(url, body);
      } else {
        url = `/participants/create-patient`;
        res = await http.post(url, body);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, maxHeight: "85vh" }}>
        <ModalHeader
          title={id ? "Edit Patient" : "Add new Patient"}
          onCloseClick={closeModal}
        />

        <Formik
          initialValues={{
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
            // middleName: "",
            email: data?.email || "",
            phoneNumber: data?.phoneNumber
              ? data?.phoneNumber?.replace(/[+]/g, "")
              : "",
            timezone: data?.timezone || "America/New_York",
            // recordNumber: "",
            dob: data?.dob ? DateTime.fromFormat(data?.dob, "MM/dd/yyyy") : "",
            // clinicId: "",
            // clinicianId: "",
            addressLine1: data?.addressLine1 || "",
            // addressLine2: data?.addressLine2 || "",
            addressCity: data?.addressCity || "",
            addressState: data?.addressState || "AL",
            addressPostcode: data?.addressPostcode || "",
            // addressCountryISOCode: "US",
            // mostAffectedSide: data?.mostAffectedSide || "1",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            errors,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="first-name">
                    First Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="first-name"
                    {...getFieldProps("firstName")}
                    placeholder="First Name"
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? (errors?.firstName as string)
                        : " "
                    }
                  />
                </FormControl>
                {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="middle-name">
                    Middle Name
                  </FormLabel>
                  <TextField
                    id="middle-name"
                    {...getFieldProps("middleName")}
                    error={
                      touched?.middleName && errors?.middleName ? true : false
                    }
                    helperText={
                      touched?.middleName && errors?.middleName
                        ? (errors?.middleName as string)
                        : " "
                    }
                  />
                </FormControl> */}
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="last-name">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="last-name"
                    {...getFieldProps("lastName")}
                    placeholder="Last Name"
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? (errors?.lastName as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="email"
                    inputProps={{ readOnly: id ? true : false }}
                    placeholder="Email"
                    {...getFieldProps("email")}
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email
                        ? (errors?.email as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl
                  sx={{
                    ...InputWrapper,
                    justifyContent: "unset",
                  }}
                >
                  <FormLabel sx={LabelStyle} htmlFor="contact-number">
                    Contact Number <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <PhoneInput
                    inputProps={{
                      id: "contact-number",
                    }}
                    value={values.phoneNumber}
                    onChange={(value) => setFieldValue("phoneNumber", value)}
                    country={"us"}
                    inputStyle={{
                      width: "100%",
                      height: "52px",
                      borderRadius: "6px",
                      fontSize: "16px",
                    }}
                    isValid={
                      touched?.phoneNumber && errors?.phoneNumber ? false : true
                    }
                  />
                  {touched?.phoneNumber && errors?.phoneNumber && (
                    <FormHelperText error>
                      {errors?.phoneNumber as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="post-code">
                    Zip Code <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="post-code"
                    {...getFieldProps("addressPostcode")}
                    placeholder="Zip Code"
                    error={
                      touched?.addressPostcode && errors?.addressPostcode
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressPostcode && errors?.addressPostcode
                        ? (errors?.addressPostcode as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="dob">
                    DOB <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <DatePicker
                    format="LL/dd/yyyy"
                    disableFuture
                    value={values?.dob || null}
                    onChange={(newValue: any) => {
                      setFieldValue("dob", newValue);
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        inputProps: {
                          placeholder: "MM/DD/YYYY",
                        },
                        error: touched?.dob && errors?.dob ? true : false,
                        helperText:
                          touched?.dob && errors?.dob
                            ? (errors?.dob as string)
                            : " ",
                      },
                    }}
                    // renderInput={(
                    //   params: JSX.IntrinsicAttributes & TextFieldProps
                    // ) => (
                    //   <TextField
                    //     {...params}
                    //     inputProps={{
                    //       ...params.inputProps,
                    //       placeholder: "MM/DD/YYYY",
                    //     }}
                    //     error={touched?.dob && errors?.dob ? true : false}
                    //     helperText={
                    //       touched?.dob && errors?.dob
                    //         ? (errors?.dob as string)
                    //         : " "
                    //     }
                    //   />
                    // )}
                  />
                </FormControl>
              </Box>
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinic-id">
                    Clinic ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="clinic-id"
                    {...getFieldProps("clinicId")}
                    error={touched?.clinicId && errors?.clinicId ? true : false}
                    helperText={
                      touched?.clinicId && errors?.clinicId
                        ? (errors?.clinicId as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinician-id">
                    Clinician ID <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="clinician-id"
                    {...getFieldProps("clinicianId")}
                    error={
                      touched?.clinicianId && errors?.clinicianId ? true : false
                    }
                    helperText={
                      touched?.clinicianId && errors?.clinicianId
                        ? (errors?.clinicianId as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address">
                    Address <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address"
                    {...getFieldProps("addressLine1")}
                    placeholder="Address"
                    error={
                      touched?.addressLine1 && errors?.addressLine1
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressLine1 && errors?.addressLine1
                        ? (errors?.addressLine1 as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="city-name">
                    City <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="city-name"
                    {...getFieldProps("addressCity")}
                    placeholder="City"
                    error={
                      touched?.addressCity && errors?.addressCity ? true : false
                    }
                    helperText={
                      touched?.addressCity && errors?.addressCity
                        ? (errors?.addressCity as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="state-name">
                    State <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="state-name"
                    value={values.addressState}
                    onChange={(e) => {
                      setFieldValue("addressState", e.target.value);
                    }}
                  >
                    {states.map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {`${item.value} - ${item?.label}`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.addressState && errors?.addressState
                      ? (errors?.addressState as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="time-zone">
                    Time Zone <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    id="time-zone"
                    value={values.timezone}
                    onChange={(e) => {
                      setFieldValue("timezone", e.target.value);
                    }}
                  >
                    {timeZoneOptions.map((item: any) => (
                      <MenuItem key={item?.value} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.timezone && errors?.timezone
                      ? (errors?.timezone as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
                {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="iso-code">
                    Country ISO Code <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="iso-code"
                    {...getFieldProps("addressCountryISOCode")}
                    // inputProps={{ readOnly: true }}
                    placeholder="Enter a Coutnry ISO Code. Eg: IN, US"
                    error={
                      touched?.addressCountryISOCode &&
                      errors?.addressCountryISOCode
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressCountryISOCode &&
                      errors?.addressCountryISOCode
                        ? (errors?.addressCountryISOCode as string)
                        : " "
                    }
                  />
                </FormControl> */}
              </Box>

              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="affected-side">
                    Most Affected Side
                  </FormLabel>
                  <Select
                    id="affected-side"
                    sx={{ mb: 2, width: "50%" }}
                    value={values.mostAffectedSide}
                    onChange={(e) => {
                      setFieldValue("mostAffectedSide", e.target.value);
                    }}
                  >
                    {[
                      { value: "1", label: "Left" },
                      { value: "2", label: "Right" },
                      { value: "3", label: "Both" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.mostAffectedSide && errors?.mostAffectedSide
                      ? (errors?.mostAffectedSide as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box> */}
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddPatientModal;

import { useState, useEffect, useMemo, useCallback } from "react";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  GridColDef,
  GridActionsCellItem,
  GridPagination,
  GridCellParams,
} from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  LinearProgress,
  TextField,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Badge,
} from "@mui/material";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledDataGrid,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { fetchParticipantsList } from "../../../Redux/actions/participantsAction";
import {
  setParticipantsFilterId,
  setParticipantsPage,
  setParticipantsSearchText,
  setParticipantToggle,
  setSelectedRows,
} from "../../../Redux/reducers/participantsSlice";
import { useNavigate } from "react-router";
import AssignDoctorModal from "./AssignDoctorModal";
import { canModifyClinicsPatients } from "../../../utils/roles";

function CustomPagination(props: any) {
  return (
    <GridPagination
      sx={TablePaginationStyle}
      labelDisplayedRows={paginationLabel}
      {...props}
    />
  );
}

const ParticipantList = () => {
  const [menuLoader, setMenuLoader] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const dispatch = useAppDispatch();
  const {
    loading,
    type,
    searchText,
    doctorsList,
    participantsData,
    totalParticipants,
    filterId,
    paginationModel,
    toggleLoader,
    selectedRows,
  } = useAppSelector((state) => state.participants);
  const { role, hasAdminAccess } = useAppSelector((state) => state.user);
  const [hasPatientsAccess] = useState(canModifyClinicsPatients(role));
  const [selectedOption, setSelectedOption] = useState(filterId);
  const [selectedId, setSelectedId] = useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      fetchParticipantsList(
        paginationModel.page,
        type,
        filterId,
        searchText,
        role
      )
    );
  }, [
    paginationModel,
    type,
    searchText,
    dispatch,
    toggleLoader,
    role,
    filterId,
  ]);

  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleFilterItem = (id: string) => {
    setSelectedOption(id);
  };

  const handleClickOK = () => {
    setAnchorEl(null);
    if (selectedOption !== filterId) {
      dispatch(setParticipantsFilterId(selectedOption));
    }
  };

  const handleReset = () => {
    setAnchorEl(null);
    setSelectedOption("");
    dispatch(setParticipantsFilterId(""));
  };

  const modifyUser = useCallback(
    async (data: any) => {
      try {
        setMenuLoader(true);
        const newData = {
          status: data?.status === "inactive" ? "active" : "inactive",
        };
        const res: AxiosResponse = await http.put(
          `/participants/${data?.id}`,
          newData
        );
        setMenuLoader(false);
        dispatch(setParticipantToggle());
        toastMessage("success", res.data.message);
      } catch (err) {
        setMenuLoader(false);
        errorToastMessage(err as Error);
      }
    },
    [dispatch]
  );

  const closeAssignModal = () => {
    setShowAssignModal(false);
    setSelectedId([]);
  };

  const columns = useMemo<GridColDef<any>[]>(() => {
    const reassignDoctor = (row: any) => {
      // dispatch(setSelectedRows([row?.id]));
      setSelectedId([row?.id]);
      setShowAssignModal(true);
    };

    return [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        sortable: false,
        cellClassName: "clickable-column",
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        sortable: false,
      },
      {
        field: "doctor",
        headerName: "Physical Therapist",
        flex: 1,
        sortable: false,
        renderCell: ({ value }: any) => (
          <Typography sx={{ textTransform: "capitalize" }}>{value}</Typography>
        ),
      },
      {
        field: "id",
        type: "actions",
        flex: 1,
        headerAlign: "right",
        renderHeader: () => (
          <IconButton onClick={openFilterMenu}>
            <Badge
              color="error"
              variant="dot"
              overlap="circular"
              invisible={filterId ? false : true}
            >
              <FilterAltIcon color={filterId ? "primary" : "disabled"} />
            </Badge>
          </IconButton>
        ),
        align: "right",
        getActions: (params: any) => {
          const actions: JSX.Element[] = [];

          if (hasAdminAccess) {
            actions.push(
              <GridActionsCellItem
                showInMenu
                label={
                  params?.row?.status === "inactive"
                    ? "Make Active"
                    : "Make Inactive"
                }
                onClick={() => modifyUser(params?.row)}
                disabled={menuLoader}
                icon={
                  menuLoader ? (
                    <CircularProgress size={18} sx={{ ml: 1 }} />
                  ) : undefined
                }
              />
            );
          }

          if (params?.row?.status === "active") {
            actions.push(
              <GridActionsCellItem
                showInMenu
                label={"Assign Physical Therapist"}
                onClick={() => reassignDoctor(params?.row)}
                disabled={menuLoader}
                icon={
                  menuLoader ? (
                    <CircularProgress size={18} sx={{ ml: 1 }} />
                  ) : undefined
                }
              />
            );
          }

          return actions;
        },
      },
    ];
  }, [modifyUser, menuLoader, setShowAssignModal, filterId, hasAdminAccess]);

  const handleCellClick = (params: GridCellParams) => {
    const { field, row } = params;
    if (field === "name" && type !== "unregistered") {
      navigate(`/app/participants/${row?.id}`);
    }
  };
  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setParticipantsSearchText(val));
      }, 500),
    [dispatch]
  );

  return (
    <>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "70%" }}
          placeholder="Search for Patients"
          defaultValue={searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          id="participants-search"
          key={type}
        />
      </Box>
      <Box
        sx={{
          height:
            totalParticipants > pageSize
              ? "calc(100vh - 312px)"
              : "calc(100vh - 315px)",
          bgcolor:
            loading || totalParticipants <= pageSize ? "transparent" : "#fff",
        }}
      >
        <StyledDataGrid
          rows={loading ? [] : participantsData}
          loading={loading}
          slots={{
            loadingOverlay: () => <LinearProgress />,
            pagination: CustomPagination,
            // totalParticipants < pageSize ? () => <></> :
            noRowsOverlay: () => (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            ),
          }}
          hideFooter={loading || totalParticipants <= pageSize}
          columns={columns}
          columnVisibilityModel={{
            // type: type !== "unregistered",
            id: hasAdminAccess,
            doctor: hasAdminAccess,
          }}
          onCellClick={handleCellClick}
          keepNonExistentRowsSelected
          disableRowSelectionOnClick
          checkboxSelection={hasAdminAccess && type === "active"}
          onRowSelectionModelChange={(newRowSelectionModel: any) => {
            dispatch(setSelectedRows(newRowSelectionModel));
          }}
          rowSelectionModel={selectedRows}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model) =>
            dispatch(setParticipantsPage(model))
          }
          pageSizeOptions={[pageSize]}
          rowCount={totalParticipants}
          disableColumnMenu
          // autoHeight
          columnHeaderHeight={52}
          rowHeight={65}
        />
      </Box>
      {showAssignModal && hasPatientsAccess && (
        <AssignDoctorModal
          showModal={showAssignModal}
          closeModal={closeAssignModal}
          selectedRows={selectedId}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClickOK}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "25ch",
          },
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="regular"
          sx={{
            p: "10px 14px",
          }}
        >
          Physical Therapist
        </Typography>
        {doctorsList?.map((item: any) => (
          <MenuItem
            selected={item?.value === selectedOption}
            key={item?.value}
            onClick={() => handleFilterItem(item?.value)}
          >
            <ListItemIcon>
              {item?.value === selectedOption ? (
                <RadioButtonCheckedIcon fontSize="small" color="primary" />
              ) : (
                <RadioButtonUncheckedIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText> {item?.text}</ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleClickOK}>
            OK
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default ParticipantList;

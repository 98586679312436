import {
  // ChangeEvent,
  useState,
  // useState,
} from "react";
import {
  Box,
  Button,
  // Button,
  // CircularProgress,
  Typography,
} from "@mui/material";
// import UploadIcon from "@mui/icons-material/Upload";

import {
  HeaderLeftContent,
  HeaderRightContent,
  // HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setParticipantToggle,
  // setParticipantToggle,
} from "../../../Redux/reducers/participantsSlice";
// import { Add } from "@mui/icons-material";
import { canModifyClinicsPatients } from "../../../utils/roles";
import AddPatientModal from "../../Clinics/Modals/AddPatientModal";
import AssignDoctorModal from "./AssignDoctorModal";
// import { errorToastMessage, toastMessage } from "../../../utils/toast";
// import http from "../../../utils/http";
// import AssignDoctorModal from "./AssignDoctorModal";
// import { downloadStudyNumber } from "../../../utils/download";

const ParticipantHeader = () => {
  const dispatch = useAppDispatch();
  // const [loader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const { role } = useAppSelector((state) => state.user);
  const { selectedRows } = useAppSelector((state) => state.participants);
  const [hasPatientsAccess] = useState(canModifyClinicsPatients(role));

  // const uploadStudyList = async (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     const isLt5M = file.size / 1024 / 1024 < 5;
  //     if (!isLt5M) {
  //       toastMessage("warning", "File size must smaller than 5MB!");
  //       return false;
  //     }
  //     try {
  //       setLoader(true);
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       await http.post("/participants/import-study-numbers", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       toastMessage("success", "Study numbers added successfully");
  //       if (type === "unregistered") {
  //         dispatch(setParticipantToggle());
  //       }
  //       setLoader(false);
  //     } catch (err) {
  //       errorToastMessage(err as Error);
  //       setLoader(false);
  //     }
  //   }
  // };

  const reassignDoctor = () => {
    setShowAssignModal(true);
  };

  const closeAssignModal = () => {
    setShowAssignModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const refreshPage = () => {
    dispatch(setParticipantToggle());
  };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Patients
        </Typography>
      </Box>
      {hasPatientsAccess && (
        <Box sx={HeaderRightContent}>
          {selectedRows?.length > 0 && (
            <Button
              variant="outlined"
              component="label"
              onClick={reassignDoctor}
            >
              Assign Physical Therapist
            </Button>
          )}
          {/* <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setShowModal(true)}
          >
            Add Patient
          </Button> */}
        </Box>
      )}
      {/* {hasAdminAccess && (
        <Box sx={HeaderRightContent}>
          {selectedRows?.length > 0 && (
            <Button
              variant="outlined"
              component="label"
              onClick={reassignDoctor}
            >
              Assign Doctors
            </Button>
          )}
          {/* {loader ? (
            <CircularProgress size={25} />
          ) : (
            <>
              {selectedRows?.length > 0 && (
                <Button
                  variant="outlined"
                  component="label"
                  onClick={reassignDoctor}
                >
                  Assign Doctors
                </Button>
              )}
              <Button variant="contained" onClick={downloadSample}>
                Download Sample CSV
              </Button>
              <Button
                variant="outlined"
                component="label"
                startIcon={<UploadIcon />}
              >
                Upload Study Numbers
                <input
                  hidden
                  accept="text/csv"
                  type="file"
                  onChange={uploadStudyList}
                />
              </Button>
            </>
          )}
        </Box>
      )} */}
      {showModal && (
        <AddPatientModal
          showModal={showModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
        />
      )}
      {showAssignModal && hasPatientsAccess && (
        <AssignDoctorModal
          showModal={showAssignModal}
          closeModal={closeAssignModal}
          selectedRows={selectedRows}
        />
      )}
    </StyledHeader>
  );
};

export default ParticipantHeader;

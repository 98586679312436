import QuestionBlock from "../QuestionBlock";

export const GroupType: React.FC<any> = ({ question, groupIndex }) => {
  return question.questions.map((subQ: any, index: number) => {
    return (
      <QuestionBlock
        parentIndex={groupIndex}
        index={index}
        q={subQ}
        key={subQ.id}
      />
    );
  });
};

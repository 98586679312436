import { SxProps } from "@mui/material";

export const CardsWrapper: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gridGap: "16px",
  mb: 3,
};

export const DashboardCardStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  bgcolor: "#fff",
  boxShadow: " 0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  minHeight: "122px",
  borderRadius: 2,
  padding: 2,
  overflow: "hidden",
  gap: 2.5,
};

export const ChartContainer: SxProps = {
  height: "421px",
  bgcolor: "#fff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  borderRadius: 2,
};

export const DTxCardStyle: SxProps = {
  ...DashboardCardStyle,
  minWidth: "unset",
  maxWidth: "unset",
  minHeight: "370px",
};

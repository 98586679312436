import { SxProps } from "@mui/material";

export const OpenTokIconStyle: SxProps = {
  fontSize: "30px",
};

export const PublisherMiniWindowWrapper = {
  position: "absolute",
  top: "20px",
  right: "100px",
  zIndex: 999,
  borderRadius: "4px",
  overflow: "hidden",
};

export const PublisherIconWrapper: SxProps = {
  height: 35,
  width: 35,
  borderRadius: "50%",
  bgcolor: "#0F1821",
  opacity: 0.7,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const ControlsWrapper: SxProps = {
  position: "fixed",
  bottom: "76px",
  zIndex: 999,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  paddingInline: 5,
};

import React, { useState } from "react";
import ReactPlayer from "react-player";
// import { logCustomGaEvent } from "../../../utils/webviewAnalytics";
import VideoTranscript from "./VideoTranscript";
import { Backdrop, Box } from "@mui/material";

type Props = {
  item: any;
  pageId: string;
  lang: string;
};

export const AudioPlayer: React.FC<Props> = ({ item, pageId }) => {
  // const playHandler = () => {
  //   logCustomGaEvent("em_audio_play", {
  //     page_id: pageId,
  //     section_id: item?.id,
  //   });
  // };

  // const pauseHandler = () => {
  //   logCustomGaEvent("em_audio_pause", {
  //     page_id: pageId,
  //     section_id: item?.id,
  //   });
  // };

  // const endHandler = () => {
  //   logCustomGaEvent("em_audio_end", {
  //     page_id: pageId,
  //     section_id: item?.id,
  //   });
  // };

  return (
    <div className="cms-audio">
      <div className="cms-media-label">{item?.resourceTitle}</div>
      <ReactPlayer
        className="education-audio"
        controls
        width="100%"
        url={item?.resourceUrl}
        // onPause={pauseHandler}
        // onPlay={playHandler}
        // onEnded={endHandler}
      />
    </div>
  );
};

export const VideoPlayer: React.FC<Props> = ({ item, pageId, lang }) => {
  // const playHandler = () => {
  //   logCustomGaEvent("em_video_play", {
  //     page_id: pageId,
  //     section_id: item?.id,
  //   });
  // };

  // const pauseHandler = () => {
  //   logCustomGaEvent("em_video_pause", {
  //     page_id: pageId,
  //     section_id: item?.id,
  //   });
  // };

  // const endHandler = () => {
  //   logCustomGaEvent("em_video_end", {
  //     page_id: pageId,
  //     section_id: item?.id,
  //   });
  // };

  return (
    <>
      <div className="cms-video">
        <div className="cms-media-label">{item?.resourceTitle}</div>
        <ReactPlayer
          className="education-video"
          controls
          width="100%"
          height="400px"
          url={item?.resourceUrl}
          // onPause={pauseHandler}
          // onPlay={playHandler}
          // onEnded={endHandler}
        />
      </div>
      {item.transcript && (
        <VideoTranscript
          transcript={item.transcript}
          lang={lang}
          pageId={pageId}
          sectionId={item?.id}
        />
      )}
    </>
  );
};

const PreviewImageWrapper = {
  height: "65%",
  overflow: "hidden",
  ".previewImage": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
};

type PreviewProps = {
  handleClose: Function;
  url: string;
  altText: string;
};

const ImagePreview: React.FC<PreviewProps> = ({
  handleClose,
  url,
  altText,
}) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={() => {
        handleClose();
      }}
    >
      <Box
        sx={PreviewImageWrapper}
        onClick={() => {
          handleClose();
        }}
      >
        <img
          src={url}
          className="previewImage"
          alt={altText}
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
    </Backdrop>
  );
};

export const ImageSection: React.FC<Props> = ({ item, pageId }) => {
  const [preview, setPreview] = useState(false);

  const togglePreview = () => {
    if (preview) {
      setPreview(false);
    } else {
      // logCustomGaEvent("em_image_preview", {
      //   page_id: pageId,
      //   section_id: item?.id,
      // });
      setPreview(true);
    }
  };

  return (
    <>
      <div className="cms-picture">
        <div className="cms-media-label">{item?.resourceTitle}</div>
        <img
          src={item.resourceUrl}
          alt={item.resourceAltTitle || "section"}
          onClick={togglePreview}
        />
      </div>
      {preview && (
        <ImagePreview
          handleClose={togglePreview}
          url={item.resourceUrl}
          altText={item.resourceAltTitle || "section"}
        />
      )}
    </>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CloudState {
  urlLoaded: boolean;
  loading: boolean;
  toggleLoader: boolean;
  type: string;
  searchText: string;
  sortOrder: string;
  sortColumn: string;
  tagsData: any[];
  highlightsData: any[];
  totalHighlights: number;
  filterId: string;
  page: number;
  modalProps: {
    show: boolean;
    id: string;
    title: string;
    description: string;
  };

  learningPathPatientType: string;
  learningPathLanguage: string;
}

const initialState: CloudState = {
  urlLoaded: false,
  loading: true,
  toggleLoader: false,

  type: "tag",
  searchText: "",
  filterId: "",
  sortOrder: "",
  sortColumn: "",
  page: 0,

  tagsData: [],
  highlightsData: [],
  totalHighlights: 0,

  modalProps: {
    show: false,
    title: "",
    description: "",
    id: "",
  },

  learningPathLanguage: "en",
  learningPathPatientType: "participant",
};

export const cloudSlice = createSlice({
  name: "cloud",
  initialState: initialState,
  reducers: {
    setCloudLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    toggleCloud: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setTagsData: (state, action: PayloadAction<any>) => {
      state.tagsData = action.payload;
    },
    setHighlightsData: (state, action: PayloadAction<any>) => {
      state.highlightsData = action.payload;
    },
    setHighlightsCount: (state, action: PayloadAction<number>) => {
      state.totalHighlights = action.payload;
    },
    setHighlightsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setCloudType: (state, action: PayloadAction<string>) => {
      state.searchText = "";
      state.type = action.payload;
    },
    setcloudFilterId: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.filterId = action.payload;
    },
    setCloudSearchText: (state, action: PayloadAction<string>) => {
      state.page = 0;
      state.searchText = action.payload;
    },
    setcloudSort: (
      state,
      action: PayloadAction<{ column: string; order: string }>
    ) => {
      state.sortColumn = action.payload.column;
      state.sortOrder = action.payload.order;
    },
    setCloudDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        page: number;
        type: string;
      }>
    ) => {
      state.type = action.payload.type;
      state.searchText = action.payload.search;
      state.page = action.payload.page;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    setLearningPathLang: (state, action: PayloadAction<string>) => {
      state.learningPathLanguage = action.payload;
    },
    setLearningPathPatientType: (state, action: PayloadAction<string>) => {
      state.learningPathPatientType = action.payload;
    },
    setCloudModalProps: (
      state,
      action: PayloadAction<{
        show: boolean;
        title: string;
        description: string;
        id: string;
      }>
    ) => {
      state.modalProps.id = action.payload.id;
      state.modalProps.show = action.payload.show;
      state.modalProps.title = action.payload.title;
      state.modalProps.description = action.payload.description;
    },
    reset: () => initialState,
  },
});

export const {
  setCloudLoader,
  setTagsData,
  setHighlightsData,
  setHighlightsCount,
  setHighlightsPage,
  setCloudType,
  setcloudFilterId,
  setCloudSearchText,
  setUrlLoadedFalse,
  setCloudDefaults,
  setcloudSort,
  setLearningPathLang,
  setLearningPathPatientType,
  toggleCloud,
  setCloudModalProps,
  reset,
} = cloudSlice.actions;

export default cloudSlice.reducer;

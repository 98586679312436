import { Box } from "@mui/material";
import React, { useState } from "react";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../../../../../Common/UI/TabPanel";
import Questionnaire from "./Questionnaire";

const QuestionnaireTabs = () => {
  const [type, setType] = useState("onDemand");

  const handleChange = (_: any, newValue: string) => {
    setType(newValue);
  };

  return (
    <>
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="On Demand" value="onDemand" {...a11yProps(0)} />
          <StyledTab label="Trigger" value="trigger" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <MainTabPanel value={type} index={type}>
        <Questionnaire type={type} />
      </MainTabPanel>
    </>
  );
};

export default QuestionnaireTabs;

import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { AxiosResponse } from "axios";

import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { AdminMap } from "./Administrators";
import { timeZoneOptions } from "../../../utils/timezone";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  type: string;
  setToggleLoader: any;
};

let schema = yup.object().shape({
  firstName: yup.string().required("*First Name is Required"),
  lastName: yup.string().required("*Last Name is Required"),
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("*Email ID is Required"),
  timezone: yup.string().when("type", {
    is: (val: string) => val === "coach",
    then: (schema) => schema.required("*TimeZone is Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const AddAdminModal = ({
  showModal,
  closeModal,
  type,
  setToggleLoader,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    timezone: "America/New_York",
    type: type,
  };

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let data;

      if (type === "coach") {
        data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          timezone: values.timezone,
          role: type,
        };
      } else {
        data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: type,
        };
      }

      let res: AxiosResponse;
      if (type === "coach") {
        res = await http.post(`/coach`, data);
      } else {
        res = await http.post(`/admins`, data);
      }
      toastMessage("success", res.data.message);
      closeModal();
      setToggleLoader((prev: any) => !prev);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={`Add ${AdminMap[type]}`}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps, setFieldValue, values }) => (
            <Form>
              <Box sx={{ display: "flex", gap: 1.5 }}>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={LabelStyle} htmlFor="firstName">
                    First Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="firstName"
                    placeholder="First Name"
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? errors?.firstName
                        : " "
                    }
                    {...getFieldProps("firstName")}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={LabelStyle} htmlFor="lastName">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="lastName"
                    placeholder="Last Name"
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? errors?.lastName
                        : " "
                    }
                    {...getFieldProps("lastName")}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="email"
                    placeholder="email@example.com"
                    error={touched?.email && errors?.email ? true : false}
                    helperText={
                      touched?.email && errors?.email ? errors?.email : " "
                    }
                    {...getFieldProps("email")}
                  />
                </FormControl>
              </Box>
              {type === "coach" && (
                <Box>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="time-zone">
                      Time Zone <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      id="time-zone"
                      value={values.timezone}
                      onChange={(e) => {
                        setFieldValue("timezone", e.target.value);
                      }}
                    >
                      {timeZoneOptions.map((item: any) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {touched?.timezone && errors?.timezone
                        ? (errors?.timezone as string)
                        : " "}
                    </FormHelperText>
                  </FormControl>
                </Box>
              )}
              <Box sx={{ ...ModalActionButtonStyles, mt: 1 }}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddAdminModal;

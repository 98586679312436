import { Box, Button, Slider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { indxToAlpha } from "../../../utils/education";
// import { logCustomGaEvent } from "../../../utils/webviewAnalytics";
import { getTranslation } from "../../../utils/webviewLang";

type Props = {
  questions: any;
  answers: any;
  sectionId: string;
  modifyQuestionResponse: Function;
  submitQuestion: Function;

  pageId: string;
  lang: string;
};

// const qnModified = async (pageId: string, sectionId: string, data: any) => {
//   logCustomGaEvent("em_page_qn_ans_modified", {
//     page_id: pageId,
//     section_id: sectionId,
//     ...data,
//   });
// };

const WebQuestion: React.FC<Props> = ({
  questions,
  answers,
  modifyQuestionResponse,
  submitQuestion,
  sectionId,
  pageId,
  lang,
}) => {
  const questionTypes: any = {
    multi_select: MCQ,
    single_select: Quiz,
    slider: SliderComp,
    // timer: Timer,
    text: Text,
  };

  return (
    <>
      {questions.map((q: any) => {
        const answer = answers[q.id];
        if (questionTypes[q.type] === undefined) return null;
        const TypeComponent = questionTypes[q.type];
        return (
          <div className="question-container" key={q.id}>
            <div className="question-title">{q.title}</div>
            {q.type !== "timer" && q.imageUrl && (
              <img src={q.imageUrl} className="question-image" alt="question" />
            )}
            <TypeComponent
              question={q}
              answer={answer}
              modifyQuestionResponse={modifyQuestionResponse}
              pageId={pageId}
              sectionId={sectionId}
              lang={lang}
            />
          </div>
        );
      })}
      <Button
        sx={{
          height: "60px",
          textTransform: "none",
          my: 2,
          fontWeight: 600,
          fontSize: "1.125rem",
        }}
        variant="contained"
        fullWidth
        onClick={() => submitQuestion(questions, sectionId)}
      >
        {getTranslation("show_answer", lang)}
      </Button>
    </>
  );
};

const MCQ = ({
  question,
  answer,
  modifyQuestionResponse,
  pageId,
  sectionId,
  lang,
}: any) => {
  const changeHandler = (id: string) => {
    if (answer?.questionChoiceIds) {
      if (answer?.questionChoiceIds.includes(id)) {
        const ans = answer?.questionChoiceIds.filter((aid: any) => aid !== id);
        // qnModified(pageId, sectionId, {
        //   question_id: question.id,
        //   answer: ans.join(","),
        // });
        modifyQuestionResponse(question.id, "questionChoiceIds", ans);
      } else {
        const ans = [...answer?.questionChoiceIds, id];
        // qnModified(pageId, sectionId, {
        //   question_id: question.id,
        //   answer: ans.join(","),
        // });
        modifyQuestionResponse(question.id, "questionChoiceIds", ans);
      }
    } else {
      const ans = [id];
      // qnModified(pageId, sectionId, {
      //   question_id: question.id,
      //   answer: ans.join(","),
      // });
      modifyQuestionResponse(question.id, "questionChoiceIds", ans);
    }
  };

  let submitted = answer?.submitted;
  let explanation = null;
  if (
    submitted &&
    question?.correctAnswer?.length > 0 &&
    answer?.questionChoiceIds?.length > 0
  ) {
    const correct =
      question?.correctAnswer?.every((id: any) => {
        return answer?.questionChoiceIds?.includes(id);
      }) &&
      question?.correctAnswer?.length === answer?.questionChoiceIds?.length;
    correct ? (explanation = "correct") : (explanation = "wrong");
  }

  return (
    <>
      <div className="question-choices">
        {question.choices.map((choice: any, index: number) => {
          const selected = answer?.questionChoiceIds
            ? answer?.questionChoiceIds.includes(choice.id)
            : false;
          return (
            <div
              key={choice.id}
              className={
                selected ? "question-choice selected" : "question-choice"
              }
              onClick={() => changeHandler(choice.id)}
            >
              {choice.image && (
                <img
                  src={choice.image}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
            </div>
          );
        })}
      </div>
      <div className="compulsory">
        {"(" + getTranslation("question_compulsory", lang) + ")"}
      </div>
      {submitted && explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

const Quiz = ({
  question,
  answer,
  modifyQuestionResponse,
  // pageId,
  // sectionId,
  lang,
}: any) => {
  const changeHandler = (id: string) => {
    const ans = [id];
    // qnModified(pageId, sectionId, {
    //   question_id: question.id,
    //   answer: ans.join(","),
    // });
    modifyQuestionResponse(question.id, "questionChoiceIds", ans);
  };

  let submitted = answer?.submitted;
  let explanation = null;
  if (
    submitted &&
    question?.correctAnswer?.length > 0 &&
    answer?.questionChoiceIds?.[0]
  ) {
    question?.correctAnswer?.[0] === answer?.questionChoiceIds?.[0]
      ? (explanation = "correct")
      : (explanation = "wrong");
  }

  return (
    <>
      <div className="question-choices">
        {question.choices.map((choice: any, index: number) => {
          return (
            <div
              key={choice.id}
              className={
                answer?.questionChoiceIds?.[0] === choice.id
                  ? "question-choice selected"
                  : "question-choice"
              }
              onClick={() => changeHandler(choice.id)}
            >
              {choice.image && (
                <img
                  src={choice.image}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
            </div>
          );
        })}
      </div>
      <div className="compulsory">
        {"(" + getTranslation("question_compulsory", lang) + ")"}
      </div>
      {submitted && explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

const Text = ({ question, modifyQuestionResponse, answer, lang }: any) => {
  const changeHandler = (event: any) => {
    modifyQuestionResponse(question.id, "textValue", event.target.value);
  };

  return (
    <TextField
      multiline
      rows={3}
      placeholder={getTranslation("answer_placeholder", lang)}
      fullWidth
      value={answer?.textValue || ""}
      onChange={changeHandler}
    />
  );
};

const SliderComp = ({ question, answer, modifyQuestionResponse }: any) => {
  const [marks, setMarks] = useState<any[]>([]);
  const choice = question.choices[0];

  useEffect(() => {
    const newMarks = [
      {
        value: parseInt(choice.minValue),
        label: "",
        text: choice.minLabel,
      },
    ];
    if (choice.midValue && choice.midLabel) {
      newMarks.push({
        value: parseInt(choice.midValue),
        label: "",
        text: choice.midLabel,
      });
    }
    newMarks.push({
      value: parseInt(choice.maxValue),
      label: "",
      text: choice.maxLabel,
    });
    setMarks(newMarks);
  }, [choice, setMarks]);

  const changeHandler = (_1: any, newValue: number | number[]) => {
    modifyQuestionResponse(question.id, "textValue", newValue);
  };

  let explanation = null;
  if (answer?.submitted) {
    const val = parseInt(answer?.textValue);
    const cutoff = parseInt(choice.cutoff);
    if (
      typeof cutoff === "number" &&
      !isNaN(cutoff) &&
      typeof val === "number" &&
      !isNaN(val)
    ) {
      if (val > cutoff) {
        explanation = "correct";
      } else {
        explanation = "wrong";
      }
    }
  }

  return (
    <>
      <Box sx={{ pr: 1 }}>
        <Slider
          step={1}
          marks={marks}
          min={parseInt(choice.minValue)}
          max={parseInt(choice.maxValue)}
          value={
            answer?.textValue !== undefined ? parseInt(answer?.textValue) : 0
          }
          onChange={changeHandler}
        />
      </Box>
      <div className="slider-marks">
        {marks.map((mark) => {
          return (
            <div
              className={
                answer?.textValue !== undefined &&
                parseInt(answer?.textValue) >= mark.value
                  ? "slider-mark selected"
                  : "slider-mark"
              }
              key={mark.value}
            >
              {mark.text}
            </div>
          );
        })}
      </div>
      {explanation ? (
        <div className={"explanation " + explanation}>
          {explanation === "correct"
            ? question?.correctExplanation
            : question?.wrongExplanation}
        </div>
      ) : null}
    </>
  );
};

// const Timer = ({ question, answer, modifyQuestionResponse }: any) => {
//   const { appDispatch }: any = useContext(AppContext);
//   const choice = question.choices[0];

//   const startChallenge = () => {
//     if (answer?.textValue !== "Completed") {
//       const choice = question.choices[0];
//       const callback = () => {
//         modifyQuestionResponse(question.id, "textValue", "Completed");
//       };
//       appDispatch(
//         setModalDetails("TIMER_MODAL", {
//           show: true,
//           timer: choice,
//           title: question.title,
//           callback,
//         })
//       );
//     }
//   };

//   return (
//     <>
//       {choice.image && (
//         <Image src={choice.image} className="mb-3 slider-image" alt="timer" />
//       )}
//       <Button type="primary" className="cms-button" onClick={startChallenge}>
//         {answer?.textValue === "Completed"
//           ? "Timer Challenge done"
//           : "Start Timer Challenge"}
//       </Button>
//       {answer?.textValue === "Completed" && question?.correctExplanation && (
//         <div className={"explanation correct mt-1"}>
//           {question?.correctExplanation}
//         </div>
//       )}
//     </>
//   );
// };

export default WebQuestion;

/* eslint-disable no-underscore-dangle */
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import arrayRemoveItem from '../../helpers/arrayRemoveItem';
import PeerConnection from './peer_connection';
import SubscriberPeerConnectionQueue from './subscriberPeerConnectionQueue';
import createPeerConnectionSDPDefault from './peerConnectionSDP';
import SDPHelpersDefault from './sdp_helpers';

const PeerConnectionDefault = PeerConnection();

class SinglePeerConnectionController {
  constructor(session, deps = {}) {
    this.createPeerConnectionSDP = deps.createPeerConnectionSDP || createPeerConnectionSDPDefault;
    this._session = session;
    this.PeerConnection = deps.PeerConnection || PeerConnectionDefault;
    this.sdpHelpers = deps.helpers || SDPHelpersDefault;
    this._init();
  }

  _init() {
    this._reset();
  }

  _reset() {
    this._originalSendMessages = {};
    this._tracks = [];
    this._currentAndPreviousMids = [];
    this._subscriberPcs = {};
    this._peerConnectionSDP = this.createPeerConnectionSDP();
    this._subscriberQueue = new SubscriberPeerConnectionQueue();
    this._sendMessage = () => {};
    if (this._peerConnection) {
      this._peerConnection.disconnect();
      this._peerConnection = null;
    }
  }

  _parseOptions(options) {
    return {
      ...options,
      // We always remove unused codecs after iceRestart in SPC
      removeUnusedCodecs: true,
      sendMessage: (type, payload) => {
        const content = {
          ...payload,
        };

        if (content.sdp) {
          const partialSdp = this._peerConnectionSDP.getPartialSdp(type, content.sdp);
          content.sdp = partialSdp;
        }

        const completion = this._createSendMessageErrorHandler(type, content);

        this._sendMessage(type, content, completion);

        if (type === 'answer') {
          // We only send an answer when Mantis previously sent an offer, i.e. we added a new
          // subscriber. Therefore at this point we can consider the negotiation done.
          this.subscriberComplete();
        }
      },
    };
  }

  _addListeners() {
    if (!this._peerConnection) {
      return;
    }

    // override the trackAdded listener so we can route the tracks to the proper subscriber.
    this._peerConnection.on('trackAdded', (stream) => {
      const { track, transceiver } = stream;
      if (this._currentAndPreviousMids.includes(transceiver.mid)) {
        return;
      }
      // We store all tracks per Subscriber.
      this._subscriberPcs[this._subscriberPcToAdd._id].push(track.id);
      // Also store all tracks per SinglePeerConnection
      this._tracks.push(track.id);
      this._currentAndPreviousMids.push(transceiver.mid);
      this._subscriberPcToAdd._onTrackAdded(stream);
    });
  }

  _removeMessageSender(subscriberPcId) {
    delete this._originalSendMessages[subscriberPcId];
    this._sendMessage = this._getLatestSendMessage();
  }

  async _generateOffer() {
    return new Promise((resolve) => {
      const createAnswer = () => {
        const answer = this._peerConnectionSDP.createSdp();
        this._peerConnection.processMessage('answer', { content: { sdp: answer } });
        resolve();
      };
      this._peerConnection.generateOffer(createAnswer);
    });
  }

  _removeSubscriber(subscriberPcId, transceiverIds) {
    delete this._subscriberPcs[subscriberPcId];
    this._removeMessageSender(subscriberPcId);
    this._peerConnection.stopTransceivers(transceiverIds);
    this._peerConnectionSDP.removeSubscriberMids(subscriberPcId);
  }

  async _removeSubscribersDestroyedByMantis(remoteTracks) {
    const missingMids = difference(this._peerConnectionSDP.sdp.tracks, remoteTracks);
    if (missingMids.length === 0) {
      return;
    }
    const subscribersRemovedByMantisPcIds = uniq(missingMids
      .map(mid => this._peerConnectionSDP.subscribePcIdsByMid[mid])
      .filter(subId => subId !== undefined));

    subscribersRemovedByMantisPcIds.forEach((subscriberPcId) => {
      const subscriberMids = missingMids.filter(mid =>
        this._peerConnectionSDP.subscribePcIdsByMid[mid] === subscriberPcId
      );
      this._removeSubscriber(subscriberPcId, subscriberMids);
    });
    // eslint-disable-next-line consistent-return
    return this._generateOffer();
  }

  _getLatestSendMessage() {
    const [sendMessage] = Object.values(this._originalSendMessages).slice(-1);
    return sendMessage || function () {};
  }

  _createSendMessageErrorHandler(type, content) {
    const handler = (error, message) => {
      // If the corresponding stream does not longer exist, we are going to try using
      // another sendMessage.
      if (error && message?.status === '404') {
        const subscriberPcIds = Object.keys(this._originalSendMessages);
        if (subscriberPcIds.length) {
          const subscriberPcId = subscriberPcIds.find(subPcId =>
            this._originalSendMessages[subPcId] === this._sendMessage);
          // We delete this sendMessage because its correponding stream doesn't exist.
          this._removeMessageSender(subscriberPcId);
          // We try to send the message again.
          this._sendMessage(type, content, handler);
        }
      }
    };
    return handler;
  }

  // Singleton to make sure we are using only one PC when SPC. If SPC, we will add Subscriber
  // specific options to handle analytics and negotiation per Subscriber. This will take care
  // to send the answer back to Rumor by its respective Subcriber and not multiple answers.
  // It will instantiate a new regular PC for all other cases.
  getPeerConnection(opt, subscriberPc) {
    this._subscriberPcToAdd = subscriberPc;
    this._sendMessage = opt.sendMessage;
    this._originalSendMessages[subscriberPc._id] = this._sendMessage;

    if (this._peerConnection) {
      this._peerConnection.addOptions(opt);
    } else {
      const parsedOptions = this._parseOptions(opt);
      this._peerConnection = new this.PeerConnection(parsedOptions);

      // Add listeners, which will take care all the tracks for all the subs and if any
      // negotiation needed.
      this._addListeners();
    }

    this._subscriberPcs[subscriberPc._id] = [];

    return this._peerConnection;
  }

  removeSubscriber(subscriberPcId, transceiversIds) {
    this._subscriberQueue.enqueue(async () => {
      const isHeadMid = this._peerConnectionSDP.isHead(subscriberPcId);
      this._removeSubscriber(subscriberPcId, transceiversIds);

      if (!Object.keys(this._subscriberPcs).length) {
        // Reset all components since we have no subscriber left. Then return,
        // nothing else to do here.
        this._reset();
        return;
      }

      if (isHeadMid) {
        // We process iceRestart at the end of the negotiation
        this._subscriberQueue.finally(() => this.generateOfferWithIceRestart());
      } else {
        await this._generateOffer();
      }
      this._subscriberQueue.dequeueAndProcessNext();
    });
  }

  generateOfferWithIceRestart() {
    this._peerConnection.iceRestart();
  }

  removeTrack(track, subscriberPcId) {
    const trackId = track?.id;
    if (trackId) {
      arrayRemoveItem(this._tracks, trackId);
      arrayRemoveItem(this._subscriberPcs[subscriberPcId] || [], trackId);
    }
  }

  destroy() {
    this._reset();
    this._session = null;
  }

  addSubscriber(subscriberCreate) {
    this._subscriberQueue.enqueue(subscriberCreate);
  }

  addSubscriberMid(mid, subscriberPcId) {
    this._peerConnectionSDP.addSubscriberMid(mid, subscriberPcId);
  }

  subscriberComplete() {
    this._subscriberQueue.dequeueAndProcessNext();
  }

  processAnswer(sdp) {
    return this._peerConnectionSDP.processAnswer(sdp);
  }

  async processOffer(sdp) {
    const parsedRemoteSdp = this.sdpHelpers.parseMantisSDP(sdp);
    // Check for any missing subscribers in the offer that mantis has removed but we haven't yet
    await this._removeSubscribersDestroyedByMantis(parsedRemoteSdp.tracks);
    return this._peerConnectionSDP.processOffer(parsedRemoteSdp);
  }
}

export default SinglePeerConnectionController;

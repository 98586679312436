import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledHeader, HeaderLeftContent } from "../Common/styles/header";
import {
  StyledTabs,
  StyledTab,
  a11yProps,
  MainTabPanel,
} from "../Common/UI/TabPanel";
import { useSearchParams } from "react-router-dom";
import MyCalendar from "../MyCalendar/MyCalendar";
import MyAvailability from "./MyAvailability";

const MySchedule = () => {
  let [searchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get("tab") || "appointments");
  const [, setSearchParams] = useSearchParams();

  const handleChange = (_: any, newType: string) => {
    setTab(newType);
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (tab) {
      params.set("tab", tab);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, tab]);
  return (
    <>
      <StyledHeader>
        <Box
          sx={{
            ...HeaderLeftContent,
            gap: 0,
            display: "flex",
            flexDirection: "column",
            borderBottom: "1px",
            borderColor: "lightgray",
          }}
        >
          <Typography fontSize={30} fontWeight="bold">
            My Schedule
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={tab} onChange={handleChange}>
          <StyledTab
            label="Appointments"
            value="appointments"
            {...a11yProps(0)}
          />
          <StyledTab
            label="My Availability"
            value="availability"
            {...a11yProps(1)}
          />
        </StyledTabs>
        <MainTabPanel value={"appointments"} index={tab}>
          <MyCalendar />
        </MainTabPanel>
        <MainTabPanel value={"availability"} index={tab}>
          <MyAvailability />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default MySchedule;

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Backdrop,
  CircularProgress,
  Chip,
  Checkbox,
  FormHelperText,
  Switch,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import * as yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { uploadFile } from "../../../utils/upload";
import { BuilderWrapper, CardStyle, ChipStyle } from "./exercise.style";
import { LoadingContainer, UploadWrapper } from "../../CMS/cms.style";
import { DeleteIcon, VideoUploadIcon } from "../../CMS/Icons";
import http from "../../../utils/http";
import { ImageUploadIcon } from "../Icons";
import { useAppSelector } from "../../../Redux/hooks";
import { canModifyExercises } from "../../../utils/roles";

const UploadItem: React.FC<any> = ({ video, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "questionnaire_image");
          setFieldValue("videoUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "video/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...UploadWrapper, width: "376px", height: "242px" }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {video ? (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File available. Drop Files to change
            </Typography>
          ) : (
            <>
              <VideoUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const UploadImageItem: React.FC<any> = ({ type, image, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "questionnaire_image");
          setFieldValue(
            type === "thumbnail" ? "thumbnailUrl" : "credits.iconUrl",
            url
          );
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue, type]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...UploadWrapper, width: "376px", height: "242px" }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const schema = yup.object().shape({
  name: yup.string().required("Exercise Name is Required"),
  instructions: yup.array().of(
    yup.object().shape({
      value: yup.string().required("Instruction cannot be empty"),
    })
  ),
  muscleGroups: yup.array().min(1, "Atleast one Muscle group is Required"),
  videoUrl: yup.string().required("Video is Required"),
  thumbnailUrl: yup.string().required("Thumbnail is Required"),
  credits: yup.object().when("showCredits", {
    is: (showCredits: boolean) => showCredits === true,
    then: (schema) =>
      schema.shape({
        title: yup.string().required("Title is Required"),
        description: yup.string().required("Description is Required"),
        resourceUrl: yup.string().required("Resource URL is Required"),
        iconUrl: yup.string().required("Image URL is Required"),
      }),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ExerciseBuilder = () => {
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [groupsList] = useState<string[]>(["Core", "Legs"]);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();
  const id = useQuery().get("id");
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canModifyExercises(userRole));
  useEffect(() => {
    const fetchExerciseData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`exercise/${id}`);
        const newData = res.data?.data;
        const exerciseData = {
          name: newData?.name,
          instructions: newData?.instructions
            ? newData?.instructions.map((item: any) => ({ value: item?.value }))
            : null,
          muscleGroups: newData?.muscleGroups,
          videoUrl: newData?.resourceUrl,
          thumbnailUrl: newData?.thumbnailUrl,
          credits: newData?.credits,
          showCredits: newData?.credits ? true : false,
        };
        setData(exerciseData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    if (id) {
      fetchExerciseData();
    }
  }, [id]);

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);

      if (!values.name.trim()) {
        throw new Error("Exercise Name cannot be empty");
      }

      if (!values.muscleGroups.length) {
        throw new Error("Atleast one Muscle group is Required");
      }

      const instructions = values.instructions;

      if (!instructions.length) {
        throw new Error("Atleast one Instruction is required");
      }

      if (instructions.some((instr: any) => instr.value.trim() === "")) {
        throw new Error("Instructions cannot be empty");
      }

      const body = {
        name: values?.name,
        instructions: instructions.map((instr: any, index: number) => ({
          position: index + 1,
          value: instr.value,
        })),
        muscleGroups: values?.muscleGroups,
        resourceUrl: values?.videoUrl,
        thumbnailUrl: values?.thumbnailUrl,
        credits: values?.showCredits
          ? {
              title: values?.credits.title,
              description: values?.credits.description,
              resourceUrl: values?.credits.resourceUrl,
              iconUrl: values?.credits.iconUrl,
            }
          : null,
      };
      let res: AxiosResponse;
      if (id) {
        res = await http.put(`/exercise/${id}`, body);
      } else {
        res = await http.post("/exercise", body);
      }

      toastMessage("success", res.data.message);
      setButtonLoader(false);
      navigate("/app/exercise");
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Formik
      initialValues={{
        name: data?.name || "",
        instructions: data?.instructions
          ? [...data?.instructions]
          : [
              {
                value: "",
              },
            ],
        muscleGroups: data?.muscleGroups || [],
        credits:
          {
            title: data?.credits?.title || "",
            description: data?.credits?.description || "",
            resourceUrl: data?.credits?.resourceUrl || "",
            iconUrl: data?.credits?.iconUrl || null,
          } || null,
        videoUrl: data?.videoUrl || "",
        thumbnailUrl: data?.thumbnailUrl || "",
        showCredits: data?.showCredits || false,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        submitHandler(values);
      }}
      enableReinitialize
    >
      {({ getFieldProps, setFieldValue, values, errors, touched }) => (
        <Form>
          <StyledHeader>
            <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
              <IconButton onClick={handleBack}>
                <ArrowBack htmlColor="#637E85" />
              </IconButton>
              <Typography variant="h2">
                {id ? "Edit" : "Add"} Exercise
              </Typography>
            </Box>
            <Box sx={HeaderRightContent}>
              {!buttonLoader ? (
                <>
                  <Button variant="outlined" onClick={handleBack}>
                    Cancel
                  </Button>
                  {hasModifyAccess && (
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  )}
                </>
              ) : (
                <Box>
                  <CircularProgress size={"2rem"} />
                </Box>
              )}
            </Box>
          </StyledHeader>
          {!loading ? (
            <Box sx={BuilderWrapper}>
              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Workout Information
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="exercise-name">
                    Exercise Name
                  </FormLabel>
                  <TextField
                    placeholder="Exercise Name"
                    id="exercise-name"
                    {...getFieldProps("name")}
                    error={touched?.name && errors?.name ? true : false}
                    helperText={
                      touched?.name && errors?.name
                        ? (errors?.name as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Instructions
                </Typography>
                <FieldArray name="instructions">
                  {({ remove, push }) => (
                    <>
                      {values?.instructions.map((_: any, index: number) => (
                        <Box key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              mb: 2,
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #E0E3EB",
                                borderRadius: "8px",
                                padding: "12px 16px",
                                width: "48px",
                              }}
                            >
                              <Typography variant="subtitle1">
                                {index + 1}
                              </Typography>
                            </Box>
                            <TextField
                              fullWidth
                              key={index}
                              {...getFieldProps(`instructions.${index}.value`)}
                            />
                            <Box sx={{ width: "3%", alignSelf: "flex-end" }}>
                              <IconButton
                                onClick={() =>
                                  push({
                                    value: "",
                                  })
                                }
                              >
                                <Add />
                              </IconButton>
                            </Box>
                            <Box sx={{ width: "3%", alignSelf: "flex-end" }}>
                              <IconButton
                                disabled={values?.instructions?.length < 2}
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          {/* @ts-ignore */}
                          {touched?.instructions?.[index]?.value &&
                            // @ts-ignore
                            errors?.instructions?.[index]?.value && (
                              <FormHelperText error sx={{ mb: 1 }}>
                                {/* @ts-ignore */}
                                {errors?.instructions?.[index]?.value as string}
                              </FormHelperText>
                            )}
                        </Box>
                      ))}
                    </>
                  )}
                </FieldArray>
              </Box>
              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Muscle Groups
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle}>Select Muscle Group</FormLabel>
                  <Select
                    multiple
                    displayEmpty
                    fullWidth
                    value={values.muscleGroups}
                    onChange={(e) => {
                      setFieldValue("muscleGroups", e.target.value);
                    }}
                    error={
                      touched?.muscleGroups && errors?.muscleGroups
                        ? true
                        : false
                    }
                    renderValue={() => {
                      return <></>;
                    }}
                    sx={{
                      maxWidth: "477px",
                    }}
                  >
                    {groupsList.map((item: any) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={values.muscleGroups.indexOf(item) > -1}
                          size="small"
                        />
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched?.muscleGroups && errors?.muscleGroups && (
                    <FormHelperText error>
                      {errors?.muscleGroups as string}
                    </FormHelperText>
                  )}
                </FormControl>
                {values.muscleGroups.length > 0 && (
                  <Box sx={{ display: "flex", gap: 2, mt: 2.5 }}>
                    {values.muscleGroups.map((tag: any) => (
                      <Chip
                        label={tag}
                        key={tag}
                        sx={ChipStyle}
                        deleteIcon={
                          <Close htmlColor="#374151" fontSize="small" />
                        }
                        onDelete={() => {
                          const data = values.muscleGroups.filter(
                            (group: any) => group !== tag
                          );
                          setFieldValue("muscleGroups", data);
                        }}
                      />
                    ))}
                  </Box>
                )}
              </Box>
              <Box sx={CardStyle}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    color="#355962"
                    mb={3}
                  >
                    Credits
                  </Typography>
                  <Switch
                    sx={{ mt: "-5px" }}
                    checked={values?.showCredits}
                    onClick={() => {
                      setFieldValue("showCredits", !values?.showCredits);
                    }}
                  />
                </Box>
                {values?.showCredits && (
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle}>Title</FormLabel>
                      <TextField
                        placeholder="Title"
                        id="credit-title"
                        {...getFieldProps("credits.title")}
                        error={
                          touched?.credits?.title && errors?.credits?.title
                            ? true
                            : false
                        }
                        helperText={
                          touched?.credits?.title && errors?.credits?.title
                            ? (errors?.credits?.title as string)
                            : " "
                        }
                      />
                    </FormControl>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle}>Description</FormLabel>
                      <TextField
                        placeholder="Description"
                        id="credit-description"
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={5}
                        {...getFieldProps("credits.description")}
                        error={
                          touched?.credits?.description &&
                          errors?.credits?.description
                            ? true
                            : false
                        }
                        helperText={
                          touched?.credits?.description &&
                          errors?.credits?.description
                            ? (errors?.credits?.description as string)
                            : " "
                        }
                      />
                    </FormControl>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle}>ResourceUrl</FormLabel>
                      <TextField
                        placeholder="Resource Url"
                        id="credit-resource-url"
                        {...getFieldProps("credits.resourceUrl")}
                        error={
                          touched?.credits?.resourceUrl &&
                          errors?.credits?.resourceUrl
                            ? true
                            : false
                        }
                        helperText={
                          touched?.credits?.resourceUrl &&
                          errors?.credits?.resourceUrl
                            ? (errors?.credits?.resourceUrl as string)
                            : " "
                        }
                      />
                    </FormControl>
                    <Box>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle}>Image</FormLabel>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 1,
                          }}
                        >
                          <UploadImageItem
                            type="iconUrl"
                            image={values.credits?.iconUrl}
                            setFieldValue={setFieldValue}
                          />
                          {values.credits?.iconUrl && (
                            <IconButton
                              onClick={() =>
                                setFieldValue("credits.iconUrl", "")
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Box>
                      </FormControl>
                      <FormLabel sx={LabelStyle}>Or</FormLabel>
                      <FormControl sx={{ ...InputWrapper, mt: 2 }}>
                        <FormLabel sx={LabelStyle} htmlFor="credits-icon-url">
                          Paste Image URL here
                        </FormLabel>
                        <TextField
                          fullWidth
                          id="credits-icon-url"
                          {...getFieldProps("credits.iconUrl")}
                          error={
                            touched?.credits?.iconUrl &&
                            errors?.credits?.iconUrl
                              ? true
                              : false
                          }
                          helperText={
                            touched?.credits?.iconUrl &&
                            errors?.credits?.iconUrl
                              ? (errors?.credits?.iconUrl as string)
                              : " "
                          }
                        />
                      </FormControl>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Thumbnail
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle}>Upload Image</FormLabel>
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}
                  >
                    <UploadImageItem
                      type="thumbnail"
                      image={values.thumbnailUrl}
                      setFieldValue={setFieldValue}
                    />
                    {values.thumbnailUrl && (
                      <IconButton
                        onClick={() => setFieldValue("thumbnailUrl", "")}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </FormControl>
                <FormLabel sx={LabelStyle}>Or</FormLabel>
                <FormControl sx={{ ...InputWrapper, mt: 2 }}>
                  <FormLabel sx={LabelStyle} htmlFor="thumbnail-url">
                    Paste Image URL here
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="thumbnail-url"
                    {...getFieldProps("thumbnailUrl")}
                    error={
                      touched?.thumbnailUrl && errors?.thumbnailUrl
                        ? true
                        : false
                    }
                    helperText={
                      touched?.thumbnailUrl && errors?.thumbnailUrl
                        ? (errors?.thumbnailUrl as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>

              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Exercise Video
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle}>Upload Video</FormLabel>
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}
                  >
                    <UploadItem
                      setFieldValue={setFieldValue}
                      video={values.videoUrl}
                    />
                    {values.videoUrl && (
                      <IconButton onClick={() => setFieldValue("videoUrl", "")}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </FormControl>
                <FormLabel sx={LabelStyle}>Or</FormLabel>
                <FormControl sx={{ ...InputWrapper, mt: 2 }}>
                  <FormLabel sx={LabelStyle} htmlFor="video-url">
                    Paste Video URL here
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="video-url"
                    {...getFieldProps("videoUrl")}
                    error={touched?.videoUrl && errors?.videoUrl ? true : false}
                    helperText={
                      touched?.videoUrl && errors?.videoUrl
                        ? (errors?.videoUrl as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          ) : (
            <Box sx={LoadingContainer}>
              <CircularProgress />
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ExerciseBuilder;

import { Box, Button, LinearProgress, Typography, styled } from "@mui/material";
import {
  NoDataContainer,
  StyledDataGrid,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../Common/styles/table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorToastMessage } from "../../utils/toast";
import http from "../../utils/http";
import { DateTime } from "luxon";
import {
  GridCellParams,
  GridColDef,
  GridPagination,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useAppSelector } from "../../Redux/hooks";
import { AxiosResponse } from "axios";
import DownloadFormatModal from "./DownloadFormatModal";

const DownloadButton = styled(Button)(() => ({
  color: "#FF8A4C",
  borderColor: "#FF8A4C",
  borderRadius: "8px",
  "&:hover": {
    borderColor: "#FF8A4C",
  },
}));

function CustomPagination(props: any) {
  return (
    <GridPagination
      sx={TablePaginationStyle}
      labelDisplayedRows={paginationLabel}
      {...props}
    />
  );
}

const RenderPatientDetails = (props: GridRenderCellParams<any>) => {
  const { value, row } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 0.5 }}>
      {value}
      <Typography fontSize={12} fontWeight="regular" sx={{ color: "#6B7280" }}>
        {row?.patientEmail || "-"}
      </Typography>
    </Box>
  );
};

const RenderActivityDays = (props: GridRenderCellParams<any>) => {
  const { value, row } = props;
  return (
    <>
      {value} {row?.activeDaysCptCode ? `(${row.activeDaysCptCode})` : "(NA)"}
    </>
  );
};

const RenderActivityMins = (props: GridRenderCellParams<any>) => {
  const { value, row } = props;
  return (
    <>
      {value} {row?.activeMinsCptCode ? `(${row.activeMinsCptCode})` : "(NA)"}
    </>
  );
};

const BillingReportsTab = ({
  activityDays,
  activityMins,
  physioName,
  physioId,
  searchText,
  page,
  setPage,
  filterCount,
  type,
  totalBillings,
  setTotalBillings,
  toggleLoader,
  refreshPage,
}: any) => {
  const navigate = useNavigate();
  const [billingsData, setBillingsData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<any>(null);

  const { hasAdminAccess } = useAppSelector((state) => state.user);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        let url = `/user-invoices?page=${
          page + 1
        }&size=${pageSize}&billingState=${type}`;

        if (activityDays) {
          url += `&activeDays=${activityDays}`;
        }
        if (activityMins) {
          url += `&activeMins=${activityMins}`;
        }
        if (physioId && physioName) {
          url += `&physicalTherapistId=${physioId}`;
        }
        if (searchText) {
          url += `&search=${searchText}`;
        }

        const res: AxiosResponse = await http.get(url);
        const mappedData = res?.data?.data?.invoices?.map((item: any) => {
          return {
            id: item?.id,
            pid: item?.participantDoctor?.participant?.externalPid || "",
            physicalTherapist: `${
              item?.participantDoctor?.doctor?.firstName || ""
            }  ${item?.participantDoctor?.doctor?.lastName || ""}`,
            patientId: item?.participantDoctor?.participant?.id,
            patientName: `${
              item?.participantDoctor?.participant?.firstName || ""
            } ${item?.participantDoctor?.participant?.lastName || ""}`,
            patientEmail: item?.participantDoctor?.participant?.email,
            activityDays: item?.activeDays?.toString(),
            activityMins: item?.activeMins?.toString(),
            activeDaysCptCode: item?.activeDaysCptCode,
            activeMinsCptCode: item?.activeMinsCptCode,
            billingPeriod: `${DateTime?.fromFormat(
              item?.billingCycleStartDate,
              "yyyy-LL-dd"
            ).toFormat("LL/dd/yyyy")} - ${DateTime.fromFormat(
              item?.billingCycleEndDate,
              "yyyy-LL-dd"
            ).toFormat("LL/dd/yyyy")}`,
            billingCycleStartDate: DateTime?.fromFormat(
              item?.billingCycleStartDate,
              "yyyy-LL-dd"
            ).toFormat("LL/dd/yyyy"),
            billingCycleEndDate: DateTime.fromFormat(
              item?.billingCycleEndDate,
              "yyyy-LL-dd"
            ).toFormat("LL/dd/yyyy"),
            enrollmentDate: DateTime.fromFormat(
              item?.enrollmentDate,
              "yyyy-LL-dd"
            ).toFormat("LL/dd/yyyy"),
            exitDate: item?.exitDate
              ? DateTime?.fromFormat(item?.exitDate, "yyyy-LL-dd")?.toFormat(
                  "LL/dd/yyyy"
                )
              : "",
            status: item?.billingState,
          };
        });
        setBillingsData(mappedData || []);
        setTotalBillings(res?.data?.data?.count || 0);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        errorToastMessage(error as Error);
      }
    };

    getData();
  }, [
    page,
    activityDays,
    activityMins,
    type,
    physioId,
    physioName,
    searchText,
    toggleLoader,
    setTotalBillings,
  ]);

  const openModal = (value: any) => {
    setShowModal(true);
    setModalData(value);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const columns: GridColDef<any>[] = [
    {
      field: "pid",
      headerName: "PID",
      flex: 1,
      sortable: false,
      cellClassName: "clickable-column",
      valueGetter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      flex: 1,
      sortable: false,
      renderCell: RenderPatientDetails,
      // valueGetter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "physicalTherapist",
      headerName: "Physical Therapist",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "enrollmentDate",
      headerName: "Enrollment Date",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "exitDate",
      headerName: "Drop off Date",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "billingPeriod",
      headerName: "Activity Period",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => (value ? value : "-"),
    },
    {
      field: "activityDays",
      renderHeader: () => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" fontWeight="medium">
            Active Days
          </Typography>
          <Typography variant="body1" fontWeight="medium">
            (CPT Code)
          </Typography>
        </Box>
      ),
      flex: 1,
      sortable: false,
      renderCell: RenderActivityDays,
    },
    {
      field: "activityMins",
      renderHeader: () => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" fontWeight="medium">
            Active Minutes
          </Typography>
          <Typography variant="body1" fontWeight="medium">
            (CPT Code)
          </Typography>
        </Box>
      ),
      flex: 1,
      sortable: false,
      renderCell: RenderActivityMins,
    },
    {
      field: "status",
      headerName: "Action",
      flex: 1,
      minWidth: 225,
      sortable: false,
      renderCell: (props: GridRenderCellParams<any>) => {
        const { value, row } = props;

        return value === "ready_to_download" ? (
          <Box>
            <DownloadButton variant="outlined" onClick={() => openModal(row)}>
              Ready to Download
            </DownloadButton>
          </Box>
        ) : (
          <span style={{ color: "#0E9F6E" }}>Downloaded</span>
        );
      },
    },
  ];

  const handleCellClick = (params: GridCellParams) => {
    const { field, row } = params;

    if (field === "pid") {
      navigate(
        `/app/billing-reports/details/${row?.patientId}?startDate=${row?.billingCycleStartDate}&endDate=${row?.billingCycleEndDate}`
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          height:
            filterCount > 0 ? "calc(100vh - 367px)" : "calc(100vh - 312px)",
          bgcolor:
            loading || totalBillings <= pageSize ? "transparent" : "#fff",
        }}
      >
        <StyledDataGrid
          rows={loading ? [] : billingsData}
          loading={loading}
          slots={{
            loadingOverlay: () => <LinearProgress />,
            pagination: CustomPagination,
            // totalParticipants < pageSize ? () => <></> :
            noRowsOverlay: () => (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            ),
          }}
          hideFooter={loading || totalBillings <= pageSize}
          columns={columns}
          onCellClick={handleCellClick}
          // keepNonExistentRowsSelected
          disableRowSelectionOnClick
          // onRowSelectionModelChange={(newRowSelectionModel: any) => {
          //   setSelectedRows(newRowSelectionModel);
          // }}
          columnVisibilityModel={{
            physicalTherapist: hasAdminAccess,
          }}
          // checkboxSelection
          // rowSelectionModel={selectedRows}
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(model) => setPage(model?.page)}
          pageSizeOptions={[pageSize]}
          rowCount={totalBillings}
          disableColumnMenu
          // autoHeight
          columnHeaderHeight={62}
          rowHeight={80}
        />
      </Box>
      {showModal && (
        <DownloadFormatModal
          showModal={showModal}
          closeModal={closeModal}
          userId={modalData?.patientId}
          startDate={modalData?.billingCycleStartDate}
          endDate={modalData?.billingCycleEndDate}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default BillingReportsTab;

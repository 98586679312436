import { Button, SxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setHealthEndDate,
  setHealthStartDate,
} from "../../../Redux/reducers/healthSlice";

const HeaderStyle: SxProps = {
  height: "80px",
  width: "100%",
  background: "#ffffff",
  borderRadius: "0px 0px 8px 8px",
  // mb: 2,
  display: "flex",
  alignItems: "center",
  p: 2,
  borderTop: "1px solid",
  borderColor: "#E0E3EB",
};

const HealthDateSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [minDate, setMinDate] = useState<DateTime | null>(null);
  const [maxStart] = useState(DateTime.now().plus({ days: -1 }));
  const [maxDate, setMaxDate] = useState(DateTime.now());
  const { endDate, startDate } = useAppSelector((state) => state.health);

  const changeDate = (val: DateTime | null, type: "start" | "end") => {
    if (type === "end") {
      dispatch(setHealthEndDate(val?.toFormat("LL-dd-yyyy") || null));
    } else {
      if (endDate && val) {
        const end = DateTime.fromFormat(endDate, "LL-dd-yyyy");
        const days = end.diff(val, "days").get("days");
        if (days > 90) {
          dispatch(setHealthEndDate(null));
          setOpenEnd(true);
        }
      }
      dispatch(setHealthStartDate(val?.toFormat("LL-dd-yyyy") || null));
    }
  };

  const modifyDates = (offsetInDays: number) => {
    const end = DateTime.now();
    const start = end.plus({ days: -offsetInDays });
    dispatch(setHealthEndDate(end.toFormat("LL-dd-yyyy")));
    dispatch(setHealthStartDate(start.toFormat("LL-dd-yyyy")));
  };

  useEffect(() => {
    if (startDate) {
      const days = DateTime.now()
        .diff(DateTime.fromFormat(startDate, "LL-dd-yyyy"), "days")
        .get("days");
      if (days > 90) {
        setMaxDate(
          DateTime.fromFormat(startDate, "LL-dd-yyyy").plus({ days: 90 })
        );
      } else {
        setMaxDate(DateTime.now());
      }
      setMinDate(
        DateTime.fromFormat(startDate, "LL-dd-yyyy").plus({ days: 1 })
      );
    } else {
      setMaxDate(DateTime.now());
      setMinDate(null);
    }
  }, [startDate, setMaxDate, setMinDate]);

  return (
    <Box sx={HeaderStyle}>
      <Typography fontSize={"14px"} fontWeight={"regular"} mr={1}>
        Start Date
      </Typography>
      <DatePicker
        format="MM/dd/yyyy"
        onChange={(newValue: any) => {
          changeDate(newValue, "start");
        }}
        value={startDate ? DateTime.fromFormat(startDate, "LL-dd-yyyy") : null}
        open={openStart}
        onOpen={() => {
          setOpenStart(true);
        }}
        onClose={() => {
          setOpenStart(false);
        }}
        maxDate={maxStart}
        slotProps={{
          textField: {
            variant: "outlined",
            sx: {
              maxWidth: "200px",
              mb: 1,
              mr: 2,
            },
            inputProps: {
              readOnly: true,
              placeholder: "MM/DD/YYYY",
            },
          },
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     sx={{ maxWidth: "200px", mb: 1, mr: 2 }}
        //     inputProps={{
        //       ...params.inputProps,
        //       readOnly: true,
        //       placeholder: "MM/DD/YYYY",
        //     }}
        //   />
        // )}
      />
      <Typography fontSize={"14px"} fontWeight={"regular"} mr={1}>
        End Date
      </Typography>
      <DatePicker
        format="MM/dd/yyyy"
        onChange={(newValue: any) => {
          changeDate(newValue, "end");
        }}
        open={openEnd}
        onOpen={() => {
          setOpenEnd(true);
        }}
        onClose={() => {
          setOpenEnd(false);
        }}
        maxDate={maxDate}
        minDate={minDate}
        value={endDate ? DateTime.fromFormat(endDate, "LL-dd-yyyy") : null}
        slotProps={{
          textField: {
            variant: "outlined",
            sx: {
              maxWidth: "200px",
              mb: 1,
            },
            inputProps: {
              readOnly: true,
              placeholder: "MM/DD/YYYY",
            },
          },
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     sx={{ maxWidth: "200px", mb: 1 }}
        //     inputProps={{
        //       ...params.inputProps,
        //       readOnly: true,
        //       placeholder: "MM/DD/YYYY",
        //     }}
        //   />
        // )}
      />
      <span style={{ marginLeft: "auto" }}></span>
      <Button variant="text" sx={{ mr: 1 }} onClick={() => modifyDates(7)}>
        Last 7 Days
      </Button>
      <Button variant="text" sx={{ mr: 1 }} onClick={() => modifyDates(30)}>
        Last 30 Days
      </Button>
      <Button variant="text" sx={{ mr: 1 }} onClick={() => modifyDates(60)}>
        Last 60 Days
      </Button>
      <Button variant="text" onClick={() => modifyDates(90)}>
        Last 90 Days
      </Button>
    </Box>
  );
};

export default HealthDateSelector;

import { TextField } from "@mui/material";
import { useState } from "react";

export const TextType: React.FC<any> = ({ question }) => {
  const [value, setValue] = useState("");
  const { properties, type } = question;

  return (
    <TextField
      fullWidth
      multiline
      value={value}
      onChange={(e) => setValue(e.target.value)}
      helperText={`${value.length}/${properties.maxRange}`}
      placeholder="Type your text here"
      rows={type === "short_text" ? 2 : 3}
      inputProps={{ maxLength: properties.maxRange }}
      sx={{
        ".MuiFormHelperText-root": {
          textAlign: "right",
          mr: 0,
        },
      }}
    />
  );
};

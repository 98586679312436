import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../Redux/hooks";
import { setQuestionListDefaults } from "../../Redux/reducers/questionListSlice";

const QuestionUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const search = searchParams.get("search") || "";
    const filterId = searchParams.get("filter") || "";
    const page = parseInt(searchParams.get("page") || "0") || 0;
    dispatch(
      setQuestionListDefaults({
        filterId,
        search,
        page,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(QuestionUrlLoader);

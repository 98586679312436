import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import {
  DownloadIcon,
  FilterSortIcon,
} from "../Common/assets/FoodJournalIcons";
import BillingReportsTab from "./BillingReportsTab";
import BillingFilterModal from "./BillingFilterModal";
import { useSearchParams } from "react-router-dom";
import { Close, Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { useAppSelector } from "../../Redux/hooks";
import DownloadFormatModal from "./DownloadFormatModal";

const FilterChip = ({ title, value, setPage, setValue }: any) => {
  return (
    <Chip
      label={`${title} : ${value}`}
      variant="outlined"
      onDelete={() => {
        setValue("");
        setPage(0);
      }}
      sx={{
        fontSize: "16px",
        fontWeight: "600",
        backgroundColor: "#EDEDEE",
        border: "none",
        borderRadius: "8px",
      }}
      deleteIcon={<Close htmlColor="#FFFFFF" fontSize="small" />}
    />
  );
};

const BillingReports = () => {
  const { hasAdminAccess } = useAppSelector((state) => state.user);
  let [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState(
    searchParams.get("type") || "ready_to_download"
  );
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [activityDays, setActivityDays] = useState<any>(
    searchParams.get("activityDays") || ""
  );
  const [activityMins, setActivityMins] = useState<any>(
    searchParams.get("activityMins") || ""
  );
  const [physioId, setPhysioId] = useState<any>(
    searchParams.get("physioId") || ""
  );
  const [physioName, setPhysioName] = useState<any>(
    searchParams.get("physioName") || ""
  );
  const [filterCount, setFilterCount] = useState(0);
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [totalBillings, setTotalBillings] = useState<any>(0);
  const [showModal, setShowModal] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (type) params.set("type", type);
    if (activityDays) params.set("activityDays", activityDays.toString());
    if (activityMins) params.set("activityMins", activityMins.toString());
    if (hasAdminAccess && physioId && physioName) {
      params.set("physioId", physioId.toString());
      params.set("physioName", physioName.toString());
    }
    if (searchText) params.set("search", searchText);
    setSearchParams(params, {
      replace: true,
    });
  }, [
    setSearchParams,
    page,
    activityMins,
    activityDays,
    physioName,
    physioId,
    type,
    searchText,
    hasAdminAccess,
  ]);

  useEffect(() => {
    let count = 0;
    if (activityDays) count++;
    if (activityMins) count++;
    if (physioId && physioName) count++;

    setFilterCount(count || 0);
  }, [activityDays, activityMins, physioId, physioName]);

  const handleChange = (_: any, newValue: string) => {
    setSearchText("");
    setPage(0);
    setType(newValue);
    setTotalBillings(0);
  };

  const openFilters = () => {
    setShowFilterModal(true);
  };

  const closeFilters = () => {
    setShowFilterModal(false);
  };

  const clearPhysioFilter = (val: string) => {
    setPhysioId(val);
    setPhysioName(val);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setPage(0);
        setSearchText(val);
      }, 500),
    [setSearchText]
  );

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Activity Reports
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          {type === "ready_to_download" && totalBillings > 0 && (
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={openModal}
            >
              Download All Reports
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={openFilters}
            startIcon={<FilterSortIcon />}
          >
            Filters {filterCount > 0 && `(${filterCount})`}
          </Button>
        </Box>
      </StyledHeader>

      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Ready to Download"
            value="ready_to_download"
            {...a11yProps(0)}
          />
          <StyledTab label="Downloaded" value="downloaded" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        {filterCount > 0 && (
          <Box
            sx={{
              marginBottom: "24px",
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="body1" fontWeight="medium" color="primary">
                Filters Applied:
              </Typography>
            </Box>
            {activityDays && (
              <FilterChip
                title="Activity days"
                value={activityDays}
                setValue={setActivityDays}
                setPage={setPage}
              />
            )}
            {activityMins && (
              <FilterChip
                title="Activity Minutes"
                value={activityMins}
                setValue={setActivityMins}
                setPage={setPage}
              />
            )}
            {hasAdminAccess && physioName && (
              <FilterChip
                title="Physical Therapist"
                value={physioName}
                setValue={clearPhysioFilter}
                setPage={setPage}
              />
            )}
          </Box>
        )}
        <Box
          sx={{
            padding: "16px",
            bgcolor: "#FFFFFF",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <TextField
            onChange={(e) => modifySearchTerm(e.target.value)}
            style={{ width: "70%" }}
            placeholder={`Search for Patients`}
            defaultValue={searchText}
            key={type}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <MainTabPanel value={type} index={type}>
          <BillingReportsTab
            activityDays={activityDays}
            activityMins={activityMins}
            physioName={physioName}
            physioId={physioId}
            searchText={searchText}
            page={page}
            setPage={setPage}
            filterCount={filterCount}
            type={type}
            totalBillings={totalBillings}
            setTotalBillings={setTotalBillings}
            toggleLoader={toggleLoader}
            refreshPage={refreshPage}
          />
        </MainTabPanel>
      </Box>
      {showFilterModal && (
        <BillingFilterModal
          showModal={showFilterModal}
          closeModal={closeFilters}
          setActivityDays={setActivityDays}
          activityDays={activityDays}
          setActivityMins={setActivityMins}
          activityMins={activityMins}
          physioId={physioId}
          physioName={physioName}
          setPhysioId={setPhysioId}
          setPhysioName={setPhysioName}
          setPage={setPage}
        />
      )}
      {showModal && (
        <DownloadFormatModal
          showModal={showModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default BillingReports;

import {
  OnboardingQuestion,
  onboardingQuestionType,
} from "../types/onboarding";
import { v4 as uuid } from "uuid";
import {
  CalendarIcon,
  DocTextIcon,
  GroupQuestionIcon,
  LinkertScaleIcon,
  CheckboxIcon,
  MCQIcon,
  NumberIcon,
  PictureChoiceIcon,
  TextIcon,
} from "../Components/Onboarding/Builder/Icons";
import { DateTime } from "luxon";
export const onboardingQuestionTypes: {
  label: string;
  type: onboardingQuestionType;
}[] = [
  {
    label: "Statement",
    type: "statement",
  },
  {
    label: "Text",
    type: "text",
  },
  {
    label: "Number",
    type: "number",
  },
  {
    label: "Date",
    type: "date",
  },
  {
    label: "Multiple Choice",
    type: "multiple_choice",
  },
  {
    label: "Likert Scale",
    type: "likert_scale",
  },
  {
    label: "Group",
    type: "group",
  },
  {
    label: "Checkbox",
    type: "checkbox",
  },
  {
    label: "Picture Choice",
    type: "picture_choice",
  },
];

export const getQLabelFromType = (type: onboardingQuestionType) => {
  return onboardingQuestionTypes.find((q) => q.type === type)?.label;
};
export const OnboardingBuilderStepIconMap: any = {
  statement: DocTextIcon,
  text: TextIcon,
  number: NumberIcon,
  date: CalendarIcon,
  multiple_choice: MCQIcon,
  likert_scale: LinkertScaleIcon,
  group: GroupQuestionIcon,
  checkbox: CheckboxIcon,
  picture_choice: PictureChoiceIcon,
};

export const createQuestion = (
  type: onboardingQuestionType
): OnboardingQuestion => {
  const question: OnboardingQuestion = {
    id: uuid(),
    type: type,
    variableName: "",
    title: "",
    exit: false,
    placeholder: null,
    isTooltip: false,
    tooltipLabel: null,
    tooltipValue: null,
    unitOfMeasurement: [],
    imageUrl: "",
    userType: 0,
    description: "",
  };
  if (
    ["multiple_choice", "likert_scale", "checkbox", "picture_choice"].includes(
      type
    )
  ) {
    question.choices = [
      {
        id: uuid(),
        enableUserInput: false,
        label: "",
        response: "",
        nextStepId: "",
        exit: false,
        value: "",
        iconUrl: null,
      },
    ];
  } else if (type === "group") {
    question.childSteps = [
      {
        id: uuid(),
        type: "text",
        variableName: "",
        title: "",
        imageUrl: "",
        description: "",
        exit: false,
        placeholder: null,
        isTooltip: false,
        tooltipLabel: null,
        tooltipValue: null,
        unitOfMeasurement: [],
        userType: 0,
      },
    ];
  }
  return question;
};

export const cloneQuestion = (
  question: OnboardingQuestion
): OnboardingQuestion => {
  if (
    ["multiple_choice", "likert_scale", "checkbox", "picture_choice"].includes(
      question.type
    )
  ) {
    return {
      ...question,
      variableName: question.variableName
        ? question.variableName + " copy"
        : "copy",
      id: uuid(),
      choices: question.choices?.map((option) => {
        return {
          ...option,
          id: uuid(),
        };
      }),
    };
  } else if (question.type === "group") {
    return {
      ...question,
      variableName: question.variableName
        ? question.variableName + " copy"
        : "copy",
      id: uuid(),
      childSteps: question.childSteps?.map((subQ) => {
        return cloneQuestion(subQ);
      }),
    };
  }
  return {
    ...question,
    unitOfMeasurement: [...question.unitOfMeasurement],
    variableName: question.variableName
      ? question.variableName + " copy"
      : "copy",
    id: uuid(),
  };
};

export const createNewOption = () => {
  return {
    id: uuid(),
    enableUserInput: false,
    label: "",
    response: "",
    nextStepId: "",
    exit: false,
    value: "",
    iconUrl: null,
  };
};

export const modifySubquestion = (
  question: OnboardingQuestion,
  newType: onboardingQuestionType
) => {
  if (
    ["multiple_choice", "likert_scale", "checkbox", "picture_choice"].includes(
      newType
    )
  ) {
    const newQuestion = {
      ...question,
      id: uuid(),
      type: newType,
    };
    if (!newQuestion.choices) {
      newQuestion.choices = [
        {
          id: uuid(),
          enableUserInput: false,
          label: "",
          response: "",
          nextStepId: "",
          exit: false,
          value: "",
          iconUrl: null,
        },
      ];
    }
    return newQuestion;
  } else {
    const newQuestion = {
      ...question,
      id: uuid(),
      type: newType,
    };
    delete newQuestion["choices"];
    return newQuestion;
  }
};

export const validateOnboarding = (
  questions: OnboardingQuestion[],
  parentQuestionIndex?: number
) => {
  const sanitizedQuestions = questions.map((question, index) => {
    const newQuestion: any = {
      id: question.id,
      type: question.type,
      title: question.title,
      variableName: question.variableName,
      imageUrl: question.imageUrl || null,
      exit: question.exit || false,
      placeholder: question.placeholder || null,
      isTooltip: question.isTooltip || false,
      tooltipLabel: question.isTooltip ? question.tooltipLabel || null : null,
      tooltipValue: question.isTooltip ? question.tooltipValue || null : null,
      unitOfMeasurement: question.unitOfMeasurement || [],
      position: index + 1,
      choices: [],
      childSteps: [],
      userType: question.userType || 0,
      description: question.description || "",
    };
    if (!newQuestion.title) {
      if (parentQuestionIndex !== undefined) {
        throw new Error(
          `Question ${parentQuestionIndex + 1} - SubQuestion ${
            index + 1
          } title cannot be empty`
        );
      } else {
        throw new Error(`Question ${index + 1} title cannot be empty`);
      }
    }
    // if (newQuestion.title.length > 50) {
    //   if (parentQuestionIndex !== undefined) {
    //     throw new Error(
    //       `Question ${parentQuestionIndex + 1} - SubQuestion ${
    //         index + 1
    //       } title should be of 50 or less characters`
    //     );
    //   } else {
    //     throw new Error(
    //       `Question ${index + 1} title should be of 50 or less characters`
    //     );
    //   }
    // }
    if (!newQuestion.variableName) {
      if (parentQuestionIndex !== undefined) {
        throw new Error(
          `Question ${parentQuestionIndex + 1} - SubQuestion ${
            index + 1
          } variable name cannot be empty`
        );
      } else {
        throw new Error(`Question ${index + 1} variable name cannot be empty`);
      }
    }

    if (
      question.type === "text" ||
      question.type === "number" ||
      question.type === "date"
    ) {
      if (!newQuestion.placeholder) {
        if (parentQuestionIndex !== undefined) {
          throw new Error(
            `Question ${parentQuestionIndex + 1} - SubQuestion ${
              index + 1
            } placeholder cannot be empty`
          );
        } else {
          throw new Error(`Question ${index + 1} placeholder cannot be empty`);
        }
      }
    }

    if (newQuestion.isTooltip) {
      if (!newQuestion.tooltipLabel) {
        if (parentQuestionIndex !== undefined) {
          throw new Error(
            `Question ${parentQuestionIndex + 1} - SubQuestion ${
              index + 1
            } tooltip label cannot be empty`
          );
        } else {
          throw new Error(
            `Question ${index + 1} tooltip label cannot be empty`
          );
        }
      }
      if (!newQuestion.tooltipValue) {
        if (parentQuestionIndex !== undefined) {
          throw new Error(
            `Question ${parentQuestionIndex + 1} - SubQuestion ${
              index + 1
            } tooltip value cannot be empty`
          );
        } else {
          throw new Error(
            `Question ${index + 1} tooltip value cannot be empty`
          );
        }
      }
    }
    if (
      [
        "multiple_choice",
        "likert_scale",
        "checkbox",
        "picture_choice",
      ].includes(question.type)
    ) {
      newQuestion.choices = question.choices?.map((option, optionIndex) => {
        if (!option.label) {
          if (parentQuestionIndex !== undefined) {
            throw new Error(
              `Question ${parentQuestionIndex + 1} - SubQuestion ${
                index + 1
              } Option ${optionIndex + 1} cannot be empty`
            );
          } else {
            throw new Error(
              `Question ${index + 1} Option ${optionIndex + 1} cannot be empty`
            );
          }
        }
        return {
          position: optionIndex + 1,
          enableUserInput: option.enableUserInput || false,
          label: option.label,
          response: option.response || "",
          exit: option.nextStepId === "exit" ? true : false,
          nextStepId:
            option.nextStepId !== "exit" ? option.nextStepId || null : null,
          value: option.value || "",
          iconUrl: option.iconUrl || null,
        };
      });
    }
    if (question.type === "picture_choice") {
      newQuestion.choices.forEach((choice: any, choiceIndex: number) => {
        if (!choice.iconUrl) {
          if (parentQuestionIndex !== undefined) {
            throw new Error(
              `Question ${parentQuestionIndex + 1} - SubQuestion ${
                index + 1
              } , Picture ${choiceIndex + 1}  cannot be empty`
            );
          } else {
            throw new Error(
              `Question ${index + 1} , Picture ${
                choiceIndex + 1
              }  cannot be empty`
            );
          }
        }
      });
    }
    if (question.type === "number") {
      newQuestion.unitOfMeasurement.forEach(
        (unit: string, unitIndex: number) => {
          if (unit.trim() === "") {
            if (parentQuestionIndex !== undefined) {
              throw new Error(
                `Question ${parentQuestionIndex + 1} - SubQuestion ${
                  index + 1
                } , Unit ${unitIndex + 1}  cannot be empty`
              );
            } else {
              throw new Error(
                `Question ${index + 1} , Unit ${unitIndex + 1} cannot be empty`
              );
            }
          }
        }
      );
    }
    if (question.type === "group" && question.childSteps) {
      newQuestion.childSteps = validateOnboarding(question.childSteps, index);
    }
    return newQuestion;
  });
  return sanitizedQuestions;
};

export const sanitizeQuestions = (
  questions: any[],
  isSubquestion?: boolean
): OnboardingQuestion[] => {
  const sanitizedQuestions: OnboardingQuestion[] = questions
    .sort((a: any, b: any) => {
      return (
        a[isSubquestion ? "childPosition" : "position"] -
        b[isSubquestion ? "childPosition" : "position"]
      );
    })
    .map((question) => {
      const newQuestion: OnboardingQuestion = {
        id: question.id || uuid(),
        type: question.type,
        title: question.title || "",
        variableName: question.variableName || "",
        imageUrl: question.imageUrl || null,
        exit: question.exit || false,
        placeholder: question.placeholder || null,
        isTooltip: question.isTooltip || false,
        tooltipLabel: question.tooltipLabel || null,
        tooltipValue: question.tooltipValue || null,
        unitOfMeasurement: question.unitOfMeasurement || [],
        userType: question.userType || 0,
        description: question.description || "",
        typeLabel: getQLabelFromType(question.type),
      };
      if (
        [
          "multiple_choice",
          "likert_scale",
          "picture_choice",
          "checkbox",
        ].includes(question.type)
      ) {
        newQuestion.choices = question.choices
          ?.sort((a: any, b: any) => {
            return a.position - b.position;
          })
          .map((option: any) => {
            return {
              id: option.id || uuid(),
              enableUserInput: option.enableUserInput || false,
              label: option.label || "",
              response: option.response || "",
              exit: false,
              nextStepId: option.exit ? "exit" : option.nextStepId || "",
              value: option.value || "",
              iconUrl: option.iconUrl || null,
            };
          });
      }
      if (question.type === "group" && question.childSteps) {
        newQuestion.childSteps = sanitizeQuestions(question.childSteps, true);
      }
      return newQuestion;
    });
  return sanitizedQuestions;
};

export const formatQuestionsWithResponses = (
  questions: any[],
  response: any
) => {
  let formattedQuestions = sanitizeQuestions(questions);
  formattedQuestions = formattedQuestions.map((question) => {
    if (question.type !== "group") {
      const res: any = {};
      const val = response?.[question?.["variableName"]];
      if (
        ["multiple_choice", "likert_scale", "picture_choice"].includes(
          question.type
        )
      ) {
        res.stepChoiceIds = val?.stepChoiceId ? [val?.stepChoiceId] : [];
      } else if (question.type === "checkbox") {
        res.stepChoiceIds = val?.stepChoiceIds || [];
      } else if (question.type === "date") {
        res.value = DateTime.fromISO(val?.value).toFormat("dd-LL-yyyy");
      } else {
        res.value = val?.value;
      }
      return {
        ...question,
        response: res,
      };
    } else {
      return {
        ...question,
        childSteps: question.childSteps?.map((q) => {
          const res: any = {};
          const val = response?.[q?.["variableName"]];
          if (
            ["multiple_choice", "likert_scale", "picture_choice"].includes(
              q.type
            )
          ) {
            res.stepChoiceIds = val?.stepChoiceId ? [val?.stepChoiceId] : [];
          } else if (q.type === "checkbox") {
            res.stepChoiceIds = val?.stepChoiceIds || [];
          } else if (q.type === "date") {
            res.value = DateTime.fromISO(val?.value).toFormat("dd-LL-yyyy");
          } else {
            res.value = val?.value;
          }
          return {
            ...q,
            response: res,
          };
        }),
      };
    }
  });
  return formattedQuestions;
};

export const formatQuestionsWithResponsesV2 = (
  questions: any[],
  type: string
) => {
  let formattedQuestions = sanitizeQuestions(questions);
  const key = type === "daily-log" ? "dailyResponses" : "symptomsResponses";
  formattedQuestions = formattedQuestions.map((question) => {
    const ques: any = questions.find((q) => q.id === question.id);
    if (question.type !== "group") {
      const res: any = {};
      const val = ques?.[key]?.[0];
      if (["checkbox"].includes(ques.type)) {
        res.stepChoiceIds = val?.onboardingBotStepChoiceIds || [];
        res.value = val?.value;
      } else if (
        ["likert_scale", "multiple_choice", "picture_choice"].includes(
          ques.type
        )
      ) {
        res.stepChoiceIds = val?.onboardingStepChoiceId
          ? [val?.onboardingStepChoiceId]
          : [];
        res.value = val?.value;
      } else if (ques.type === "date") {
        res.value = DateTime.fromISO(val?.value).toFormat("dd-LL-yyyy");
      } else {
        res.value = val?.value;
      }
      return {
        ...question,
        response: res,
      };
    } else {
      return {
        ...question,
        childSteps: question.childSteps?.map((q) => {
          const res: any = {};
          const step = ques?.childSteps.find((item: any) => item?.id === q.id);
          const val = step?.[key]?.[0];
          if (["checkbox"].includes(step?.type)) {
            res.stepChoiceIds = val?.onboardingBotStepChoiceIds || [];
            res.value = val?.value;
          } else if (
            ["likert_scale", "multiple_choice", "picture_choice"].includes(
              step.type
            )
          ) {
            res.stepChoiceIds = val?.onboardingStepChoiceId
              ? [val?.onboardingStepChoiceId]
              : [];
            res.value = val?.value;
          } else if (step?.type === "date") {
            res.value = DateTime.fromISO(val?.value).toFormat("dd-LL-yyyy");
          } else {
            res.value = val?.value;
          }

          return {
            ...q,
            response: res,
          };
        }),
      };
    }
  });
  return formattedQuestions;
};

import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import ClinicsList from "./ClinicsList";
import { useAppSelector } from "../../Redux/hooks";
import { canModifyClinics } from "../../utils/roles";

const Clinics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [showModal, setShowModal] = useState(false);
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canModifyClinics(userRole));
  const [searchText, setSearchText] = useState<string>(
    searchParams.get("search") || ""
  );

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (searchText) {
      params.set("search", searchText);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, page, searchText]);

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, mr: 3 }}>
          <Typography fontSize={30} fontWeight="bold">
            Clinics
          </Typography>
        </Box>
        {hasModifyAccess && (
          <Box sx={HeaderRightContent}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setShowModal(true)}
            >
              Add Clinics
            </Button>
          </Box>
        )}
      </StyledHeader>
      <ClinicsList
        page={page}
        setPage={setPage}
        showModal={showModal}
        setShowModal={setShowModal}
        hasModifyAccess={hasModifyAccess}
        searchText={searchText}
        setSearchText={setSearchText}
      />
    </>
  );
};

export default Clinics;

import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { useState } from "react";
import { layout } from "../../Common/styles/timepicker";

export const DateType: React.FC<any> = ({ question }) => {
  const [value, setValue] = useState<Moment | null>(null);
  return (
    <DatePicker
      value={value}
      onChange={(momentVal) => {
        if (momentVal?.isValid()) {
          setValue(momentVal);
        }
      }}
      format="DD/MM/YYYY"
      slotProps={{
        textField: {
          variant: "outlined",
          fullWidth: true,
        },
      }}
      // renderInput={(params) => <TextField {...params} fullWidth />}
    />
  );
};

export const DateTimeType: React.FC<any> = ({ question }) => {
  const [value, setValue] = useState<Moment | null>(null);
  return (
    <DateTimePicker
      value={value}
      onChange={(val) => {
        if (val?.isValid()) setValue(val);
      }}
      format="DD/MM/YYYY HH:mm"
      slotProps={{
        textField: {
          variant: "outlined",
          fullWidth: true,
        },
      }}
      // renderInput={(params) => <TextField {...params} fullWidth />}
    />
  );
};

export const TimeType: React.FC<any> = ({ question }) => {
  const [value, setValue] = useState<Moment | null>(null);
  return (
    <TimePicker
      value={value}
      onChange={(val) => {
        if (val?.isValid()) setValue(val);
      }}
      format="HH:mm"
      slotProps={{
        textField: {
          variant: "outlined",
          fullWidth: true,
        },
        layout: layout,
      }}
      // renderInput={(params) => <TextField {...params} fullWidth />}
    />
  );
};

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  title: string;
  page: number;
  type: string;
  searchText: string;
};

const ClinicsUrlSetter = ({ title, page, type, searchText }: Props) => {
  let [, setSearchParams] = useSearchParams();
  useEffect(() => {
    const params = new URLSearchParams();
    params.set("title", title);
    params.set("page", page.toString());
    if (type) {
      params.set("type", type);
    }
    if (searchText) {
      params.set("search", searchText);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, type, page, searchText, title]);

  return <></>;
};

export default ClinicsUrlSetter;

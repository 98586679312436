export const notificationEntitiesMap: any = {
  general: "General",
  lesson: "Education Lesson",
  bot: "Bot",
  faq_bot: "FAQ",
  food_diary: "Food Diary",
  feet_diary: "Feet Diary",
  medication_diary: "Medication",
};

export const Gender = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const genderMap: any = {
  male: "Male",
  female: "Female",
};

export const Ethnicity = [
  {
    label: "Malay",
    value: "malay",
  },
  {
    label: "Chinese",
    value: "chinese",
  },
  {
    label: "Indian",
    value: "indian",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const ethnicityMap: any = {
  chinese: "Chinese",
  malay: "Malay",
  indian: "Indian",
  other: "Other",
};

import SDPHelpers from '../sdp_helpers';
import getMediaSectionsForAnswerDefault from './getMediaSectionsForAnswer';
import getMediaSectionsForOfferDefault from './getMediaSectionsForOffer';
import createLogger from '../../../helpers/log';

const logging = createLogger('partialSdp');

const isPartialSdp = sdp => sdp.tracks.length !== sdp.mids.length;

const createGetFullRemoteSdp = (deps = {}) => {
  const getMediaSectionsForAnswer = deps.getMediaSectionsForAnswer
    || getMediaSectionsForAnswerDefault;
  const getMediaSectionsForOffer = deps.getMediaSectionsForOffer || getMediaSectionsForOfferDefault;

  const getFullSdpFromPartial = (type, partialSdp, currentSdp) => {
    let newMediaSections;
    if (type === 'answer') {
      newMediaSections = getMediaSectionsForAnswer(partialSdp, currentSdp);
    } else {
      newMediaSections = getMediaSectionsForOffer(partialSdp, currentSdp);
    }

    const fullSdp = {
      ...partialSdp,
      mediaSections: newMediaSections,
      mids: newMediaSections.map(SDPHelpers.getSectionMid),
    };
    return fullSdp;
  };

  const getFullRemoteSdp = (type, newRemoteSdp, currentRemoteSdp) => {
    if (!currentRemoteSdp.bundle || !isPartialSdp(newRemoteSdp)) {
    // This isn't a partial SDP, return as is
      return newRemoteSdp;
    }

    logging.debug('partial remote sdp: ', newRemoteSdp);
    const fullSdp = getFullSdpFromPartial(type, newRemoteSdp, currentRemoteSdp);
    logging.debug('full reconstructed remote sdp: ', fullSdp);
    return getFullSdpFromPartial(type, newRemoteSdp, currentRemoteSdp);
  };

  return getFullRemoteSdp;
};

export default createGetFullRemoteSdp;

import { SxProps, IconButton, Box, Typography, Divider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const ModalBaseStyles: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "white",
  boxShadow: 4,
  borderRadius: "0.7rem",
  padding: 3,
  paddingTop: 2,
  outline: "none",
  minHeight: "40vh",
  maxHeight: "80vh",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
};

export const ModalActionButtonStyles: SxProps = {
  mt: 1,
  display: "flex",
  gap: 2,
};

type ModalHeaderProps = {
  title: string;
  onCloseClick: () => void;
};

export const ModalHeader = ({ title, onCloseClick }: ModalHeaderProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#355962",
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          textTransform={"capitalize"}
        >
          {title}
        </Typography>
        <IconButton onClick={onCloseClick}>
          <CloseRoundedIcon htmlColor="#637E85" />
        </IconButton>
      </Box>
      <Divider sx={{ mt: 1, mb: 2 }} />
    </>
  );
};

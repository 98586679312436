/* eslint-disable global-require */
import createLogger from '../helpers/log';

export default function getUserMediaFactory(deps = {}) {
  ['processPubOptions'].forEach((key) => {
    if (!deps[key]) {
      throw new Error(`${key} dependency must be injected into OT.getUserMedia`);
    }
  });

  const logging = deps.logging || createLogger('getUserMedia');
  const { processPubOptions } = deps;

  /**
  * Gets a MediaStream object that uses a microphone and camera as the audio and video source.
  * This method returns a promise that is resolved when the user has granted permission to
  * use the camera or microphone (or both) and the MediaStream object is available. The
  * promise is rejected if there is an error in getting the media or if the end user denies
  * access to the camera or microphone.
  * <p>
  * You can use this method to get MediaStreamTrack objects to pass into the
  * <code>audioSource</code> and <code>videoSource</code> options of the
  * <a href="OT.html#initPublisher">OT.initPublisher()</a> method. For example, call this method
  * to get a MediaStream that uses the microphone and camera as the audio and video sources. Then
  * you can call <code>getVideoTracks()[0]</code> on the MediaStream object to get a video
  * MediaStreamTrack object for the camera's video. You can then add the video MediaStreamTrack
  * as a source for an HTML Video element, draw that Video element into an HTML Canvas element, and
  * apply video filters or effects to the Canvas. Then you can call the <code>captureStream()</code>
  * method of the HTMLCanvasElement object to get a MediaStream object and call
  * <code>getVideoTracks()[0]</code> on the MediaStream object to get a video MediaStreamTrack
  * object (with the video filters or effects applied). Finally, you can use the video
  * MediaStreamTrack object as the <code>videoSource</code> property of the options you pass
  * into <code>OT.initPublisher()</code>. The OpenTok Publisher will use the
  * video track (with the filters and effects applied) as the video source.
  * </p>
  *
  * @param {Object} options <p>(optional) Defines settings for the MediaStream object.
  * The options are the same as those defined for the
  * <a href="OT.html#initPublisher">OT.initPublisher()</a> method. However, only the following
  * properties apply for <code>OT.getUserMedia()</code>:
  * </p>
  * <p>
  * <ul>
  *   <li><code>audioSource</code></li>
  *   <li><code>autoGainControl</code></li>
  *   <li><code>disableAudioProcessing</code></li>
  *   <li><code>echoCancellation</code></li>
  *   <li><code>enableStereo</code></li>
  *   <li><code>facingMode</code></li>
  *   <li><code>frameRate</code></li>
  *   <li><code>maxResolution</code></li>
  *   <li><code>noiseSuppression</code></li>
  *   <li><code>resolution</code></li>
  *   <li><code>videoSource</code></li>
  * </ul>
  * </p>
  *
  * @return {Promise} A promise that is resolved when the user has granted permission to
  * use the camera or microphone (or both) and the MediaStream object is available. The
  * promise is rejected if there is an error in getting the media, if the end user denies
  * access to the camera or microphone, or if the method is called on Internet Explorer.
  *
  * @see The options (especially <code>audioSource</code> and <code>videoSource</code>) of the
  * <a href="OT.html#initPublisher">OT.initPublisher()</a> method
  *
  * @method OT.getUserMedia
  * @memberof OT
  */
  const getUserMedia = (pubOptions = {}) => {
    const processedOptions = processPubOptions(pubOptions, 'OT.getUserMedia', () => false);
    return processedOptions.getUserMedia().catch((err) => {
      logging.error(`OT.getUserMedia failed to getUserMedia: ${err}`);
      throw err;
    });
  };

  return getUserMedia;
}

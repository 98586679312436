import { Add } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../../Redux/hooks";
import { addQuestion } from "../../../Redux/reducers/onboardingSlice";
import { onboardingQuestionType } from "../../../types/onboarding";
import {
  onboardingQuestionTypes,
  OnboardingBuilderStepIconMap,
} from "../../../utils/onboarding";
import QuestionPickerItems from "./QuestionPickerItems";
import { QuestionPickerWrapper } from "./styles";

const QuestionPicker = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addNewQuestion = (type: onboardingQuestionType) => {
    dispatch(addQuestion({ type }));
    handleClose();
  };

  return (
    <Box sx={QuestionPickerWrapper}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h6">Add New Steps</Typography>
        <IconButton sx={{ marginLeft: "auto" }} onClick={handleClick}>
          <Add />
        </IconButton>
      </Box>
      <QuestionPickerItems />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "313px",
            padding: "16px",
          },
        }}
      >
        {onboardingQuestionTypes.map((question) => {
          const Icon = OnboardingBuilderStepIconMap[question?.type];
          return (
            <MenuItem
              key={question.type}
              onClick={() => addNewQuestion(question.type)}
              sx={{
                padding: "10px",
                height: "44px",
                border: "1px solid #e5e7eb",
                borderRadius: "10px",
                marginBottom: "16px",
              }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>
                <span style={{ fontSize: "16px", fontWeight: 500 }}>
                  {question.label}
                </span>
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default QuestionPicker;

/* eslint-disable no-underscore-dangle */
import MediaProcessorConnector from '../mediaProcessorConnector/mediaProcessorConnector';
import createLogger from '../../helpers/log';
import updateTrackOnStreamDefault from '../../helpers/updateTrackOnStream';
import createAudioTrackHolderDefault from '../../helpers/createAudioTrackHolder';

const logging = createLogger('Subscriber');
const routedSourceStreamId = 'MANTIS';
const relayedSourceStreamId = 'P2P';

const createAudioConnector = (streams, deps = {}) => {
  const updateTrackOnStream = deps.updateTrackOnStream || updateTrackOnStreamDefault;
  const createAudioTrackHolder = deps.createAudioTrackHolder || createAudioTrackHolderDefault;
  const mediaStreams = streams;
  let _audioMediaProcessorConnector;
  let _originalAudioTrack;
  let _audioTrackHolder;

  const clearConnectorAndAudioHolder = () => {
    _audioMediaProcessorConnector?.destroy();
    _audioMediaProcessorConnector = null;

    if (_audioTrackHolder) {
      _audioTrackHolder.srcObject = null;
      _audioTrackHolder = null;
    }
    _originalAudioTrack = null;
  };

  const applyAudioConnector = async (sourceStreamId) => {
    const [originalAudioTrack] = mediaStreams[sourceStreamId]?.getAudioTracks();
    if (!originalAudioTrack) {
      const message = 'Connector not set as no audio track is present.';
      const error = new Error(message);
      logging.error(`setAudioMediaProcessorConnector: ${message}`);
      _audioMediaProcessorConnector = null;
      throw error;
    }

    try {
      // We create a video element to hold a new stream containing the original audio track.
      // If the track is not used in a stream, it is stopped and no audio is heard through
      // the filtered track.
      _audioTrackHolder = createAudioTrackHolder(originalAudioTrack);
      const filteredAudioTrack = await _audioMediaProcessorConnector.setTrack(originalAudioTrack);
      _originalAudioTrack = originalAudioTrack;
      updateTrackOnStream(
        mediaStreams[sourceStreamId],
        originalAudioTrack,
        filteredAudioTrack
      );
    } catch (err) {
      clearConnectorAndAudioHolder();
      throw err;
    }
  };

  const resetAudioStream = (sourceStreamId) => {
    try {
      const [filteredAudioTrack] = mediaStreams[sourceStreamId].getAudioTracks();
      updateTrackOnStream(mediaStreams[sourceStreamId], filteredAudioTrack, _originalAudioTrack);
    } catch (error) {
      throw Error(
        `setAudioMediaProcessorConnector: Error resetting track: ${error.message}`
      );
    }
  };

  return {
    get audioMediaProcessorConnector() {
      return _audioMediaProcessorConnector;
    },

    initializeConnector(mediaProcessorConnector) {
      _audioMediaProcessorConnector = new MediaProcessorConnector(mediaProcessorConnector);
    },

    async setAudioMediaProcessorConnector(sourceStreamId) {
      await applyAudioConnector(sourceStreamId);
    },

    async applyAudioConnectorOnRoutedToRelayedTransition() {
      // We restore the Mantis stream's original track, so when we transition again,
      // we can apply a filter to the unfiltered track.
      this.resetAudioStreamAndTrack(routedSourceStreamId);

      if (!_audioMediaProcessorConnector) {
        return;
      }

      await applyAudioConnector(relayedSourceStreamId);
    },

    async applyAudioConnectorOnRelayedToRoutedTransition() {
      // we don't restore the P2P stream, because it will be destroyed
      if (!_audioMediaProcessorConnector) {
        return;
      }
      await applyAudioConnector(routedSourceStreamId);
    },

    clearAudioMediaProcessorConnector(sourceStreamId) {
      if (!_originalAudioTrack) {
        return;
      }
      resetAudioStream(sourceStreamId);
      clearConnectorAndAudioHolder();
    },

    resetAudioStreamAndTrack(sourceStreamId) {
      if (!_originalAudioTrack) {
        return;
      }

      resetAudioStream(sourceStreamId);
      _originalAudioTrack = null;
    },
  };
};

export default createAudioConnector;

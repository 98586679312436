import { MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { useState } from "react";

const DateType: React.FC<any> = ({ question }) => {
  const [value, setValue] = useState<Moment | null>(null);
  return (
    <DatePicker
      value={value}
      onChange={(momentVal) => {
        if (momentVal?.isValid()) {
          setValue(momentVal);
        }
      }}
      format="DD/MM/YYYY"
      slotProps={{
        textField: {
          variant: "outlined",
          fullWidth: true,
        },
      }}
      // renderInput={(params) => <TextField {...params} fullWidth />}
    />
  );
};

export const GridType: React.FC<any> = ({ question }) => {
  const { properties } = question;
  const { rows, columns } = properties;

  const renderCol = (column: any) => {
    switch (column.type) {
      case "short_text":
        return <TextField fullWidth />;
      case "number":
        return <TextField fullWidth type="number" />;
      case "date":
        return <DateType />;
      case "checkbox":
        return (
          <Select fullWidth defaultValue={""}>
            {column.options.map((opt: any, index: number) => {
              let text = opt || "Option " + (index + 1);
              return (
                <MenuItem key={"opt" + index} value={opt}>
                  {text}
                </MenuItem>
              );
            })}
          </Select>
        );
      case "score_input":
        return <TextField fullWidth type="number" />;
    }
  };

  return (
    <table className="grid-preview">
      <thead>
        <tr>
          <th></th>
          {columns.map((col: any, idx: number) => {
            return <th key={idx}>{col.label || "Column " + (idx + 1)}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row: any, idx: number) => {
          return (
            <tr key={idx}>
              <td key={"row" + idx}>{row.label || "Row " + (idx + 1)}</td>
              {columns.map((col: any, idx: number) => {
                return <td key={"col" + idx}>{renderCol(col)}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

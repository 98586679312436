import { AxiosResponse } from "axios";
import {
  cards,
  LessonQuestions,
  LessonQuestionsV2,
} from "../Components/CMS/CMSTypes";
import http from "./http";
import { v4 as uuid } from "uuid";

const valueOnlyTypes = ["title", "subtitle", "text"];
const textAndValueTypes = ["picture", "video", "audio"];

export const downloadFile = async (myData: any) => {
  const fileName = myData.name || myData.title;
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const createNewSection = (type: string) => {
  const newSection: any = {
    ...cards.find((card: any) => card.type === type),
    key: uuid(),
  };
  if (type === "resource") {
    newSection["resources"] = [{ label: "", value: "", key: uuid() }];
  }
  if (type === "text") {
    newSection["tags"] = [];
    newSection["highlights"] = [];
  }
  if (type === "question") {
    newSection["questions"] = [
      {
        key: uuid(),
        choices: [{ key: uuid(), label: "", image: "", isCorrect: false }],
        ...LessonQuestions[0],
      },
    ];
  }
  if (type === "spaced_learning") {
    newSection["spacedLearning"] = {
      choices: [
        { key: uuid(), label: "", imageUrl: "", isCorrect: false },
        { key: uuid(), label: "", imageUrl: "", isCorrect: false },
      ],
      allowMultipleSelection: false,
      title: "",
      wrongExplanation: "",
      correctExplanation: "",
      imageUrl: "",
      notification: {
        title: "",
        body: "",
        botId: "",
        triggerIn: 5,
      },
    };
  }
  if (type === "poll") {
    newSection["choices"] = [{ key: uuid(), label: "", image: "" }];
  }
  return newSection;
};

const handleResponseQuestions = (questions: any[]) => {
  const updatedQuestions = [...questions];
  updatedQuestions.sort((a: any, b: any) => {
    return a.position - b.position;
  });
  let result = updatedQuestions.map((item) => {
    const question: any = {
      title: item.title,
      type: item.type,
      key: item.id,
      choices: item.choices,
      imageUrl: item.imageUrl || undefined,
    };
    question.wrongExplanation = item.wrongExplanation || "";
    question.correctExplanation = item.correctExplanation || "";
    if (question.type === "single_select" || question.type === "multi_select") {
      question.choices.sort((a: any, b: any) => {
        return a.position - b.position;
      });
      question.choices = question.choices.map((choice: any) => {
        return {
          label: choice.label,
          image: choice.image,
          key: choice.id,
          isCorrect: choice.isCorrect || false,
        };
      });
    } else if (question.type === "slider") {
      const choice = question.choices[0];
      question.choices = [
        {
          maxValue: choice.maxValue || "",
          minValue: choice.minValue || "",
          midValue: choice.midValue || "",
          maxLabel: choice.maxLabel || "",
          midLabel: choice.midLabel || "",
          minLabel: choice.minLabel || "",
          cutoff: choice.cutoff || "",
        },
      ];
      // } else if (question.type === "timer") {
      //   const choice = question.choices[0];
      //   question.choices = [
      //     {
      //       minutes: choice.minutes || "",
      //       seconds: choice.seconds || "",
      //     },
      //   ];
    } else if (question.type === "text") {
      delete question["choices"];
    }
    return question;
  });

  return result;
};

const educationQuestionHandler = (QuestionArr: any[], position: number) => {
  let result = QuestionArr.map((item, index) => {
    const question: any = {
      title: item.title,
      position: index + 1,
      type: item.type,
      imageUrl: item.imageUrl || undefined,
    };
    if (!question.title) {
      throw new Error(
        `Question text of Assessment at position ${position} cannot be empty`
      );
    }
    question.wrongExplanation = item.wrongExplanation || undefined;
    question.correctExplanation = item.correctExplanation || undefined;
    if (question.type === "single_select" || question.type === "multi_select") {
      question.choices = item.choices.map(
        (choice: any, choiceIndex: number) => {
          if (!choice.label) {
            throw new Error(
              `Choice text of Assessment at position ${position} cannot be empty`
            );
          }
          return {
            label: choice.label,
            image: choice.image,
            position: choiceIndex + 1,
            isCorrect: choice.isCorrect || false,
          };
        }
      );
    } else if (question.type !== "text") {
      const { key, ...data } = item.choices[0];
      // if (question.type === "timer") {
      //   if (
      //     (!data.minutes && !data.seconds) ||
      //     isNaN(data.minutes) ||
      //     isNaN(data.seconds)
      //   ) {
      //     throw new Error(
      //       `Timer value for Assessment at position ${position} must be a number`
      //     );
      //   }
      // } else
      if (question.type === "slider") {
        if (!data.maxLabel || !data.minLabel) {
          throw new Error(
            `Slider labels for Assessment at position ${position} cannot be empty`
          );
        }
        if (
          data.maxValue === "" ||
          isNaN(data.maxValue) ||
          data.minValue === "" ||
          isNaN(data.minValue)
        ) {
          throw new Error(
            `Slider values for Assessment at position ${position} must be a number`
          );
        } else {
          if (data.midValue !== "" && !isNaN(data.midValue)) {
            if (
              +data.maxValue < +data.midValue ||
              +data.maxValue < +data.minValue
            ) {
              throw new Error(
                `Slider Max value for Assessment at position ${position} cannot be less than mid and min value`
              );
            } else if (+data.midValue < +data.minValue) {
              throw new Error(
                `Slider Mid value for Assessment at position ${position} cannot be less than min value`
              );
            }
          } else {
            if (+data.maxValue < +data.minValue) {
              throw new Error(
                `Slider Max value for Assessment at position ${position} cannot be less than min value`
              );
            }
          }
        }
      }
      question.choices = [{ ...data }];
    }
    return question;
  });

  return result;
};

const spacedLearningHandler = (Question: any, position: number) => {
  const spacedLearning: any = {
    title: Question.title,
    allowMultipleSelection: Question.allowMultipleSelection,
    correctExplanation: Question.correctExplanation,
    wrongExplanation: Question.wrongExplanation,
    imageUrl: Question?.imageUrl || undefined,
  };

  if (!spacedLearning.title) {
    throw new Error(
      `Question text of spaced learning at position ${position} cannot be empty`
    );
  }
  if (!spacedLearning.correctExplanation) {
    throw new Error(
      `Correct Answer Explanation of spaced learning at position ${position} cannot be empty`
    );
  }
  if (!spacedLearning.wrongExplanation) {
    throw new Error(
      `Wrong Answer Explanation of spaced learning at position ${position} cannot be empty`
    );
  }

  spacedLearning.choices = Question.choices.map(
    (choice: any, choiceIndex: number) => {
      if (!choice.label) {
        throw new Error(
          `Choice text of Spaced Learning at position ${position} cannot be empty`
        );
      }
      return {
        label: choice.label,
        imageUrl: choice.imageUrl,
        position: choiceIndex + 1,
        isCorrect: choice.isCorrect || false,
      };
    }
  );

  const correct = spacedLearning.choices.filter(
    (choice: any) => choice.isCorrect
  ).length;
  if (correct === 0) {
    throw new Error(
      `Atleast one answer has to be marked as correct at postion ${position}`
    );
  } else if (correct > 1 && !spacedLearning.allowMultipleSelection) {
    throw new Error(
      `For single selection, only one option can be marked as correct at postion ${position}`
    );
  }

  spacedLearning.notification = {
    title: Question.notification.title,
    body: Question.notification.body,
    botId: Question.notification.botId,
    triggerIn: Question.notification.triggerIn,
  };

  if (!spacedLearning.notification.title) {
    throw new Error(
      `Notification Title text of spaced learning at position ${position} cannot be empty`
    );
  }
  if (!spacedLearning.notification.body) {
    throw new Error(
      `Notification Body text of spaced learning at position ${position} cannot be empty`
    );
  }
  if (!spacedLearning.notification.botId) {
    throw new Error(
      `Chatbot of spaced learning at position ${position} cannot be empty`
    );
  }
  if (
    !spacedLearning.notification.triggerIn &&
    spacedLearning.notification.triggerIn !== 0
  ) {
    throw new Error(
      `Notification Trigger Days of spaced learning at position ${position} cannot be empty`
    );
  }
  return spacedLearning;
};

export const educationResponse = (sections: any[]) => {
  const newSections: any[] = [];

  const updatedSections = [...sections];
  updatedSections.sort((a: any, b: any) => {
    return a.position - b.position;
  });

  updatedSections.forEach((section) => {
    const typeDetails = cards.find((card) => card.type === section.type);
    if (!typeDetails) {
      return;
    }
    const res: any = {
      ...typeDetails,
      key: section.id,
      value: section.value,
    };

    if (textAndValueTypes.includes(section.type)) {
      res.label = section.label;
      if (section.altText) {
        res.altText = section.altText;
      }
      if (section.transcript) {
        res.transcript = section.transcript;
      }
    } else if (section.type === "text") {
      res.tags = section.tags;
      res.highlights = section.highlights.map((highlight: any) => {
        return {
          id: highlight.id,
          label: highlight.label,
          description: highlight.description,
        };
      });
    } else if (section.type === "resource") {
      const resources: any[] = JSON.parse(res.value);
      res.resources = resources.map((resource) => {
        return {
          ...resource,
          key: uuid(),
        };
      });
      delete res.value;
    } else if (section.type === "poll") {
      const newChoices = [...section.choices];
      newChoices?.sort((a: any, b: any) => {
        return a.position - b.position;
      });
      res.choices =
        newChoices.map((choice: any) => {
          return {
            label: choice.label,
            image: choice.image,
            key: choice.id,
          };
        }) || [];
    } else if (section.type === "question") {
      res.questions = handleResponseQuestions(section.questions);
    } else if (section.type === "spaced_learning") {
      const spacedLearning: any = {
        title: section.spacedLearning.title,
        allowMultipleSelection: section.spacedLearning.allowMultipleSelection,
        correctExplanation: section.spacedLearning.correctExplanation,
        wrongExplanation: section.spacedLearning.wrongExplanation,
        imageUrl: section.spacedLearning?.imageUrl || undefined,
        choices: section.spacedLearning.choices,
        notification: section.spacedLearning.notification,
      };
      spacedLearning.choices.sort((a: any, b: any) => {
        return a.position - b.position;
      });

      spacedLearning.choices = spacedLearning.choices.map((choice: any) => {
        return {
          label: choice.label,
          imageUrl: choice.imageUrl,
          key: choice.id,
          isCorrect: choice.isCorrect || false,
        };
      });

      res.spacedLearning = spacedLearning;
    }

    newSections.push(res);
  });

  return newSections;
};

export const educationRequest = (sections: any[]) => {
  let position = 1;
  const newSections: any[] = [];

  sections.forEach((section) => {
    const res: any = {
      value: section.value || "",
      type: section.type,
      label: section.label || "",
      position: position,
    };

    if (valueOnlyTypes.includes(section.type)) {
      if (!res.value) {
        throw new Error(
          `${section.name} at position ${position} cannot be empty`
        );
      }
      if (section.type === "text") {
        res.tags = section.tags;
        res.highlights = section.highlights;
      }
    } else if (textAndValueTypes.includes(section.type)) {
      // if (!res.label) {
      //   throw new Error(
      //     `${section.name} title at position ${position} cannot be empty`
      //   );
      // } else
      if (!res.value) {
        throw new Error(
          `${section.name} source at position ${position} cannot be empty`
        );
      }
    } else if (section.type === "resource") {
      const resources = section.resources
        .filter((resource: any) => resource.label && resource.value)
        .map((resource: any) => {
          return {
            label: resource.label,
            value: resource.value,
          };
        });
      if (resources.length === 0) {
        throw new Error(
          `Atleast one resource has to be provided at position ${position}`
        );
      } else {
        res.value = JSON.stringify(resources);
      }
    } else if (section.type === "poll") {
      res.choices = section.choices.map((choice: any, choiceIndex: number) => {
        if (!choice.label) {
          throw new Error(
            `Option text of Poll at position ${position} cannot be empty`
          );
        }
        return {
          label: choice.label,
          image: choice.image || undefined,
          position: choiceIndex + 1,
        };
      });
      if (res.choices.length < 2) {
        throw new Error(
          `Atleast two options has to be provided to the poll at position ${position}`
        );
      }
    } else if (section.type === "question") {
      res.questions = educationQuestionHandler(section.questions, position);
      res.value = "QNo " + position;
    } else if (section.type === "spaced_learning") {
      res.spacedLearning = spacedLearningHandler(
        section.spacedLearning,
        position
      );
      res.value = "spaced";
    }

    if (section.altText) {
      res.altText = section.altText;
    }

    if (section.transcript) {
      res.transcript = section.transcript;
    }

    if (!res.label) {
      delete res.label;
    }

    newSections.push(res);
    position++;
  });

  return newSections;
};

export const importPage = async (body: any, unitId: string) => {
  const sections = educationResponse(body.sections);
  const sectionResult = educationRequest(sections);
  let obj = {
    title: body.title,
    imageUrl: body.imageUrl,
    sections: sectionResult,
    areQuestionsMandatory: body.areQuestionsMandatory,
  };
  if (!obj.imageUrl) {
    delete obj.imageUrl;
  }
  await http.post(`/lms/lessons/${unitId}/pages`, obj);
};

export const exportLesson = async (eid: any) => {
  const res: AxiosResponse = await http.get(`/lms/lessons/${eid}`);
  downloadFile(res.data.data);
};

export const previewLesson = (sections: any[]) => {
  let questionPresent = false;
  let pollPresent = false;
  let spacedPresent = false;
  const previewSections = sections.map((section: any) => {
    const newSection = {
      ...section,
    };
    if (!newSection.id) {
      newSection.id = uuid();
    }
    if (section.altText) {
      newSection.altText = section.altText;
    }
    if (section.transcript) {
      newSection.transcript = section.transcript;
    }
    if (section.type === "resource") {
      const resources = JSON.parse(newSection.value);
      newSection.resources = resources.map((res: any) => {
        return {
          ...res,
          key: uuid(),
        };
      });
    }
    if (section.type === "question") {
      questionPresent = true;
      newSection.questions = newSection.questions
        .slice()
        .sort((a: any, b: any) => {
          return a.position - b.position;
        })
        .map((question: any) => {
          if (
            question.type === "single_select" ||
            question.type === "multi_select"
          ) {
            const choices = question.choices.map((choice: any) => {
              return {
                ...choice,
                id: choice.id || uuid(),
              };
            });
            return {
              ...question,
              id: question.id || uuid(),
              choices: choices,
              correctAnswer: choices
                .filter((choice: any) => choice.isCorrect)
                .map((choice: any) => choice.id),
            };
          } else {
            return {
              ...question,
              id: question.id || uuid(),
            };
          }
        });
    }
    if (section.type === "text") {
      newSection.highlights.forEach((highlight: any) => {
        const regEx = new RegExp(highlight.label, "ig");
        newSection.value = (newSection.value as string).replace(
          regEx,
          `<span class="cms-highlight" onclick="showHighlight(event)">${highlight.label}<span class="highlight-text" style="display:none">
            <span class="highlight-header"><span class="title">${highlight.label}</span><span class="highlight-close"><img src="/close.svg" onclick="closeHighlight(event)"/></span></span>
            <span class="description">${highlight.description}</span>
          </span>
          </span>`
        );
      });
    }
    if (section.type === "poll") {
      pollPresent = true;
      const newChoices = [...section?.choices];
      newChoices?.sort((a: any, b: any) => {
        return a.position - b.position;
      });
      newSection.choices = newChoices?.map((choice: any) => {
        return {
          label: choice.label,
          image: choice.image,
          id: choice.id || uuid(),
        };
      });
    }
    if (section.type === "spaced_learning") {
      spacedPresent = true;
      const spacedLearning = JSON.parse(
        JSON.stringify(newSection.spacedLearning)
      );
      newSection.spacedLearning = {
        id: spacedLearning.id || uuid(),
        title: spacedLearning.title,
        allowMultipleSelection: spacedLearning.allowMultipleSelection,
        correctExplanation: spacedLearning.correctExplanation,
        wrongExplanation: spacedLearning.wrongExplanation,
        imageUrl: spacedLearning?.imageUrl || undefined,
        choices: spacedLearning.choices
          .sort((a: any, b: any) => {
            return a.position - b.position;
          })
          .map((choice: any) => {
            return {
              id: choice.id || uuid(),
              label: choice.label,
              imageUrl: choice.imageUrl,
              isCorrect: choice.isCorrect,
            };
          }),
      };
      newSection.spacedLearning.correctAnswer =
        newSection.spacedLearning.choices
          .filter((choice: any) => choice.isCorrect)
          .map((choice: any) => choice.id);
    }
    return newSection;
  });
  return { questionPresent, previewSections, pollPresent, spacedPresent };
};

export const previewLessonProps = (sections: any[]) => {
  let questionPresent = false;
  let pollPresent = false;
  let spacedPresent = false;
  sections.forEach((section: any) => {
    if (section.type === "question") {
      questionPresent = true;
    } else if (section.type === "poll") {
      pollPresent = true;
    } else if (section.type === "spaced_learning") {
      spacedPresent = true;
    }
  });
  return { questionPresent, pollPresent, spacedPresent };
};

export const pollAggregateHelper = (response: any[]) => {
  const sectionGroup: any = {};
  response.forEach((option: any) => {
    if (sectionGroup[option.sectionId]) {
      sectionGroup[option.sectionId].push({
        choiceId: option.questionChoiceIds,
        count: +option.count,
      });
    } else {
      sectionGroup[option.sectionId] = [
        { choiceId: option.questionChoiceIds, count: +option.count },
      ];
    }
  });
  return sectionGroup;
};

export const pollAggregatePercentageHelper = (response: any[]) => {
  const aggregate: any = {};
  if (!response) {
    return aggregate;
  }
  let total = 0;
  response.forEach((option: any) => {
    total += option.count;
  });
  if (total > 0) {
    response.forEach((option: any) => {
      const percent = (option.count * 100) / total;
      aggregate[option.choiceId] = Math.round(percent * 100) / 100 + "%";
    });
  }
  return aggregate;
};

export const highlightScript = () => {
  return `function showHighlight(e){
    e = e || window.event;
    const arr = document.getElementsByClassName("highlight-text");
    for(let i=0; i< arr.length; i++){
      arr[i].style.display = "none";
    }
    const src = e.target || e.srcElement;
    const elem = src.getElementsByClassName("highlight-text")[0];
    if(elem){
      elem.style.display = "block";
      if(e.clientY - 34 > elem.offsetHeight){
        const docWidth = "innerWidth" in window ? window.innerWidth : document.documentElement.offsetWidth;
        if(docWidth < 801){
          const docHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
          const clickPosition = docHeight - e.clientY;
          elem.style.top = "auto";
          elem.style.bottom = (clickPosition + 24) + "px";
        }else{
          const x = document.getElementsByClassName("cms-preview-container")?.[0]?.getBoundingClientRect()?.x;
          if(x){
            elem.style.left = (x + 16) + "px" 
          }
          elem.style.bottom = "auto";
          elem.style.top = (e.clientY + 24) + "px";
        }
      }else{
        elem.style.top = (e.clientY + 24) + "px";
        elem.style.bottom = "auto";
      }
    }
}
function closeHighlight(e){
  e = e || window.event;
  const src = e.target || e.srcElement;
  const parent1 = src.parentElement;
  if(parent1){
    const parent2 = parent1.parentElement;
    if(parent2){
      const parent3 = parent2.parentElement;
      parent3.style.display = "none";
    }
  }
}
`;
};

export const createNewAssessment = () => {
  return {
    key: uuid(),
    choices: [{ key: uuid(), label: "", image: "", isCorrect: false }],
    ...LessonQuestionsV2[0],
  };
};

export const modifyAssessment = (type: string) => {
  if (type === LessonQuestions[0].type) {
    return {
      key: uuid(),
      choices: [{ key: uuid(), label: "", image: "", isCorrect: false }],
      ...LessonQuestions[0],
    };
  } else if (type === LessonQuestions[1].type) {
    return {
      key: uuid(),
      choices: [
        {
          image: "",
          maxValue: "",
          minValue: "",
          midValue: "",
          maxLabel: "",
          midLabel: "",
          minLabel: "",
        },
      ],
      ...LessonQuestions[1],
    };
  } else if (type === LessonQuestions[2].type) {
    return {
      key: uuid(),
      choices: [{ image: "", minutes: "", seconds: "" }],
      ...LessonQuestions[2],
    };
  } else if (type === LessonQuestions[3].type) {
    return {
      key: uuid(),
      choices: [{ key: uuid(), label: "", image: "", isCorrect: false }],
      ...LessonQuestions[3],
    };
  } else if (type === LessonQuestions[4].type) {
    return {
      key: uuid(),
      ...LessonQuestions[4],
    };
  }
};

export const indxToAlpha = (indx: number) => {
  const index = indx % 26;
  return String.fromCharCode(65 + index);
};

import { SxProps } from "@mui/material";

export const DateWrapper: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50px",
  padding: "8px 16px",
  borderRadius: "4px",
  border: "1.5px solid",
  cursor: "pointer",
};

export const GridContainer: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill , minmax(210px , 1fr))",
  gap: 2,
};

export const CardWrapper: SxProps = {
  mt: 3,
  bgcolor: "#fff",
  boxShadow: "0px 4px 34px 0px rgba(0, 0, 0, 0.12)",
  borderRadius: "12px",
  overflowX: "scroll",
};

import React from "react";
import { Box, Typography } from "@mui/material";
import { FemaleIcon, MaleIcon } from "../Common/assets/HeaderIcons";
import { errorToastMessage } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../Redux/hooks";
import http from "../../utils/http";
import { setRefreshNotification } from "../../Redux/reducers/notificationSlice";
import { setHealthType } from "../../Redux/reducers/healthSlice";

const ChatNotifications = ({ setAnchorElChat, data, unreadChatCount }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const markAsRead = async (id?: any) => {
    try {
      if (id) {
        const url = `/notifications/${id}`;
        const body = { isRead: true };
        await http.put(url, body);
      } else {
        const url = `notifications/chat`;
        const body = { isRead: true };
        await http.patch(url, body);
      }

      dispatch(setRefreshNotification());
    } catch (error) {
      errorToastMessage(error as Error);
    }
  };

  const handleClick = async (item: any) => {
    if (item?.patientId) {
      if (!item?.isRead) {
        await markAsRead(item?.id);
      }
      dispatch(setHealthType("chat"));
      navigate(`/app/participants/${item?.patientId}`);
      setAnchorElChat(null);
    } else {
      errorToastMessage(new Error("User Id not available"));
    }
  };

  function navigateToNotificationPage() {
    try {
      navigate(`/app/all-notifications?type=chat`);
      setAnchorElChat(null);
    } catch (error) {
      errorToastMessage(error as Error);
    }
  }

  return (
    <>
      {data.length !== 0 ? (
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "6px",
            border: "1px solid rgba(33, 33, 33, 0.10)",
            boxShadow: "6px 6px 38px 0px rgba(0, 0, 0, 0.06)",
            width: "18.75rem",
            minHeight: "9.5rem",
            maxHeight: "30rem",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: "2rem",
              alignItems: "center",
              padding: ".5rem",
              borderBottom: "1px solid  #E0E3EB",
            }}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              Messages
            </Typography>
            {unreadChatCount !== 0 && (
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{ cursor: "pointer" }}
                onClick={() => markAsRead()}
              >
                Mark all as read
              </Typography>
            )}
          </Box>
          {data?.map((item: any) => (
            <Box
              key={item.id}
              sx={{
                borderRadius: "5px 5px 0px 0px",
                borderBottom: "1px solid #E0E3EB",
                background: item?.isRead ? "" : "#FEF1F4",
                height: "5rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClick(item);
              }}
            >
              <Box
                sx={{
                  borderRadius: "8px",
                  height: "3rem",
                  width: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "1rem",
                  background: item?.isRead ? "#F3F4F6" : "#D714401A",
                }}
              >
                {item.gender === "female" ? <FemaleIcon /> : <MaleIcon />}
              </Box>
              <Box
                sx={{
                  marginLeft: ".5rem",
                  width: "13rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="regular"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "9rem",
                      }}
                    >
                      {item?.patientCode}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "9rem",
                      }}
                    >
                      {item?.message}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        alignSelf: "flex-end",
                      }}
                    >
                      {item.day}
                    </Typography>
                    <Typography
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        alignSelf: "flex-end",
                      }}
                    >
                      {item.time}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "2.5rem",
              alignItems: "center",
              padding: ".5rem",
              borderBottom: "1px solid  #E0E3EB",
              alignSelf: "flex-end",
            }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              fontWeight="regular"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigateToNotificationPage();
              }}
            >
              See all Chat Notifications
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            borderRadius: "5px 5px 0px 0px",
            borderBottom: "1px solid  #E0E3EB",
            minHeight: "15rem",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2rem",
            justifyContent: "center",
            width: "18.75rem",
          }}
        >
          <Typography variant="subtitle1" fontWeight="light" color={"#808080"}>
            There are no Chat Notifications
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ChatNotifications;

/*
 * Installs a watcher that will calls the given callback if the audio acquisition seems to not
 * work porperly.
 *
 * It waits 3 seconds and poll getStats to find any sign of audio bytes sent. If it can't find any
 * it signals the anomaly by executing the given function.
 *
 * @param {function(cb: function(DOMError, Array<RTCStats>))} getStats
 *    the function to call to get the stats
 * @param {function} warningCb to be called when a potential problem is detected
 * @returns {function} cancel the watch
 */
export default function watchAudioAcquisition(getStats, warningCb) {
  // detection of Chrome failure to acquire audio
  // inspired by https://medium.com/the-making-of-appear-in/working-around-webrtc-bugs-d4f6fdb763f
  const to = setTimeout(() => {
    getStats((error, stats) => {
      if (error) {
        return;
      }
      for (let idxStats = 0; idxStats < stats.length; idxStats += 1) {
        const rtcStats = stats[idxStats];
        if (rtcStats.id.indexOf('_send') !== -1 &&
          rtcStats.type === 'ssrc' &&
          (rtcStats.kind === 'audio' || rtcStats.mediaType === 'audio') &&
          parseInt(rtcStats.bytesSent, 10) === 0) {
          // abnormal condition detected
          warningCb();
        }
      }
    });
  }, 3000);

  return function cancel() {
    clearTimeout(to);
  };
}

import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GrayBox,
  HeaderStyle,
  PolicyBorderedContainer,
  PolicyContainer,
} from "./settings.style";
import { errorToastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import DOMPurify from "dompurify";

const PreviewPrivacyPolicy = () => {
  const [policyData, setPolicyData] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchPolicyData = async () => {
      try {
        setLoader(true);
        const res: AxiosResponse = await http.get(
          `/app/settings/privacy-policy?lang=en`
        );
        const newText = DOMPurify.sanitize(res.data.data.value || "");
        setPolicyData(newText);
        setLoader(false);
      } catch (err) {
        setLoader(false);
        errorToastMessage(err as Error);
      }
    };

    fetchPolicyData();
  }, []);

  return (
    <>
      <Box sx={HeaderStyle}>
        <img
          src="/header-logo.svg"
          alt="logo"
          // style={{
          //   maxWidth: "71px",
          // }}
        />
      </Box>
      <Box sx={GrayBox} />
      <Box
        sx={{
          height: "calc(100vh - 143px)",
          overflow: "auto",
          bgcolor: "#fff",
        }}
      >
        {!loader ? (
          <Box sx={PolicyContainer}>
            <Box sx={PolicyBorderedContainer}>
              <div dangerouslySetInnerHTML={{ __html: policyData }}></div>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              height: "calc(100% - 143px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default PreviewPrivacyPolicy;

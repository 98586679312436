import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import http from "../../../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../../../utils/toast";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../../../../Common/styles/modal";
import { InputWrapper } from "../../../../../ExerciseCollection/ExerciseCollection.style";
import { LabelStyle } from "../../../../../Common/styles/form";

const schema = yup.object().shape({
  doses: yup.string().optional(),
  notes: yup.string().optional(),
});

const BasicDetailsModal: React.FC<any> = ({
  showModal,
  closeModal,
  entry,
  refreshPage,
}: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      const body: any = {
        strength: values.doses,
        notes: values.notes,
      };
      setButtonLoader(true);
      let res = await http.patch(`/medication/${entry?.id}`, body);
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={"Edit Basic Details"} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            doses: entry?.strength || "",
            notes: entry?.notes || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps }: any) => (
            <Form>
              <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                <FormLabel sx={LabelStyle} htmlFor="medicationName">
                  Medication Name
                </FormLabel>
                <TextField
                  id="name"
                  placeholder="name"
                  value={entry?.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="doses">
                  Doses
                </FormLabel>
                <TextField
                  id="doses"
                  placeholder="Doses"
                  error={touched?.doses && errors?.doses ? true : false}
                  helperText={
                    touched?.doses && errors?.doses ? errors?.doses : " "
                  }
                  {...getFieldProps("doses")}
                />
              </FormControl>

              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="notes">
                  Notes
                </FormLabel>
                <TextField
                  multiline
                  minRows={2}
                  id="notes"
                  placeholder="Notes"
                  error={touched?.notes && errors?.notes ? true : false}
                  helperText={
                    touched?.notes && errors?.notes ? errors?.notes : " "
                  }
                  {...getFieldProps("notes")}
                />
              </FormControl>

              <Box sx={ModalActionButtonStyles}>
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default BasicDetailsModal;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledHeader,
  HeaderLeftContent,
  HeaderRightContent,
} from "../Common/styles/header";
import { ExpandMore } from "@mui/icons-material";
import { StyledTableCell, NoDataContainer } from "../Common/styles/table";
import { errorToastMessage } from "../../utils/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import http from "../../utils/http";
import { DateTime } from "luxon";
import { DownloadIcon } from "../Common/assets/FoodJournalIcons";
import DownloadFormatModal from "./DownloadFormatModal";
import { useAppSelector } from "../../Redux/hooks";

const AccMap = [
  { label: "Mahalo Survey Questions Answered", value: "msqa" },
  { label: "Exercise", value: "exercise" },
  { label: "chat", value: "chat" },
  { label: "Telehealth Consultation Attended", value: "tca" },
];

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BillingDetails = () => {
  const { id } = useParams();
  const { hasAdminAccess } = useAppSelector((state) => state.user);

  const startDate = useQuery().get("startDate");
  const endDate = useQuery().get("endDate");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [data, setData] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((item) => item !== panel)
      );
    };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const isoStartDate = DateTime?.fromFormat(
          startDate || "",
          "LL/dd/yyyy"
        )?.toFormat("yyyy-MM-dd");
        const isoEndDate = DateTime?.fromFormat(
          endDate || "",
          "LL/dd/yyyy"
        )?.toFormat("yyyy-MM-dd");

        const res = await http.get(
          `user-invoices/billing-cycle?userId=${id}&startDate=${isoStartDate}&endDate=${isoEndDate}`
        );
        const resData = res?.data?.data;
        const mappedData = {
          msqa: resData?.invoiceData?.questionnaire?.map((item: any) => {
            return {
              id: item?.id,
              surveyName: item?.questionnaireAnswered,
              surveyQuestionAnswered: item?.questionAnswered,
              date: DateTime?.fromISO(item?.answeredAt)?.toFormat("LL/dd/yyyy"),
              time: DateTime?.fromISO(item?.answeredAt)?.toFormat("hh:mm a"),
            };
          }),
          exercise: resData?.invoiceData?.exercise.map((item: any) => {
            return {
              id: item?.id,
              protocolName: item?.protocolName,
              exerciseName: item?.exerciseName,
              date: DateTime?.fromISO(item?.exerciseDateTime)?.toFormat(
                "LL/dd/yyyy"
              ),
              time: DateTime?.fromISO(item?.exerciseDateTime)?.toFormat(
                "hh:mm a"
              ),
            };
          }),
          chat: resData?.invoiceData?.chat.map((item: any) => {
            return {
              id: item?.id,
              physicalTherapist: item?.physicianName,
              patientName: item?.userName,
              date: DateTime?.fromISO(item?.createdAt)?.toFormat("LL/dd/yyyy"),
              time: DateTime?.fromISO(item?.createdAt)?.toFormat("hh:mm a"),
            };
          }),
          tca: resData?.invoiceData?.teleConsultation.map((item: any) => {
            return {
              id: item?.id,
              physicalTherapist: item?.physicianName,
              patientName: item?.patientName,
              date: DateTime?.fromISO(item?.startDateTime)?.toFormat(
                "LL/dd/yyyy"
              ),
              time: DateTime?.fromISO(item?.startDateTime)?.toFormat("hh:mm a"),
              duration: item?.duration,
            };
          }),
          userData: {
            name: `${
              resData?.participantDoctorData?.participant?.firstName || ""
            } ${resData?.participantDoctorData?.participant?.lastName || ""}`,
            activeDays: resData?.invoiceGeneratedFile?.activeDays?.toString(),
            billingPeriod: `${DateTime?.fromFormat(
              resData?.invoiceGeneratedFile?.billingCycleStartDate,
              "yyyy-MM-dd"
            ).toFormat("LL/dd/yyyy")} - ${DateTime?.fromFormat(
              resData?.invoiceGeneratedFile?.billingCycleEndDate,
              "yyyy-MM-dd"
            ).toFormat("LL/dd/yyyy")}`,
          },
        };

        setData(mappedData);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    if (id && startDate && endDate) getData();
  }, [id, startDate, endDate]);

  const handleBack = () => {
    navigate("/app/billing-reports");
  };

  const navigateToHistory = () => {
    navigate(`/app/billing-reports/history/${id}`);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon sx={{ color: "#637E85" }} />
          </IconButton>
          <Box>
            {loading ? (
              <Skeleton variant="text" sx={{ height: "26px" }} />
            ) : (
              <Typography fontSize={30} fontWeight="bold">
                {data?.userData?.name || ""}
              </Typography>
            )}
            <Box sx={{ display: "flex", gap: 1 }}>
              {loading ? (
                <Skeleton variant="text" sx={{ width: "8ch" }} />
              ) : (
                <Typography variant="body1" fontWeight="medium" color="#6B7280">
                  Active Days - {data?.userData?.activeDays || ""}
                </Typography>
              )}
              <Divider
                flexItem
                orientation="vertical"
                sx={{ borderColor: "#D7D7D7" }}
              />
              {loading ? (
                <Skeleton variant="text" sx={{ width: "8ch" }} />
              ) : (
                <Typography variant="body1" fontWeight="medium" color="#6B7280">
                  ({data?.userData?.billingPeriod || ""})
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={HeaderRightContent}>
          <>
            <Button variant="outlined" onClick={navigateToHistory}>
              View History
            </Button>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={() => setShowModal(true)}
            >
              Download Report
            </Button>
          </>
        </Box>
      </StyledHeader>
      <Box sx={{ mt: "24px" }}>
        {!loading ? (
          <>
            {AccMap.map((accItem) => {
              const item = accItem.value;
              const rowData = data?.[item];
              return (
                <Accordion
                  key={item}
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={expanded.includes(`panel${item}`)}
                  onChange={handleChange(`panel${item}`)}
                  sx={{
                    boxShadow: "none",
                    borderRadius: "8px !important",
                    mb: 2.5,
                    ":before": { height: 0 },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <IconButton>
                        <ExpandMore sx={{ fontSize: 24 }} />
                      </IconButton>
                    }
                    sx={{
                      ".Mui-expanded": {
                        m: "12px 0px !important",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        py: 1.5,
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        fontWeight="medium"
                        color="primary"
                        mb={0.5}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {accItem.label}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      p: 2,
                      display: "flex",
                      gap: "16px",
                      flexWrap: "wrap",
                      mb: 2,
                      borderTop: "1px solid #E0E3EB",
                    }}
                  >
                    {data && data?.[item] ? (
                      <>
                        {item === "msqa" && (
                          <>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Survey Name</StyledTableCell>
                                  <StyledTableCell>Date</StyledTableCell>
                                  <StyledTableCell>Time</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {rowData?.length > 0 && (
                                <TableBody>
                                  {rowData.map((item: any) => (
                                    <TableRow key={item?.id}>
                                      <StyledTableCell>
                                        {item?.surveyName || "-"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {item?.date || "-"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {item?.time || "-"}
                                      </StyledTableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              )}
                            </Table>
                            {rowData?.length === 0 && (
                              <NoDataContainer>
                                <Typography variant="body1" color="gray">
                                  No Data
                                </Typography>
                              </NoDataContainer>
                            )}
                          </>
                        )}
                        {item === "exercise" && (
                          <>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>
                                    Protocol Name
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    Exercise Name
                                  </StyledTableCell>
                                  <StyledTableCell>Date</StyledTableCell>
                                  <StyledTableCell>Time</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {rowData?.length > 0 && (
                                <TableBody>
                                  {rowData.map((item: any) => (
                                    <TableRow key={item?.id}>
                                      <StyledTableCell>
                                        {item?.protocolName || "-"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {item?.exerciseName || "-"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {item?.date || "-"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {item?.time || "-"}
                                      </StyledTableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              )}
                            </Table>
                            {rowData?.length === 0 && (
                              <NoDataContainer>
                                <Typography variant="body1" color="gray">
                                  No Data
                                </Typography>
                              </NoDataContainer>
                            )}
                          </>
                        )}
                        {item === "chat" && (
                          <>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {hasAdminAccess && (
                                    <StyledTableCell>
                                      Physical Therapist
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell>Date</StyledTableCell>
                                  <StyledTableCell>Time</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {rowData?.length > 0 && (
                                <TableBody>
                                  {rowData.map((item: any) => (
                                    <TableRow key={item?.id}>
                                      {hasAdminAccess && (
                                        <StyledTableCell>
                                          {item?.physicalTherapist || "-"}
                                        </StyledTableCell>
                                      )}
                                      <StyledTableCell>
                                        {item?.date || "-"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {item?.time || "-"}
                                      </StyledTableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              )}
                            </Table>
                            {rowData?.length === 0 && (
                              <NoDataContainer>
                                <Typography variant="body1" color="gray">
                                  No Data
                                </Typography>
                              </NoDataContainer>
                            )}
                          </>
                        )}
                        {item === "tca" && (
                          <>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {hasAdminAccess && (
                                    <StyledTableCell>
                                      Physical Therapist
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell>Date</StyledTableCell>
                                  <StyledTableCell>Time</StyledTableCell>
                                  <StyledTableCell>Duration</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {rowData?.length > 0 && (
                                <TableBody>
                                  {rowData.map((item: any) => (
                                    <TableRow key={item?.id}>
                                      {hasAdminAccess && (
                                        <StyledTableCell>
                                          {item?.physicalTherapist || "-"}
                                        </StyledTableCell>
                                      )}
                                      <StyledTableCell>
                                        {item?.date || "-"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {item?.time || "-"}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {item?.duration || "-"}
                                      </StyledTableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              )}
                            </Table>
                            {rowData?.length === 0 && (
                              <NoDataContainer>
                                <Typography variant="body1" color="gray">
                                  No Data
                                </Typography>
                              </NoDataContainer>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          padding: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1" color="gray">
                          No Data
                        </Typography>
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
      {showModal && (
        <DownloadFormatModal
          showModal={showModal}
          closeModal={closeModal}
          userId={id}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
};

export default BillingDetails;

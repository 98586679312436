/* eslint-disable consistent-return */
/* eslint-disable global-require */
import getDeviceHelpers from '../helpers/deviceHelpers';
import getCurrentAudioInputDeviceFactory from './currentAudioInputDevice';

export default (deps = {}) => {
  const {
    getAudioInputMediaDevices,
    getDefaultAudioInputDevice,
    hasDevice,
  } = deps.deviceHelpers || getDeviceHelpers();
  const {
    getCurrentAudioInputDevice,
    setCurrentAudioInputDevice,
  } = deps.currentAudioOutputDevice || getCurrentAudioInputDeviceFactory;

  const audioInputDevicesChangeHandler = async () => {
    const devices = await getAudioInputMediaDevices();

    // If there are no devices, let's store an empty value.
    if (devices.length === 0) {
      setCurrentAudioInputDevice('');
      return;
    }
    const currentAudioInputDevice = getCurrentAudioInputDevice();
    const isStillConnected = hasDevice(devices, currentAudioInputDevice.deviceId);
    const newDevice = getDefaultAudioInputDevice(devices);

    if (isStillConnected && (currentAudioInputDevice.label === newDevice.label)) {
      // The current output device is still connected, so we don't need to update it.
      // The default device also does not need to be updated
      return;
    }

    // Since the current output device was removed, let's use the default device.
    setCurrentAudioInputDevice(newDevice);

    return newDevice.deviceId;
  };

  return audioInputDevicesChangeHandler;
};

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { LabelStyle } from "../Common/styles/form";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { AxiosResponse } from "axios";
import { DateTime } from "luxon";

const DownloadFormatModal = ({
  showModal,
  closeModal,
  userId = "",
  startDate = "",
  endDate = "",
  refreshPage = undefined,
}: any) => {
  const [value, setValue] = useState("pdf");
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const downloadReport = async () => {
    try {
      setSubmitLoader(true);
      // const isoStartDate = DateTime?.fromFormat(startDate, "LL/dd/yyyy")
      //   ?.toUTC()
      //   ?.toISO();
      // const isoEndDate = DateTime?.fromFormat(endDate, "LL/dd/yyyy")
      //   ?.toUTC()
      //   ?.toISO();

      let res: AxiosResponse;

      if (userId) {
        const isoStartDate = DateTime?.fromFormat(
          startDate || "",
          "LL/dd/yyyy"
        )?.toFormat("yyyy-MM-dd");
        const isoEndDate = DateTime?.fromFormat(
          endDate || "",
          "LL/dd/yyyy"
        )?.toFormat("yyyy-MM-dd");

        res = await http.get(
          `/user-invoices/download-${value}?userId=${userId}&startDate=${isoStartDate}&endDate=${isoEndDate}`,
          {
            responseType: "blob",
          }
        );
      } else {
        res = await http.get(`/user-invoices/download-${value}-bulk`);
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Reports.${value}`);
      document.body.appendChild(link);
      link.click();
      closeModal();
      if (refreshPage) {
        refreshPage();
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      errorToastMessage(error as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title={"Download Report"} onCloseClick={closeModal} />
        <FormControl>
          <FormLabel sx={LabelStyle}>Choose the download format</FormLabel>
          <RadioGroup value={value} onChange={handleChange} row>
            <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            <FormControlLabel value="csv" control={<Radio />} label="CSV" />
          </RadioGroup>
        </FormControl>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 1 }}
        >
          {submitLoader ? (
            <CircularProgress size={25} />
          ) : (
            <>
              <Button variant="contained" onClick={downloadReport}>
                Download
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DownloadFormatModal;

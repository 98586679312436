const debounceDuration = 100;

class SubscriberPeerConnectionQueue {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  enqueue(process) {
    if (this.isProcessing) {
      this.queue.push(process);
    } else {
      this.isProcessing = true;
      process();
    }
  }

  debounce() {
    setTimeout(() => this.dequeueAndProcessNext(), debounceDuration);
  }

  finally(process) {
    if (this.isProcessing) {
      this.processFinal = process;
    } else {
      this.isProcessing = true;
      this.processFinal = process;
      this.debounce();
    }
  }

  dequeueAndProcessNext() {
    const nextProcess = this.queue.shift();
    if (nextProcess) {
      nextProcess();
    } else if (this.processFinal) {
      this.processFinal();
      this.processFinal = undefined;
    } else {
      this.isProcessing = false;
    }
  }
}

export default SubscriberPeerConnectionQueue;

import React, { useEffect, useRef, useState } from "react";
import OT, { Publisher, Session } from "@opentok/client";
import {
  Box,
  Button,
  Fab,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  CallEnd,
  Mic,
  MicOff,
  PictureInPictureAlt,
  Videocam,
  VideocamOff,
} from "@mui/icons-material";
import {
  ControlsWrapper,
  OpenTokIconStyle,
  PublisherIconWrapper,
  PublisherMiniWindowWrapper,
} from "./style";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";

const EndCall = () => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();

  const onOk = () => {
    setOpen(false);
    navigate(`/app/participants/${id}`);
  };

  const openDialog = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to end the call?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={onOk} autoFocus color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Fab onClick={openDialog}>
        <CallEnd htmlColor="#E9595B" sx={OpenTokIconStyle} />
      </Fab>
    </>
  );
};

const PublisherComp: React.FC<any> = ({
  session,
  creds,
  isSubscriberAvailable,
}: {
  session: Session;
  creds: any;
  isSubscriberAvailable: any;
}) => {
  const publishRef = useRef<Publisher | any>(null);

  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);
  const [inPictureInPicture, setInPictureInPicture] = useState(false);

  useEffect(() => {
    const handlePictureInPictureChange = () => {
      setInPictureInPicture(document.pictureInPictureElement !== null);
    };

    document.addEventListener(
      "enterpictureinpicture",
      handlePictureInPictureChange
    );
    document.addEventListener(
      "leavepictureinpicture",
      handlePictureInPictureChange
    );

    return () => {
      document.removeEventListener(
        "enterpictureinpicture",
        handlePictureInPictureChange
      );
      document.removeEventListener(
        "leavepictureinpicture",
        handlePictureInPictureChange
      );
    };
  }, []);

  const toggleAudio = () => {
    if (publishRef.current) {
      (publishRef.current as Publisher).publishAudio(!audio);
      setAudio((prev) => !prev);
    }
  };

  const toggleVideo = () => {
    if (publishRef.current) {
      (publishRef.current as Publisher).publishVideo(!video);
      setVideo((prev) => !prev);
    }
  };

  const resizeWindow = () => {
    const videoElement: any = document.querySelector("#ot-subscriber video");
    if (document.pictureInPictureElement === null) {
      if (videoElement?.requestPictureInPicture) {
        videoElement?.requestPictureInPicture().catch((error: any) => {
          console.error(`Failed to enter Picture-in-Picture mode: ${error}`);
        });
      } else {
        console.error("Picture-in-Picture is not supported by this browser.");
      }
    } else {
      document.exitPictureInPicture();
    }
  };

  useEffect(() => {
    const publisher = OT.initPublisher(
      "ot-publisher",
      {
        insertMode: "append",
        showControls: false,
      },
      function (error) {
        if (error) {
          toastMessage(
            "warning",
            "Please allow access to your camera and microphone."
          );
        }
      }
    );
    publishRef.current = publisher;
    const publish = () => {
      session.publish(publisher, (err: any) => {
        if (err) {
          if (err.code === 1500) {
            toastMessage("error", "Permission denied");
          } else errorToastMessage(err);
        } else {
          console.log("publishing");
        }
      });
    };
    //@ts-ignore
    if (session.isConnected()) {
      publish();
    } else {
      session.on("sessionConnected", () => {
        publish();
      });
    }

    return () => {
      if (publisher) {
        try {
          publisher.destroy();
        } catch (err) {
          console.log("Error on publisher destroy");
          console.log(err);
        }
      }
    };
  }, [session, creds]);

  return (
    <>
      <Box sx={ControlsWrapper}>
        <Box />
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Fab onClick={toggleVideo}>
            {video ? (
              <Videocam sx={OpenTokIconStyle} />
            ) : (
              <VideocamOff sx={OpenTokIconStyle} />
            )}
          </Fab>
          <Fab onClick={toggleAudio}>
            {audio ? (
              <Mic sx={OpenTokIconStyle} />
            ) : (
              <MicOff sx={OpenTokIconStyle} />
            )}
          </Fab>
          <EndCall />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 4,
            width: "60px",
          }}
        >
          {!inPictureInPicture && isSubscriberAvailable && (
            <Fab onClick={resizeWindow}>
              <PictureInPictureAlt sx={OpenTokIconStyle} />
            </Fab>
          )}
        </Box>
      </Box>
      <Box
        sx={
          isSubscriberAvailable
            ? PublisherMiniWindowWrapper
            : { height: "100%" }
        }
      >
        <Box
          id="ot-publisher"
          className="ot-publisher"
          sx={{
            display: inPictureInPicture ? "none" : "block",
            height: "100%",
            "& > div": {
              height: isSubscriberAvailable
                ? "268px !important"
                : "100% !important",
              width: isSubscriberAvailable
                ? "268px !important"
                : "100% !important",
            },
          }}
        />

        {isSubscriberAvailable && (
          <Box sx={{ position: "absolute", top: 10, right: 10 }}>
            {!audio && (
              <Box sx={PublisherIconWrapper}>
                <MicOff htmlColor="#fff" />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default React.memo(PublisherComp);

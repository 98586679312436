import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LabelStyle } from "../../Common/styles/form";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";
import { NoDataContainer } from "../../Common/styles/table";
import { useAppSelector } from "../../../Redux/hooks";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const ClinicOverview = () => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { role } = useAppSelector((state) => state.user);
  const { id } = useParams();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const param = role === "clinician" ? "me" : id;
        const res: AxiosResponse = await http.get(`/clinic/${param}`);
        const response = res.data.data;
        setData({
          id: response?.id,
          name: response?.name,
          clinicName: response?.name,
          primaryContact: response?.primaryContact,
          email: response?.email,
          phoneNumber: response?.phoneNumber,
          address: response?.addressStreet,
          city: response?.addressCity,
          state: response?.addressState,
          addressPostalCode: response?.addressPostalCode,
          countryISOCode: response?.countryISOCode,
          contactPosition: response?.contactPosition,
          fulfilmentScheme: response?.fulfilmentScheme,
          sponsorId: response?.sponsorId,
          clinicNotes: response?.clinicNotes,
          principalClinicianName: response?.principalClinicianName,
          principalClinicianPosition: response?.principalClinicianPosition,
          principalClinicianPhone: response?.principalClinicianPhone,
          timezone: response?.timezone,
          region: response?.region,
          patientType: response?.patientType,
          fulfilmentTypeId: response?.fulfilmentTypeId,
          fulfilmentTypeLabel: response?.fulfilmentTypeLabel,
          testClinic: response?.testClinic,
          summaryEnabled: response?.summaryEnabled,
          kelvinStartDate: response?.kelvinStartDate,
          kelvinEndDate: response?.kelvinEndDate,
          contactEmail: response?.contactEmail,
          principalClinicianEmail: response?.principalClinicianEmail,
        });
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    if (id || role === "clinician") {
      fetchDetails();
    } else {
      setData({});
      setLoading(false);
    }
  }, [id, setData, setLoading, role]);

  return (
    <Box p={4} bgcolor={"#FFFFFF"}>
      {!loading && data.id && (
        <Box
          sx={{
            display: "grid",
            gap: 2,
          }}
        >
          <Box>
            <Typography sx={LabelStyle}>Primary Contact</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data.primaryContact}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Email</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data.email}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Phone Number</Typography>
            <PhoneInput
              value={data?.phoneNumber}
              copyNumbersOnly={false}
              containerStyle={{
                width: "100%",
              }}
              inputStyle={{
                border: "none",
                backgroundColor: "transparent",
                paddingLeft: 0,
                width: "fit-content",
                fontWeight: 400,
                fontSize: "14px",
              }}
              buttonStyle={{
                display: "none",
              }}
              disableDropdown={true}
              inputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Address</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.address}, {data?.city}, {data?.state} -{" "}
              {data?.addressPostalCode}, {data?.countryISOCode}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Fulfilment Scheme</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.fulfilmentScheme ? "Yes" : "No"}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Sponsor ID</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.sponsorId}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Clinic Notes</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.clinicNotes}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Principal Clinician Name</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.principalClinicianName}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>
              Principal Clinician Position
            </Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.principalClinicianPosition ? "Yes" : "No"}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Principal Clinician Phone</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.principalClinicianPhone}
            </Typography>
          </Box>

          <Box>
            <Typography sx={LabelStyle}>Principal Clinician Email</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.principalClinicianEmail}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Time Zone</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.timezone}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Region</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.region}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Patient Type</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.patientType}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Fulfilment ID</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.fulfilmentTypeId}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Fulfilment Label</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.fulfilmentTypeLabel}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Region</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.region}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Test Clinic</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.testClinic ? "Yes" : "No"}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Summary Enabled</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.summaryEnabled ? "Yes" : "No"}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Kelvin Start Date</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.kelvinStartDate}
            </Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Kelvin End Date</Typography>
            <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
              {data?.kelvinEndDate}
            </Typography>
          </Box>
          {data?.contactEmail && (
            <Box display={"flex"} gap={18}>
              <Box>
                <Typography sx={LabelStyle}>Contact Email</Typography>
                <Typography sx={{ ...LabelStyle, fontWeight: "400" }}>
                  {data?.contactEmail}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}

      {!loading && !data.id && (
        <NoDataContainer>
          <Typography variant="body2" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default ClinicOverview;

import { Box, Typography } from "@mui/material";
import { DashboardCardStyle } from "../../../../Dashboard/dashboard.style";

type Props = {
  data: any;
};

const AnalysisItem = ({ data }: Props) => {
  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // borderRadius: 2,
        // padding: 2,
        // overflow: "hidden",
        // gap: 2,
        //  maxWidth: "600px",
        ...DashboardCardStyle,
        minHeight: "200px",
        boxShadow: "none",
        border: 1,
        borderColor: "divider",
        p: 4,
      }}
    >
      <Box
        sx={{
          border: 1,
          borderColor: "#355962",
          backgroundColor: "#DBF5F2",
          borderRadius: 2,
          width: "fit-content",
          padding: "10px 20px",
        }}
      >
        <Typography
          fontSize={24}
          fontWeight="medium"
          color="#355962"
          component="span"
        >
          {data?.value}
        </Typography>
      </Box>
      <Typography fontSize={24} fontWeight="medium" color="#355962">
        {data?.title}
      </Typography>
      <Typography fontSize={20} fontWeight="medium" color="#6B7280">
        {data?.notes}
      </Typography>

      {/* {progress && (
          <CircularProgressWithLabel value={progress} size={80} fontSize={20} />
        )} */}
    </Box>
  );
};

export default AnalysisItem;

import { Badge, Box, IconButton, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { initializeFirebaseApp } from "../../utils/firebase";
import { BellIcon, ChatIcon } from "../Common/assets/HeaderIcons";
import ChatNotifications from "./ChatNotifications";
import NotificationList from "./NotificationList";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { DateTime } from "luxon";
import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import { app } from "../../utils/firebaseInit";
import {
  setRefreshNotifTable,
  setRefreshNotification,
} from "../../Redux/reducers/notificationSlice";

const playAudio = () => {
  try {
    const audio = new Audio("/Notification.mp3");
    audio.play();
  } catch (err) {}
};

const Header = () => {
  const { refreshNotifications } = useAppSelector(
    (state) => state.notification
  );
  const [unreadChatCount, setUnreadChatCount] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [anchorElChat, setAnchorElChat] = useState<null | HTMLElement>(null);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [chatNotificationData, setChatNotificationData] = useState<any[]>([]);

  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();

  const handleChatClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElChat(event.currentTarget);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(anchorElNotification ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElNotification(null);
    setAnchorElChat(null);
  };

  useEffect(() => {
    initializeFirebaseApp();
  }, []);

  const getDate = (date: any) => {
    const formattedDate = date
      ? DateTime.fromISO(date).toFormat("dd/LL/yy")
      : "";

    const isToday =
      date && DateTime.fromISO(date).hasSame(DateTime.now(), "day");
    const isYesterday =
      date &&
      DateTime.fromISO(date).hasSame(DateTime.now().minus({ days: 1 }), "day");

    const dateString = isToday
      ? "Today"
      : isYesterday
      ? "Yesterday"
      : formattedDate;

    return dateString;
  };

  useEffect(() => {
    const getMessage = async (listener: any) => {
      const messaging = await isSupported()
        .then((support) => {
          if (support) {
            return getMessaging(app);
          } else {
            return false;
          }
        })
        .catch(() => {
          return null;
        });
      try {
        if (messaging) {
          onMessage(messaging, () => {
            // setRefreshPage((prev: any) => !prev);
            dispatch(setRefreshNotification());
            dispatch(setRefreshNotifTable());
            playAudio();
          });
          navigator.serviceWorker.addEventListener("message", listener);
        }
      } catch (err) {
        console.error(err);
      }
    };
    const listener = (event: any) => {
      if (event.data.message === "notification_received") {
        // setRefreshPage(;)
        dispatch(setRefreshNotification());
        dispatch(setRefreshNotifTable());
      } else if (event.data.message === "notification_sound") {
        playAudio();
      }
    };
    getMessage(listener);
    return () => {
      navigator.serviceWorker.removeEventListener("message", listener);
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchNotificationData = async () => {
      try {
        const url = `/notifications`;
        const res: AxiosResponse = await http.get(url);
        const resNotificationData = res.data?.data?.notifications;
        const newNotificationData = resNotificationData?.map((item: any) => {
          return {
            ...item,
            heading: item?.title,
            time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
            date: getDate(item?.createdAt),
            read: item?.isRead,
          };
        });

        setUnreadNotificationCount(res.data?.data?.unreadCount);
        setNotificationData(newNotificationData);
      } catch (error) {
        errorToastMessage(error as Error);
      }
    };
    fetchNotificationData();
  }, [refreshNotifications]);

  useEffect(() => {
    //Chat Notification Get
    const fetchChatNotifcation = async () => {
      try {
        const url = `/notifications/chat`;
        const res: AxiosResponse = await http.get(url);
        const newChatData = res?.data?.data?.notifications?.map((item: any) => {
          return {
            id: item?.id,
            patientId: item?.data?.data?.participantId,
            patientCode:
              item?.data?.data?.firstName + " " + item?.data?.data?.lastName,
            gender: item?.data?.data?.gender,
            message: item?.data?.params?.message,
            time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
            isRead: item?.isRead,
            day: getDate(item?.createdAt),
          };
        });
        setChatNotificationData(newChatData);
        setUnreadChatCount(res.data?.data?.unreadCount);
      } catch (error) {
        errorToastMessage(error as Error);
      }
    };
    fetchChatNotifcation();
  }, [refreshNotifications]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 3,
        height: "50px",
      }}
    >
      <Box>
        <IconButton onClick={handleNotificationClick}>
          <Badge
            badgeContent={unreadNotificationCount}
            color="secondary"
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                border: "2px solid #FFFFFF",
              },
            }}
          >
            <BellIcon />
          </Badge>
        </IconButton>

        <IconButton onClick={handleChatClick}>
          <Badge
            badgeContent={unreadChatCount}
            color="secondary"
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                border: "2px solid #FFFFFF",
              },
            }}
          >
            <ChatIcon />
          </Badge>
        </IconButton>
      </Box>

      <Popover
        open={Boolean(anchorElChat)}
        anchorEl={anchorElChat}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ChatNotifications
          setAnchorElChat={setAnchorElChat}
          data={chatNotificationData}
          unreadChatCount={unreadChatCount}
        />
      </Popover>

      <Popover
        open={Boolean(anchorElNotification)}
        anchorEl={anchorElNotification}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <NotificationList
          data={notificationData}
          setAnchorElNotification={setAnchorElNotification}
          unreadNotificationCount={unreadNotificationCount}
        />
      </Popover>
    </Box>
  );
};

export default Header;

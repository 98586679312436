import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { useState } from "react";
import RearrangeSectionModal from "../modals/RearrangeSectionModal";
import { errorToastMessage } from "../../../utils/toast";
import {
  setPreviewMode,
  setSectionInLesson,
} from "../../../Redux/reducers/cmsBuilderV2Slice";
import { educationRequestForPreview } from "../../../utils/educationV2";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Header = () => {
  const id = useParams().id || "";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pageId = useQuery().get("pageId") || "";
  const type = useQuery().get("type") || "";
  const [showModal, setShowModal] = useState(false);

  const { sections } = useAppSelector((state) => state.cmsBuilderV2);

  const handleBack = () => {
    navigate(-1);
  };

  const showPreview = () => {
    try {
      const sectionResult = educationRequestForPreview(sections);

      dispatch(
        setSectionInLesson({
          sections: sectionResult,
          key: pageId,
        })
      );
      dispatch(setPreviewMode(true));
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 0 }}>
          <ChevronLeft
            onClick={handleBack}
            sx={{ cursor: "pointer", height: 50, width: 50, mr: 1 }}
          />
          <Typography
            fontSize={24}
            fontWeight="medium"
            textTransform="capitalize"
          >
            {type || "Add"} Page
          </Typography>
        </Box>
        <Box sx={{ ...HeaderRightContent, gap: 1 }}>
          {sections.length > 1 && (
            <Button onClick={openModal} variant="outlined" sx={{ mr: 1.5 }}>
              Rearrange
            </Button>
          )}
          {sections.length > 0 && (
            <Button onClick={showPreview} variant="contained" sx={{ mr: 1.5 }}>
              Preview
            </Button>
          )}
        </Box>
      </StyledHeader>
      {showModal && (
        <RearrangeSectionModal
          closeModal={closeModal}
          lessonId={id}
          pageId={pageId}
          title="Rearrange Sections"
          items={sections}
        />
      )}
    </>
  );
};

export default Header;

import envDefault from '../env';
import createVideoElementUsingNativeAPIDefault from './createVideoElementUsingNativeAPI';
import createVideoElementForSafariDefault from './createVideoElementForSafari';

// Tries to return an always autoplayable video element
const createVideoElement = (deps = {}) => {
  // The mocked `env` can be set to `undefined` to test some edge case(s), hence
  // the use of `hasOwnProperty` instead of the other patterns below.
  // eslint-disable-next-line no-prototype-builtins
  const env = deps.hasOwnProperty('env')
    ? deps.env
    : envDefault;
  const createVideoElementUsingNativeAPI =
    deps.createVideoElementUsingNativeAPI || createVideoElementUsingNativeAPIDefault;
  const createVideoElementForSafari =
    deps.createVideoElementForSafari || createVideoElementForSafariDefault;

  // See VIDCS-1523
  // Safari requires special handling to ensure that the video element is
  // autoplayable.
  if (env?.isSafari) {
    return createVideoElementForSafari();
  }

  // For most browsers, once any video element is granted autoplay permission, then
  // all video elements in the execution context inherit the same permissions.  This
  // is true when if the video element was created after the browser tab/window
  // loses focus and was backgrounded.
  return createVideoElementUsingNativeAPI();
};

export default createVideoElement;

import { SxProps } from "@mui/material";

export const ProfileCard: SxProps = {
  bgcolor: "#fff",
  borderRadius: "8px",
  border: 1,
  p: 3,
  mt: 3,
  borderColor: "divider",
  height: "calc(100% - 170px)",
  overflow: "auto",
};

export const imageTheme: SxProps = {
  backgroundColor: "rgba(222, 141, 51, 0.10)",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#DE8D33",
  borderRadius: "6px",
  width: "120px",
  height: "120px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 3,

  "& .preview-image": {
    height: "90px",
    width: "90px",
    objectFit: "contain",
  },
};

export const circleTheme: SxProps = {
  position: "absolute",
  bottom: "-15px",
  right: "-15px",
  padding: "5px",
  zIndex: 1,
  backgroundColor: "white",
  borderRadius: "50%",
  width: "35px",
  height: "35px",
  borderColor: "#D714401A",
  borderStyle: "solid",
  borderWidth: "1px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

export const AppleButton: SxProps = {
  marginTop: -1.5,
  width: 65,
  "& .MuiSwitch-thumb": {
    marginTop: 0.5,
    // marginLeft: 0.6,
    backgroundColor: "white",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    height: 24,
    width: 65,
    borderRadius: 14,
    backgroundColor: "#6B7280",
    opacity: 1,
  },

  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#65C466",
    height: 24,
    opacity: 1,
    borderRadius: 14,
    width: 65,
  },
};

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { useNavigate } from "react-router-dom";
import { fetchExerciseLibraryData } from "../../../Redux/actions/exerciseCollectionAction";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  CardAvatar,
  CardMainText,
  ExerciseDetails,
  LoadingContainer,
  ModuleCardStyle,
  ModuleCardsContainer,
} from "../ExerciseCollection.style";
import {
  Delete,
  Edit,
  MoreVertRounded,
  PhotoSizeSelectActualOutlined,
} from "@mui/icons-material";
import {
  CustomPagination,
  NoDataContainer,
  paddedPageSize,
} from "../../Common/styles/table";
import {
  setExerciseCollectionLoader,
  setExercisePage,
  toggleExerciseCollection,
} from "../../../Redux/reducers/exerciseCollectionSlice";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
type Props = {
  hasModifyAccess:boolean
}
const ExerciseLibraryTab = ({hasModifyAccess}:Props) => {
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const {
    exerciseLibraryData,
    exerciseLibraryDataCount,
    loading,
    toggler,
    page,
    searchText,
  } = useAppSelector((state) => state.exerciseCollection);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  useEffect(() => {
    dispatch(fetchExerciseLibraryData(page, searchText));
  }, [dispatch, toggler, page, searchText]);

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedModule(null);
  };

  const handleEdit = () => {
    const targetId = selectedModule?.id;
    navigate(`/app/exercise/builder?id=${targetId}`);
  };
  const handleDelete = async () => {
    const targetId = selectedModule?.id;
    try {
      dispatch(setExerciseCollectionLoader(true));
      const res: AxiosResponse = await http.delete(`/exercise/${targetId}`);
      toastMessage("success", res.data.message);
      closeMenu();
      dispatch(toggleExerciseCollection());
    } catch (err) {
      dispatch(setExerciseCollectionLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const handlePreview = (id: string) => {
    navigate(`/app/exercise/builder?id=${id}`);
  };

  const handleChange = (_: any, value: number) => {
    dispatch(setExercisePage(value - 1));
  };

  return !loading ? (
    <>
      {exerciseLibraryData.length > 0 ? (
        <Box>
          <Box sx={ModuleCardsContainer}>
            {exerciseLibraryData?.map((module: any) => (
              <Box
                key={module.id}
                sx={{ ...ModuleCardStyle }}
                onClick={() => handlePreview(module.id)}
              >
                <Avatar src={module?.imageUrl} sx={CardAvatar}>
                  <PhotoSizeSelectActualOutlined
                    sx={{ width: "45px", height: "45px" }}
                  />
                </Avatar>
                <Box sx={ExerciseDetails}>
                  <Typography
                    fontSize={"24px"}
                    fontWeight="medium"
                    sx={CardMainText}
                  >
                    {module?.title}
                  </Typography>
                </Box>
               {hasModifyAccess && <Box sx={{ ml: "auto" }}>
                  <IconButton onClick={(e) => openMenu(e, module)}>
                    <MoreVertRounded />
                  </IconButton>
                </Box>}
              </Box>
            ))}
          </Box>

          {exerciseLibraryDataCount > paddedPageSize && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginY: "20px",
              }}
            >
              <CustomPagination
                count={Math.ceil(exerciseLibraryDataCount / paddedPageSize)}
                variant="outlined"
                shape="rounded"
                page={page + 1}
                onChange={handleChange}
              />
            </Box>
          )}
        </Box>
      ) : (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem key="edit" onClick={handleEdit}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem key="delete" onClick={handleDelete}>
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default ExerciseLibraryTab;

import React, { useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  StyledHeader,
  HeaderLeftContent,
  HeaderRightContent,
} from "../Common/styles/header";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import ExerciseCollectionTab from "./Tabs/ExerciseCollectionTab";
import ExerciseLibraryTab from "./Tabs/ExerciseLibraryTab";
import ExerciseCollectionModal from "./Modals/ExerciseCollectionModal";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setExerciseCollectionModalId,
  setExerciseSearchText,
  setExerciseType,
  toggleExerciseCollection,
} from "../../Redux/reducers/exerciseCollectionSlice";
import { useNavigate } from "react-router-dom";
import ExerciseUrlLoader from "./ExerciseUrlLoader";
import ExerciseUrlSetter from "./ExerciseUrlSetter";
import { debounce } from "lodash";
import { useMemo } from "react";
import RearrangeExerciseModal from "./Sections/RearrangeExerciseModal";
import { canModifyExercises } from "../../utils/roles";
import SearchIcon from "@mui/icons-material/Search";

const ExerciseCollection = () => {
  const [showRearrangeModal, setShowRearrangeModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    exerciseCollectionModalId,
    type,
    urlLoaded,
    searchText,
    exerciseCollectionData,
  } = useAppSelector((state) => state.exerciseCollection);
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess] = useState(canModifyExercises(userRole));
  const handleChange = (_: any, newValue: string) => {
    dispatch(setExerciseType(newValue));
  };

  const showModal = () => {
    dispatch(setExerciseCollectionModalId("new"));
  };

  const closeRearrangeModal = () => {
    setShowRearrangeModal(false);
  };

  const refreshPage = () => {
    dispatch(toggleExerciseCollection());
  };

  const handleNavigate = () => {
    navigate("/app/exercise/builder");
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setExerciseSearchText(val));
      }, 500),
    [dispatch]
  );

  return urlLoaded ? (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Exercise
          </Typography>
        </Box>
        {hasModifyAccess && (
          <Box sx={HeaderRightContent}>
            {type === "collection" && (
              <Box sx={HeaderRightContent}>
                {exerciseCollectionData?.length > 1 && (
                  <Button
                    variant="outlined"
                    onClick={() => setShowRearrangeModal(true)}
                  >
                    Rearrange
                  </Button>
                )}
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={showModal}
                >
                  Add Collection
                </Button>
              </Box>
            )}
            {type === "library" && (
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleNavigate}
              >
                Add Exercise
              </Button>
            )}
          </Box>
        )}
      </StyledHeader>
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="Collection" value="collection" {...a11yProps(0)} />
          <StyledTab label="Library" value="library" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        {type === "library" && (
          <Box
            sx={{
              padding: "16px",
              bgcolor: "#FFFFFF",
              borderRadius: "8px",
            }}
          >
            <TextField
              onChange={(e) => modifySearchTerm(e.target.value)}
              style={{ width: "70%" }}
              placeholder="Search for Exercises"
              defaultValue={searchText}
              key={type}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        <MainTabPanel value={type} index={"collection"}>
          <ExerciseCollectionTab hasModifyAccess={hasModifyAccess} />
        </MainTabPanel>
        <MainTabPanel value={type} index={"library"}>
          <ExerciseLibraryTab hasModifyAccess={hasModifyAccess} />
        </MainTabPanel>
      </Box>
      {exerciseCollectionModalId && <ExerciseCollectionModal />}
      {showRearrangeModal && (
        <RearrangeExerciseModal
          callback={refreshPage}
          closeModal={closeRearrangeModal}
          items={exerciseCollectionData}
          title="Rearrange Collections"
          type="collections"
        />
      )}
      <ExerciseUrlSetter />
    </>
  ) : (
    <ExerciseUrlLoader />
  );
};

export default ExerciseCollection;

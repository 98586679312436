import React, { useEffect } from "react";
import HealthHeader from "./HealthHeader";
import HealthTabs from "./HealthTabs";
import HealthDateSelector from "./HealthDateSelector";
// import HealthGraph from "./HealthGraph";
// import HealthMetrics from "./HealthMetrics";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setHealthData,
  setHealthLoading,
} from "../../../Redux/reducers/healthSlice";
import { DateTime } from "luxon";
import HealthBlockSelector from "./HealthBlockSelector";

const Health: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading, type } = useAppSelector((state) => state.health);
  useEffect(() => {
    dispatch(
      setHealthData({
        start: DateTime.now().plus({ days: -7 }).toFormat("LL-dd-yyyy"),
        end: DateTime.now().toFormat("LL-dd-yyyy"),
      })
    );
    return () => {
      dispatch(setHealthLoading(true));
    };
  }, [dispatch]);

  return (
    <>
      <HealthHeader />
      <HealthTabs />
      {loading ? null : (
        <>
          {type !== "patient_overview" &&
            type !== "chat" &&
            type !== "exercise_analysis" && <HealthDateSelector />}
          <HealthBlockSelector />
        </>
      )}
    </>
  );
};

export default Health;

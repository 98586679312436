import {
  Delete,
  Edit,
  // FilterAlt
} from "@mui/icons-material";
import {
  Box,
  // Chip,
  CircularProgress,
  // Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  // Radio,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteLearningModule,
  fetchLearningData,
} from "../../../../Redux/actions/cmsAction";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  setModalLearningId,
  // setLanguageFilter,
  // clearLanguageFilter,
} from "../../../../Redux/reducers/cmsSlice";
import {
  LoadingContainer,
  ModuleCardsContainer,
  ModulesHeaderContainer,
} from "../../cms.style";
import ModuleCard from "../ModuleCard";
// import {LANGUAGES,langMap } from "../../../../utils/lang";
type Props = {
  hasModifyAccess: boolean;
};
const LearningModules = ({ hasModifyAccess }: Props) => {
  const dispatch = useAppDispatch();
  const {
    learningData,
    searchText,
    loading,
    toggler,
    // languageFilter
  } = useAppSelector((state) => state.cms);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedModule, setSelectedModule] = useState<any>(null);
  // const [languageFilterEl, setLanguageFilterEl] = useState<null | HTMLElement>(
  //   null
  // );
  // const openLanguageFilter = Boolean(languageFilterEl);
  // const openLanguageFilterMenu = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   setLanguageFilterEl(event.currentTarget);
  // };
  // const closeLanguageFilterMenu = () => {
  //   setLanguageFilterEl(null);
  // };
  // const handleLanguageFilter = (value: string) => {
  //   dispatch(setLanguageFilter(value));
  //   setLanguageFilterEl(null);
  // };

  // const clearLangFilter = () => {
  //   dispatch(clearLanguageFilter());
  //   setLanguageFilterEl(null);
  // };
  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedModule(null);
  };

  useEffect(() => {
    dispatch(fetchLearningData(searchText));
  }, [dispatch, searchText, toggler]);

  const handleAction = (action: string, module?: any) => {
    const targetId = selectedModule?.id;
    if (action === "click") {
      navigate("/app/cms/learning/" + module.id);
    } else if (action === "edit" && targetId) {
      closeMenu();
      dispatch(setModalLearningId(targetId));
    } else if (action === "delete" && targetId) {
      closeMenu();
      dispatch(deleteLearningModule(targetId));
    }
  };

  return !loading ? (
    <>
      <Box sx={ModulesHeaderContainer}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography variant="subtitle1" fontWeight={600}>
            {learningData.length} Learning Module
            {learningData.length > 1 && "s"}
          </Typography>
          {/* {languageFilter?.map((filter: string) => (
            <Chip
              key={filter}
              label={langMap[filter]}
              onDelete={() => clearLangFilter()}
              variant="outlined"
              sx={{
                fontWeight: 600,
                fontSize: 16,
                textTransform: "capitalize",
              }}
            />
          ))} */}
        </Box>
        {/* <Button
          variant="outlined"
          startIcon={<FilterAlt />}
          onClick={openLanguageFilterMenu}
        >
          Language
        </Button> */}
      </Box>
      {learningData.length > 0 ? (
        <Box
          sx={{
            height: "calc(100vh - 380px)",
            overflow: "auto",
            px: 1,
          }}
        >
          <Box sx={ModuleCardsContainer}>
            {learningData?.map((module: any) => (
              <ModuleCard
                key={module?.id}
                module={module}
                type="learning"
                handleAction={handleAction}
                openMenu={openMenu}
                hasModifyAccess={hasModifyAccess}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", padding: "5vh" }}>
          <Typography variant="h2">
            {searchText ? "No matches found" : "No learning modules present"}
          </Typography>
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => {
            handleAction("edit");
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          key="delete"
          onClick={() => {
            handleAction("delete");
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      {/* <Menu
        anchorEl={languageFilterEl}
        open={openLanguageFilter}
        onClose={closeLanguageFilterMenu}
        sx={{
          "& .MuiPaper-root": {
            width: 200,
          },
        }}
      >
        {LANGUAGES.map((language) => (
          <MenuItem
            key={language.value}
            onClick={() => handleLanguageFilter(language.value)}
          >
            <ListItemIcon>
              <Radio checked={languageFilter.includes(language.value)} />
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {language.label}
            </ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={clearLangFilter}>Clear Filter</MenuItem>
      </Menu> */}
    </>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default LearningModules;

import {
  Modal,
  Box,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { Form, Formik } from "formik";
import { LabelStyle } from "../Common/styles/form";
import { InputWrapper } from "../ExerciseCollection/ExerciseCollection.style";
import * as yup from "yup";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { useAppSelector } from "../../Redux/hooks";

const schema = yup.object().shape({
  activityDays: yup.number().min(0, "*Must not be negative number"),
  activityMinutes: yup.number().min(0, "*Must not be negative number"),
});

const BillingFilterModal = ({
  showModal,
  closeModal,
  setActivityDays,
  setActivityMins,
  activityDays,
  activityMins,
  physioId,
  physioName,
  setPhysioId,
  setPhysioName,
  setPage,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const { hasAdminAccess } = useAppSelector((state) => state.user);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          setOptions([]);
          setLoading(true);
          let url = `/coach?search=${value}&status=active`;

          const res: AxiosResponse = await http.get(url);
          const coaches = res.data?.data.map((coach: any) => {
            return {
              id: coach?.id,
              label: coach?.firstName + " " + coach?.lastName,
            };
          });
          setOptions(coaches || []);
          setLoading(false);
        } catch (err) {
          errorToastMessage(err as Error);
          setLoading(false);
        }
      }, 500),
    []
  );

  useEffect(() => {
    if (hasAdminAccess) {
      handleSearch("");
    }
  }, [handleSearch, hasAdminAccess]);

  const handleSubmit = (values: any) => {
    setActivityDays(values.activityDays?.toString() || "");
    setActivityMins(values.activityMinutes?.toString() || "");
    if (hasAdminAccess) {
      setPhysioId(values?.physio?.id || "");
      setPhysioName(values?.physio?.label || "");
    }
    closeModal();
    setPage(0);
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: 0,
          // width: "max-content",
        }}
      >
        <ModalHeader title={`Filters`} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            activityDays: activityDays || "",
            activityMinutes: activityMins || "",
            physio:
              physioId && hasAdminAccess
                ? {
                    id: physioId,
                    label: physioName,
                  }
                : null,
          }}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={schema}
        >
          {({ values, errors, touched, setFieldValue, getFieldProps }: any) => (
            <Form>
              {/* <FormControl sx={InputWrapper}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                    }}
                  >
                    <FormLabel sx={LabelStyle}>From Date</FormLabel>
                    <Box sx={{ display: "flex", gap: 1.5 }}>
                      <DatePicker
                        inputFormat="LL/dd/yyyy"
                        value={values?.fromDate ? values?.fromDate : null}
                        onChange={(newValue: any) => {
                          setFieldValue("fromDate", newValue);
                          if (values?.toDate && values?.toDate < newValue) {
                            setFieldValue("toDate", null);
                          }
                        }}
                        renderInput={(
                          params: JSX.IntrinsicAttributes & TextFieldProps
                        ) => (
                          <TextField
                            {...params}
                            sx={{ width: "100%" }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                              placeholder: "Select date",
                            }}
                            error={
                              touched?.fromDate && errors?.fromDate
                                ? true
                                : false
                            }
                            helperText={
                              touched?.fromDate && errors?.fromDate
                                ? errors?.fromDate
                                : " "
                            }
                          />
                        )}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "50%",
                    }}
                  >
                    <FormLabel sx={LabelStyle}>To Date</FormLabel>
                    <Box sx={{ display: "flex", gap: 1.5 }}>
                      <DatePicker
                        inputFormat="LL/dd/yyyy"
                        shouldDisableDate={(date) => {
                          return date < values.fromDate;
                        }}
                        value={values?.toDate ? values?.toDate : null}
                        onChange={(newValue: any) => {
                          setFieldValue("toDate", newValue);
                        }}
                        renderInput={(
                          params: JSX.IntrinsicAttributes & TextFieldProps
                        ) => (
                          <TextField
                            {...params}
                            sx={{ width: "100%" }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                              placeholder: "Select date",
                            }}
                            error={
                              touched?.toDate && errors?.toDate ? true : false
                            }
                            helperText={
                              touched?.toDate && errors?.toDate
                                ? errors?.toDate
                                : " "
                            }
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </FormControl> */}
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="actDays">
                    Activity days
                  </FormLabel>
                  <TextField
                    id="actDays"
                    placeholder="Enter days"
                    type="number"
                    {...getFieldProps("activityDays")}
                    error={
                      touched?.activityDays && errors?.activityDays
                        ? true
                        : false
                    }
                    helperText={
                      touched?.activityDays && errors?.activityDays
                        ? errors?.activityDays
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="actMins">
                    Activity Minutes
                  </FormLabel>
                  <TextField
                    id="actMins"
                    placeholder="Enter minutes"
                    type="number"
                    {...getFieldProps("activityMinutes")}
                    error={
                      touched?.activityMinutes && errors?.activityMinutes
                        ? true
                        : false
                    }
                    helperText={
                      touched?.activityMinutes && errors?.activityMinutes
                        ? errors?.activityMinutes
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              {hasAdminAccess && (
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="title">
                    Physical Therapist
                  </FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") handleSearch(value);
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("physio", value);
                    }}
                    options={options}
                    value={values?.physio || null}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    loading={loading}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    clearOnBlur={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search for Physical Therapist"
                      />
                    )}
                  />
                </FormControl>
              )}
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 1,
                }}
              >
                <Button variant="contained" type="submit">
                  Save
                </Button>
                <Button variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default BillingFilterModal;
